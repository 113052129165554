import React from "react";
import find from "lodash/find";
import some from "lodash/some";
import { roles } from "settings";

export const RoleCell = ({ data }) => (
  <>
    {find(roles, (role) => some(data, (userRole) => role.value === userRole))
      ?.label || "-"}
  </>
);

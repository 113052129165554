import React, { useCallback } from "react";
import _every from "lodash/every";
import { Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  csvFormat,
  documentsSize3Mb,
  fieldCannotBeEmpty,
  fileSize3Mb,
  palette
} from "settings";

import { isCSV } from "helpers/validation";
import { useDropzone } from "react-dropzone";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";

const templateLink = window?.location?.host.includes("raise")
  ? "https://api.getslide.com/static/assets/recipients_template.csv"
  : "https://api.getslidestaging.com/static/assets/recipients_template.csv";

export const UploadDropzone = ({
  control,
  fieldName = "file",
  setValue,
  watch,
  formState: { errors },
  setError,
  onDropAcceptedFiles,
  required = false,
  multiple = false,
  accepted = { "image/jpeg": [], "image/png": [] },
  dropzoneText = "Before uploading a recipient list, please review the template. Also you can drag a file here to upload."
}) => {
  const files = watch(fieldName);
  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles?.length > 0) {
        switch (rejectedFiles[0].errors[0].code) {
          case "file-invalid-type":
            setError(fieldName, {
              type: "manual",
              message: csvFormat
            });
            break;
          default:
            setError(fieldName, {
              type: "manual",
              message: rejectedFiles[0].errors[0].message
            });
        }
      }
      if (acceptedFiles?.length > 0) {
        if (onDropAcceptedFiles) onDropAcceptedFiles(acceptedFiles);
        setValue(fieldName, [...acceptedFiles, ...files], {
          shouldValidate: true
        });
      }
    },
    [files]
  );

  const dropzoneValidator = (file) => {
    if (file.size > fileSize3Mb) {
      return {
        code: "file-too-large",
        message: documentsSize3Mb
      };
    }
    return null;
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple,
    accept: accepted,
    onDrop,
    validator: dropzoneValidator
  });

  return (
    <FormControl variant="filled" error={!!errors[fieldName]}>
      <Controller
        name={fieldName}
        control={control}
        rules={{
          required: fieldCannotBeEmpty,
          validate: {
            lessThan3MB: (files) =>
              _every(files, (o) => o.size < fileSize3Mb) || documentsSize3Mb,
            acceptedFormats: (files) =>
              _every(files, (o) => isCSV(o)) || csvFormat
          }
        }}
        defaultValue=""
        render={() => (
          <Box
            sx={{
              background: palette.grayscale["100"],
              padding: "24px",
              cursor: "pointer",
              textAlign: "center"
            }}
            {...getRootProps({ className: "dropzone" })}
          >
            <svg
              width={48}
              height={48}
              stroke={palette.primary.main}
              style={{ minWidth: 48, margin: "0 auto" }}
            >
              <use xlinkHref={"#upload"} />
            </svg>
            <Typography
              mt={"16px"}
              variant={"smallTitle"}
              fontWeight={700}
              color={"grayscale.300"}
            >
              {dropzoneText}
            </Typography>
            <Stack
              direction={"row"}
              mt={"16px"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={2}
            >
              <Button
                component={Link}
                disableRipple
                variant={"text"}
                download
                to={templateLink}
                target="_blank"
                sx={{ padding: { xs: "0" } }}
                onClick={(e) => e.stopPropagation()}
              >
                Download template
              </Button>
              <Stack
                direction="row"
                alignItems={"center"}
                justifyContent={"center"}
                variant={"h3"}
                component={"p"}
                color={"grayscale.300"}
                sx={{
                  lineHeight: 0,
                  "&:before": {
                    width: 8,
                    content: '"•"',
                    display: "block"
                  }
                }}
              />
              <Button
                variant={"text"}
                disableRipple
                sx={{ padding: { xs: "0" } }}
              >
                Browse files
              </Button>
            </Stack>
            <input {...getInputProps()} required={required} accept={accepted} />
          </Box>
        )}
      />
      {!!errors[fieldName] && (
        <FormHelperText>{errors[fieldName].message}</FormHelperText>
      )}
    </FormControl>
  );
};

import React from "react";
import { palette } from "settings";

import Typography from "@mui/material/Typography";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import CircularProgress from "@mui/material/CircularProgress";

export const EmptyTablePlaceholder = ({ headCells, loading, total }) => {
  if (!loading && total) return null;

  return (
    <TableRow>
      <TableCell colSpan={headCells.length} sx={{ border: "none" }}>
        {loading && (
          <CircularProgress
            size={60}
            sx={{
              position: "absolute",
              top: "calc(50% - 30px)",
              left: "calc(50% - 30px)"
            }}
          />
        )}
        {!loading && total === 0 && (
          <Typography
            variant={"lead"}
            fontWeight={700}
            color={palette.primary.main}
            sx={{
              textAlign: "center",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)"
            }}
          >
            No Data Found
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSnackbar } from "notistack";
import _map from "lodash/map";
import _difference from "lodash/difference";
import { FormProvider, useForm } from "react-hook-form";
import {
  formatISO,
  parseISO,
  getHours,
  getMinutes,
  setHours,
  setMinutes
} from "date-fns";
import { ChipStatus, tabProps } from "components/shared";
import {
  Step1,
  Step2,
  Step3,
  Step4,
  Step5
} from "components/pages/portal/campaign";
import { campaignFormTabs } from "components/pages/portal/campaign/constant";
import { useAuth } from "components/AuthProvider";
import { createApiCall } from "helpers/api";
import { moneyAsNumber } from "helpers/money";
import { CAMPAIGN_PAGE, CAMPAIGNS_PAGE, palette, theme } from "settings";

import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";

const CampaignForm = () => {
  const { uuid } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const { session, getBalance } = useAuth();
  const navigate = useNavigate();
  const isTabletOrSmall = useMediaQuery(theme.breakpoints.down("md"));

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      sort_recipient: "tag"
    }
  });
  const { trigger, reset, getValues, watch } = methods;
  const [step, setStep] = useState(0);
  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openSelected, setOpenSelected] = useState(false);
  const [confirmSelectedRecipients, setConfirmSelectedRecipients] =
    useState(false);
  const [confirmSelectedBrands, setConfirmSelectedBrands] = useState(false);
  const name = watch("name") || "Untitled";
  const customersSelected = watch("customersSelected");
  const brandsSelected = watch("checked_brands");

  const deliveryMethod =
    searchParams.get("delivery_method") || campaign?.deliveryMethod;

  const isCampaignLoading = useMemo(() => {
    return loading && uuid && !campaign;
  }, [loading, uuid, campaign]);

  const campaignFormTabsWithStatus = useMemo(() => {
    return _map(campaignFormTabs, (item) => {
      const { id } = item;
      switch (id) {
        case 0:
          return {
            ...item,
            approved: !!campaign?.name
          };
        case 1:
          return {
            ...item,
            approved: !!campaign?.customersCount || !!campaign?.tagsCount
          };
        case 2:
          return {
            ...item,
            approved: !!campaign?.brandsCount && !!campaign?.codeValue
          };
        case 3:
          return {
            ...item,
            approved:
              !!campaign?.duration?.start &&
              !!campaign?.duration?.end &&
              (deliveryMethod === "SMS" || !!campaign?.message)
          };
        default:
          return {
            ...item
          };
      }
    });
  }, [campaign]);

  useEffect(() => {
    if (uuid && session?.clientUuid) {
      setStep(4);
      fetchCampaign(session?.clientUuid, uuid);
    }
    if (!uuid && !searchParams.has("delivery_method")) {
      enqueueSnackbar("Delivery method hasn't been provided", {
        variant: "error"
      });
      navigate(CAMPAIGNS_PAGE);
    }
  }, [uuid, session?.clientUuid]);

  useEffect(() => {
    getBalance();
  }, [campaign?.status]);

  const fetchCampaign = (clientUuid, campaignUuid) => {
    setLoading(true);
    createApiCall({
      url: `campaigns/${clientUuid}/${campaignUuid}`
    }).then(({ data, status }) => {
      if (status === 200 && data?.campaign) {
        const { campaign } = data;
        if (campaign.status === "DRAFT" || campaign.status === "PENDING") {
          setCampaign(campaign);
          const deliveryDay = campaign.duration?.start
            ? parseISO(campaign.duration?.start)
            : null;
          reset({
            ...campaign,
            tagsCount: campaign.tagsCount,
            customersCount: campaign.customersCount,
            sort_recipient:
              !campaign.tagsCount && !!campaign.customersCount
                ? "email"
                : "tag",
            amount_field: moneyAsNumber(campaign.codeValue) || null,
            delivery_day: deliveryDay,
            expiration_day: campaign.duration?.end
              ? parseISO(campaign.duration?.end)
              : null,
            delivery_time: deliveryDay
          });
        } else {
          navigate(CAMPAIGN_PAGE(campaign.uuid));
        }
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  };

  const handleChangeTab = (event, newValue: number) => {
    setStep(newValue);
  };

  const handleNextStep = (leaveForm = false) => {
    if (leaveForm) {
      navigate(CAMPAIGNS_PAGE);
    } else {
      setStep(step + 1);
    }
  };

  const handleSaveDraft = async (e, leaveForm = false) => {
    e.preventDefault();
    const isStepValid = await trigger();
    if (isStepValid) {
      switch (step) {
        case 0:
          onSubmitStep1(leaveForm);
          break;
        case 1:
          if (confirmSelectedRecipients) {
            onSubmitStep2(leaveForm);
            setConfirmSelectedRecipients(false);
            setOpenSelected(false);
          } else {
            setConfirmSelectedRecipients(true);
            setOpenSelected(true);
          }
          break;
        case 2:
          if (confirmSelectedBrands && brandsSelected?.length) {
            onSubmitStep3(leaveForm);
            setConfirmSelectedBrands(false);
            setOpenSelected(false);
          } else {
            setConfirmSelectedBrands(true);
            setOpenSelected(true);
          }
          break;
        case 3:
          onSubmitStep4(leaveForm);
          break;
        case 4:
          handleNextStep(leaveForm);
          break;
        default:
          console.error(`${step} step not found`);
      }
    }
  };

  const onSubmitStep1 = (leaveForm) => {
    const formData = getValues();
    if (formData.name === campaign?.name) {
      handleNextStep(leaveForm);
    } else {
      setLoading(true);
      const data = {
        client_uuid: session?.clientUuid,
        name: formData?.name,
        delivery_method: deliveryMethod
      };
      if (uuid) {
        data.campaign_uuid = uuid;
      }
      if (campaign?.uuid) {
        data.campaign_uuid = campaign.uuid;
      }
      if (campaign?.codeValue) {
        data.codeValue = campaign.codeValue;
      }
      if (campaign?.duration?.start || campaign?.duration?.end) {
        data.duration = {
          start: campaign.duration.start || "",
          end: campaign.duration.end || ""
        };
      }
      if (campaign?.message) {
        data.message = campaign.message;
      }
      createApiCall({
        method: "POST",
        url: `campaigns`,
        data
      }).then(({ data, status }) => {
        if (status === 200 && data?.campaign) {
          setCampaign(data.campaign);
          if (searchParams.has("delivery_method")) {
            searchParams.delete("delivery_method");
            setSearchParams(searchParams);
          }
          if (leaveForm) {
            enqueueSnackbar(
              `A campaign ${formData.name} was saved as a draft successfully.`,
              {
                variant: "success"
              }
            );
          }
          handleNextStep(leaveForm);
        } else {
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
        }
        setLoading(false);
      });
    }
  };

  const onSubmitStep2 = (leaveForm) => {
    const formData = getValues();

    const toRemove = _difference(
      _map(formData?.campaignCustomers, "uuid"),
      _map(formData?.customersSelected?.items, "uuid")
    );
    const toAdd = _difference(
      _map(formData?.customersSelected?.items, "uuid"),
      _map(formData?.campaignCustomers, "uuid")
    );

    let removeData = null;
    if (toRemove?.length) {
      removeData = {
        campaign_uuid: campaign?.uuid,
        association: "CUSTOMER",
        action: "REMOVE",
        values: toRemove
      };
    }
    upsertCampaignAssociation(
      {
        addData: {
          campaign_uuid: campaign?.uuid,
          association: "CUSTOMER",
          action: "ADD",
          values: toAdd
        },
        removeData
      },
      ({ success }) => {
        if (success) {
          if (leaveForm) {
            enqueueSnackbar(
              `A campaign ${
                campaign?.name || ""
              } was saved as a draft successfully.`,
              {
                variant: "success"
              }
            );
          }
          fetchCampaign(session?.clientUuid, campaign?.uuid);
          handleNextStep(leaveForm);
        }
      }
    );
  };

  const onSubmitStep3 = (leaveForm) => {
    let removeData = null;
    const formData = getValues();
    const newAmount = Math.round(
      (formData.amount_field || formData.custom_amount) * 100
    );
    const checkedBrands =
      _map(formData.checked_brands, (el) => el.uuid || el) || [];
    const deletedBrands = _difference(
      formData.campaign_brandUuids || [],
      checkedBrands
    );
    const addedBrands = _difference(
      checkedBrands,
      formData.campaign_brandUuids || []
    );

    if (addedBrands?.length || deletedBrands?.length) {
      removeData = {
        client_uuid: session?.clientUuid,
        campaign_uuid: campaign?.uuid,
        association: "BRAND",
        action: "REMOVE",
        values: deletedBrands
      };
      upsertCampaignAssociation(
        {
          addData: {
            client_uuid: session?.clientUuid,
            campaign_uuid: campaign?.uuid,
            association: "BRAND",
            action: "ADD",
            values: addedBrands
          },
          removeData: deletedBrands?.length ? removeData : null
        },
        ({ success }) => {
          if (success && campaign.codeValue === newAmount) {
            handleNextStep(leaveForm);
          }
        }
      );
    }

    if (campaign.codeValue !== newAmount) {
      setLoading(true);
      const data = {
        client_uuid: session?.clientUuid,
        name: formData?.name,
        codeValue: newAmount
      };

      if (uuid) {
        data.campaign_uuid = uuid;
      }
      if (campaign?.uuid) {
        data.campaign_uuid = campaign.uuid;
      }
      if (campaign?.duration?.start || campaign?.duration?.end) {
        data.duration = {
          start: campaign.duration.start || "",
          end: campaign.duration.end || ""
        };
      }
      if (campaign?.message) {
        data.message = campaign.message;
      }
      createApiCall({
        method: "POST",
        url: `campaigns`,
        data
      }).then(({ data, status }) => {
        if (status === 200 && data?.campaign) {
          setCampaign(data.campaign);
          if (leaveForm) {
            enqueueSnackbar(
              `A campaign ${
                campaign?.name || ""
              } was saved as a draft successfully.`,
              {
                variant: "success"
              }
            );
          }
          handleNextStep(leaveForm);
        } else {
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
        }
        setLoading(false);
      });
    } else {
      handleNextStep(leaveForm);
    }
  };

  const onSubmitStep4 = (leaveForm) => {
    const formData = getValues();
    const data = {
      client_uuid: session?.clientUuid,
      name: campaign?.name,
      duration: {
        start: formatISO(
          setHours(
            setMinutes(
              new Date(formData.delivery_day),
              getMinutes(formData.delivery_time)
            ),
            getHours(formData.delivery_time)
          )
        ),
        end: formatISO(
          setHours(
            setMinutes(
              new Date(formData.expiration_day),
              getMinutes(formData.delivery_time)
            ),
            getHours(formData.delivery_time)
          )
        )
      }
    };

    if (uuid) {
      data.campaign_uuid = uuid;
    }
    if (campaign?.uuid) {
      data.campaign_uuid = campaign.uuid;
    }
    if (campaign?.codeValue) {
      data.codeValue = campaign.codeValue;
    }
    if (formData.message) {
      data.message = formData.message;
    }
    createApiCall({
      method: "POST",
      url: `campaigns`,
      data
    }).then(({ data, status }) => {
      if (status === 200 && data?.campaign) {
        setCampaign(data.campaign);
        if (leaveForm) {
          enqueueSnackbar(
            `A campaign ${
              campaign?.name || ""
            } was saved as a draft successfully.`,
            {
              variant: "success"
            }
          );
        }

        handleNextStep(leaveForm);
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  };

  const upsertCampaignAssociation = ({ addData, removeData }, callback) => {
    if (addData?.values?.length || removeData?.values?.length) {
      setLoading(true);
      createApiCall({
        method: "PATCH",
        url: `campaigns`,
        data: removeData || addData
      }).then(({ data, status }) => {
        if (status === 200 && addData) {
          upsertCampaignAssociation({ removeData: addData }, callback);
        } else if (status === 200) {
          fetchCampaign(session?.clientUuid, campaign?.uuid);
          if (callback) {
            callback({ success: true });
          }
        } else {
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
        }
        setLoading(false);
      });
    } else {
      callback({ success: true });
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Step1 activeStep={step} />;
      case 1:
        return (
          <Step2
            activeStep={step}
            confirmSelectedRecipients={confirmSelectedRecipients}
            openSelected={openSelected}
            setOpenSelected={setOpenSelected}
            campaign={campaign}
            deliveryMethod={deliveryMethod}
            handleSaveDraft={handleSaveDraft}
            campaignUuid={uuid || campaign?.uuid}
          />
        );
      case 2:
        return (
          <Step3
            activeStep={step}
            openSelected={openSelected}
            setOpenSelected={setOpenSelected}
            confirmSelectedBrands={confirmSelectedBrands}
            campaignUuid={uuid || campaign?.uuid}
            handleSaveDraft={handleSaveDraft}
          />
        );
      case 3:
        return <Step4 activeStep={step} deliveryMethod={deliveryMethod} />;
      case 4:
        return (
          <Step5 activeStep={step} setStep={setStep} campaign={campaign} />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Helmet>
        <title>{uuid ? "Edit" : "Create"} campaign</title>
      </Helmet>
      <Box sx={{ minHeight: "100%", overFlowX: "hidden" }}>
        <Breadcrumbs aria-label="breadcrumb" mb={"12px"}>
          <Link
            underline="hover"
            color={"inherit"}
            variant="leadCaption"
            fontWeight={600}
            sx={{ display: "block" }}
            href={CAMPAIGNS_PAGE}
          >
            Campaigns
          </Link>
          <Typography
            variant="leadCaption"
            color={"grayscale.800"}
            fontWeight={600}
          >
            {uuid ? "Edit" : "Create a campaign"}
          </Typography>
        </Breadcrumbs>
        <Stack
          direction={"row"}
          alignItems={{ xs: "flex-start", md: "center" }}
          justifyContent={"space-between"}
          gap={3}
          mb={"12px"}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={1}
            flexWrap={"wrap"}
          >
            <Typography
              variant={"h5"}
              color={"grayscale.800"}
              sx={{ wordBreak: "break-word" }}
            >
              {isCampaignLoading ? <Skeleton width={200} /> : name}
            </Typography>
            {campaign?.status && <ChipStatus data={campaign.status} />}
          </Stack>
          {!!campaign && (
            <Button
              disableRipple
              disabled={loading}
              variant={"text"}
              sx={{
                minWidth: "32px",
                padding: { xs: "4px 8px" },
                whiteSpace: "pre-wrap",
                textAlign: "right"
              }}
              onClick={(e) => {
                if (step === 1 && !!customersSelected?.items?.length) {
                  setOpenSelected(true);
                  setConfirmSelectedRecipients(false);
                } else {
                  handleSaveDraft(e, true);
                }
              }}
            >
              {step === 1 &&
              (customersSelected?.all || customersSelected?.items?.length)
                ? "View selected recipients"
                : !isTabletOrSmall
                ? "Save as draft"
                : "Save"}
            </Button>
          )}
        </Stack>
        <Paper
          elevation={2}
          sx={{ width: "100%", borderRadius: "8px", overflow: "hidden" }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={step} onChange={handleChangeTab} variant="scrollable">
              {campaignFormTabsWithStatus.map(
                ({ id, title, approved }, index) => (
                  <Tab
                    key={id}
                    iconPosition="start"
                    label={title}
                    {...tabProps(id)}
                    disabled={index && !campaign?.name}
                    sx={{
                      color: approved
                        ? palette.primary.main
                        : palette.grayscale[800],
                      "& .MuiTab-iconWrapper": {
                        marginRight: "4px",
                        display: approved ? "block" : "none"
                      }
                    }}
                    icon={
                      <svg width={20} height={20} fill={palette.primary.main}>
                        <use xlinkHref={"#approve-sm"} />
                      </svg>
                    }
                  />
                )
              )}
            </Tabs>
          </Box>
          {isCampaignLoading ? (
            <Stack p={4}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Stack>
          ) : (
            <FormProvider {...methods}>
              <form onSubmit={handleSaveDraft}>{getStepContent(step)}</form>
            </FormProvider>
          )}
        </Paper>
        <Stack
          mt={2}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={3}
        >
          {step > 0 && (
            <Button
              variant={"outlinedPrimary"}
              onClick={() => setStep(step - 1)}
            >
              Back
            </Button>
          )}
          {step < 4 && (
            <Button sx={{ marginLeft: "auto" }} onClick={handleSaveDraft}>
              Continue
            </Button>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default CampaignForm;

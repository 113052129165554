import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  AccountSidebar,
  HeaderDashboard,
  Sidebar,
  NotificationSidebar,
  AddFundsSidebar,
  PaymentMethodsSidebar
} from "components/common";
import { hasAccessByRole, palette, theme } from "settings";
import { useAuth } from "components/AuthProvider";
import { CircularBackdrop } from "components/shared";

import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";

export const LayoutDefault = () => {
  const isTabletOrSmall = useMediaQuery(theme.breakpoints.down("md"));
  const { session, config, kyc } = useAuth();
  const [openBurger, setOpenBurger] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      config?.kyc?.enabled === "TRUE" &&
      !!session &&
      hasAccessByRole(session.roles, ["CLIENT_OWNER"])
    ) {
      setLoading(!session || !config || !kyc);
    } else {
      setLoading(!session || !config);
    }
  }, [session, config, kyc]);

  return (
    <div>
      <Helmet>
        <link rel="icon" href="/favicon_business.ico" />
      </Helmet>

      <CircularBackdrop
        size={72}
        open={loading}
        sx={{
          background: "#fff",
          top: isTabletOrSmall ? 70 : 100
        }}
      />

      <HeaderDashboard openBurger={openBurger} setOpenBurger={setOpenBurger} />
      <Sidebar openBurger={openBurger} setOpenBurger={setOpenBurger} />
      <AccountSidebar />
      <NotificationSidebar />
      <AddFundsSidebar />
      <PaymentMethodsSidebar />
      <Box
        sx={{
          ml: { xs: "0", md: "230px" },
          width: "auto",
          p: {
            xs: "102px 16px 32px",
            sm: "102px 24px 32px",
            md: "134px 60px 46px"
          },
          position: "relative",
          minHeight: "100vh",
          background: palette.grayscale[100]
        }}
      >
        <Outlet />
      </Box>
    </div>
  );
};

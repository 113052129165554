import React, { useEffect, useMemo, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useAuth } from "components/AuthProvider";
import { useSnackbar } from "notistack";
import { createApiCall } from "helpers/api";
import { handleMetaEnter } from "helpers/eventHandlers";
import { palette } from "settings";
import { useDebounce } from "hooks/useDebounce";

import _pullAllBy from "lodash/pullAllBy";
import _map from "lodash/map";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

const PAGE_LIMIT = 4;

const categoryOptions = [
  {
    id: "email",
    label: "Email",
    value: "example@raise.com",
    icon: "#email"
  },
  {
    id: "phoneNumber",
    label: "Phone Number",
    value: "(123) 456-7890",
    icon: "#phone"
  },
  {
    id: "name",
    label: "Recipient Name",
    value: "Charlie Brown",
    icon: "#user-new"
  },
  {
    id: "tag",
    label: "Tag",
    value: "Employees",
    icon: "#tag-new"
  }
];

export const CategorySearchAutocomplete = ({
  control,
  setValue,
  watch,
  formState: { errors },
  label = "",
  fieldName = "search_category_option",
  placeholder = "Search name, email, phone number, or tag",
  rules = {},
  activeCategory = null,
  setActiveCategory,
  deliveryMethod,
  filterBySelected = false
}) => {
  const value = watch(fieldName) || null;
  const { session } = useAuth();
  const wrapperRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const debouncedFetchData = useDebounce(() => {
    if (session?.clientUuid && activeCategory) {
      return activeCategory.id === "tag" ? getTags() : getRecipients();
    }
  }, 300);

  useEffect(() => {
    return () => {
      handleClear();
      debouncedFetchData.cancel();
    };
  }, []);

  useEffect(() => {
    debouncedFetchData();
  }, [session?.clientUuid, activeCategory, inputValue]);

  const filteredOptions = useMemo(() => {
    return [..._pullAllBy([...options], value, "uuid")];
  }, [options, value, activeCategory]);

  const searchRecipientsParams = useMemo(() => {
    if (activeCategory?.id === "name" && inputValue?.length) {
      const [firstName = "", lastName = ""] = inputValue.split(" ");
      if (!firstName && lastName) {
        return `&lastName=${lastName.trim()}`;
      }
      if (!lastName && firstName) {
        return `&firstName=${firstName.trim()}`;
      }
      return `&firstName=${firstName.trim()}&lastName=${lastName.trim()}`;
    }
    if (activeCategory?.id === "tag") {
      return `&name=${inputValue.trim()}`;
    }
    if (activeCategory?.id === "phoneNumber") {
      return `&phoneNumber=${inputValue.replace(/[ \-()+_]/g, "")}`;
    }
    if (activeCategory && inputValue) {
      return `&${activeCategory.id}=${inputValue.trim()}`;
    }
    return "";
  }, [activeCategory, inputValue]);

  const renderOptionText = (option) => {
    if (!option || typeof option === "string") {
      return option || "-";
    }
    if (!activeCategory || activeCategory?.id === "tag") {
      return option.name || "-";
    }
    if (activeCategory?.id === "name") {
      if (option.firstName && option.lastName) {
        return `${option.firstName} ${option.lastName}`;
      } else return "-";
    }

    return option[activeCategory?.id] || "-";
  };

  const getTags = () => {
    setLoading(true);
    setOptions([]);
    createApiCall({
      url: `tags/${session?.clientUuid}?disabled=FALSE&pagination.sortBy.field=created_at&pagination.sortBy.order=DESC&pagination.pageLimit=${PAGE_LIMIT}&pagination.page=0${searchRecipientsParams}`
    }).then(({ status, data }) => {
      if (status === 200) {
        setOptions(
          _map(data.tags, ({ clientTagUuid, ...rest }) => ({
            ...rest,
            uuid: clientTagUuid
          }))
        );
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  };

  const getRecipients = () => {
    setLoading(true);
    setOptions([]);
    createApiCall({
      url: `customers/${session?.clientUuid}?pagination.sortBy.field=name&pagination.sortBy.order=ASC&pagination.pageLimit=${PAGE_LIMIT}&pagination.page=0&delivery_flag=${deliveryMethod}${searchRecipientsParams}`
    }).then(({ status, data }) => {
      if (status === 200) {
        setOptions(data.customers);
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  };

  const handleCloseAutocomplete = (event, reason) => {
    if (
      reason === "selectOption" ||
      reason === "blur" ||
      reason === "toggleInput"
    ) {
      return;
    }
    setIsOpen(false);
  };

  const handleClear = () => {
    setValue(fieldName, null);
    setInputValue("");
    setActiveCategory(null);
  };

  const handleBlur = (e) => {
    if (
      e.target?.id !== fieldName &&
      !e.target?.classList?.contains("popper-autocomplete") &&
      !e.target?.closest(".popper-autocomplete")
    ) {
      return setIsOpen(false);
    }
  };
  const handleInputChange = (_event, newValue) => {
    if (newValue !== inputValue) {
      setLoading(true);
      setOptions([]);
    }
    setInputValue(newValue);
  };

  const handleSearch = (item = null) => {
    if (activeCategory?.id === "tag" && !item && !filterBySelected) return;
    if (activeCategory?.id === "tag" && !filterBySelected) {
      setValue(fieldName, item);
      return;
    }
    const searchValue = renderOptionText(item || inputValue);
    setValue(fieldName, searchValue === "-" ? "" : searchValue);
  };

  const CategoryOption = ({ option }) => (
    <Stack direction={"row"} alignItems={"center"} gap={2}>
      <Chip
        clickable
        variant="squared-secondary"
        tabIndex={-1}
        label={option.label}
        onClick={() => setActiveCategory(option)}
        onDelete={() => false}
        deleteIcon={
          <svg
            width={16}
            height={16}
            style={{
              minWidth: 16,
              color: palette.primary.main
            }}
          >
            <use xlinkHref={option.icon} />
          </svg>
        }
      />
      <Typography variant={"smallTitle"}>{option.value}</Typography>
    </Stack>
  );

  return (
    <FormControl error={!!errors[fieldName]} sx={{ width: "100%" }}>
      <Controller
        name={fieldName}
        rules={rules}
        control={control}
        render={({ field: { onChange } }) => {
          return (
            <ClickAwayListener onClickAway={handleBlur}>
              <Autocomplete
                open={isOpen}
                autoHighlight={false}
                onOpen={() => setIsOpen(true)}
                onClose={handleCloseAutocomplete}
                ListboxProps={{
                  role: "list-box",
                  style: { maxHeight: filterBySelected ? 200 : "auto" }
                }}
                loading={loading}
                clearOnBlur={false}
                autoSelect={false}
                onChange={(_event, item, reason) => {
                  if (reason === "clear") {
                    return setInputValue("");
                  }
                  onChange(item);
                  setIsOpen(false);
                  handleSearch(item);
                }}
                sx={{
                  "& .MuiFilledInput-root": {
                    paddingRight: "16px !important"
                  }
                }}
                value={value}
                inputValue={inputValue}
                isOptionEqualToValue={(option, value) => {
                  return (
                    option.uuid === value.uuid ||
                    renderOptionText(option) === value
                  );
                }}
                onInputChange={handleInputChange}
                multiple={false}
                forcePopupIcon={false}
                id={fieldName}
                options={filteredOptions}
                noOptionsText={
                  activeCategory?.id === "tag"
                    ? "No tags found"
                    : "No result found"
                }
                getOptionLabel={(option) => renderOptionText(option)}
                popupIcon={
                  <svg width={24} height={24} stroke={palette.primary.main}>
                    <use href="#cheveron-down" />
                  </svg>
                }
                PopperComponent={(props) => {
                  return (
                    <Popper
                      {...props}
                      sx={{
                        "& .MuiPaper-root": {
                          marginTop: "6px",
                          backgroundColor: palette.grayscale[100]
                        }
                      }}
                    >
                      <Box className={"popper-autocomplete"} ref={wrapperRef}>
                        {activeCategory?.id ? (
                          props.children
                        ) : (
                          <Paper sx={{ padding: 3 }}>
                            <Stack gap={2}>
                              <Typography
                                variant={"body1"}
                                fontWeight={600}
                                color={"grayscale.800"}
                              >
                                Select category to search
                              </Typography>
                              {categoryOptions.map((el) => (
                                <CategoryOption key={el.id} option={el} />
                              ))}
                            </Stack>
                          </Paper>
                        )}
                      </Box>
                    </Popper>
                  );
                }}
                renderOption={(props, option) => {
                  if (activeCategory?.id === "tag") {
                    return (
                      <Box
                        {...props}
                        key={option.uuid}
                        sx={{
                          py: "12px !important"
                        }}
                      >
                        <Chip
                          clickable={true}
                          variant="squared-secondary"
                          tabIndex={-1}
                          label={option.name || "-"}
                          sx={{
                            "& .MuiChip-label": {
                              padding: "0 8px"
                            },
                            "& .MuiChip-icon": {
                              order: 1,
                              margin: "0 8px 0 0",
                              minWidth: 12,
                              color: palette.grayscale[800]
                            }
                          }}
                          icon={
                            <svg width={12} height={12}>
                              <use xlinkHref={"#small-plus"} />
                            </svg>
                          }
                        />
                      </Box>
                    );
                  } else {
                    return (
                      <Box component="li" {...props}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                          sx={{ width: "100%" }}
                        >
                          {renderOptionText(option)}
                        </Stack>
                      </Box>
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={"filled"}
                    label={label}
                    className={"filled-rounded"}
                    placeholder={placeholder}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      readOnly: !activeCategory,
                      startAdornment: (
                        <>
                          <InputAdornment
                            position="start"
                            sx={{
                              width: "auto",
                              height: "auto",
                              marginTop: "0 !important"
                            }}
                          >
                            {activeCategory?.id ? (
                              <Chip
                                variant="squared-secondary"
                                tabIndex={-1}
                                label={activeCategory.label}
                                onDelete={() => false}
                                deleteIcon={
                                  <svg
                                    width={16}
                                    height={16}
                                    style={{
                                      minWidth: 16,
                                      color: palette.primary.main
                                    }}
                                  >
                                    <use xlinkHref={activeCategory.icon} />
                                  </svg>
                                }
                              />
                            ) : (
                              <svg
                                width={24}
                                height={24}
                                stroke={palette.grayscale["800"]}
                              >
                                <use xlinkHref={"#search-new"} />
                              </svg>
                            )}
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      ),
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : activeCategory?.id || inputValue ? (
                            <IconButton size={"small"} onClick={handleClear}>
                              <svg
                                width={24}
                                height={24}
                                stroke={palette.grayscale["700"]}
                              >
                                <use href="#close" />
                              </svg>
                            </IconButton>
                          ) : null}
                        </>
                      )
                    }}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: !activeCategory
                    }}
                    onKeyDown={(event) => handleMetaEnter(event, handleSearch)}
                  />
                )}
              />
            </ClickAwayListener>
          );
        }}
      />
      {errors[fieldName] && (
        <FormHelperText>{errors[fieldName].message}</FormHelperText>
      )}
    </FormControl>
  );
};

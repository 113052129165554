import auth from "./auth";
import dashboard from "./dashboard";
import { AuthLayout, LayoutDefault } from "layouts";

export const routesWithTemplate = [
  {
    id: 0,
    routes: auth,
    layout: AuthLayout
  },
  {
    id: 2,
    routes: dashboard,
    layout: LayoutDefault
  }
];

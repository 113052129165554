import React, { useMemo } from "react";
import find from "lodash/find";
import { cardBrandCode } from "components/fields";
import { useAuth } from "components/AuthProvider";
import { hasAccessByRole, midnightBlue, palette } from "settings";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

export const PaymentCard = ({ card, onRemove }) => {
  const { session } = useAuth();

  const icon = useMemo(() => {
    const brandCode = find(
      cardBrandCode,
      (o) =>
        o ===
        card.braintreeDetails?.details?.creditcardDetails?.brandCode
          ?.toLowerCase()
          ?.replace(/[ \-()+_]/g, "")
    );
    if (brandCode) {
      return (
        <svg width={44} height={30} style={{ minWidth: 24 }} color={"#5020F7"}>
          <use xlinkHref={`#${brandCode}-active`} />
        </svg>
      );
    }
    return (
      <svg width={24} height={24} style={{ minWidth: 24 }} color={"#5020F7"}>
        <use xlinkHref={"#library"} />
      </svg>
    );
  }, [card.braintreeDetails?.details?.creditcardDetails?.brandCode]);

  return (
    <Stack
      width={"100%"}
      direction={"row"}
      alignItems={"center"}
      gap={{ xs: 1, sm: 2 }}
    >
      <Stack sx={{ width: "44px", alignItems: "center" }}>{icon}</Stack>

      <Stack
        direction={{ xs: !onRemove && "row", sm: "row" }}
        justifyContent={"space-between"}
        alignItems={{ sm: "center" }}
        width={"100%"}
        gap={{ xs: 1, sm: 2 }}
      >
        <Box>
          <Typography
            variant={"body1"}
            fontWeight={600}
            color={"grayscale.800"}
            lineHeight={1.38}
          >
            {card.braintreeDetails?.details?.origin?.type || "Bank account"}
          </Typography>
          <Typography
            variant={"body1"}
            color={"grayscale.300"}
            lineHeight={1.38}
          >
            ****{" "}
            {card.braintreeDetails?.details?.creditcardDetails?.last4 ||
              card.last4}
          </Typography>
        </Box>
        <Box sx={{ textAlign: { xs: !onRemove && "right", sm: "right" } }}>
          <Typography
            variant={"body1"}
            lineHeight={1.38}
            color={palette.primary.main}
            fontWeight={600}
            component={"span"}
          >
            {card.braintreeDetails ? "3% Fee" : "No Fee"}
          </Typography>
          {card.braintreeDetails?.details?.creditcardDetails && (
            <Typography
              variant={"body1"}
              color={"grayscale.300"}
              lineHeight={1.38}
            >
              Exp{" "}
              {card.braintreeDetails.details.creditcardDetails.expirationMonth}/
              {card.braintreeDetails.details.creditcardDetails.expirationYear}
            </Typography>
          )}
        </Box>
      </Stack>
      {onRemove &&
        hasAccessByRole(session?.roles, ["CLIENT_OWNER", "CLIENT_ADMIN"]) && (
          <IconButton
            sx={{ alignSelf: "center" }}
            size={"small"}
            onClick={() => onRemove(card)}
          >
            <svg
              width={24}
              height={24}
              stroke={midnightBlue}
              style={{ minWidth: 24 }}
            >
              <use href="#trash" />
            </svg>
          </IconButton>
        )}
    </Stack>
  );
};

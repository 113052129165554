import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { SnackbarProvider } from "notistack";
import App from "./pages";
import Sprite from "components/Sprite";
import { AuthProvider } from "components/AuthProvider";
import { Snackbar } from "components/Notistack/Snackbar";
import ErrorBoundary from "components/error-boundary";
import { theme } from "settings";

import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider } from "@mui/material/styles";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { getCookie } from "helpers/cookies";

const container = document.getElementById("root");
const root = createRoot(container);
const captchaSiteKey = getCookie("CAPTCHA_SITE_KEY") || "_";

// test
root.render(
  <ThemeProvider theme={theme}>
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <Sprite />
      <ErrorBoundary>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          Components={{
            success: Snackbar,
            error: Snackbar,
            info: Snackbar
          }}
        >
          <AuthProvider>
            <GoogleReCaptchaProvider
              reCaptchaKey={captchaSiteKey}
              language="en"
              useRecaptchaNet={true}
              scriptProps={{
                appendTo: "head",
                defer: true
              }}
              container={{
                parameters: {
                  badge: "inline"
                }
              }}
            >
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </GoogleReCaptchaProvider>
          </AuthProvider>
        </SnackbarProvider>
      </ErrorBoundary>
    </StyledEngineProvider>
  </ThemeProvider>
);

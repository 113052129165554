import React from "react";
import { BrandsLogo } from "components/pages/portal/brands";
import _findIndex from "lodash/findIndex";
import { theme } from "settings";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

export const Brands = ({
  data,
  onSelectAll,
  onSelect,
  uniqueBrands,
  disabled,
  hideOption,
  onLoadMore
}) => {
  const isTabletOrSmall = useMediaQuery(theme.breakpoints.down("md"));

  if (!uniqueBrands?.length && !data?.brands?.length) {
    return <Typography color={"grayscale.400"}>No brands</Typography>;
  }

  return (
    <Stack alignItems={"center"} gap={3} sx={{ width: "100%", pb: 1 }}>
      <Box
        sx={{
          p: {
            md: "0 16px"
          },
          width: "100%"
        }}
      >
        {!isTabletOrSmall && !!onSelectAll && (
          <Button
            disableRipple
            variant={"text"}
            onClick={onSelectAll}
            sx={{
              fontWeight: 900,
              ml: "auto",
              display: "flex",
              mb: 3,
              width: "fit-content",
              visibility: data?.brands?.length ? "visible" : "hidden"
            }}
          >
            Select all
          </Button>
        )}
        <Stack direction={"row"} flexWrap={"wrap"} gap={{ xs: 2.5, md: 3 }}>
          {!!data?.brands?.length &&
            data.brands.map((element) => {
              const isDisabled = _findIndex(
                uniqueBrands,
                (el) => el === element.uuid
              );
              return (
                <BrandsLogo
                  key={element.uuid}
                  data={element}
                  onSelect={onSelect}
                  status={{ disabled: hideOption ? false : isDisabled === -1 }}
                />
              );
            })}
        </Stack>
      </Box>
      {data?.brands?.length < data?.pagination?.totalResults && (
        <Button
          variant="text"
          disabled={disabled}
          onClick={onLoadMore}
          sx={{
            width: "fit-content",
            margin: "auto auto 0",
            display: "flex"
          }}
        >
          Show more
        </Button>
      )}
    </Stack>
  );
};

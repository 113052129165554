import React, { useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import filter from "lodash/filter";
import { SidebarLinks } from "./constants";
import { KYC_PAGE, midnightBlue, sidebarWidth, theme } from "settings";
import { useAuth } from "components/AuthProvider";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import Avatar from "@mui/material/Avatar";
import useMediaQuery from "@mui/material/useMediaQuery";

export const Sidebar = ({ openBurger, setOpenBurger }) => {
  const isTabletOrSmall = useMediaQuery(theme.breakpoints.down("md"));
  const { session, kyc, isApiEnabled, isCampaignEnabled } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  //103542 - story id to this fix
  const isPortalClient =
    session?.clientUuid === "d7863e97-9b6b-41fb-bc28-189320017961" ||
    !isApiEnabled;

  const { pathname } = location;
  const filteredSidebarLinks = useMemo(() => {
    if (!kyc || kyc.status === "ACCEPTED") {
      return filter(SidebarLinks, (link) => link.path !== KYC_PAGE);
    }
    if (kyc && kyc.status !== "ACCEPTED") {
      return filter(SidebarLinks, (link) => link.path === KYC_PAGE);
    }
    return SidebarLinks;
  }, [kyc]);

  const handleShowBar = () => {
    setOpenBurger(false);
    navigate(pathname, { state: { showAccountBar: true } });
  };
  const onLinkClick = () => {
    if (isTabletOrSmall) {
      return setOpenBurger(false);
    }
  };

  return (
    <Drawer
      variant={isTabletOrSmall ? "temporary" : "permanent"}
      open={isTabletOrSmall ? openBurger : true}
      anchor={isTabletOrSmall ? "right" : "left"}
      onClose={() => (isTabletOrSmall ? setOpenBurger(false) : null)}
      sx={{
        zIndex: (theme) => theme.zIndex.modal + 1,
        ".MuiPaper-root": {
          zIndex: 9,
          background: "#FFFFFF",
          width: { xs: "100%", md: sidebarWidth },
          gap: { xs: 2, md: "36px" },
          padding: { xs: "152px 16px 32px", md: "136px 16px 36px" }
        },

        "& .link": {
          borderRadius: "4px",
          fontWeight: 400,
          whiteSpace: "nowrap",
          width: "100%",
          p: "6px",
          transition: "all 0.3s",
          color: "#281E49",
          "& svg": {
            color: midnightBlue
          }
        },
        "& .link.active": {
          color: "#FFFFFF",
          fontWeight: 700,
          background: midnightBlue,
          "& svg": {
            color: "#FFFFFF"
          }
        }
      }}
    >
      {filteredSidebarLinks.map(
        ({
          id,
          path,
          title,
          icon,
          onlyApiClient = false,
          onlyCampaignAPIEnabled = false
        }) => {
          if (isPortalClient && onlyApiClient) return false;
          if (!isCampaignEnabled && onlyCampaignAPIEnabled) return false;

          return (
            <Stack
              direction={"row"}
              component={Link}
              onClick={onLinkClick}
              key={id}
              to={path}
              alignItems={"center"}
              gap={"12px"}
              className={pathname.includes(path) ? "link active" : "link"}
            >
              <svg
                width={36}
                height={36}
                style={{ minWidth: 36, padding: "6px" }}
              >
                <use xlinkHref={icon} />
              </svg>
              <Typography
                variant={"subLead"}
                sx={{ color: "inherit", fontWeight: "inherit" }}
              >
                {title}
              </Typography>
            </Stack>
          );
        }
      )}

      <Avatar
        onClick={handleShowBar}
        sx={{
          bgcolor: midnightBlue,
          marginTop: "auto",
          cursor: "pointer",
          width: 48,
          height: 48
        }}
        alt={session?.email}
      >
        <Typography variant={"lead"} sx={{ color: "#fff", fontWeight: 700 }}>
          {session?.email?.charAt(0).toUpperCase()}
        </Typography>
      </Avatar>
    </Drawer>
  );
};

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import _filter from "lodash/filter";
import { useSnackbar } from "notistack";
import {
  ConfirmModal,
  DebitCreditCardForm,
  PaymentCard,
  PlaidLink,
  DebitCreditCard,
  CircularBackdrop
} from "components/shared";
import { useAuth } from "components/AuthProvider";
import { hasAccessByRole, palette } from "settings";
import { createApiCall } from "helpers/api";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";

export const PaymentMethodsSidebar = () => {
  const { session } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname, state } = location;

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [plaidLoading, setPlaidLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false);
  const [submittedStatus, setSubmittedStatus] = useState({
    isDirty: false
  });
  const [confirmModalData, setConfirmModalData] = useState(null);
  const [plaidToken, setPlaidToken] = useState(null);

  const accessAddPaymentMethod = useMemo(() => {
    return hasAccessByRole(session?.roles, ["CLIENT_OWNER", "CLIENT_ADMIN"]);
  }, [session?.roles]);

  useEffect(() => {
    if (open && session?.uuid) {
      setShowCardForm(false);
      fetchPaymentMethods();
      createLinkToken();
    }
  }, [open, session?.uuid]);

  useEffect(() => setOpen(state?.showPaymentMethods || false), [state]);

  const fetchPaymentMethods = () => {
    setLoading(true);
    createApiCall({
      url: "payment-methods/all"
    }).then(({ status, data }) => {
      if (status === 200 && data?.paymentMethods) {
        setPaymentMethods(data.paymentMethods);
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  };

  const createLinkToken = useCallback(() => {
    if (session?.uuid) {
      createApiCall({
        url: `payment-methods/link/${session?.uuid}`
      }).then(({ status, data }) => {
        if (status === 200 && data.token) {
          setPlaidToken(data.token);
        } else {
          enqueueSnackbar(data?.title || "Plaid doesn't ready", {
            variant: "error"
          });
        }
      });
    }
  }, [session?.uuid]);

  const onClose = () => {
    if (submittedStatus.isDirty) {
      setConfirmModalData({
        open: true,
        data: {
          action: "exit"
        },
        title: "Are you sure that you want to quit?",
        description: "Entered data will not be saved."
      });
    } else {
      navigate(pathname, {});
    }
  };

  const onBack = () => navigate(null, { state: { showAccountBar: true } });

  const onClickDeletePaymentMethod = (card) => {
    setConfirmModalData({
      data: {
        action: "delete-card",
        card
      },
      icon: (
        <svg width={24} height={24} stroke={palette.primary.main}>
          <use xlinkHref={"#trash"} />
        </svg>
      ),
      title: "Are you sure that you want to delete?",
      description: "Data about this payment method will be removed."
    });
  };

  const onDeletePaymentMethod = (card) => {
    setLoading(true);
    createApiCall({
      url: "payment-methods/deactivate",
      method: "PATCH",
      data: {
        uuid: card.uuid
      }
    }).then(({ status, data }) => {
      if (status === 200) {
        enqueueSnackbar(
          `A Card **** ${
            card?.braintreeDetails?.details?.creditcardDetails?.last4 ||
            card?.last4
          } was successfully deleted.`,
          {
            variant: "success"
          }
        );
        setPaymentMethods(_filter(paymentMethods, (o) => o.uuid !== card.uuid));
        fetchPaymentMethods();
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
      setConfirmModalData(null);
    });
  };

  const onConfirm = (data) => {
    switch (data.action) {
      case "delete-card":
        onDeletePaymentMethod(data.card);
        break;
      case "exit":
        setConfirmModalData(null);
        setSubmittedStatus({
          isDirty: false
        });
        navigate(pathname, {});
        break;
      default:
        return null;
    }
  };

  return (
    <>
      <CircularBackdrop open={plaidLoading} />
      <Drawer
        open={open}
        onClose={onClose}
        ModalProps={{
          keepMounted: true
        }}
      >
        <Stack sx={{ height: "100%" }}>
          <Stack
            alignItems={"center"}
            direction={"row"}
            justifyContent={"space-between"}
            gap={"12px"}
            sx={{
              p: "32px 24px",
              borderBottom: `1px solid ${palette.gray[200]}`
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={"12px"}
            >
              <IconButton size={"small"} onClick={onBack}>
                <svg
                  width={24}
                  height={24}
                  stroke={palette.grayscale["800"]}
                  style={{ transform: "rotate(180deg)" }}
                >
                  <use href={"#cheveron-right"} />
                </svg>
              </IconButton>

              <Typography variant={"subLead"} sx={{ fontWeight: 700 }}>
                Payment methods
              </Typography>
            </Stack>

            <IconButton size={"small"} onClick={onClose}>
              <svg width={24} height={24} stroke={palette.grayscale["700"]}>
                <use href="#close" />
              </svg>
            </IconButton>
          </Stack>
          <Stack spacing={2} sx={{ overflowY: "auto", p: "24px 24px 40px" }}>
            {loading && !paymentMethods.length && (
              <Skeleton variant="rounded" height={96} />
            )}

            {paymentMethods?.map((card) => (
              <Box
                key={card.uuid}
                sx={{
                  padding: {
                    xs: 2,
                    sm: 3
                  },
                  borderRadius: "8px",
                  border: `1px solid ${palette.grayscale["200"]}`
                }}
              >
                <PaymentCard
                  card={card}
                  onRemove={onClickDeletePaymentMethod}
                />
              </Box>
            ))}
            {accessAddPaymentMethod && (
              <>
                <Box
                  sx={{
                    padding: { xs: 2, sm: 3 },
                    borderRadius: "8px",
                    border: `1px solid ${palette.grayscale["200"]}`
                  }}
                >
                  <Box onClick={() => setShowCardForm(!showCardForm)}>
                    <DebitCreditCard />
                  </Box>
                  {showCardForm && (
                    <Box pt={3}>
                      <DebitCreditCardForm
                        setSubmittedStatus={setSubmittedStatus}
                        fetchPaymentMethods={fetchPaymentMethods}
                      />
                    </Box>
                  )}
                </Box>
                <PlaidLink
                  token={plaidToken}
                  style={{
                    padding: { xs: 2, sm: 3 },
                    borderRadius: "8px",
                    border: `1px solid ${palette.grayscale["200"]}`,
                    cursor: "pointer",
                    textAlign: "left"
                  }}
                  fetchPaymentMethods={fetchPaymentMethods}
                  setPlaidLoading={setPlaidLoading}
                />
              </>
            )}
          </Stack>
        </Stack>
      </Drawer>
      <ConfirmModal
        {...confirmModalData}
        loading={loading}
        open={!!confirmModalData}
        onClose={() => setConfirmModalData(null)}
        onConfirm={onConfirm}
      />
    </>
  );
};

import React from "react";
import { midnightBlue } from "settings";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export const TableToolbar = ({ title, actions, toolbarSpacing = 4 }) => {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      gap={2}
      bgcolor={midnightBlue}
      sx={{
        p: 2
      }}
    >
      {title && (
        <Typography
          variant={"smallTitle"}
          fontWeight={"bold"}
          color={"#fff"}
          sx={{
            flex: { xs: "1 1 100%", md: "1 0 auto" },
            fontSize: { xs: 18, md: 14 }
          }}
        >
          {title}
        </Typography>
      )}
      {actions && (
        <Stack
          direction={"row"}
          spacing={toolbarSpacing}
          alignItems={"center"}
          justifyContent={"flex-end"}
          my={"-5px"}
          sx={{ flex: { xs: "1 1 100%", md: "1 0 auto" } }}
        >
          {actions}
        </Stack>
      )}
    </Stack>
  );
};

import React from "react";
import { Helmet } from "react-helmet";
import { AuthBenefitsWrapper, CreatePasswordForm } from "components/pages/auth";

const ResetPasswordPage = () => {
  return (
    <>
      <Helmet>
        <title>Reset password</title>
      </Helmet>
      <AuthBenefitsWrapper>
        <CreatePasswordForm reset />
      </AuthBenefitsWrapper>
    </>
  );
};

export default ResetPasswordPage;

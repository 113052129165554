import React, { useCallback, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
import { DebitCreditCard, PaymentCard, PlaidLink } from "components/shared";
import { useAuth } from "components/AuthProvider";
import { createApiCall } from "helpers/api";
import { fieldCannotBeEmpty, hasAccessByRole } from "settings";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

export const FundsSelect = ({
  control,
  watch,
  formState: { errors },
  label = "",
  fieldName = "",
  options,
  setPlaidLoading,
  fetchPaymentMethods,
  validation = {
    required: fieldCannotBeEmpty
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useAuth();
  const fundsSelectValue = watch(fieldName, false);
  const [plaidToken, setPlaidToken] = useState(null);

  const accessAddPaymentMethod = useMemo(() => {
    return hasAccessByRole(session?.roles, ["CLIENT_OWNER", "CLIENT_ADMIN"]);
  }, [session?.roles]);

  const createLinkToken = useCallback(() => {
    if (session?.uuid) {
      createApiCall({
        url: `payment-methods/link/${session?.uuid}`
      }).then(({ status, data }) => {
        if (status === 200 && data.token) {
          setPlaidToken(data.token);
        } else {
          enqueueSnackbar(data?.title || "Plaid doesn't ready", {
            variant: "error"
          });
        }
      });
    }
  }, [session?.uuid]);

  return (
    <FormControl error={!!errors[fieldName]} sx={{ width: "100%" }}>
      {label && !fundsSelectValue && (
        <InputLabel variant={"filled"}>{label}</InputLabel>
      )}
      <Controller
        control={control}
        defaultValue={""}
        name={fieldName}
        rules={validation}
        render={({ field }) => {
          return (
            <Select
              {...field}
              defaultValue={""}
              options={options}
              sx={{
                "& .MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
                  {
                    paddingTop: "18px"
                  }
              }}
              displayEmpty
              fullWidth
              MenuProps={{ keepMounted: true, disablePortal: true }}
              onOpen={() => {
                if (!plaidToken) createLinkToken();
              }}
            >
              {options?.map((el) => (
                <MenuItem key={el.uuid} value={el.uuid}>
                  <Box
                    width={"100%"}
                    sx={{
                      padding: {
                        xs: "8px 0",
                        sm: "16px 8px"
                      },
                      img: { maxWidth: "44px", width: "100%", height: "30px" }
                    }}
                  >
                    <PaymentCard card={el} />
                  </Box>
                </MenuItem>
              ))}

              {accessAddPaymentMethod && (
                <MenuItem value={"debit-credit-card"}>
                  <DebitCreditCard
                    style={{
                      padding: "16px 8px"
                    }}
                  />
                </MenuItem>
              )}

              {accessAddPaymentMethod && (
                <MenuItem value={"bank-account"} disabled={!plaidToken}>
                  <PlaidLink
                    setPlaidLoading={setPlaidLoading}
                    fetchPaymentMethods={fetchPaymentMethods}
                    token={plaidToken}
                    style={{
                      padding: "16px 8px"
                    }}
                  />
                </MenuItem>
              )}
            </Select>
          );
        }}
      />

      {errors[fieldName] && (
        <FormHelperText>{errors[fieldName].message}</FormHelperText>
      )}
    </FormControl>
  );
};

import React from "react";
import _isArray from "lodash/isArray";
import _findIndex from "lodash/findIndex";
import _some from "lodash/some";
import Slider from "react-slick";
import { palette } from "settings";
import { BrandsLogo } from "./BrandsLogo";

import Box from "@mui/material/Box";

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <Box
      onClick={onClick}
      sx={{
        "& svg": {
          right: -4,
          top: "30%",
          zIndex: 2,
          width: 24,
          height: 24,
          transform: "rotate(90deg)"
        },
        "& svg:before": {
          content: "none"
        },
        "&:before": {
          content: "''",
          pointerEvents: "none",
          position: "absolute",
          zIndex: 1,
          top: 0,
          right: 0,
          filter: "blur(8px)",
          background:
            "linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)",
          width: { xs: 24, md: 124 },
          height: "100%",
          display: "block"
        }
      }}
    >
      <svg
        width={24}
        height={24}
        stroke={palette.primary.main}
        onClick={onClick}
        className={className}
      >
        <use xlinkHref={"#cheveron-up"} />
      </svg>
    </Box>
  );
};

const PrevArrow = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <Box
      onClick={onClick}
      sx={{
        visibility: currentSlide ? "visibility" : "hidden",
        "& svg": {
          left: -4,
          width: 24,
          top: "30%",
          zIndex: 2,
          height: 24,
          transform: "rotate(-90deg)"
        },
        "& svg:before": {
          content: "none"
        },
        "&:before": {
          content: "''",
          zIndex: 1,
          pointerEvents: "none",
          background:
            "linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)",
          width: { xs: 24, md: 124 },
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          filter: "blur(8px)",
          display: "block"
        }
      }}
    >
      <svg
        width={24}
        height={24}
        stroke={palette.primary.main}
        onClick={onClick}
        className={className}
      >
        <use xlinkHref={"#cheveron-up"} />
      </svg>
    </Box>
  );
};

const settings = {
  className: "popularBrands",
  dots: false,
  slidesToShow: 1,
  swipeToSlide: true,
  variableWidth: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
};

export const BrandsCarousel = ({
  items,
  onSelect = false,
  amount = null,
  infinite = false,
  onDelete = false,
  checkedBrands = [],
  hideText = false,
  showAmount = false,
  size = 80
}) => {
  if (!_isArray(items)) {
    return <>Something went wrong</>;
  }

  if (!items?.length) {
    return <></>;
  }

  return (
    <Slider {...{ ...settings, infinite }}>
      {items?.map((element) => {
        const { fixedLoad, variableLoad } = element;
        let isError = false;
        const isDisabled = _findIndex(
          checkedBrands,
          (el) => el.uuid === element.uuid
        );

        if (amount) {
          const amountCent = Number(amount || 0) * 100;
          if (!!amountCent && fixedLoad?.amounts?.length) {
            isError = !_some(fixedLoad.amounts, (o) => o === amountCent);
          } else if (!!amountCent) {
            isError = amount
              ? amountCent < variableLoad?.minimumAmount ||
                amountCent > variableLoad?.maximumAmount
              : false;
          }
        }

        return (
          <Box
            key={element.uuid}
            data-index={element.uuid}
            sx={{ px: { xs: size < 60 ? 1 : 2.5, md: size < 60 ? 1 : 3 } }}
          >
            <BrandsLogo
              data={element}
              size={size}
              hideText={hideText}
              onRemove={onDelete}
              onSelect={onSelect}
              status={{
                error: isError,
                disabled: isDisabled !== -1,
                showAmount: showAmount
              }}
            />
          </Box>
        );
      })}
    </Slider>
  );
};

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { palette } from "settings";
import { displayFullMoney } from "helpers/money";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import Fade from "@mui/material/Fade";

const CustomLabel = ({ data }) => (
  <Stack direction={"row"} gap={1 / 2} alignItems={"center"}>
    <Box
      component={"span"}
      sx={{
        display: "inline-block",
        width: 14,
        height: 14,
        borderRadius: "50%",
        background: data.color
      }}
    />
    <Typography fontWeight={600} variant={"smallTitle"} component={"span"}>
      {data.name}
    </Typography>
    <Typography variant={"smallTitle"} component={"span"}>
      {displayFullMoney(data.value)}
    </Typography>
  </Stack>
);

const AvailableBalance = ({ balance, loading, isCampaignEnabled }) => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [chartWidth, setChartWidth] = useState(0);

  useEffect(() => {
    setChartWidth(0);
    handleResize();
  }, [ref, loading]);

  const handleResize = () => {
    if (ref?.current) {
      setChartWidth(ref.current.clientWidth);
    }
  };

  const data = useMemo(() => {
    return [
      {
        name: "Active balance",
        value: +balance?.availableBalance || 0,
        color: palette.primary.main
      },
      {
        name: "Pending balance",
        value: +balance?.pendingBalance || 0,
        color: palette.grayscale[200]
      },
      {
        name: "Funds in campaigns",
        value: +balance?.reservedBalance || 0,
        color: palette.purple[200]
      }
    ];
  }, [balance]);

  if (loading) {
    return <Skeleton variant="rounded" height={400} width={"100%"} />;
  }

  return (
    <Paper
      ref={ref}
      elevation={2}
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: 2,
        padding: 3
      }}
    >
      <Typography
        mb={1}
        variant={"subLead"}
        fontWeight={700}
        color={"grayscale.800"}
      >
        Available Balance
      </Typography>
      <ResponsiveContainer
        width="100%"
        height={chartWidth * 0.42}
        onResize={handleResize}
      >
        <PieChart>
          <Pie
            animationBegin={300}
            animationDuration={1000}
            data={data}
            legendType={"none"}
            label={false}
            labelLine={false}
            dataKey="value"
            nameKey="name"
            cx={"50%"}
            cy={"100%"}
            paddingAngle={-4}
            cornerRadius={8}
            startAngle={185}
            endAngle={0}
            innerRadius={chartWidth * 0.29}
            outerRadius={chartWidth * 0.4}
          >
            {data.map((entry, index) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.color}
                  style={{ outline: "none" }}
                />
              );
            })}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <Fade in={!!chartWidth} timeout={1300} easing={"ease-in-out"}>
        <Box>
          <Typography
            variant={"h5"}
            color={"primary"}
            lineHeight={{ xs: "1" }}
            fontWeight={900}
            sx={{
              fontSize: { xs: 24, sm: 28, lg: "1.9vw" },
              position: "relative",
              textAlign: "center",
              top: { xs: -28, sm: -32, lg: "-2.1vw" }
            }}
          >
            {displayFullMoney(balance?.availableBalance || 0)}
          </Typography>
          <Stack gap={2} mx={"auto"} mt={-1} mb={3} width={"fit-content"}>
            <CustomLabel data={data[1]} />
            {isCampaignEnabled && <CustomLabel data={data[2]} />}
          </Stack>
          <Button
            fullWidth
            size={"small"}
            sx={{ padding: "9px 24px" }}
            onClick={() => navigate(null, { state: { showAddFundsBar: true } })}
          >
            Add funds
          </Button>
        </Box>
      </Fade>
    </Paper>
  );
};
export default AvailableBalance;

import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import _map from "lodash/map";
import _difference from "lodash/difference";
import {
  emailValidationPattern,
  FieldEmail,
  FieldPhone,
  FieldTagsAutocomplete,
  FieldText,
  validatePhone
} from "components/fields";
import { useSnackbar } from "notistack";
import { useAuth } from "components/AuthProvider";
import { createApiCall } from "helpers/api";
import { stopPropagate } from "helpers/stopPropagate";
import { fieldCannotBeEmpty, max32characters, palette } from "settings";
import { defaultRecipientsForm } from "./constants";

import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";

export const RecipientForm = ({ recipientUuid, open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useAuth();
  const methods = useForm();
  const [activeRecipient, setActiveRecipient] = useState(false);
  const [recipient, setRecipient] = useState(null);
  const [recipientLoading, setRecipientLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleSubmit, reset, setValue, watch, trigger } = methods;
  const emailValue = watch("email");
  const phoneValue = watch("phoneNumber");

  const validationRules = useMemo(() => {
    if (phoneValue?.length && validatePhone(phoneValue)) {
      trigger("email");
      return {
        email: {
          required: false,
          pattern: emailValidationPattern
        },
        phoneNumber: {
          required: fieldCannotBeEmpty,
          validate: {
            check: validatePhone
          }
        }
      };
    }
    if (emailValue?.length) {
      trigger("phoneNumber");
      return {
        email: {
          required: fieldCannotBeEmpty,
          pattern: emailValidationPattern
        },
        phoneNumber: {
          required: false,
          validate: {
            check: validatePhone
          }
        }
      };
    }
    return {
      email: {
        required: fieldCannotBeEmpty,
        pattern: emailValidationPattern
      },
      phoneNumber: {
        required: fieldCannotBeEmpty,
        validate: {
          check: validatePhone
        }
      }
    };
  }, [emailValue, phoneValue]);

  useEffect(() => {
    setRecipient(null);
    if (recipientUuid) {
      setRecipientLoading(true);
      createApiCall({
        url: `customers/${session?.clientUuid}/${recipientUuid}`
      }).then(({ data, status }) => {
        if (status === 200 && data?.customer) {
          const { customer } = data;
          setRecipient(customer);
          setActiveRecipient(customer.disabled === "FALSE");
          reset(customer);
          setValue("firstName", customer.firstName || "");
          setValue("lastName", customer.lastName || "");
          setValue("email", customer.email || "");
          setValue("tags", customer.tags || []);
          setValue(
            "phoneNumber",
            data.customer.phoneNumber ? `+1${data.customer.phoneNumber}` : ""
          );
        } else {
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
        }
        setRecipientLoading(false);
      });
    } else {
      reset(defaultRecipientsForm);
    }
  }, [recipientUuid, open]);

  const onSubmit = handleSubmit((data) => {
    const { email, firstName, lastName, tags, phoneNumber } = data;
    const reqData = {
      client_uuid: session?.clientUuid,
      email,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber?.replace(/[ \-()+_]/g, "")?.slice(1),
      clientTagUuids: _map(tags, "clientTagUuid")
    };
    if (recipientUuid) {
      reqData.customer_uuid = recipientUuid;
    }
    const toRemoveTags = _difference(
      _map(recipient?.tags, "clientTagUuid"),
      _map(tags, "clientTagUuid")
    );
    setLoading(true);
    createApiCall({
      url: `customers`,
      method: "POST",
      data: reqData
    }).then(({ data, status }) => {
      setLoading(false);
      if (status === 200) {
        enqueueSnackbar(
          recipientUuid
            ? `Recipient ${firstName} ${lastName} was updated successfully.`
            : `A new recipient was added successfully.`,
          { variant: "success" }
        );
        if (toRemoveTags.length) {
          createApiCall({
            url: "tags/customers",
            method: "PATCH",
            data: {
              customer_uuids: [recipientUuid],
              action: "REMOVE",
              client_tag_uuids: toRemoveTags
            }
          }).then(({ status }) => {
            if (status === 200) {
              setLoading(false);
              closeHandler(true);
            }
          });
        } else {
          setLoading(false);
          closeHandler(true);
        }
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
        setLoading(false);
      }
    });
  });

  const onChangeStatus = () => {
    setLoading(true);
    createApiCall({
      url: `customers/status`,
      method: "PATCH",
      data: {
        customer_uuid: recipientUuid,
        disabled: activeRecipient ? "TRUE" : "FALSE"
      }
    }).then(({ data, status }) => {
      if (status === 200) {
        setActiveRecipient(!activeRecipient);
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  };

  const closeHandler = (reload: false) => {
    onClose(
      reload || activeRecipient.toString().toUpperCase() === recipient?.disabled
    );
  };

  return (
    <Drawer open={open} onClose={() => closeHandler(false)}>
      <Stack sx={{ height: "100%" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            p: 3,
            borderBottom: `1px solid ${palette.grayscale["200"]}`
          }}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
            <svg width={24} height={24} color={palette.purple["500"]}>
              <use href={`#${recipientUuid ? "pencil" : "add-user"}`} />
            </svg>
            <Typography
              variant={"subLead"}
              fontWeight={700}
              color={"purple.900"}
            >
              {recipientUuid ? "Edit" : "Add a New Recipient"}
            </Typography>
          </Stack>
          <IconButton size={"small"} onClick={() => closeHandler(false)}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use href="#close" />
            </svg>
          </IconButton>
        </Stack>
        <Stack
          component={"form"}
          onSubmit={stopPropagate(onSubmit)}
          noValidate
          sx={{
            p: 3,
            flex: "0 1 100%",
            overflowY: "auto",
            height: "100%"
          }}
        >
          <Stack gap={"16px"} paddingBottom={"40px"} sx={{ flexGrow: 1 }}>
            {recipientLoading ? (
              <Skeleton variant="rounded" height={70} width={"100%"} />
            ) : (
              <FieldText
                {...methods}
                fieldName={"firstName"}
                label={"First Name"}
                placeholder={"Enter first name"}
                validation={{
                  required: fieldCannotBeEmpty,
                  maxLength: {
                    value: 32,
                    message: max32characters
                  }
                }}
              />
            )}
            {recipientLoading ? (
              <Skeleton variant="rounded" height={70} width={"100%"} />
            ) : (
              <FieldText
                {...methods}
                fieldName={"lastName"}
                label={"Last Name"}
                placeholder={"Enter last name"}
                validation={{
                  required: fieldCannotBeEmpty,
                  maxLength: {
                    value: 32,
                    message: max32characters
                  }
                }}
              />
            )}
            {recipientLoading ? (
              <Skeleton variant="rounded" height={70} width={"100%"} />
            ) : (
              <FieldEmail
                {...methods}
                validation={validationRules.email}
                label={"Email"}
              />
            )}
            {recipientLoading ? (
              <Skeleton variant="rounded" height={70} width={"100%"} />
            ) : (
              <FieldPhone
                {...methods}
                validation={validationRules.phoneNumber}
                fieldName={"phoneNumber"}
                placeholder={"Enter phone number"}
                label={"Phone Number"}
              />
            )}
            {recipientLoading ? (
              <Skeleton variant="rounded" height={70} width={"100%"} />
            ) : (
              <FieldTagsAutocomplete {...methods} rules={{}} />
            )}

            {recipient && (
              <>
                <Box mt={0.5}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Typography fontWeight={700} color={"grayscale.800"}>
                      Status is{" "}
                      <Typography
                        component={"span"}
                        color={"vibrantBlue.500"}
                        fontWeight={700}
                      >
                        {activeRecipient ? "active" : "inactive"}
                      </Typography>
                    </Typography>
                    <Switch
                      disabled={loading}
                      checked={activeRecipient}
                      onChange={onChangeStatus}
                    />
                  </Stack>
                  <Typography color={"grayscale.800"}>
                    Active users are able to take part in campaigns.
                  </Typography>
                </Box>

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography fontWeight={700} color={"grayscale.800"}>
                    Created at
                  </Typography>
                  <Typography color={"grayscale.800"}>
                    {format(
                      new Date(recipient.createdAt),
                      "MM/dd/yyyy, HH:mm:ss a"
                    )}
                  </Typography>
                </Stack>

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography fontWeight={700} color={"grayscale.800"}>
                    Updated at
                  </Typography>
                  <Typography color={"grayscale.800"}>
                    {format(
                      new Date(recipient.updatedAt),
                      "MM/dd/yyyy, HH:mm:ss a"
                    )}
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>
          <LoadingButton
            loading={loading || recipientLoading}
            fullWidth
            variant={"contained"}
            type={"submit"}
          >
            {recipientUuid ? "Apply changes" : "Add"}
          </LoadingButton>
        </Stack>
      </Stack>
    </Drawer>
  );
};

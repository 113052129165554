import React from "react";
import LoginPage from "pages/portal/auth/login";
import SignUpPage from "pages/portal/auth/sign-up";
import CreatePasswordPage from "pages/portal/auth/create-password";
import ResetPasswordPage from "pages/portal/auth/reset-password";

export const LOGIN_PAGE = "/business/login";
export const SIGN_UP_PAGE = "/business/sign-up";
export const CREATE_PASSWORD_PAGE = "/business/create-password";
export const RESET_PASSWORD_PAGE = "/business/account/reset-password";

const routes = [
  {
    id: 0,
    element: <LoginPage />,
    path: LOGIN_PAGE,
    isPublic: true
  },
  {
    id: 1,
    element: <SignUpPage />,
    path: SIGN_UP_PAGE,
    isPublic: true
  },
  {
    id: 2,
    element: <CreatePasswordPage />,
    path: CREATE_PASSWORD_PAGE,
    isPublic: true
  },
  {
    id: 3,
    element: <ResetPasswordPage />,
    path: RESET_PASSWORD_PAGE,
    isPublic: true
  }
];

export default routes;

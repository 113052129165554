import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FieldText } from "components/fields";
import { useSnackbar } from "notistack";
import { useAuth } from "components/AuthProvider";
import { createApiCall } from "helpers/api";
import { fieldCannotBeEmpty, tagIsNotCorrect, tagsRex } from "settings";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";

const AddTagForm = ({ onClose, onAddTag }) => {
  const { session } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm();
  const { handleSubmit, watch } = methods;

  const [loading, setLoading] = useState(false);
  const tagValue = watch("name");

  const onSubmit = handleSubmit(({ name }) => {
    setLoading(true);
    createApiCall({
      method: "POST",
      url: `tags`,
      data: {
        client_uuid: session?.clientUuid,
        name
      }
    }).then(({ status, data }) => {
      if (status === 200 && data.tag) {
        onAddTag(data.tag);
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  });

  return (
    <Box component={"form"} onSubmit={onSubmit}>
      <Typography fontWeight={700} mb={2}>
        Adding New
      </Typography>
      <FieldText
        {...methods}
        autoFocus
        label={"Name"}
        fieldName={"name"}
        validation={{
          required: fieldCannotBeEmpty,
          pattern: {
            value: tagsRex,
            message: tagIsNotCorrect
          }
        }}
      />
      <Stack
        sx={{ m: "16px 0 0" }}
        direction={"row"}
        alignItems={"center"}
        gap={"8px"}
        justifyContent={"space-between"}
      >
        <Button variant={"outlinedPrimary"} fullWidth onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          disabled={!tagValue}
          variant={"contained"}
          fullWidth
          type={"submit"}
        >
          Add
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default AddTagForm;

import React from "react";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";

export const TableOptions = ({ anchorEl, onClose, children }) => {
  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      transitionDuration={anchorEl ? 500 : 150}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      sx={{
        ".MuiPaper-root": {
          borderTopRightRadius: 0
        }
      }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Box sx={{ position: "relative" }}>
          <Stack sx={{ padding: "6px", minWidth: "230px" }} gap={"20px"}>
            {children}
          </Stack>
        </Box>
      </ClickAwayListener>
    </Popover>
  );
};

import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import includes from "lodash/includes";
import filter from "lodash/filter";
import { fieldCannotBeEmpty, max32characters, palette, roles } from "settings";
import {
  CustomSelect,
  FieldEmail,
  FieldPhone,
  FieldText
} from "components/fields";
import { useAuth } from "components/AuthProvider";
import { useSnackbar } from "notistack";
import { createApiCall } from "helpers/api";

import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiLink from "@mui/material/Link";

export const AddUser = ({ onClose, open, onSubmitted, onOpenRoles }) => {
  const { session } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm();
  const [loading, setLoading] = useState(false);
  const { handleSubmit } = methods;

  const filteredRoles = useMemo(() => {
    if (includes(session?.roles, "CLIENT_MANAGER")) {
      return filter(
        roles,
        (role) =>
          role.value === "CLIENT_MANAGER" || role.value === "CLIENT_AGENT"
      );
    }
    return roles;
  }, [session]);

  const onSubmit = handleSubmit(({ role, ...data }) => {
    setLoading(true);
    createApiCall({
      url: `user-management/users`,
      method: "POST",
      data: {
        parent_client: {
          uuid: session?.clientUuid
        },
        user: {
          ...data,
          phoneNumber: data.phoneNumber?.replace(/[ \-()+_]/g, "")?.slice(1)
        }
      }
    }).then(({ data, status }) => {
      setLoading(false);
      if (status === 200) {
        createApiCall({
          url: `user-management/users/${data?.user?.uuid}/roles`,
          method: "POST",
          data: {
            roleName: role
          }
        }).then(({ data, status }) => {
          setLoading(false);
          if (status === 200) {
            onSubmitted();
          } else {
            enqueueSnackbar(data?.title || "Something went wrong", {
              variant: "error"
            });
          }
        });
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
    });
  });

  return (
    <Drawer open={open} onClose={onClose}>
      <Stack sx={{ height: "100%" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            p: 3,
            borderBottom: `1px solid ${palette.grayscale["200"]}`
          }}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
            <svg width={24} height={24} color={palette.purple["500"]}>
              <use href="#add-user" />
            </svg>
            <Typography
              variant={"subLead"}
              fontWeight={700}
              color={"purple.900"}
            >
              Add User
            </Typography>
          </Stack>
          <IconButton size={"small"} onClick={onClose}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use href="#close" />
            </svg>
          </IconButton>
        </Stack>
        <Stack
          component={"form"}
          onSubmit={onSubmit}
          noValidate
          sx={{
            p: 3,
            flex: "0 1 100%",
            overflowY: "auto",
            height: "100%"
          }}
        >
          <Stack gap={"16px"} paddingBottom={"40px"} sx={{ flexGrow: 1 }}>
            <Box>
              <CustomSelect
                {...methods}
                options={filteredRoles}
                fieldName={"role"}
                label={"Role"}
              />
              <MuiLink
                sx={{ mt: "8px", textAlign: "end", display: "block" }}
                variant={"smallTitle"}
                color={palette.primary.main}
                underline="none"
                component={"button"}
                type={"button"}
                onClick={onOpenRoles}
              >
                Learn more about role descriptions
              </MuiLink>
            </Box>
            <FieldText
              {...methods}
              fieldName={"firstName"}
              label={"First Name"}
              placeholder={"Enter first name"}
              validation={{
                required: fieldCannotBeEmpty,
                maxLength: {
                  value: 32,
                  message: max32characters
                }
              }}
            />
            <FieldText
              {...methods}
              fieldName={"lastName"}
              label={"Last Name"}
              placeholder={"Enter last name"}
              validation={{
                required: fieldCannotBeEmpty,
                maxLength: {
                  value: 32,
                  message: max32characters
                }
              }}
            />
            <FieldEmail {...methods} label={"Email"} />
            <FieldPhone
              {...methods}
              label={"Phone Number"}
              fieldName={"phoneNumber"}
            />
          </Stack>
          <Typography
            sx={{ mt: "auto", mb: "16px" }}
            variant={"smallTitle"}
            color={palette.grayscale["800"]}
          >
            Clicking “add user” will send a confirmation message with an
            activation link to the new users email provided above.
          </Typography>
          <LoadingButton
            loading={loading}
            fullWidth
            variant={"contained"}
            type={"submit"}
          >
            Add User
          </LoadingButton>
        </Stack>
      </Stack>
    </Drawer>
  );
};

import React from "react";
import { greenAlert, palette } from "settings";
import Typography from "@mui/material/Typography";

export const BooleanStatus = ({ data }) => (
  <Typography
    variant={"smallTitle"}
    component={"span"}
    sx={{
      color: data ? greenAlert : palette.vibrantRed["300"]
    }}
  >
    {data ? "Enabled" : "Disabled"}
  </Typography>
);

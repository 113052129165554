import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import includes from "lodash/includes";
import filter from "lodash/filter";
import { accountSidebarLinks } from "../constants";
import { palette, USERS_PAGE } from "settings";
import { useAuth } from "components/AuthProvider";

import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

export const AccountSidebar = () => {
  const { logout, session, isCampaignApiClient } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname, state } = location;
  const [open, setOpen] = useState(false);

  const filteredAccountSidebarLinks = useMemo(() => {
    if (includes(session?.roles, "CLIENT_AGENT")) {
      return filter(
        accountSidebarLinks,
        (link) => link.path !== USERS_PAGE && link.action !== "payment-methods"
      );
    }
    return accountSidebarLinks;
  }, [session]);

  useEffect(() => setOpen(state?.showAccountBar || false), [state]);

  const onMenuClick = (action) => {
    if (action === "payment-methods") {
      navigate(null, { state: { showPaymentMethods: true } });
    }
  };

  const onClose = () => navigate(pathname, {});

  return (
    <Drawer
      open={open}
      onClose={onClose}
      sx={{
        zIndex: (theme) => theme.zIndex.modal + 1
      }}
    >
      <Stack
        sx={{
          p: "30px 24px 0",
          height: "100%"
        }}
      >
        <Stack
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}
          gap={"12px"}
          sx={{
            paddingBottom: "30px",
            mb: "24px",
            borderBottom: `1px solid ${palette.gray[200]}`
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            flexWrap={"wrap"}
            gap={"12px"}
          >
            <Avatar
              sx={{
                background: palette.primary.main,
                width: 24,
                height: 24
              }}
              alt={session?.email}
            >
              <Typography variant={"leadCaption"} sx={{ color: "#fff" }}>
                {session?.email?.charAt(0).toUpperCase()}
              </Typography>
            </Avatar>
            <Typography
              variant={"subLead"}
              sx={{ fontWeight: 700, wordBreak: "break-word" }}
            >
              Hi {session?.email}
            </Typography>
          </Stack>

          <IconButton size={"small"} onClick={onClose}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use href="#close" />
            </svg>
          </IconButton>
        </Stack>
        <Stack gap={"40px"} sx={{ py: 3 }}>
          {filteredAccountSidebarLinks.map(
            ({ id, path, action, title, icon, onlyVerifiedUser }) => {
              if (
                onlyVerifiedUser &&
                !isCampaignApiClient &&
                session?.kycStatus !== "ACCEPTED"
              ) {
                return null;
              }
              return (
                <Typography
                  key={id}
                  variant={"subLead"}
                  as={path ? Link : "button"}
                  to={path || ""}
                  color={"grayscale.800"}
                  onClick={() => {
                    if (action) {
                      onMenuClick(action);
                    }
                  }}
                >
                  <Stack direction={"row"} spacing={1.5}>
                    <svg
                      width={24}
                      height={24}
                      stroke={palette.grayscale["800"]}
                    >
                      <use href={icon} />
                    </svg>
                    <span>{title}</span>
                    <svg
                      width={24}
                      height={24}
                      stroke={palette.grayscale["800"]}
                      style={{ marginLeft: "auto" }}
                    >
                      <use href={"#cheveron-right"} />
                    </svg>
                  </Stack>
                </Typography>
              );
            }
          )}
        </Stack>
        <Box
          sx={{
            mt: "auto",
            borderTop: `1px solid ${palette.gray[200]}`,
            padding: "48px 24px"
          }}
        >
          <Typography
            onClick={logout}
            variant={"subLead"}
            sx={{
              color: palette.grayscale[800],
              letterSpacing: "0.005em",
              cursor: "pointer"
            }}
          >
            Log Out
          </Typography>
        </Box>
      </Stack>
    </Drawer>
  );
};

export default AccountSidebar;

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { palette, redAlert } from "../../settings";

import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const NotificationSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname, state } = location;
  const [open, setOpen] = useState(false);
  const isNewNotifications = true;

  useEffect(() => setOpen(state?.showNotificationBar || false), [state]);

  const onClose = () => navigate(pathname, {});

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={"right"}
      sx={{
        ".MuiPaper-root": {
          boxShadow: "-10px 0 20px rgba(0, 0, 0, 0.25)"
        }
      }}
    >
      <Stack
        sx={{
          p: "30px 24px 48px",
          height: "100%",
          width: { xs: "100%", md: 550 }
        }}
      >
        <Stack
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}
          gap={2}
          sx={{
            paddingBottom: "30px",
            mb: "24px",
            borderBottom: `1px solid ${palette.gray[200]}`
          }}
        >
          <IconButton aria-label="notifications">
            <Box
              sx={{
                display: "flex",
                position: "relative",
                "&:before": {
                  content: isNewNotifications ? '""' : "none",
                  position: "absolute",
                  right: 4,
                  top: 0,
                  width: 8,
                  height: 8,
                  background: redAlert,
                  borderRadius: "50%"
                },
                svg: { minWidth: 24, color: palette.primary.main }
              }}
            >
              <svg width={24} height={24}>
                <use xlinkHref={"#notification"} />
              </svg>
            </Box>
          </IconButton>

          <IconButton size={"small"} onClick={onClose}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use href="#close" />
            </svg>
          </IconButton>
        </Stack>
        <Box sx={{ padding: "24px 0" }}>
          <Stack direction={"row"} spacing={"12px"} padding={"0 0 0 24px"}>
            <Box
              width={16}
              height={16}
              sx={{
                minWidth: 16,
                minHeight: 16,
                background: redAlert,
                borderRadius: "50%"
              }}
            />
            <Typography
              variant={"subLead"}
              sx={{
                color: palette.grayscale[400]
              }}
            >
              In order to start sending rewards you must complete our KYC
              application.{" "}
              <Typography
                as={Link}
                variant={"subLead"}
                to={"/portal/KYC"}
                sx={{
                  color: palette.primary.main,
                  textDecoration: "underline"
                }}
              >
                Start your application
              </Typography>
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Drawer>
  );
};

import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Dashboard from "components/pages/portal/Dashboard";
import { WelcomeHelpModal } from "components/pages/portal";
import { useAuth } from "components/AuthProvider";
import { KYC_PAGE } from "settings";

const DashboardPage = () => {
  const navigate = useNavigate();
  const { session } = useAuth();

  const open = useMemo(() => {
    if (session) {
      return session?.kycStatus === "DENIED";
    }
    return false;
  }, [session]);

  return (
    <div>
      <WelcomeHelpModal open={open} onClose={() => navigate(KYC_PAGE)} />
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Dashboard />
    </div>
  );
};

export default DashboardPage;

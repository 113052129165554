import {
  DASHBOARD_PAGE,
  TRANSACTIONS_PAGE,
  SUPPORT_PAGE,
  COMMISSIONS_PAGE,
  USERS_PAGE,
  KYC_PAGE,
  CAMPAIGNS_PAGE,
  RECIPIENTS_PAGE
} from "settings";

export const HeaderLinks = [
  {
    id: 0,
    title: "Request a Demo",
    path: "https://www.raise.com/business/#request-demo"
  },
  {
    id: 1,
    title: "Brand Catalog",
    path: "https://www.raise.com/business/brands/"
  },
  {
    id: 2,
    title: "API Doc",
    path: "https://docs.raise.com/"
  }
];

export const FooterLinks = [
  {
    id: 0,
    title: "Company",
    path: "#",
    submenu: [
      {
        id: 0,
        title: "Privacy Policy",
        path: "https://www.raise.com/business/privacy-policy"
      },
      {
        id: 1,
        title: "Terms of Service",
        path: "https://www.raise.com/business/terms-of-service/"
      },
      {
        id: 2,
        title: "Disclosure Policy",
        path: "https://www.raise.com/business/disclosure-policy"
      }
    ]
  },
  {
    id: 1,
    title: "API Documentation",
    path: "#",
    submenu: [
      {
        id: 0,
        title: "Raise",
        path: "https://docs.raise.com/"
      }
    ]
  }
];

export const SocialNetwork = [
  {
    id: 0,
    title: "Stay in Touch",
    path: "#",
    submenu: [
      {
        id: 0,
        icon: "facebook",
        path: "https://www.facebook.com/RaiseMarketplace"
      },
      {
        id: 1,
        icon: "instagram",
        path: "https://www.instagram.com/raise/"
      },
      {
        id: 2,
        icon: "twitter",
        path: "https://twitter.com/RaiseMarket"
      }
    ]
  }
];

export const SidebarLinks = [
  {
    id: 0,
    title: "Dashboard",
    path: DASHBOARD_PAGE,
    icon: "#home",
    onlyVerifiedUser: false
  },
  {
    id: 1,
    title: "Commissions",
    path: COMMISSIONS_PAGE,
    icon: "#commissions",
    onlyVerifiedUser: true,
    onlyApiClient: true
  },
  {
    id: 2,
    title: "Transactions",
    path: TRANSACTIONS_PAGE,
    icon: "#transactions",
    onlyVerifiedUser: true,
    onlyApiClient: true
  },
  {
    id: 3,
    title: "Campaigns",
    path: CAMPAIGNS_PAGE,
    icon: "#campaigns",
    onlyVerifiedUser: true,
    onlyCampaignAPIEnabled: true
  },
  {
    id: 4,
    title: "Recipients list",
    path: RECIPIENTS_PAGE,
    icon: "#user",
    onlyVerifiedUser: true,
    onlyCampaignAPIEnabled: true
  },
  {
    id: 5,
    title: "Verification",
    path: KYC_PAGE,
    icon: "#accountKYC",
    onlyVerifiedUser: false
  }
];

export const accountSidebarLinks = [
  {
    id: 0,
    title: "User Management",
    path: USERS_PAGE,
    icon: "#share",
    onlyVerifiedUser: true
  },
  {
    id: 1,
    title: "Payment Methods",
    action: "payment-methods",
    icon: "#credit-card",
    onlyVerifiedUser: true
  },
  {
    id: 2,
    title: "Support",
    path: SUPPORT_PAGE,
    icon: "#chat-alt-2",
    onlyVerifiedUser: false
  }
];

import React from "react";
import { greenAlert, palette } from "settings";
import Typography from "@mui/material/Typography";

export const Status = ({ data }) => {
  const lowerCaseData = data.toLowerCase();
  return (
    <Typography
      variant={"smallTitle"}
      component={"span"}
      sx={[
        (lowerCaseData === "successful" ||
          lowerCaseData === "success" ||
          lowerCaseData === "active" ||
          lowerCaseData === "enabled" ||
          lowerCaseData === "sent") && {
          color: greenAlert
        },
        (lowerCaseData === "dispute" || lowerCaseData === "disputed") && {
          color: palette.vibrantYellow["500"]
        },
        (lowerCaseData === "pending" || lowerCaseData === "redeemed") && {
          color: palette.vibrantBlue["300"]
        },
        (lowerCaseData === "failure" ||
          lowerCaseData === "disabled" ||
          lowerCaseData === "voided") && {
          color: palette.vibrantRed["300"]
        },
        lowerCaseData === "not sent" && {
          color: palette.grayscale["400"]
        },
        {
          textTransform: "capitalize"
        }
      ]}
    >
      {data.toLowerCase()}
    </Typography>
  );
};

export const numberFormat = (number = 0, opts = {}) =>
  new Intl.NumberFormat("en-US", {
    ...opts
  }).format(number);

export const median = (values) => {
  values.sort(function (a, b) {
    return a - b;
  });

  var half = Math.floor(values.length / 2);

  if (values.length % 2) return values[half];
  else return (values[half - 1] + values[half]) / 2.0;
};

export const calculatePercentDifference = (oldValue, newValue) => {
  if (!newValue && !oldValue) return 0;
  const percentDifference = ((newValue - oldValue) / (oldValue || 1)) * 100;
  return +percentDifference.toFixed(2);
};

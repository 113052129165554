import React, { useMemo } from "react";
import { format } from "date-fns";
import { palette } from "settings";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

const CampaignInfo = ({
  campaign,
  width,
  period = "DAY",
  onOpen,
  onClose,
  isStartBeforeRange = false,
  isChartView = false
}) => {
  const { name, customersCount, codesCreated, codesRedeemed, dateRange } =
    campaign;

  const size = useMemo(() => {
    if (width <= 200) {
      return "md";
    }
    return "lg";
  }, [width]);

  const codes = useMemo(() => {
    return {
      redeemed: Math.round((codesRedeemed / codesCreated) * 100),
      notRedeemed: !codesRedeemed
        ? 100
        : Math.round(((codesCreated - codesRedeemed) / codesCreated) * 100)
    };
  }, [codesCreated, codesRedeemed]);

  return (
    <Box
      xmlns="http://www.w3.org/1999/xhtml"
      sx={[
        {
          p: 2,
          minWidth: onClose ? "90%" : 94,
          borderRadius: 1,
          background: "rgba(245, 245, 245, 1)",
          boxShadow: "0px 4px 8px 0px rgba(39, 27, 97, 0.15)",
          position: "relative"
        },
        isStartBeforeRange && {
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            right: "100%",
            top: 0,
            height: "100%",
            width: 40,
            background:
              "linear-gradient(-90deg, rgba(245, 245, 245, 1), rgba(245, 245, 245, .1))"
          }
        }
      ]}
    >
      {period === "DAY" ? (
        <>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={2}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={1}
              sx={{
                width: size === "lg" ? "calc(100% - 100px)" : "100%"
              }}
            >
              {isChartView ? (
                <Tooltip title={name} placement="top">
                  <Typography
                    variant={"smallTitle"}
                    fontWeight={700}
                    color={"grayscale.800"}
                    className={"ellipsis"}
                    sx={{
                      width: "auto !important"
                    }}
                  >
                    {name}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  variant={"smallTitle"}
                  fontWeight={700}
                  color={"grayscale.800"}
                  className={"ellipsis"}
                  sx={{
                    width: "auto !important"
                  }}
                >
                  {name}
                </Typography>
              )}
              {size === "lg" && (
                <Typography
                  variant={"leadCaption"}
                  color={"grayscale.800"}
                  className={"ellipsis"}
                  sx={{
                    width: "auto !important"
                  }}
                >
                  {format(new Date(dateRange.createdAtFrom), "MM/dd/yyyy")} -{" "}
                  {format(new Date(dateRange.createdAtTo), "MM/dd/yyyy")}
                </Typography>
              )}
            </Stack>
            {size === "lg" && (
              <Chip
                label={`${customersCount} recipient${
                  customersCount > 1 ? "s" : ""
                }`}
                color={"purple"}
                size={"small"}
                sx={{}}
              />
            )}
            {onClose && (
              <IconButton size={"small"} onClick={onClose} sx={{ ml: 1 }}>
                <svg width={24} height={24} stroke={palette.purple["500"]}>
                  <use href="#close" />
                </svg>
              </IconButton>
            )}
          </Stack>
          <Stack direction={"row"} spacing={0.5}>
            {!!codes.redeemed && (
              <Stack
                spacing={0.5}
                sx={{
                  width: `${codes.redeemed}%`,
                  minWidth: 50
                }}
              >
                <Box
                  sx={{
                    height: 8,
                    borderRadius: "100px",
                    background: palette.purple["500"]
                  }}
                />
                <Typography
                  variant={"leadCaption"}
                  color={"grayscale.800"}
                  className={"ellipsis"}
                >
                  {codes.redeemed}% Redeemed
                </Typography>
              </Stack>
            )}
            {!!codes.notRedeemed && (
              <Stack
                spacing={0.5}
                sx={{
                  width: `${codes.notRedeemed}%`,
                  minWidth: 50
                }}
              >
                <Box
                  sx={{
                    height: 8,
                    borderRadius: "100px",
                    background: palette.grayscale["500"]
                  }}
                />
                <Typography
                  variant={"leadCaption"}
                  color={"grayscale.800"}
                  className={"ellipsis"}
                >
                  {codes.notRedeemed}% Not Redeemed
                </Typography>
              </Stack>
            )}
          </Stack>
        </>
      ) : (
        <Stack direction={"row"} alignItems={"center"}>
          {isChartView ? (
            <Tooltip title={name} placement="top">
              <Typography
                variant={"smallTitle"}
                fontWeight={700}
                color={"grayscale.800"}
                className={"ellipsis"}
                sx={{
                  width: "auto !important"
                }}
              >
                {name}
              </Typography>
            </Tooltip>
          ) : (
            <Typography
              variant={"smallTitle"}
              fontWeight={700}
              color={"grayscale.800"}
              className={"ellipsis"}
              sx={{
                width: "auto !important"
              }}
            >
              {name}
            </Typography>
          )}
          <IconButton size={"small"} onClick={() => onOpen(campaign)}>
            <svg width={24} height={24} color={palette.purple["500"]}>
              <use href="#cheveron-right" />
            </svg>
          </IconButton>
        </Stack>
      )}
    </Box>
  );
};

export default CampaignInfo;

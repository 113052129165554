import React, { useEffect, useRef, useState } from "react";
import {
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine
} from "recharts";

import { palette } from "settings";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const formatYAxisValue = (tickItem) => {
  if (tickItem >= 1000) {
    return `${Math.round(tickItem / 1000)}k`;
  }
  return tickItem;
};

const formatXAxisDate = (tickItem) => {
  const date = new Date(tickItem);
  return `${date.getMonth() + 1}/${date.getDate()}/${date
    .getFullYear()
    .toString()
    .slice(2)}`;
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { payload: data } = payload[0];
    return (
      <Paper
        elevation={2}
        sx={{
          p: 1,
          borderRadius: "8px",
          background: palette.primary.main
        }}
      >
        <Typography variant={"smallTitle"} color={"white"}>
          ${data.sum || 0}
        </Typography>
      </Paper>
    );
  }

  return null;
};

const CommissionsChart = ({ data }) => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState([]);
  const [yTicks, setYTicks] = useState([]);
  const [yTicksSecondary, setYTicksSecondary] = useState([]);

  useEffect(() => {
    if (data) {
      generateChartData(data);
    }
  }, [data]);

  const generateChartData = (data) => {
    const newData = data?.map((el) => ({
      ...el,
      sum: +(el.sum / 100).toFixed(2)
    }));
    setChartData(newData);

    const sums = newData.map((item) => item.sum);
    const maxValue = Math.max(...sums);
    const { ticks, secondaryTicks } = calculateTicks(maxValue);

    setYTicks(ticks);
    setYTicksSecondary(secondaryTicks);
  };

  const calculateTicks = (maxValue) => {
    let tickInterval = maxValue <= 1000 ? 100 : 1000;
    const tickCount = Math.ceil(maxValue / tickInterval) || 1;
    const tickStep = Math.max(Math.ceil(tickCount / 6), 1) || 1;
    const ticks = [];
    for (let i = 0; i <= tickCount; i += tickStep) {
      ticks.push(i * tickInterval);
    }
    const secondaryTicks = ticks
      .slice(0, -1)
      .map((value, index) => (value + ticks[index + 1]) / 2);
    return { ticks, secondaryTicks };
  };

  return (
    <Paper
      elevation={2}
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: 2,
        padding: 3
      }}
    >
      <Typography
        mb={2}
        variant={"subLead"}
        fontWeight={700}
        color={"grayscale.800"}
      >
        Commissions Earned
      </Typography>

      <Box
        sx={{
          height: 265
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            ref={chartRef}
            data={chartData}
            margin={{
              top: 24,
              bottom: 8,
              left: -32,
              right: 16
            }}
          >
            <XAxis
              dataKey="transactionDate"
              interval={"equidistantPreserveStart"}
              tickMargin={10}
              minTickGap={12}
              tickFormatter={formatXAxisDate}
              padding={{ left: 16, right: 16 }}
              tickLine={false}
              tick={{ fill: palette.grayscale[800] }}
            />
            <YAxis
              tickLine={false}
              width={70}
              type="number"
              dataKey="sum"
              axisLine={false}
              tickMargin={0}
              minTickGap={8}
              interval={"preserveStartEnd"}
              domain={["dataMin", "dataMax"]}
              ticks={yTicks}
              tickFormatter={formatYAxisValue}
              tick={{ fill: palette.grayscale[800] }}
            />
            {yTicks.map((tick) => (
              <ReferenceLine
                key={tick}
                y={tick}
                isOverflow={"extendDomain"}
                stroke={palette.grayscale[200]}
                strokeWidth={2}
                strokeDasharray="8 8"
              />
            ))}
            {yTicksSecondary.map((tick) => (
              <ReferenceLine
                key={tick}
                isOverflow={"extendDomain"}
                y={tick}
                stroke={palette.grayscale[200]}
                strokeWidth={1}
                strokeDasharray="2 2"
              />
            ))}
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="sum"
              strokeWidth={2}
              stroke={palette.primary.main}
              dot={{ r: 8 }}
              activeDot={{ r: 8 }}
              name="Amount"
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};
export default CommissionsChart;

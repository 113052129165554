import React from "react";
import { displayFullMoney } from "helpers/money";
import { numberFormat } from "helpers/math";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const StatisticItem = ({ title, number, amount }) => {
  return (
    <Box>
      <Typography variant={"smallTitle"} color={"grayscale.400"}>
        {title}
      </Typography>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
      >
        <Typography variant={"h4"} color={"grayscale.800"} fontWeight={600}>
          {numberFormat(number || 0)}
        </Typography>
        <Typography variant={"h4"} color={"grayscale.800"} fontWeight={600}>
          {amount ? displayFullMoney(amount) : `$0`}
        </Typography>
      </Stack>
    </Box>
  );
};

export default StatisticItem;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FieldText } from "components/fields";
import { midnightBlue, palette, royalBlue } from "settings";
import { useAuth } from "components/AuthProvider";
import { createApiCall } from "helpers/api";

import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";

const VerifyModal = ({ formState, open, onClose }) => {
  const { login } = useAuth();
  const methods = useForm();
  const { handleSubmit, setError } = methods;
  const [loading, setLoading] = useState(false);

  const onSubmit = handleSubmit((data) => {
    setLoading(true);
    const { email, password } = formState;
    createApiCall({
      method: "POST",
      url: "login-2fa",
      data: {
        email,
        password,
        smsCode: {
          code: data.code
        },
        // trusted_device: 0 => invalid / 1 => untrusted / 2 => trusted
        trusted_device: 0
      }
    })
      .then(({ data }) => {
        setLoading(false);
        if (data.token) {
          login(data.token);
        } else {
          setError("code", {
            type: "manual",
            message: "Invalid code"
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setError("code", {
          type: "manual",
          message: "Invalid code"
        });
      });
  });

  const resendCodeHandler = () => {
    console.log("resendCodeHandler");
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            variant={"smallTitle"}
            fontWeight={700}
            color={midnightBlue}
          >
            {formState.isDeactivate
              ? "Your Account Has Been Deactivated"
              : "Verify Your Device"}
          </Typography>
          <IconButton size={"small"} onClick={onClose}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use href="#close" />
            </svg>
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant={"smallTitle"}
          color={"grayscale.400"}
          mt={"10px"}
          mb={2}
        >
          {formState.isDeactivate ? (
            <span>
              We take the security and integrity of our platform seriously, and
              we must ensure that all users comply with our policies. As a
              result, your access to your account has been restricted. If you
              believe that this deactivation was made in error, please contact
              our support team at{" "}
              <a href="mailto:support@raise.com" style={{ color: royalBlue }}>
                support@raise.com
              </a>{" "}
              as soon as possible. We will investigate the issue and get back to
              you promptly.
            </span>
          ) : (
            "Please enter the verification code sent to your mobile device below."
          )}
        </Typography>

        {formState.isDeactivate ? (
          <LoadingButton
            loading={false}
            fullWidth
            sx={{ mt: 1 }}
            variant={"contained"}
            type={"button"}
            onClick={onClose}
          >
            Close
          </LoadingButton>
        ) : (
          <form onSubmit={onSubmit} noValidate>
            <Stack alignItems={"center"} gap={4}>
              <Box width={"100%"}>
                <Typography
                  color={"purple.500"}
                  sx={{ fontSize: 15, mb: "3px" }}
                >
                  Code
                </Typography>
                <FieldText
                  fieldName={"code"}
                  label={""}
                  size={"small"}
                  placeholder={""}
                  autoFocus
                  {...methods}
                />
              </Box>

              <Box width={"100%"}>
                <LoadingButton
                  loading={loading}
                  fullWidth
                  variant={"contained"}
                  type={"submit"}
                >
                  Verify Device
                </LoadingButton>
                <Typography
                  mt={1}
                  variant={"smallTitle"}
                  color={royalBlue}
                  textAlign={"center"}
                >
                  Didn’t receive a code?{" "}
                  <Typography
                    component={"button"}
                    type={"button"}
                    aria-label="Resend code"
                    sx={{ cursor: "pointer" }}
                    variant={"smallTitle"}
                    color={"purple.500"}
                    onClick={resendCodeHandler}
                  >
                    Resend code
                  </Typography>
                </Typography>
              </Box>
            </Stack>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default VerifyModal;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CAMPAIGNS_PAGE, palette } from "settings";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { PreviewMail } from "components/pages/portal/campaign";
import StatisticItem from "./StatisticItem";
import { displayFullMoney } from "helpers/money";
import { createApiCall } from "helpers/api";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

export const Summary = ({ campaign }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (
      campaign?.status === "ACTIVE" ||
      campaign?.status === "EXPIRED" ||
      campaign?.status === "VOID"
    ) {
      createApiCall({
        url: `campaigns/${campaign?.uuid}/stats`
      }).then(({ data, status }) => {
        if (status === 200) {
          setStatistics(data);
        }
      });
    }
  }, [campaign?.status]);

  const startCampaign = () => {
    setLoading(true);
    createApiCall({
      method: "PATCH",
      url: `campaigns/status`,
      data: {
        campaign_uuid: campaign?.uuid,
        status: "ACTIVE"
      }
    }).then(({ data, status }) => {
      if (status === 200) {
        enqueueSnackbar(
          `A campaign ${campaign?.name} was started successfully.`,
          {
            variant: "success"
          }
        );
        navigate(CAMPAIGNS_PAGE);
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  };

  const voidCampaign = () => {
    setLoading(true);
    createApiCall({
      method: "PATCH",
      url: `campaigns/status`,
      data: {
        campaign_uuid: campaign?.uuid,
        status: "VOID"
      }
    }).then(({ data, status }) => {
      if (status === 200) {
        enqueueSnackbar(
          `A campaign ${campaign?.name} was voided successfully.`,
          {
            variant: "success"
          }
        );
        navigate(CAMPAIGNS_PAGE);
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  };

  return (
    <>
      <PreviewMail
        open={showPreview}
        onClose={() => setShowPreview(false)}
        data={campaign}
      />
      <Grid container>
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            p: {
              xs: "24px 16px",
              md: "24px 32px"
            }
          }}
        >
          <Box
            sx={{
              pb: 4,
              borderBottom: `1px solid ${palette.grayscale["200"]} `
            }}
          >
            <Typography
              variant={"subLead"}
              fontWeight={700}
              mb={2}
              color={"grayscale.800"}
            >
              Recipients
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant={"smallTitle"} color={"grayscale.400"}>
                  {campaign.deliveryMethod === "SMS"
                    ? "Phone Numbers"
                    : "Emails"}
                </Typography>
                <Typography
                  variant={"body1"}
                  fontWeight={"bold"}
                  color={"grayscale.800"}
                >
                  {campaign.customersCount || "0"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant={"smallTitle"} color={"grayscale.400"}>
                  Tags
                </Typography>
                <Typography
                  variant={"body1"}
                  fontWeight={"bold"}
                  color={"grayscale.800"}
                >
                  {campaign.tagsCount || "0"}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              pb: 4,
              pt: 4,
              borderBottom: `1px solid ${palette.grayscale["200"]} `
            }}
          >
            <Typography
              variant={"subLead"}
              fontWeight={700}
              mb={2}
              color={"grayscale.800"}
            >
              Offer
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant={"smallTitle"} color={"grayscale.400"}>
                  Amount
                </Typography>
                <Typography
                  variant={"body1"}
                  fontWeight={"bold"}
                  color={"grayscale.800"}
                >
                  {displayFullMoney(campaign.codeValue)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant={"smallTitle"} color={"grayscale.400"}>
                  Brands
                </Typography>
                <Typography
                  variant={"body1"}
                  fontWeight={"bold"}
                  color={"grayscale.800"}
                >
                  {campaign.brandsCount || "0"}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              pb: 1,
              pt: 4
            }}
          >
            <Stack
              mb={2}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography
                variant={"subLead"}
                fontWeight={700}
                color={"grayscale.800"}
              >
                Email
              </Typography>
              {campaign.deliveryMethod === "EMAIL" && (
                <Button
                  disableRipple
                  variant={"text"}
                  onClick={() => setShowPreview(true)}
                  startIcon={
                    <svg
                      width={24}
                      height={24}
                      stroke={palette.primary.main}
                      style={{ minWidth: 24 }}
                    >
                      <use xlinkHref={"#eye-new"} />
                    </svg>
                  }
                >
                  Preview
                </Button>
              )}
            </Stack>

            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={6}>
                <Typography variant={"smallTitle"} color={"grayscale.400"}>
                  Delivery date
                </Typography>
                <Typography
                  variant={"body1"}
                  fontWeight={"bold"}
                  color={"grayscale.800"}
                >
                  {campaign.duration?.start
                    ? format(
                        new Date(campaign.duration.start),
                        "MMM/dd/yyyy, HH:mm OOOO"
                      )
                    : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant={"smallTitle"} color={"grayscale.400"}>
                  Redemption code expiration
                </Typography>
                <Typography
                  variant={"body1"}
                  fontWeight={"bold"}
                  color={"grayscale.800"}
                >
                  {campaign.duration?.end
                    ? format(
                        new Date(campaign.duration.end),
                        "MMM/dd/yyyy, HH:mm OOOO"
                      )
                    : "N/A"}
                </Typography>
              </Grid>
            </Grid>
            {campaign.deliveryMethod === "EMAIL" && (
              <Box>
                <Typography
                  variant={"smallTitle"}
                  color={"grayscale.400"}
                  mb={"4px"}
                >
                  Message
                </Typography>
                <Typography
                  variant={"body1"}
                  fontWeight={"bold"}
                  color={"grayscale.800"}
                  sx={{
                    wordWrap: "break-word"
                  }}
                >
                  {campaign.message || "N/A"}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid
          item
          p={"24px"}
          xs={12}
          md={4}
          sx={{
            borderLeft: `1px solid ${palette.grayscale["200"]} `
          }}
        >
          <Stack gap={2}>
            <Typography
              variant={"subLead"}
              fontWeight={700}
              color={"vibrantBlue.900"}
            >
              Codes statistics
            </Typography>

            <StatisticItem
              title={"Unclaimed"}
              number={statistics?.unclaimedNumber}
              amount={statistics?.unclaimedAmount}
            />

            <StatisticItem
              title={"Redeemed"}
              number={statistics?.claimedNumber}
              amount={statistics?.claimedAmount}
            />

            <StatisticItem
              title={"Campaign totals"}
              number={statistics?.campaignTotalNumber}
              amount={statistics?.campaignTotalAmount}
            />

            {campaign.status === "PENDING" && (
              <LoadingButton
                loading={loading}
                variant={"outlinedPrimary"}
                onClick={startCampaign}
              >
                Start Campaign Now
              </LoadingButton>
            )}
            {campaign.status === "ACTIVE" && (
              <LoadingButton
                loading={loading}
                variant={"outlinedPrimary"}
                onClick={voidCampaign}
              >
                Void Campaign
              </LoadingButton>
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

import { getToken } from "helpers/session";
import { getCookie, removeCookie, setCookie } from "./cookies";
import authRoutes from "settings/routes/auth";
import _map from "lodash/map";
import _some from "lodash/some";
import { LOGIN_PAGE } from "settings";

const { host } = require("./apiHost");

const PREFIX = "/commercefront-api/";
export const ZENDESK_OAUTH_TOKEN =
  "70689cee285c36c073f282137747d55f661a7187e253eb7802180758377db439";

export const createApiCall = async ({
  url = "",
  method = "GET",
  data,
  headers = {},
  prefix = "/admin/v1/"
}) => {
  const token = getCookie("token");
  const auth = token
    ? {
        Authorization: `Bearer ${token}`
      }
    : {};
  const request = {
    method: method,
    headers: { "Content-Type": "application/json", ...auth, ...headers }
  };
  if (data) {
    request.body = JSON.stringify(data);
  }
  let response = {};
  if (prefix === "/admin/v1/" || prefix === "/admin/v2/") {
    response = await fetch(`${host}${prefix}${url}`, request);
  } else {
    response = await fetch(`${prefix}${url}`, request);
  }
  let responseData = {};
  try {
    responseData = {
      data: await response.json(),
      status: response.status
    };
  } catch (e) {
    console.log(e);
    responseData = {
      data: "Something went wrong!",
      status: response.status
    };
  }
  if (response.status === 401) {
    removeCookie("token");
    const {
      location: { pathname }
    } = window;
    if (!_some(_map(authRoutes, "path"), (o) => pathname.includes(o))) {
      setCookie("redirect", pathname);
    }
    window.location.href = LOGIN_PAGE;
  }
  return responseData;
};

const API = ({
  url,
  method = "GET",
  data,
  prefix = "/commercefront-api/",
  custom = {
    headers: {}
  }
}) => {
  let rawData = {};
  const { headers } = custom;
  const token = getCookie("token");
  const auth = token
    ? {
        Authorization: `${window.btoa(token)}`
      }
    : {};
  const request = {
    method: method,
    headers: { "Content-Type": "application/json", ...headers, ...auth },
    credentials: "include"
  };
  if (data) {
    request.body = JSON.stringify(data);
  }
  return fetch(`${prefix}${url}`, request)
    .then((data) => {
      if (data.status === 401) {
        removeCookie("token");
        window.location.href = LOGIN_PAGE;
      }
      rawData = data;
      return data;
    })
    .then((data) => data.json())
    .then((data) => {
      if (rawData.status < 200 || rawData.status > 300) {
        throw data;
      }
      return data;
    });
};

export const asyncApi = async ({
  url,
  method,
  data,
  prefix = "/commercefront-api/",
  custom = {
    headers: {}
  }
}) => {
  const { headers } = custom;
  const token = await getToken();
  const auth = token
    ? {
        Authorization: `${window.btoa(token)}`
      }
    : {};
  const request = {
    method: method || "GET",
    headers: { "Content-Type": "application/json", ...headers, ...auth },
    credentials: "include"
  };

  if (data) {
    request.body = JSON.stringify(data);
  }

  const res = await fetch(`${prefix}${url}`, request);

  if (res.status === 401) {
    removeCookie("token");
    window.location.href = LOGIN_PAGE;
  }

  const json = await res.json();

  if (res.status < 200 || res.status > 300) {
    throw new Error(json.msg);
  }

  return json;
};

export const rfc3339 = (d) => {
  const pad = (n) => {
    return n < 10 ? "0" + n : n;
  };

  const timezoneOffset = (offset) => {
    let sign;
    if (offset === 0) {
      return "Z";
    }
    sign = offset > 0 ? "-" : "+";
    offset = Math.abs(offset);
    return sign + pad(Math.floor(offset / 60)) + ":" + pad(offset % 60);
  };

  return (
    d.getFullYear() +
    "-" +
    pad(d.getMonth() + 1) +
    "-" +
    pad(d.getDate()) +
    "T" +
    pad(d.getHours()) +
    ":" +
    pad(d.getMinutes()) +
    ":" +
    pad(d.getSeconds()) +
    timezoneOffset(d.getTimezoneOffset())
  );
};

export const fileAPI = (url, method, data, custom = {}, extractData) => {
  const { headers } = custom;
  const token = getToken();
  const auth = token
    ? {
        Authorization: `${window.btoa(token)}`
      }
    : {};
  const request = {
    method: method || "GET",
    headers: { "Content-Type": "application/json", ...headers, ...auth },
    credentials: "include"
  };
  if (data) {
    request.body = JSON.stringify(data);
  }
  return fetch(`${PREFIX}${url}`, request)
    .then((data) => {
      if (data.status < 200 || data.status > 300) {
        throw new Error(data);
      }
      return data;
    })
    .then((data) => (extractData ? extractData(data).blob() : data.blob()));
};

export default API;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { CAMPAIGNS_PAGE, EDIT_CAMPAIGN_PAGE, palette, theme } from "settings";
import { ChipStatus, tabProps, CustomTabPanel } from "components/shared";
import { Summary, Brands, Recipients } from "components/pages/portal/campaigns";
import { createApiCall } from "helpers/api";
import { useAuth } from "components/AuthProvider";
import { useSnackbar } from "notistack";

import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";

const defaultBrandsState = {
  pagination: {
    totalResults: 0
  },
  brands: []
};
const defaultBrandsFilter = {
  page: 0,
  pageLimit: 24
};

const CampaignPage = () => {
  const { uuid } = useParams();
  const { session } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const isTabletOrSmall = useMediaQuery(theme.breakpoints.down("md"));

  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState(null);
  const [brandsLoading, setBrandsLoading] = useState(false);
  const [brandsFilter, setBrandsFilter] = useState(defaultBrandsFilter);
  const [brands, setBrands] = useState(defaultBrandsState);

  useEffect(() => {
    setLoading(true);
    createApiCall({
      url: `campaigns/${session?.clientUuid}/${uuid}`
    }).then(({ data, status }) => {
      if (status === 200 && data.campaign) {
        setCampaign(data.campaign);
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
        navigate(CAMPAIGNS_PAGE);
      }
      setLoading(false);
    });
  }, [uuid]);

  useEffect(() => {
    if (uuid) {
      setBrandsLoading(true);
      const { pageLimit, page } = brandsFilter;
      createApiCall({
        url: `campaigns/brands?filter.campaign_uuid=${uuid}&pagination.sortBy.order=ASC&pagination.pageLimit=${pageLimit}&pagination.page=${page}`
      }).then(({ data, status }) => {
        if (status === 200 && data?.brands) {
          setBrands({
            ...data,
            brands: page ? [...brands.brands, ...data.brands] : data.brands
          });
        } else {
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
        }
        setBrandsLoading(false);
      });
    }
  }, [uuid, brandsFilter]);

  const handleChange = (event, newValue: number) => {
    setValue(newValue);
  };

  if (loading || !campaign) {
    return <div>loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>{campaign?.name}</title>
      </Helmet>
      <Box sx={{ minHeight: "100%" }}>
        <Breadcrumbs aria-label="breadcrumb" mb={"12px"}>
          <Link
            underline="hover"
            color={"inherit"}
            variant="leadCaption"
            fontWeight={600}
            sx={{ display: "block" }}
            href={CAMPAIGNS_PAGE}
          >
            Campaigns
          </Link>
          <Typography
            variant="leadCaption"
            color={"grayscale.800"}
            fontWeight={600}
          >
            {campaign?.name}
          </Typography>
        </Breadcrumbs>
        <Stack
          direction={"row"}
          alignItems={{ xs: "flex-start", md: "center" }}
          justifyContent={"space-between"}
          gap={3}
          mb={"12px"}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={"12px"}
            flexWrap={"wrap"}
          >
            <Typography variant={"h5"} color={"grayscale.800"}>
              {campaign?.name}
            </Typography>
            <ChipStatus data={campaign.status} />
          </Stack>
          {(campaign?.status === "DRAFT" || campaign?.status === "PENDING") && (
            <Button
              variant={"text"}
              onClick={() => navigate(EDIT_CAMPAIGN_PAGE(campaign.uuid))}
              sx={{
                gap: 1,
                minWidth: "32px",
                padding: { xs: "4px 8px" },
                justifyContent: "flex-start"
              }}
            >
              <svg
                width={24}
                height={24}
                style={{
                  minWidth: 24,
                  color: palette.primary.main
                }}
              >
                <use href="#edit-pencil" />
              </svg>
              {!isTabletOrSmall && (
                <Typography
                  variant={"body1"}
                  fontWeight={900}
                  color={palette.primary.main}
                >
                  Edit
                </Typography>
              )}
            </Button>
          )}
        </Stack>
        <Paper
          elevation={2}
          sx={{ width: "100%", borderRadius: "8px", overflow: "hidden" }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="campaigns tabs"
            >
              <Tab label="Summary" {...tabProps(0)} />
              <Tab label="Recipients" {...tabProps(1)} />
              <Tab label="Brands" {...tabProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Summary campaign={campaign} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Recipients campaign={campaign} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Box p={"24px 16px"}>
              <Brands
                data={brands}
                disabled={brandsLoading}
                onLoadMore={() =>
                  setBrandsFilter({
                    ...brandsFilter,
                    page: brandsFilter.page + 1
                  })
                }
                hideOption
              />
            </Box>
          </CustomTabPanel>
        </Paper>
      </Box>
    </>
  );
};
export default CampaignPage;

import React from "react";
import { TableVirtuoso } from "react-virtuoso";
import {
  InfinityLoader,
  TableBodyRow,
  TableHeadRow,
  TableToolbar
} from "components/shared";
import { virtuosoTableComponents } from "helpers/tableComponents";

import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";

/**
 *
 * @param headCells {Array}
 * @param data {Array}
 * @param loading {boolean}
 * @param hideToolbar {boolean}
 * @param total {number}
 * @param toolbarSpacing {number}
 * @param toolbarTitle {string}
 * @param toolbarActions {JSX.Element}
 * @param emptyStateComponent {JSX.Element}
 * @param ToolbarDescription {JSX.Element | null}
 * @param tableHeight {number}
 * @param elevation {number}
 * @param order {"asc"/"desc"}
 * @param orderBy {headCells.id}
 * @param createSortHandler {function(id, order)}
 * @param onLoadMore {function}
 * @param onMoreOptions {function}
 * @param onRowClick {function(rowData)}
 * @param onAction {function(rowData)}
 * @param onCheckedItems {function}
 * @param checkedItems {Array}
 * @returns {JSX.Element}
 * @constructor
 */
export const InfinityTable = ({
  headCells = [],
  data = [],
  loading,
  total,
  toolbarTitle,
  toolbarActions,
  toolbarSpacing,
  ToolbarDescription = null,
  tableHeight = 400,
  order,
  orderBy,
  createSortHandler,
  onLoadMore,
  onRowClick,
  checkedItems,
  onCheckedItems,
  onMoreOptions,
  onAction,
  hideToolbar = false,
  emptyStateComponent = null,
  elevation = 2
}) => {
  return (
    <Paper
      className={"table-wrapper"}
      elevation={elevation}
      sx={{ width: "100%", borderRadius: "8px", overflow: "hidden" }}
    >
      {!hideToolbar && (
        <TableToolbar
          title={toolbarTitle}
          actions={toolbarActions}
          toolbarSpacing={toolbarSpacing}
        />
      )}

      {!!ToolbarDescription && ToolbarDescription}
      <TableVirtuoso
        style={{ height: tableHeight }}
        data={data}
        context={{
          loading,
          total,
          headCells,
          onRowClick
        }}
        endReached={(lastIndex) => {
          if (lastIndex < total - 1) {
            onLoadMore();
          }
        }}
        components={
          emptyStateComponent && !loading
            ? {
                ...virtuosoTableComponents,
                EmptyPlaceholder: () => emptyStateComponent
              }
            : virtuosoTableComponents
        }
        fixedHeaderContent={() => (
          <TableHeadRow
            headCells={headCells}
            checkedItems={checkedItems}
            onCheckedItems={onCheckedItems}
            order={order}
            orderBy={orderBy}
            total={total}
            createSortHandler={createSortHandler}
          />
        )}
        fixedFooterContent={() => {
          if (data.length < total) {
            return <InfinityLoader headCells={headCells} />;
          }
        }}
        itemContent={(index, item) => (
          <TableBodyRow
            checkedItems={checkedItems}
            onMoreOptions={onMoreOptions}
            data={item}
            headCells={headCells}
            onCheckedItems={onCheckedItems}
            onAction={onAction}
          />
        )}
      />
      {loading && <LinearProgress />}
    </Paper>
  );
};

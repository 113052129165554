import React from "react";
import { Helmet } from "react-helmet";
import { AuthBenefitsWrapper, LoginForm } from "components/pages/auth";

const LoginPage = () => {
  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <AuthBenefitsWrapper>
        <LoginForm />
      </AuthBenefitsWrapper>
    </>
  );
};

export default LoginPage;

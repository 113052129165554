import React from "react";
import { useForm } from "react-hook-form";
import { midnightBlue, palette } from "settings";
import { FieldEmail, FieldPhone } from "components/fields";
import { useSnackbar } from "notistack";
import { useAuth } from "components/AuthProvider";
import { createApiCall } from "helpers/api";

import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

export const TestPreviewOptionModal = ({
  open,
  onClose,
  uuid,
  deliveryMethod = "EMAIL"
}) => {
  const { session } = useAuth();
  const methods = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit((data) => {
    const phone = data.phone_number || null;
    const email = data.email || null;

    const formData = {
      client_uuid: session?.clientUuid,
      campaign_uuid: uuid
    };
    if (phone && !email) {
      formData.phone_number = phone.replace(/[ \-()+_]/g, "")?.slice(1);
    }
    if (email && !phone) {
      formData.email = email;
    }

    createApiCall({
      method: "PATCH",
      url: `campaigns/preview/send`,
      data: formData
    }).then(({ data, status }) => {
      if (status === 200) {
        reset();
        enqueueSnackbar(
          `Test ${
            deliveryMethod === "EMAIL" ? "mail" : "sms"
          } has been sent successfully`,
          {
            variant: "success"
          }
        );
        onClose();
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
    });
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        ".MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.5)"
        }
      }}
    >
      <DialogTitle>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ height: "35px" }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <svg
              width={24}
              height={24}
              stroke={palette.primary.main}
              style={{ minWidth: "24px" }}
            >
              <use href={deliveryMethod === "EMAIL" ? "#mail" : "#sms-ico"} />
            </svg>
            <Typography
              variant={"subLead"}
              fontWeight={700}
              sx={{ ml: "12px" }}
              color={midnightBlue}
            >
              {deliveryMethod === "EMAIL" ? "Send test email" : "Send test SMS"}
            </Typography>
          </Stack>

          <IconButton size={"small"} onClick={onClose}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use href="#close" />
            </svg>
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ mt: "20px" }}>
        <form noValidate autoComplete="off">
          {deliveryMethod === "EMAIL" ? (
            <>
              <Typography
                variant={"subLead"}
                mb={1}
                color={"vibrantBlue.900"}
                sx={{ fontWeight: 700 }}
              >
                Enter email
              </Typography>
              <FieldEmail {...methods} />
            </>
          ) : (
            <>
              <Typography
                variant={"subLead"}
                mb={1}
                color={"vibrantBlue.900"}
                sx={{ fontWeight: 700 }}
              >
                Enter phone number
              </Typography>
              <FieldPhone {...methods} label={"Phone Number"} />
            </>
          )}

          <Button
            type={"button"}
            onClick={onSubmit}
            fullWidth
            sx={{ mt: "36px" }}
          >
            Send
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

import some from "lodash/some";

export const roles = [
  {
    value: "CLIENT_OWNER",
    label: "Owner"
  },
  {
    value: "CLIENT_ADMIN",
    label: "Client Admin"
  },
  {
    value: "CLIENT_MANAGER",
    label: "Manager"
  },
  {
    value: "CLIENT_AGENT",
    label: "Agent"
  }
];

export const hasAccessByRole = (userRoles = [], roles = []) => {
  return some(userRoles, (userRole) =>
    some(roles, (role) => role === userRole)
  );
};

import React from "react";
import { palette } from "settings";

import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";

export const ConfirmModal = ({
  data,
  loading = false,
  icon = (
    <svg width={24} height={24} stroke={palette.primary.main}>
      <use xlinkHref={"#exit"} />
    </svg>
  ),
  title = "Are you sure that you want to quit?",
  description = "Entered data will not be saved.",
  open,
  onClose,
  onConfirm
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        background: "transparent",
        backdropFilter: "blur(2px)",
        position: "absolute",
        inset: 0,
        zIndex: (theme) => theme.zIndex.modal + 1,
        "& .MuiPaper-root": {
          maxWidth: "500px"
        },
        "& .MuiBackdrop-root ": {
          backgroundColor: "rgba(0,0,0,0.5)"
        }
      }}
    >
      <DialogTitle sx={{ padding: "24px 24px 0", borderBottom: "none" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ height: "auto", "& svg": { minWidth: 24 } }}
        >
          {!!icon && icon}
          <Typography
            ml={"12px"}
            variant={"subLead"}
            fontWeight={700}
            color={palette.purple[900]}
          >
            {title}
          </Typography>

          <IconButton size={"small"} onClick={onClose} sx={{ ml: "auto" }}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use href="#close" />
            </svg>
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ padding: "0 24px 24px" }}>
        <Typography
          variant={"body1"}
          color={"grayscale.300"}
          mt={"12px"}
          mb={"36px"}
        >
          {description}
        </Typography>
        <LoadingButton
          autoFocus
          loading={loading}
          variant={"contained"}
          fullWidth
          onClick={() => onConfirm(data)}
        >
          Yes, I’m sure
        </LoadingButton>
      </DialogContent>
    </Dialog>
  );
};

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FieldEmail } from "components/fields";
import { useSnackbar } from "notistack";
import { midnightBlue, palette } from "settings";
import { createApiCall } from "helpers/api";

import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

const LoginHelpModal = ({ open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm();
  const { handleSubmit, setError } = methods;
  const [loading, setLoading] = useState(false);

  const onSubmit = handleSubmit(({ email }) => {
    createApiCall({
      method: "POST",
      url: "password-reset",
      data: {
        email
      }
    })
      .then(({ status }) => {
        setLoading(false);
        if (status === 200) {
          enqueueSnackbar("Reset link has been successfully sent", {
            variant: "success"
          });
          onClose();
        } else {
          enqueueSnackbar("Something went wrong", { variant: "error" });
        }
      })
      .catch(() => {
        setLoading(false);
        setError("email", {
          type: "manual",
          message: "Invalid email"
        });
      });
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            variant={"smallTitle"}
            fontWeight={700}
            color={midnightBlue}
          >
            Need Help Logging In?
          </Typography>

          <IconButton size={"small"} onClick={onClose}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use href="#close" />
            </svg>
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant={"smallTitle"}
          color={"grayscale.400"}
          mt={"10px"}
          mb={2}
        >
          Enter your email below and we’ll send you a link to reset your
          password.
        </Typography>

        <form onSubmit={onSubmit} noValidate>
          <Stack alignItems={"center"} gap={4}>
            <Box width={"100%"}>
              <Typography color={"purple.500"} sx={{ fontSize: 15, mb: "3px" }}>
                Email address
              </Typography>
              <FieldEmail {...methods} label={""} size="small" />
            </Box>
            <Box width={"100%"}>
              <LoadingButton
                loading={loading}
                fullWidth
                variant={"contained"}
                type={"submit"}
              >
                Send Link To Reset
              </LoadingButton>
              <Link
                sx={{ mt: 1, textAlign: "center", width: "100%" }}
                aria-label="Back to sign in"
                underline="none"
                textAlign={"center"}
                variant={"smallTitle"}
                component={"button"}
                type={"button"}
                onClick={onClose}
              >
                Back to sign in
              </Link>
            </Box>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default LoginHelpModal;

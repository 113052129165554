import React, { useMemo } from "react";
import { palette } from "settings";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

export const ToolbarDescription = ({
  recipients,
  checkedItems,
  setCheckedItems
}) => {
  const checkedCount = useMemo(() => {
    const recipientsCount = recipients?.customers?.length || 0;
    return checkedItems?.all
      ? recipientsCount
      : checkedItems?.items?.length || 0;
  }, [checkedItems, recipients?.customers?.length]);

  if (!checkedCount) {
    return null;
  }
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"center"}
      gap={"16px"}
      sx={{
        background: palette.grayscale["100"],
        padding: "8px",
        textAlign: "center",
        borderBottom: `1px solid ${palette.grayscale["200"]}`
      }}
    >
      <Typography>
        {checkedItems?.all ? "All" : checkedCount}{" "}
        {checkedCount > 1 ? "recipients are " : "recipient is "}selected
      </Typography>
      <Button
        disableRipple
        variant={"text"}
        onClick={() => {
          if (checkedItems?.all) {
            setCheckedItems({ all: false, items: [] });
          } else {
            setCheckedItems({
              all: true,
              items: recipients?.customers
                ? [...recipients.customers.map((el) => el.id)]
                : []
            });
          }
        }}
      >
        {checkedItems?.all ? "Clear selection" : `Select all recipients`}
      </Button>
    </Stack>
  );
};

import Cookies from "js-cookie";
import { subHours } from "date-fns";

export const setSession = (token) => {
  const exp = subHours(new Date(), -2);
  Cookies.set("token", token, { expires: exp, path: "/" });
};

export const getToken = () => Cookies.get("token");

export const logout = () => {
  Cookies.remove("token");
};

import React from "react";
import { DASHBOARD_PAGE } from "settings";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export const NotFound = () => {
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      spacing={2}
      height={"100vh"}
    >
      <Typography variant="h1" sx={{ fontSize: "32px" }}>
        Page not found
      </Typography>
      <Typography variant="body1">
        The page you requested could not be found.
      </Typography>
      <Button variant="contained" size="medium" href={DASHBOARD_PAGE}>
        Go home
      </Button>
    </Stack>
  );
};

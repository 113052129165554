import _ from "lodash";
import _find from "lodash/find";
import _isUndefined from "lodash/isUndefined";
import _isDate from "lodash/isDate";
import _isString from "lodash/isString";
import _isNull from "lodash/isNull";
import _omitBy from "lodash/omitBy";

export const headCells = [
  {
    id: "name",
    label: "Name",
    width: 300,
    sortKey: "name",
    sortable: true
  },
  {
    id: "status",
    label: "Status",
    type: "chip-status",
    width: 140
  },
  {
    id: "duration.start",
    label: "Start",
    width: 160,
    type: "date",
    lineBreak: true
  },
  {
    id: "duration.end",
    label: "Expiration",
    width: 160,
    type: "date",
    lineBreak: true
  },
  {
    id: "customersCount",
    label: "Recipients",
    width: 120
  },
  {
    id: "codeValue",
    label: "Gift card",
    type: "cent",
    width: 120
  },
  {
    id: "totalValue",
    label: "Campaign amount",
    type: "cent",
    width: 160
  },
  {
    id: "more_options",
    label: "",
    type: "options",
    width: 70
  },
  {
    id: "edit",
    label: "",
    width: 60,
    type: "node",
    align: "center",
    children: (
      <svg width={24} height={24} fill={"#181818"}>
        <use xlinkHref={"#cheveron-right"} />
      </svg>
    )
  }
];

export const DEFAULT_ORDER = "desc";
export const DEFAULT_ORDER_BY = "name";

export const defaultFilters = {
  startEndRange: {
    start: null,
    end: null
  },
  codeValueRange: {
    start: "",
    end: ""
  },
  campaignTotalRange: {
    start: "",
    end: ""
  },
  status: [],
  name: "",
  pagination: {
    pageLimit: 20,
    page: 0
  }
};

export const defaultCampaignsState = {
  pagination: {
    totalResults: 0
  },
  campaigns: []
};

export const campaignsStatus = [
  {
    value: "DRAFT",
    label: "Draft"
  },
  {
    value: "PENDING",
    label: "Pending"
  },
  {
    value: "ACTIVE",
    label: "Active"
  },
  {
    value: "EXPIRED",
    label: "Completed"
  },
  {
    value: "VOID",
    label: "Void"
  }
];

// ??? move to helpers folder
function removeUndefined(obj) {
  return _omitBy(
    _omitBy(
      _omitBy(obj, (o) => _isString(o) && !o.length),
      _isNull
    ),
    _isUndefined
  );
}

function deepRemoveUndefinedNullEmpty(obj) {
  if (_.isObject(obj)) {
    return removeUndefined(
      _.mapValues(obj, (val) =>
        val && _.isObject(val) && !_isDate(val)
          ? deepRemoveUndefinedNullEmpty(val)
          : val
      )
    );
  }
  return obj;
}

export const collectFilter = (
  filter,
  order = DEFAULT_ORDER,
  orderBy = DEFAULT_ORDER_BY
) => {
  const { status, ...restFilter } = filter;
  const cleanedObject = deepRemoveUndefinedNullEmpty(restFilter);
  if (status?.length) {
    cleanedObject.status = status;
  }

  if (cleanedObject.codeValueRange?.start) {
    cleanedObject.codeValueRange.start =
      cleanedObject.codeValueRange.start * 100;
  }
  if (cleanedObject.codeValueRange?.end) {
    cleanedObject.codeValueRange.end = cleanedObject.codeValueRange.end * 100;
  }
  if (cleanedObject.campaignTotalRange?.start) {
    cleanedObject.campaignTotalRange.start =
      cleanedObject.campaignTotalRange.start * 100;
  }
  if (cleanedObject.campaignTotalRange?.end) {
    cleanedObject.campaignTotalRange.end =
      cleanedObject.campaignTotalRange.end * 100;
  }
  return {
    ...cleanedObject,
    pagination: {
      ...filter.pagination,
      sortBy: {
        field: _find(headCells, ({ id }) => id === orderBy)?.sortKey || orderBy,
        order: order.toUpperCase()
      }
    }
  };
};

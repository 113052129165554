import React from "react";
import _find from "lodash/find";
import _map from "lodash/map";
import { palette } from "settings/mui/palette";

export const headCells = [
  {
    id: "checked",
    sticky: "left",
    type: "checkbox",
    width: 76
  },
  {
    id: "firstName",
    disablePadding: false,
    label: "First name",
    width: 120
  },
  {
    id: "lastName",
    sortKey: "last_name",
    disablePadding: false,
    label: "Last name",
    sortable: true,
    width: 120
  },
  {
    id: "email",
    disablePadding: false,
    label: "Email",
    minWidth: 230
  },
  {
    id: "phoneNumber",
    disablePadding: false,
    label: "Phone number",
    width: 150
  },
  {
    id: "tags",
    disablePadding: false,
    label: "Tags",
    type: "tag",
    width: 200,
    lineBreak: true
  },
  {
    id: "createdAt",
    disablePadding: false,
    type: "date",
    dateFormat: "MM/dd/yyyy",
    label: "Created",
    width: 120,
    lineBreak: true
  },
  {
    id: "updatedAt",
    disablePadding: false,
    label: "Updated",
    type: "date",
    dateFormat: "MM/dd/yyyy",
    lineBreak: true,
    width: 120
  },
  {
    id: "disabled",
    disablePadding: false,
    label: "Status",
    type: "disabled-status",
    width: 100
  },
  {
    id: "edit",
    label: "",
    width: 60,
    type: "node",
    children: (
      <svg width={24} height={24} fill={"#181818"}>
        <use xlinkHref={"#cheveron-right"} />
      </svg>
    )
  }
];
export const selectedHeadCells = [
  {
    id: "firstName",
    disablePadding: false,
    label: "First name",
    width: 90
  },
  {
    id: "lastName",
    sortKey: "last_name",
    disablePadding: false,
    label: "Last name",
    sortable: true,
    width: 90
  },
  {
    id: "email",
    disablePadding: false,
    label: "Email",
    width: 220
  },
  {
    id: "phoneNumber",
    disablePadding: false,
    label: "Phone number",
    width: 100
  },
  {
    id: "delete",
    label: "",
    width: 60,
    type: "action",
    children: (
      <svg
        width={24}
        height={24}
        stroke={palette.grayscale["400"]}
        style={{ minWidth: 24 }}
      >
        <use href="#trash" />
      </svg>
    )
  }
];

export const DEFAULT_ORDER = "asc";
export const DEFAULT_ORDER_BY = "last_name";

export const recipientStatus = [
  {
    label: "All",
    value: "ALL"
  },
  {
    label: "Active",
    value: "FALSE"
  },
  {
    label: "Inactive",
    value: "TRUE"
  }
];

export const defaultFilters = {
  firstName: "",
  lastName: "",
  email: "",
  disabled: recipientStatus[0].value,
  tags: [],
  pagination: {
    pageLimit: 20,
    page: 0
  }
};

export const defaultRecipientsState = {
  pagination: {
    nextOffset: -1,
    previousOffset: -1,
    sortBy: { field: "uuid", order: "ASC" },
    totalResults: 0
  },
  customers: []
};

export const defaultRecipientsForm = {
  firstName: "",
  lastName: "",
  email: "",
  status: null,
  phoneNumber: "",
  tags: []
};

export const collectFilter = (
  filter,
  order = DEFAULT_ORDER,
  orderBy = DEFAULT_ORDER_BY
) => {
  const cleanedFilter = {};
  if (filter.firstName) {
    cleanedFilter.firstName = filter.firstName;
  }
  if (filter.lastName) {
    cleanedFilter.lastName = filter.lastName;
  }
  if (filter.email) {
    cleanedFilter.email = filter.email;
  }
  if (filter.phoneNumber) {
    cleanedFilter.phoneNumber = filter.phoneNumber;
  }
  if (filter.tags?.length) {
    cleanedFilter.client_tag_uuids = _map(filter.tags, "clientTagUuid");
  }
  if (filter.client_tag_uuids?.length) {
    cleanedFilter.client_tag_uuids = filter.client_tag_uuids;
  }
  if (filter.disabled !== recipientStatus[0].value) {
    cleanedFilter.disabled = filter.disabled;
  }
  return {
    ...cleanedFilter,
    pagination: {
      ...filter.pagination,
      sortBy: {
        field: _find(headCells, ({ id }) => id === orderBy)?.sortKey || orderBy,
        order: order.toUpperCase()
      }
    }
  };
};

import React from "react";
import { midnightBlue, palette, royalBlue, theme } from "settings";
import { format } from "date-fns";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";

export const PreviewMail = ({ open, onClose, onShowTestModal, data }) => {
  const isTabletOrSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Drawer open={open} onClose={onClose} size={"md"}>
        <Stack sx={{ height: "100%" }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              p: 3,
              borderBottom: `1px solid ${palette.grayscale["200"]}`
            }}
          >
            <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
              <svg
                width={24}
                height={24}
                stroke={palette.primary.main}
                style={{ minWidth: 24 }}
              >
                <use xlinkHref={"#eye-sm"} />
              </svg>
              <Typography
                variant={"subLead"}
                fontWeight={700}
                color={"purple.900"}
              >
                Email preview
              </Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} gap={1.5}>
              {!isTabletOrSmall && (
                <Button
                  variant={"text"}
                  onClick={() => onShowTestModal(true)}
                  sx={{ padding: { xs: "4px 8px" } }}
                  startIcon={
                    <svg
                      width={24}
                      height={24}
                      stroke={palette.primary.main}
                      style={{ minWidth: 24 }}
                    >
                      <use href="#mail" />
                    </svg>
                  }
                >
                  Send test email
                </Button>
              )}

              <IconButton size={"small"} onClick={onClose}>
                <svg width={24} height={24} stroke={palette.grayscale["700"]}>
                  <use href="#close" />
                </svg>
              </IconButton>
            </Stack>
          </Stack>
          <Box
            sx={{
              p: 3,
              flex: "0 1 100%",
              overflowY: "auto",
              height: "100%"
            }}
          >
            {isTabletOrSmall && (
              <Button
                fullWidth
                variant={"text"}
                onClick={() => onShowTestModal(true)}
                sx={{ padding: { xs: "4px 8px" }, margin: "0 auto 24px" }}
                startIcon={
                  <svg
                    width={24}
                    height={24}
                    stroke={palette.primary.main}
                    style={{ minWidth: 24 }}
                  >
                    <use href="#mail" />
                  </svg>
                }
              >
                Send test email
              </Button>
            )}
            <Box
              sx={{
                overflow: "hidden",
                borderRadius: "20px",
                background: palette.grayscale[100],
                boxShadow: "0 4px 16px 0 rgba(39, 27, 97, 0.15)"
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                sx={{
                  padding: "23px 48px",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                  background: palette.vibrantBlue[900]
                }}
              >
                <svg width={100} height={33} fill={"#fff"}>
                  <use xlinkHref={"#logo"} />
                </svg>
              </Stack>
              <Box py={2} px={6}>
                <Typography
                  variant={"h2"}
                  color={royalBlue}
                  fontWeight={700}
                  sx={{ wordWrap: "break-word" }}
                  fontSize={38}
                >
                  <span style={{ color: palette.primary.main }}>
                    {data?.clientName || "Company name"}{" "}
                  </span>
                  has sent you a ${data?.amount_field || data?.gift_card || 0}{" "}
                  gift card!
                </Typography>
              </Box>
              <Box
                py={3}
                px={6}
                sx={{
                  background: "#FFF"
                }}
              >
                <Typography
                  variant={"body1"}
                  color={"grayscale.400"}
                  sx={{ wordWrap: "break-word" }}
                >
                  {data?.message || "[Message]"}
                </Typography>
                <Divider sx={{ my: 3 }} />
                <Typography
                  variant={"lead"}
                  mb={4}
                  color={midnightBlue}
                  fontWeight={700}
                  textAlign={"center"}
                >
                  Redeeming your gift is
                  <Typography
                    variant={"lead"}
                    component={"span"}
                    ml={1}
                    color={palette.primary.main}
                    fontWeight={700}
                  >
                    easy
                  </Typography>
                </Typography>
                <Stack component={"ul"} gap={2} mb={3}>
                  <Stack
                    component={"li"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    gap={"12px"}
                  >
                    <Typography
                      component={Stack}
                      alignItems={"center"}
                      justifyContent={"center"}
                      variant={"leadCaption"}
                      color={royalBlue}
                      fontWeight={600}
                      sx={{
                        width: 20,
                        minWidth: 20,
                        height: 20,
                        borderRadius: "50%",
                        border: `2px solid ${royalBlue}`
                      }}
                    >
                      1
                    </Typography>
                    <Typography
                      variant={"body1"}
                      component={"span"}
                      color={"grayscale.400"}
                    >
                      Click redeem your gift to go to our redemption page
                    </Typography>
                  </Stack>
                  <Stack
                    component={"li"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    gap={"12px"}
                  >
                    <Typography
                      component={Stack}
                      alignItems={"center"}
                      justifyContent={"center"}
                      variant={"leadCaption"}
                      color={royalBlue}
                      fontWeight={600}
                      sx={{
                        width: 20,
                        minWidth: 20,
                        height: 20,
                        borderRadius: "50%",
                        border: `2px solid ${royalBlue}`
                      }}
                    >
                      2
                    </Typography>
                    <Typography
                      variant={"body1"}
                      component={"span"}
                      color={"grayscale.400"}
                    >
                      Copy and paste your redemption code
                    </Typography>
                  </Stack>
                  <Stack
                    component={"li"}
                    alignItems={"center"}
                    gap={"12px"}
                    flexDirection={"row"}
                  >
                    {" "}
                    <Typography
                      component={Stack}
                      alignItems={"center"}
                      justifyContent={"center"}
                      variant={"leadCaption"}
                      color={royalBlue}
                      fontWeight={600}
                      sx={{
                        width: 20,
                        minWidth: 20,
                        height: 20,
                        borderRadius: "50%",
                        border: `2px solid ${royalBlue}`
                      }}
                    >
                      3
                    </Typography>
                    <Typography
                      variant={"body1"}
                      component={"span"}
                      color={"grayscale.400"}
                    >
                      Choose from the available brands
                    </Typography>
                  </Stack>
                </Stack>
                <Button
                  variant={"outlinedGray"}
                  fullWidth
                  sx={{ fontWeight: 400 }}
                >
                  Redemption Code:
                  <Typography
                    component={"span"}
                    lineHeight={"inherit"}
                    color={midnightBlue}
                    fontWeight={900}
                    ml={"4px"}
                  >
                    [code]
                  </Typography>
                </Button>
                <Button sx={{ my: "12px" }} fullWidth>
                  Redeem Your Gift
                </Button>
                <Typography
                  variant={"smallTitle"}
                  color={"grayscale.400"}
                  textAlign={"center"}
                >
                  Expires:{" "}
                  {data?.expiration_day
                    ? format(new Date(data?.expiration_day), "MMM/dd/yyyy")
                    : "[XX/XX/XXXX]"}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Drawer>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { palette } from "settings";
import { CategorySearchAutocomplete } from "components/fields";
import { InfinityTable } from "components/shared";
import { selectedHeadCells } from "components/pages/portal/recipients";
import { filterRecipients } from "helpers/recipientsFilter";

import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";

export const SelectedRecipients = ({
  open,
  onClose,
  handleSubmit,
  selectedItems,
  campaignUuid,
  onDelete,
  deliveryMethod,
  confirmSelectedRecipients
}) => {
  const methods = useForm();
  const { watch } = methods;
  const searchValue = watch("selected_search_category");
  const [activeCategory, setActiveCategory] = useState(null);
  const [filteredItems, setFilteredItems] = useState(
    selectedItems?.items || []
  );

  useEffect(() => {
    const items = filterRecipients(
      activeCategory?.id || "",
      selectedItems?.items || [],
      searchValue || ""
    );
    setFilteredItems(items);
  }, [searchValue, activeCategory, selectedItems]);

  const handleConfirm = (e) => {
    handleSubmit(e);
    onClose();
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      sx={{
        ".MuiPaper-root": {
          background: "#FFFFFF",
          width: { xs: "100%", md: 660 }
        }
      }}
    >
      <Stack sx={{ height: "100%" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            p: 3,
            borderBottom: `1px solid ${palette.grayscale["200"]}`
          }}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
            <svg width={24} height={24} color={palette.purple["500"]}>
              <use href="#user-group" />
            </svg>
            <Typography
              variant={"subLead"}
              fontWeight={700}
              color={"purple.900"}
            >
              {confirmSelectedRecipients
                ? `Confirm recipients (${selectedItems.items?.length || 0})`
                : `Selected recipients (${selectedItems.items?.length || 0})`}
            </Typography>
          </Stack>
          <IconButton size={"small"} onClick={() => onClose(true)}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use href="#close" />
            </svg>
          </IconButton>
        </Stack>

        <Stack
          sx={{
            p: { xs: "16px 16px 0", md: "24px 24px 0" },
            flex: "0 1 100%",
            gap: 2,
            overflowY: "auto",
            height: "100%",
            "& .table-wrapper": {
              maxWidth: "100%",
              overflowX: "auto"
            }
          }}
        >
          <CategorySearchAutocomplete
            {...methods}
            fieldName={"selected_search_category"}
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
            campaignUuid={campaignUuid}
            filterBySelected={true}
            selectedOption={selectedItems}
            deliveryMethod={deliveryMethod}
          />
          <InfinityTable
            headCells={selectedHeadCells}
            data={filteredItems || []}
            total={filteredItems?.length || 0}
            order={"ASC"}
            hideToolbar={true}
            onAction={onDelete}
            orderBy={activeCategory?.id || "lastName"}
          />
        </Stack>
        {confirmSelectedRecipients && (
          <Box p={"16px 24px 24px"}>
            <Button onClick={handleConfirm} fullWidth variant={"contained"}>
              Confirm
            </Button>
          </Box>
        )}
      </Stack>
    </Drawer>
  );
};

import React from "react";
import { fieldCannotBeEmpty, palette } from "settings";

import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FilledInput from "@mui/material/FilledInput";

export const FieldTextarea = ({
  register,
  watch,
  formState: { errors },
  fieldName = "name",
  placeholder = "",
  label,
  validation = {
    required: fieldCannotBeEmpty
  },
  type = "text",
  rows = 5,
  defaultValue = "",
  endAdornment = false
}) => {
  const textAreaValue = watch(fieldName);
  return (
    <FormControl variant="filled" error={!!errors[fieldName]}>
      {label && <InputLabel sx={{ mb: "6px" }}>{label}</InputLabel>}
      <FilledInput
        component={TextField}
        multiline
        rows={rows}
        type={type}
        fullWidth
        disableUnderline
        placeholder={placeholder}
        endAdornment={endAdornment}
        sx={{
          color:
            textAreaValue === defaultValue
              ? palette.grayscale[400]
              : palette.grayscale[800]
        }}
        {...register(fieldName, validation)}
      />
      {!!errors[fieldName] && (
        <FormHelperText>{errors[fieldName].message}</FormHelperText>
      )}
    </FormControl>
  );
};

import React, { useMemo } from "react";
import { greenAlert, palette, redAlert } from "settings";
import Typography from "@mui/material/Typography";

export const CodeStatus = ({ data }) => {
  const text = useMemo(() => {
    if (!data || data === "INVALID_STATUS") {
      return "Not sent";
    }
    if (data === "VOID") {
      return "Voided";
    }
    return data.toLowerCase();
  }, [data]);

  return (
    <Typography
      variant={"smallTitle"}
      component={"span"}
      sx={[
        data === "SENT" && {
          color: greenAlert
        },
        data === "REDEEMED" && {
          color: palette.purple["500"]
        },
        data === "VOID" && {
          color: redAlert
        },
        data === "INVALID_STATUS" && {
          color: palette.grayscale["400"],
          fontWeight: 600
        },
        {
          textTransform: "capitalize"
        }
      ]}
    >
      {text}
    </Typography>
  );
};
// Expired

import { subDays } from "date-fns";
import _find from "lodash/find";
import { formatToUTC } from "helpers/queryStrings";

export const headCells = [
  {
    id: "brandName",
    sortKey: "brand_name",
    disablePadding: false,
    label: "Brand",
    sortable: true,
    width: 180
  },
  {
    id: "totalValuePurchased",
    disablePadding: false,
    sortKey: "total_value_purchased",
    label: "Total Value Sold",
    sortable: true,
    type: "cent",
    width: 130
  },
  {
    id: "totalCardsPurchased",
    disablePadding: false,
    sortKey: "total_cards_purchased",
    label: "GC Purchased",
    sortable: true,
    width: 130
  },
  {
    id: "totalCommission",
    disablePadding: false,
    sortKey: "total_commission",
    label: "Total Commission",
    sortable: true,
    type: "cent",
    width: 130
  },
  {
    id: "commissionRate",
    disablePadding: false,
    sortKey: "commission_rate",
    label: "Commission Percentage",
    sortable: true,
    type: "percentage",
    width: 180
  },
  {
    id: "dateRange.start",
    disablePadding: false,
    sortKey: "date_range.start",
    type: "date",
    dateFormat: "MM/dd/yyyy",
    width: 120,
    lineBreak: true,
    label: "Start Date"
  },
  {
    id: "dateRange.end",
    disablePadding: false,
    sortKey: "date_range.end",
    type: "date",
    dateFormat: "MM/dd/yyyy",
    width: 120,
    lineBreak: true,
    label: "End Date"
  }
];

export const DEFAULT_ORDER = "asc";
export const DEFAULT_ORDER_BY = "brandName";

export const defaultFilters = {
  date_range: {
    start: subDays(new Date(), 30),
    end: new Date()
  },
  brand_name: "",
  client_uuid: "099b3bcd-fc75-4756-924b-29c8c4458eaf",
  pagination: {
    pageLimit: 20,
    page: 0
  }
};
export const defaultCommissionsState = {
  pagination: {},
  details: []
};

export const collectFilter = (
  filter,
  client_uuid = "",
  order = DEFAULT_ORDER,
  orderBy = DEFAULT_ORDER_BY
) => {
  const date_range = {
    ...filter.date_range
  };
  const start = formatToUTC(date_range.start);
  const end = formatToUTC(date_range.end, "end");
  return {
    ...filter,
    date_range: {
      start,
      end
    },
    client_uuid,
    pagination: {
      ...filter.pagination,
      sortBy: {
        field: _find(headCells, ({ id }) => id === orderBy)?.sortKey || orderBy,
        order: order.toUpperCase()
      }
    }
  };
};

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Typewriter from "typewriter-effect";
import { FieldEmail, FieldPhone, FieldText } from "components/fields";
import {
  fieldCannotBeEmpty,
  LOGIN_PAGE,
  max32characters,
  royalBlue
} from "settings";
import { createApiCall } from "helpers/api";
import { captchaVerify } from "helpers/captchaVerify";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Paper from "@mui/material/Paper";

const createHiddenEmail = (emailText) => {
  try {
    const splitedEmail = emailText?.split("@");
    const result1 =
      splitedEmail[0].substring(0, 2) + "*".repeat(splitedEmail[0].length - 1);
    const result2 =
      "*".repeat(splitedEmail[1].length - 4) +
      splitedEmail[1].substring(splitedEmail[1].length - 4);
    return result1 + result2;
  } catch (e) {
    return "******";
  }
};

export const SignUpForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm();
  const { handleSubmit, watch } = methods;
  const email = watch("email");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);

    // Recaptcha Verify with action 'SignUp'
    const captchaVerification = await captchaVerify(executeRecaptcha, "SignUp");
    if (!captchaVerification) {
      setLoading(false);
      return enqueueSnackbar(
        "Captcha verification failed. Please try again later.",
        {
          variant: "error"
        }
      );
    }

    return createApiCall({
      method: "POST",
      url: "account",
      data: {
        ...data,
        phone_number: data.phone_number?.replace(/[ \-()+_]/g, "")?.slice(1)
      }
    }).then(({ status, data }) => {
      setLoading(false);
      if (status === 200) {
        setSubmitted(true);
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
    });
  });

  const resendHandler = () => {
    createApiCall({
      method: "POST",
      url: "auth/password-reset",
      data: {
        email
      }
    })
      .then(({ status }) => {
        setLoading(false);
        if (status === 200) {
          enqueueSnackbar("Link has been successfully sent", {
            variant: "success"
          });
        } else {
          enqueueSnackbar("Something went wrong", { variant: "error" });
        }
      })
      .catch(() => {
        setLoading(false);
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  };

  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: "18px",
        zIndex: 1,
        width: "100%",
        maxWidth: { xs: "100%", sm: "815px" },
        margin: "0 auto",
        padding: { xs: "24px 16px", md: "24px 160px" }
      }}
    >
      <Stack gap={1}>
        {submitted ? (
          <>
            <Box>
              <Typography
                variant={"h2"}
                textAlign={"center"}
                color={royalBlue}
                fontWeight={700}
                mb={1}
                sx={{ fontSize: { xs: 28, md: 40 } }}
              >
                You’re almost done!
              </Typography>
              <Typography
                mx={"auto"}
                variant={"subLead"}
                color={royalBlue}
                textAlign={"center"}
                sx={{
                  wordWrap: "break-word",
                  fontSize: { xs: 17, md: 18 },
                  maxWidth: { xs: 290, md: 350 }
                }}
              >
                Please click the link in the email sent to{" "}
                {createHiddenEmail(email)} to complete the sign up process.
              </Typography>
            </Box>
            <Typography
              variant={"smallTitle"}
              mt={1}
              color={royalBlue}
              textAlign={"center"}
            >
              Don’t see the email?{" "}
              <Link
                component={"a"}
                sx={{ cursor: "pointer" }}
                underline="none"
                variant={"smallTitle"}
                onClick={resendHandler}
              >
                Resend email.
              </Link>
            </Typography>
          </>
        ) : (
          <>
            <Box>
              <Typography
                variant={"h2"}
                textAlign={"center"}
                color={royalBlue}
                fontWeight={700}
                mb={1}
                sx={{ fontSize: { xs: 28, md: 40 } }}
              >
                Recognize and engage
                <Typography
                  variant={"h2"}
                  color={"purple.500"}
                  fontWeight={700}
                  sx={{ fontSize: { xs: 28, md: 40 } }}
                  component={"span"}
                >
                  <Typewriter
                    options={{
                      strings: [
                        "happy customers",
                        "employees",
                        "participants",
                        "colleagues",
                        "prospects"
                      ],
                      autoStart: true,
                      loop: true
                    }}
                  />
                </Typography>
              </Typography>
              <Typography
                variant={"subLead"}
                color={royalBlue}
                textAlign={"center"}
                mb={1}
              >
                Create your account to start sending rewards with Raise{" "}
              </Typography>
            </Box>
            <form onSubmit={onSubmit} noValidate>
              <Stack gap={"6px"}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  alignItems={"stretch"}
                  justifyContent={"space-between"}
                  gap={"6px"}
                >
                  <Box sx={{ flex: "1 1 auto" }}>
                    <Typography
                      color={"purple.500"}
                      sx={{ fontSize: 15, mb: "3px" }}
                    >
                      First name
                    </Typography>
                    <FieldText
                      {...methods}
                      fieldName={"first_name"}
                      label={""}
                      size="small"
                      placeholder={""}
                      validation={{
                        required: fieldCannotBeEmpty,
                        maxLength: {
                          value: 32,
                          message: max32characters
                        }
                      }}
                    />
                  </Box>
                  <Box sx={{ flex: "1 1 auto" }}>
                    <Typography
                      color={"purple.500"}
                      sx={{ fontSize: 15, mb: "3px" }}
                    >
                      Last name
                    </Typography>
                    <FieldText
                      {...methods}
                      fieldName={"last_name"}
                      size="small"
                      label={""}
                      placeholder={""}
                      validation={{
                        required: fieldCannotBeEmpty,
                        maxLength: {
                          value: 32,
                          message: max32characters
                        }
                      }}
                    />
                  </Box>
                </Stack>
                <Box>
                  <Typography
                    color={"purple.500"}
                    sx={{ fontSize: 15, mb: "3px" }}
                  >
                    Email
                  </Typography>
                  <FieldEmail {...methods} label={""} size="small" />
                </Box>
                <Box>
                  <Typography
                    color={"purple.500"}
                    sx={{ fontSize: 15, mb: "3px" }}
                  >
                    Mobile number
                  </Typography>
                  <FieldPhone {...methods} label={""} size="small" />
                </Box>
                <Box>
                  <Typography
                    color={"purple.500"}
                    sx={{ fontSize: 15, mb: "3px" }}
                  >
                    Company
                  </Typography>
                  <FieldText
                    {...methods}
                    fieldName={"company_name"}
                    size="small"
                    label={""}
                    placeholder={""}
                  />
                </Box>

                <Typography
                  variant={"smallTitle"}
                  color={royalBlue}
                  textAlign={{ xs: "center", md: "left" }}
                >
                  By creating an account you are agreeing to our{" "}
                  <Box
                    component={"a"}
                    aria-label="Terms of Use"
                    color={"purple.500"}
                    href={"https://www.raise.com/business/terms-of-service/"}
                    target={"_blank"}
                    rel={"noreferrer noopener"}
                  >
                    Terms of Use.
                  </Box>
                </Typography>
                <LoadingButton
                  loading={loading}
                  sx={{ mt: 3 }}
                  fullWidth
                  variant={"contained"}
                  type={"submit"}
                >
                  Continue
                </LoadingButton>
                <Typography
                  mt={"2px"}
                  variant={"smallTitle"}
                  color={royalBlue}
                  textAlign={"center"}
                >
                  Already have an account?{" "}
                  <Link
                    underline={"none"}
                    aria-label="Log in"
                    variant={"smallTitle"}
                    color={"purple.500"}
                    href={LOGIN_PAGE}
                  >
                    Log in.
                  </Link>
                </Typography>
              </Stack>
            </form>
          </>
        )}
      </Stack>
    </Paper>
  );
};

import React from "react";
import DatePicker from "react-datepicker";
import { endOfDay, startOfDay } from "date-fns";

import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

export const FieldRangeDatePicker = ({
  direction = "row",
  register,
  watch,
  setValue,
  startFieldName = "startDate",
  endFieldName = "endDate"
}) => {
  const startDate = watch(startFieldName);
  const endDate = watch(endFieldName);

  const onChangeStartDate = (date) => {
    setValue(startFieldName, date ? startOfDay(date) : null);
  };

  const onChangeEndDate = (date) => {
    setValue(endFieldName, date ? endOfDay(date) : null);
  };

  return (
    <Stack
      direction={{ xs: "column", md: direction }}
      spacing={{ xs: "12px", md: 2 }}
    >
      <FormControl variant="filled">
        <InputLabel
          sx={{ mb: "6px", fontSize: 10, fontWeight: 900 }}
          shrink
          focused
        >
          FROM
        </InputLabel>
        <DatePicker
          {...register(startFieldName)}
          placeholderText={"MM/DD/YYYY"}
          showPopperArrow={false}
          selected={startDate}
          onChange={onChangeStartDate}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate}
          portalId="body"
        />
      </FormControl>
      <FormControl variant="filled">
        <InputLabel
          sx={{ mb: "6px", fontSize: 10, fontWeight: 900 }}
          shrink
          focused
        >
          TO
        </InputLabel>
        <DatePicker
          {...register(endFieldName)}
          placeholderText={"MM/DD/YYYY"}
          showPopperArrow={false}
          selected={endDate}
          onChange={onChangeEndDate}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          portalId="body"
        />
      </FormControl>
    </Stack>
  );
};

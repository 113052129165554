import React from "react";
import CampaignInfo from "./CampaignInfo";

const ReferenceAreaLabel = ({
  isStartBeforeRange,
  campaign,
  viewBox,
  period,
  onOpen
}) => {
  const { width, height, x, y } = viewBox;

  return (
    <foreignObject
      style={{
        overflow: "visible"
      }}
      x={x}
      y={y}
      height={height}
      width={width}
    >
      <CampaignInfo
        isChartView
        isStartBeforeRange={isStartBeforeRange}
        campaign={campaign}
        width={width}
        period={period}
        onOpen={onOpen}
      />
    </foreignObject>
  );
};

export default ReferenceAreaLabel;

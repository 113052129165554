import React from "react";
import { midnightBlue, palette } from "settings";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const DebitCreditCard = ({ style = {} }) => {
  return (
    <Box
      sx={[
        style,
        {
          cursor: "pointer",
          textAlign: "left",
          width: "100%"
        }
      ]}
    >
      <Stack
        gap={"16px"}
        direction={"row"}
        alignItems={"center"}
        width={"100%"}
      >
        <Stack sx={{ width: "44px", alignItems: "center" }}>
          <svg
            width={32}
            height={32}
            style={{ minWidth: 32, color: midnightBlue }}
          >
            <use xlinkHref={"#plus-ico"} />
          </svg>
        </Stack>
        <Box sx={{ width: "100%" }}>
          <Stack
            justifyContent={"space-between"}
            direction={"row"}
            alignItems={"flex-start"}
          >
            <Typography
              variant={"body1"}
              fontWeight={600}
              color={"grayscale.800"}
              lineHeight={1.38}
            >
              A debit or credit card
            </Typography>
            <Typography
              sx={{ ml: "auto", textAlign: "right", whiteSpace: "nowrap" }}
              variant={"body1"}
              fontWeight={600}
              color={palette.vibrantBlue[500]}
              lineHeight={1.38}
            >
              3% Fee
            </Typography>
          </Stack>
          <Typography
            sx={{ flexGrow: "100%" }}
            variant={"body1"}
            color={palette.grayscale[300]}
            lineHeight={1.38}
          >
            Payment proceeds immediately
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

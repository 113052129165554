import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";
import find from "lodash/find";
import size from "lodash/size";
import { FieldMoney, FundsSelect } from "components/fields";
import {
  CircularBackdrop,
  ConfirmModal,
  DebitCreditCardForm
} from "components/shared";
import { useAuth } from "components/AuthProvider";
import { useSnackbar } from "notistack";
import Summary from "./Summary";
import { createApiCall } from "helpers/api";
import { displayFullMoney } from "helpers/money";
import { captchaVerify } from "helpers/captchaVerify";
import { palette } from "settings";

import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";

export const AddFundsSidebar = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { getBalance, session } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const [plaidLoading, setPlaidLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [open, setOpen] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submittedStatus, setSubmittedStatus] = useState({
    isDirty: false
  });
  const [confirmModalData, setConfirmModalData] = useState(null);

  const methods = useForm();
  const {
    reset,
    watch,
    handleSubmit,
    setValue,
    formState: { dirtyFields }
  } = methods;

  useEffect(() => {
    if (open) {
      reset();
      fetchPaymentMethods();
    }
  }, [open]);

  useEffect(() => {
    setOpen(state?.showAddFundsBar || false);
  }, [state]);

  const paymentMethodValue = watch("paymentMethod");
  const amountValue = watch("amount");

  const amountCent = useMemo(() => {
    return (amountValue || 0) * 100;
  }, [amountValue]);

  const currentPaymentMethod = useMemo(() => {
    return find(paymentMethods, (o) => o.uuid === paymentMethodValue);
  }, [paymentMethods, paymentMethodValue]);

  const fee = useMemo(() => {
    return currentPaymentMethod?.braintreeDetails ? 3 : null;
  }, [currentPaymentMethod]);

  const fetchPaymentMethods = (uuid) => {
    setLoading(true);
    createApiCall({
      url: "payment-methods/all"
    }).then(({ status, data }) => {
      if (status === 200 && data?.paymentMethods) {
        setPaymentMethods(data.paymentMethods);
        setValue("paymentMethod", uuid);
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  };

  const onClose = () => {
    if (!showSummary && (size(dirtyFields) || submittedStatus.isDirty)) {
      setConfirmModalData({
        open: true,
        data: {
          action: "exit"
        },
        title: "Are you sure that you want to quit?",
        description: "Entered data will not be saved."
      });
    } else {
      setShowSummary(false);
      reset();
      navigate(null, { state: {} });
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);

    // Recaptcha Verify with action 'AddFunds'
    const captchaVerification = await captchaVerify(
      executeRecaptcha,
      "AddFunds"
    );
    if (!captchaVerification) {
      setLoading(false);
      return enqueueSnackbar(
        "Captcha verification failed. Please try again later.",
        {
          variant: "error"
        }
      );
    }

    return await createApiCall({
      url: "business-client-fund/adjustments",
      method: "POST",
      data: {
        client_uuid: session?.clientUuid,
        amount: data.amount * 100,
        // Payment method must be present when source is admin adjustment
        payment_method_uuid: data.paymentMethod,
        // 1 for deposit, 17 for admin adjustment
        source: 1
      }
    }).then(({ status, data }) => {
      if (status === 200) {
        setShowSummary(true);
        enqueueSnackbar(
          "Payment was successful. Your funds are available for purchase now.",
          {
            variant: "success"
          }
        );
        getBalance();
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  });

  const onConfirm = (data) => {
    if (data.action === "exit") {
      setConfirmModalData(null);
      setSubmittedStatus({
        isDirty: false
      });
      navigate(null, { state: {} });
    }
  };

  const onDoneSummary = () => {
    setShowSummary(false);
    reset();
    navigate(null, { state: {} });
  };

  return (
    <>
      <CircularBackdrop open={plaidLoading} />
      <ConfirmModal
        {...confirmModalData}
        loading={loading}
        open={!!confirmModalData}
        onClose={() => setConfirmModalData(null)}
        onConfirm={onConfirm}
      />
      <Drawer
        open={open}
        onClose={onClose}
        size="md"
        ModalProps={{
          keepMounted: true
        }}
      >
        <Stack sx={{ p: 3, flexGrow: 1 }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
              <svg width={24} height={24} color={palette.primary.main}>
                <use href="#funds-ico" />
              </svg>
              <Typography
                variant={"subLead"}
                fontWeight={700}
                color={"purple.900"}
              >
                Add funds
              </Typography>
            </Stack>
            <IconButton size={"small"} onClick={onClose}>
              <svg width={24} height={24} stroke={palette.grayscale["700"]}>
                <use href="#close" />
              </svg>
            </IconButton>
          </Stack>
          <Divider sx={{ my: 3 }} />
          {showSummary && (
            <Summary
              fee={fee}
              amountValue={amountCent}
              onClose={onDoneSummary}
            />
          )}
          {!showSummary && (
            <>
              <Stack sx={{ flexGrow: 1 }}>
                <Stack
                  component={"form"}
                  onSubmit={onSubmit}
                  noValidate
                  gap={3}
                  paddingBottom={"40px"}
                >
                  <Typography
                    variant={"subLead"}
                    sx={{ fontWeight: 700, color: "black" }}
                  >
                    How much would you like to add?
                  </Typography>
                  <FieldMoney
                    placeholder={"$0.00"}
                    label={""}
                    size={"small"}
                    fieldName={"amount"}
                    {...methods}
                  />
                  <Typography
                    variant={"subLead"}
                    sx={{ fontWeight: 700, color: "black" }}
                  >
                    Choose Payment Method
                  </Typography>
                  <FundsSelect
                    {...methods}
                    setPlaidLoading={setPlaidLoading}
                    fetchPaymentMethods={fetchPaymentMethods}
                    routeState={state}
                    options={paymentMethods}
                    fieldName={"paymentMethod"}
                    label={"Choose a funding source"}
                  />
                </Stack>
                {paymentMethodValue === "debit-credit-card" && (
                  <Box
                    pt={3}
                    sx={{
                      borderRadius: "8px",
                      p: 3,
                      border: `1px solid ${palette.grayscale["200"]}`,
                      mb: 3
                    }}
                  >
                    <DebitCreditCardForm
                      setSubmittedStatus={setSubmittedStatus}
                      fetchPaymentMethods={fetchPaymentMethods}
                    />
                  </Box>
                )}
                <Stack gap={3}>
                  <Typography
                    variant={"subLead"}
                    sx={{ fontWeight: 700, color: "black" }}
                  >
                    Checkout
                  </Typography>
                  <Box
                    sx={{
                      p: "24px",
                      background: palette.grayscale[100],
                      borderRadius: "8px"
                    }}
                  >
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Typography variant={"body1"} color={"grayscale.300"}>
                        Funds amount
                      </Typography>
                      <Typography
                        variant={"body1"}
                        color={"grayscale.500"}
                        fontWeight={600}
                      >
                        {displayFullMoney(amountCent)}
                      </Typography>
                    </Stack>
                    {fee && (
                      <Stack
                        mt={3}
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Typography variant={"body1"} color={"grayscale.300"}>
                          {fee}% Fee
                        </Typography>
                        <Typography
                          variant={"body1"}
                          color={"grayscale.500"}
                          fontWeight={600}
                        >
                          {displayFullMoney(
                            Math.ceil((amountCent / 100) * fee)
                          )}
                        </Typography>
                      </Stack>
                    )}
                    <Divider sx={{ my: 3 }} />
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Typography variant={"subLead"} color={"grayscale.800"}>
                        Total
                      </Typography>
                      <Typography
                        variant={"subLead"}
                        color={"grayscale.800"}
                        fontWeight={700}
                      >
                        {fee
                          ? displayFullMoney(
                              amountCent + Math.ceil((amountCent / 100) * fee)
                            )
                          : displayFullMoney(amountCent)}
                      </Typography>
                    </Stack>
                    <LoadingButton
                      loading={loading}
                      variant={"contained"}
                      fullWidth
                      disabled={!currentPaymentMethod || !amountValue}
                      onClick={onSubmit}
                      sx={{ mt: 2 }}
                    >
                      Proceed
                    </LoadingButton>
                  </Box>
                </Stack>
              </Stack>
              <Stack
                direction={"row"}
                gap={"20px"}
                mt={"24px"}
                sx={{
                  p: "16px",
                  background: palette.grayscale[100],
                  borderRadius: "8px"
                }}
              >
                <Box
                  sx={{ minWidth: 24, svg: { stroke: palette.grayscale[400] } }}
                >
                  <svg width={24} height={24}>
                    <use xlinkHref={"#lock-ico"} />
                  </svg>
                </Box>
                <Typography
                  variant={"leadCaption"}
                  fontWeight={600}
                  sx={{ color: palette.grayscale[400] }}
                >
                  We encrypt all your sensitive information with 256-bit
                  encryption which is practically unbreakable by brute force
                  based on current computing power, making it the strongest
                  encryption standard.
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
      </Drawer>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { emailIsNotCorrect, emailRex, midnightBlue, palette } from "settings";
import { transactionsStatus } from "components/pages/portal/transactions";
import {
  FieldBrandsAutocomplete,
  FieldRangeDatePicker,
  FieldText
} from "components/fields";

import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MuiLink from "@mui/material/Link";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Chip from "@mui/material/Chip";

const defaultForm = {
  startDate: null,
  endDate: null,
  brands: [],
  transactions: null,
  customers: null,
  emails: null,
  status: null
};

export const FilterModal = ({
  open,
  onClose,
  onFilter,
  defaultValues,
  filterParams
}) => {
  const methods = useForm({
    defaultValues
  });
  const { handleSubmit, reset, control, setValue, trigger } = methods;
  const [localValues, setLocalValues] = useState({
    transactions: [],
    customers: [],
    emails: [],
    status: []
  });

  useEffect(() => {
    setValue("brands", filterParams.brands || []);
    setValue("startDate", filterParams.date_range.created_at_from || null);
    setValue("endDate", filterParams.date_range.created_at_to || null);
    setLocalValues({
      transactions: filterParams.transaction_id || [],
      customers: filterParams.customer_id || [],
      emails: filterParams.email || [],
      status: filterParams.status || []
    });
  }, [filterParams]);

  const checkKeyDown = async (event) => {
    const fieldName = event.target?.name;
    if (event.key === "Enter" && localValues.hasOwnProperty(fieldName)) {
      event.preventDefault();
      const inputValue = event.target?.value?.trim();
      const isValid = await trigger(fieldName).then((r) => r);
      if (!isValid) return;
      const oldValues = localValues[fieldName];
      const duplicatedValues = oldValues?.indexOf(inputValue);
      setValue(fieldName, null);
      if (duplicatedValues !== -1 || !inputValue.length) return;
      setLocalValues({
        ...localValues,
        [fieldName]: [...oldValues, inputValue]
      });
    }
  };

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  const onSubmit = handleSubmit((data) => {
    const localData = {
      transactions: localValues.transactions?.length
        ? localValues.transactions
        : [],
      customers: localValues.customers?.length ? localValues.customers : [],
      emails: localValues.emails?.length ? localValues.emails : []
    };

    const filterData = {};
    Object.keys(data).forEach((el) => {
      const duplicated = localValues[el]?.indexOf(data[el]);
      const includedKey = localData.hasOwnProperty(el);
      if (includedKey && data[el]?.length && duplicated === -1) {
        return (filterData[el] = [...localData[el], data[el]]);
      }
      if (includedKey) {
        return (filterData[el] = localData[el]);
      }
      return (filterData[el] = data[el]);
    });

    onFilter(filterData);
    setLocalValues({
      transactions: [],
      customers: [],
      emails: [],
      status: []
    });
    reset(defaultValues);
  });

  const clearAllHandler = () => {
    setLocalValues({
      transactions: [],
      customers: [],
      emails: [],
      status: []
    });

    onFilter(defaultForm);
    reset(defaultForm);
  };

  const handleCheck = (item) => {
    const newStatus = localValues.status.includes(item)
      ? localValues.status?.filter((el) => el !== item)
      : [...localValues.status, item];
    setLocalValues({
      ...localValues,
      status: newStatus || []
    });
    return newStatus;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"md"}
      scroll={"paper"}
      sx={{
        ".MuiPaper-root": {
          margin: { xs: 3, md: 4 },
          width: { xs: "100%", md: "auto" }
        }
      }}
    >
      <DialogTitle>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <svg
              width={24}
              height={24}
              stroke={palette.primary.main}
              style={{ minWidth: "24px" }}
            >
              <use href="#calendar" />
            </svg>
            <Typography
              variant={"subLead"}
              fontWeight={700}
              sx={{ ml: "12px" }}
              color={midnightBlue}
            >
              Set Custom Filter
            </Typography>
          </Stack>

          <IconButton size={"small"} onClick={onClose}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use href="#close" />
            </svg>
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <form
          style={{ paddingTop: "12px" }}
          onSubmit={onSubmit}
          noValidate
          autoComplete="off"
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <Stack gap={"12px"}>
            <FieldRangeDatePicker {...methods} />
            <FieldBrandsAutocomplete {...methods} />
            <Box>
              <FieldText
                {...methods}
                fieldName={"transactions"}
                validation={{}}
                label={"Transaction ID"}
              />
              <Stack direction={"row"} gap={"0 10px"} flexWrap={"wrap"}>
                {localValues["transactions"]?.map((item) => (
                  <Chip
                    key={item}
                    variant="squared-secondary"
                    sx={{
                      mt: "10px"
                    }}
                    deleteIcon={
                      <svg
                        width={12}
                        height={12}
                        stroke={palette.grayscale["800"]}
                        style={{ minWidth: "12px" }}
                      >
                        <use href="#close" />
                      </svg>
                    }
                    tabIndex={-1}
                    label={item}
                    onDelete={() =>
                      setLocalValues({
                        ...localValues,
                        transactions: localValues["transactions"]?.filter(
                          (el) => el !== item
                        )
                      })
                    }
                  />
                ))}
              </Stack>
            </Box>
            <Box>
              <FieldText
                {...methods}
                validation={{}}
                fieldName={"customers"}
                label={"Customer ID"}
              />
              <Stack direction={"row"} gap={"0 10px"} flexWrap={"wrap"}>
                {localValues["customers"]?.map((item) => (
                  <Chip
                    key={item}
                    variant="squared-secondary"
                    sx={{
                      mt: "10px"
                    }}
                    deleteIcon={
                      <svg
                        width={12}
                        height={12}
                        stroke={palette.grayscale["800"]}
                        style={{ minWidth: "12px" }}
                      >
                        <use href="#close" />
                      </svg>
                    }
                    tabIndex={-1}
                    label={item}
                    onDelete={() =>
                      setLocalValues({
                        ...localValues,
                        customers: localValues["customers"]?.filter(
                          (el) => el !== item
                        )
                      })
                    }
                  />
                ))}
              </Stack>
            </Box>
            <Box>
              <FieldText
                {...methods}
                fieldName={"emails"}
                type={"email"}
                validation={{
                  pattern: {
                    value: emailRex,
                    message: emailIsNotCorrect
                  }
                }}
                label={"Email"}
              />
              <Stack direction={"row"} gap={"0 10px"} flexWrap={"wrap"}>
                {localValues["emails"]?.map((item) => (
                  <Chip
                    key={item}
                    variant="squared-secondary"
                    sx={{
                      mt: "10px"
                    }}
                    deleteIcon={
                      <svg
                        width={12}
                        height={12}
                        stroke={palette.grayscale["800"]}
                        style={{ minWidth: "12px" }}
                      >
                        <use href="#close" />
                      </svg>
                    }
                    tabIndex={-1}
                    label={item}
                    onDelete={() =>
                      setLocalValues({
                        ...localValues,
                        emails: localValues["emails"]?.filter(
                          (el) => el !== item
                        )
                      })
                    }
                  />
                ))}
              </Stack>
            </Box>

            <Box>
              <Typography
                variant={"body1"}
                color={palette.grayscale["800"]}
                fontWeight={700}
                mb={"4px"}
              >
                Transaction Status
              </Typography>
              <Controller
                name="status"
                control={control}
                render={({ field }) => {
                  return transactionsStatus.map((item) => (
                    <FormControlLabel
                      key={item.value}
                      control={
                        <Checkbox
                          checked={
                            localValues.status?.includes(item.value) || false
                          }
                          onChange={() =>
                            field.onChange(handleCheck(item.value))
                          }
                        />
                      }
                      label={item.label}
                    />
                  ));
                }}
              />
            </Box>
          </Stack>
          <MuiLink
            component={"button"}
            type={"button"}
            underline="none"
            onClick={clearAllHandler}
            variant={"smallTitle"}
            sx={{ mt: "12px", width: "fit-content" }}
            color={palette.primary.main}
          >
            Clear All
          </MuiLink>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button type={"submit"} fullWidth onClick={onSubmit}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const displayMoney = (num = 0, digits = 2) => {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

export const numberFormat = (number = 0, opts = {}) =>
  new Intl.NumberFormat("en-US", {
    ...opts
  }).format(number);

export const parseMoney = (n = 0) => {
  const withCents = (n / 100).toFixed(2);
  return numberFormat(withCents, {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2
  });
};

export const displayFullMoney = (n = 0) => parseMoney(n);
export const formatMoney = (value = 0) => parseMoney(value);
export const moneyAsNumber = (value = 0) =>
  Number(formatMoney(value).replace(/,/g, "")?.split("$")[1]);

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FieldEmail, FieldPassword } from "components/fields";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useSnackbar } from "notistack";

import LoginHelpModal from "./LoginHelpModal";
import VerifyModal from "./VerifyModal";
import { useAuth } from "components/AuthProvider";
import { royalBlue, fieldCannotBeEmpty, SIGN_UP_PAGE } from "settings";
import { createApiCall } from "helpers/api";
import { captchaVerify } from "helpers/captchaVerify";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Paper from "@mui/material/Paper";

export const LoginForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { login } = useAuth();
  const methods = useForm();
  const { handleSubmit, setError } = methods;
  const [formState, setFormState] = useState({});
  const [loading, setLoading] = useState(false);
  const [openHelpModal, setOpenHelpModal] = useState(false);
  const [openVerifyModal, setOpenVerifyModal] = useState(false);

  const onSubmit = handleSubmit(async ({ email, password }) => {
    setLoading(true);

    // Recaptcha Verify with action 'Login'
    const captchaVerification = await captchaVerify(executeRecaptcha, "Login");
    if (!captchaVerification) {
      setLoading(false);
      return enqueueSnackbar(
        "Captcha verification failed. Please try again later.",
        {
          variant: "error"
        }
      );
    }

    return createApiCall({
      method: "POST",
      url: "login-2fa",
      data: {
        email,
        password,
        trusted_device: 0
      }
    })
      .then(({ data, status }) => {
        setLoading(false);
        if (status === 200 && data.token) {
          login(data.token);
        } else if (status === 200) {
          setFormState({ email, password, isDeactivate: false });
          setOpenVerifyModal(true);
        } else if (data.title === "User account not active") {
          setFormState({ isDeactivate: true });
          setOpenVerifyModal(true);
        } else {
          setError("email", {
            type: "manual",
            message: "Invalid email or password"
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setError("email", {
          type: "manual",
          message: "Invalid email or password"
        });
      });
  });

  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: "18px",
        zIndex: 1,
        width: "100%",
        maxWidth: { xs: "100%", sm: "815px" },
        margin: "0 auto",
        padding: { xs: "24px 16px", md: "24px 160px" }
      }}
    >
      <Stack gap={1}>
        <Typography
          variant={"h2"}
          textAlign={"center"}
          color={royalBlue}
          fontWeight={700}
          sx={{ fontSize: { xs: 28, md: 40 } }}
        >
          Welcome back!
        </Typography>
        <Typography
          variant={"subLead"}
          color={royalBlue}
          sx={{
            letterSpacing: "-0.3px"
          }}
          textAlign={"center"}
          mb={1}
        >
          Log in to send rewards and payouts.
        </Typography>
        <form onSubmit={onSubmit} noValidate>
          <Stack gap={"6px"}>
            <Box>
              <Typography color={"purple.500"} sx={{ fontSize: 15, mb: "3px" }}>
                Email
              </Typography>
              <FieldEmail {...methods} label={""} size="small" />
            </Box>
            <Box>
              <Typography color={"purple.500"} sx={{ fontSize: 15, mb: "3px" }}>
                Password
              </Typography>
              <FieldPassword
                {...methods}
                label={""}
                placeholder={""}
                size="small"
                validation={{ required: fieldCannotBeEmpty }}
              />
              <Link
                sx={{ mt: "3px" }}
                underline="none"
                color={royalBlue}
                variant={"smallTitle"}
                component={"button"}
                type={"button"}
                onClick={() => setOpenHelpModal(true)}
              >
                Need help logging in?
              </Link>
            </Box>
            <LoadingButton
              sx={{ mt: 3 }}
              loading={loading}
              fullWidth
              variant={"contained"}
              type={"submit"}
            >
              Login
            </LoadingButton>
            <Typography
              mt={"2px"}
              variant={"smallTitle"}
              color={royalBlue}
              textAlign={"center"}
            >
              Don’t have an account?{" "}
              <Link
                underline={"none"}
                variant={"smallTitle"}
                aria-label="Go to sign up"
                color={"purple.500"}
                href={SIGN_UP_PAGE}
              >
                Sign up here.
              </Link>
            </Typography>
          </Stack>
        </form>
      </Stack>
      <LoginHelpModal
        open={openHelpModal}
        onClose={() => setOpenHelpModal(false)}
      />
      <VerifyModal
        formState={formState}
        open={openVerifyModal}
        onClose={() => setOpenVerifyModal(false)}
      />
    </Paper>
  );
};

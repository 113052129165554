import React from "react";
import { Helmet } from "react-helmet";
import { AuthBenefitsWrapper, CreatePasswordForm } from "components/pages/auth";

const CreatePasswordPage = () => {
  return (
    <>
      <Helmet>
        <title>Create password</title>
      </Helmet>
      <AuthBenefitsWrapper>
        <CreatePasswordForm />
      </AuthBenefitsWrapper>
    </>
  );
};

export default CreatePasswordPage;

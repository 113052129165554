import React, { useMemo } from "react";
import _map from "lodash/map";
import brandLogo from "assets/redemption/brandLogo.svg";
import { displayFullMoney } from "helpers/money";
import { palette, redAlert } from "settings";

import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

export const BrandsLogo = ({
  data,
  onRemove = false,
  onSelect = false,
  status = null,
  hideText = false,
  size = 80
}) => {
  const { fixedLoad, iconUrl, name, variableLoad, amount = 0 } = data;

  const brandAmount = displayFullMoney(amount || 0);

  const availableAmount = useMemo(() => {
    const maxAmount = variableLoad?.maximumAmount;
    const minAmount = variableLoad?.minimumAmount;
    if (fixedLoad?.amounts?.length) {
      return `Available amounts: ${_map(fixedLoad.amounts, (o) =>
        displayFullMoney(o)
      ).join(", ")}`;
    }
    return `Available amount ${displayFullMoney(
      minAmount
    )} - ${displayFullMoney(maxAmount)}`;
  }, [variableLoad, fixedLoad]);

  const handleClick = () => {
    if (!!onSelect && !status?.disabled) {
      onSelect(data);
    }
  };
  const currentGray = status?.disabled ? "grayscale.300" : "grayscale.800";

  const Brand = useMemo(() => {
    return (
      <Stack
        onClick={handleClick}
        alignItems={"center"}
        sx={{
          cursor: status?.disabled ? "not-allowed" : "pointer",
          position: "relative",
          textAlign: "center",
          width: size,
          "& img": {
            width: size,
            height: size,
            borderRadius: "50%",
            opacity: status?.disabled ? 0.3 : 1
          }
        }}
      >
        {onRemove && (
          <Box
            onClick={() => onRemove(data)}
            sx={{
              position: "absolute",
              cursor: "pointer",
              zIndex: 1,
              right: 0,
              top: 0,
              "& svg": {
                zIndex: 1,
                background: "white",
                borderRadius: "50%",
                color: status?.error ? redAlert : palette.grayscale[800]
              }
            }}
          >
            <svg width={24} height={24} fill={"white"}>
              <use xlinkHref={"#circle-close"} />
            </svg>
          </Box>
        )}
        <img
          src={iconUrl ? String(iconUrl).replace(/\s/g, "") : brandLogo}
          className="fit-cover"
          alt={name}
        />
        {!hideText && (
          <Typography
            variant={"smallTitle"}
            fontWeight={400}
            mt={1}
            color={status?.error ? redAlert : currentGray}
            sx={{
              width: "100%",
              whiteSpace: "nowrap",
              position: "relative",
              textAlign: "center",
              overflow: "hidden",
              "&:after": {
                content: '""',
                position: "absolute",
                display: "block",
                zIndex: 1,
                right: 0,
                top: 0,
                background: "rgba(255,255,255,0.7)",
                width: 10,
                height: "100%"
              }
            }}
          >
            {name}
          </Typography>
        )}
      </Stack>
    );
  }, [data, status]);

  if (status?.showAmount) {
    return (
      <Tooltip
        title={brandAmount}
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              textAlign: "center",
              padding: "8px 12px",
              fontSize: 12
            }
          }
        }}
        placement="top"
        variant={"small"}
      >
        {Brand}
      </Tooltip>
    );
  }
  if (status?.error) {
    return (
      <Tooltip
        title={availableAmount}
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              textAlign: "center",
              padding: "8px 12px",
              fontSize: 12
            }
          }
        }}
        placement="top"
        variant={"small"}
      >
        {Brand}
      </Tooltip>
    );
  }
  if (status?.disabled) {
    return (
      <Tooltip
        title={"This brand is already selected"}
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              textAlign: "center",
              padding: "8px 12px",
              fontSize: 12
            }
          }
        }}
        placement="top"
        variant={"small"}
      >
        {Brand}
      </Tooltip>
    );
  }
  return <>{Brand}</>;
};

import React, { useMemo } from "react";
import filter from "lodash/filter";
import sumBy from "lodash/sumBy";
import { palette } from "settings";

import Stack from "@mui/material/Stack";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";

const TableHeadCells = ({
  headCells = [],
  sticky,
  order,
  orderBy,
  createSortHandler,
  checkedItems,
  onCheckedItems,
  total
}) => {
  return headCells.map((headCell) => {
    const isActiveSort =
      orderBy === headCell.id || orderBy === headCell.sortKey;
    const direction = isActiveSort ? order : "asc";
    return (
      <TableCell
        size={"small"}
        key={headCell.id}
        align={headCell.align || "left"}
        sortDirection={isActiveSort ? order : false}
        sx={[
          {
            color: "grayscale.300",
            width: headCell.width || "auto",
            minWidth: headCell.minWidth || headCell.width || "auto",
            maxWidth: headCell.width || "none"
          },
          sticky && {
            border: "none"
          }
        ]}
      >
        {headCell.sortable && createSortHandler ? (
          <TableSortLabel
            active={isActiveSort}
            direction={isActiveSort ? order : "asc"}
            onClick={() =>
              createSortHandler(
                headCell.id,
                isActiveSort && order === "asc" ? "desc" : "asc"
              )
            }
            IconComponent={null}
          >
            {headCell.label}
            <Stack spacing={-0.5} mx={1}>
              <svg
                width={8}
                height={8}
                stroke={
                  isActiveSort && direction === "asc"
                    ? palette.grayscale["800"]
                    : palette.grayscale["300"]
                }
              >
                <use href="#cheveron-up" />
              </svg>
              <svg
                width={8}
                height={8}
                stroke={
                  isActiveSort && direction === "desc"
                    ? palette.grayscale["800"]
                    : palette.grayscale["300"]
                }
              >
                <use href="#cheveron-down" />
              </svg>
            </Stack>
          </TableSortLabel>
        ) : (
          <>
            {headCell.type === "checkbox" ? (
              <Checkbox
                disableRipple
                checked={checkedItems.all || false}
                indeterminate={
                  (!checkedItems.all &&
                    checkedItems?.items?.length &&
                    checkedItems.items?.length !== total) ||
                  false
                }
                onChange={(_event, checked) => onCheckedItems("all", checked)}
                sx={{ my: "-8px" }}
              />
            ) : (
              headCell.label
            )}
          </>
        )}
      </TableCell>
    );
  });
};

export const TableHeadRow = ({
  headCells,
  order,
  orderBy,
  createSortHandler,
  onCheckedItems,
  checkedItems,
  total
}) => {
  const filteredHeadCells = useMemo(() => {
    return {
      leftCells: filter(headCells, (o) => o.sticky === "left"),
      cells: filter(headCells, (o) => !o.sticky),
      rightCells: filter(headCells, (o) => o.sticky === "right")
    };
  }, [headCells]);

  return (
    <TableRow>
      {!!filteredHeadCells.leftCells.length && (
        <TableCell
          size={"small"}
          sx={{
            left: 0,
            position: "sticky",
            background: palette.grayscale["100"],
            zIndex: 2,
            padding: 0,
            width: sumBy(filteredHeadCells.leftCells, "width")
          }}
        >
          <Stack
            justifyContent={"center"}
            sx={{
              boxShadow: `1px 0px 0px 0px ${palette.grayscale["200"]}`,
              height: "100%"
            }}
          >
            <table width={"100%"}>
              <thead>
                <tr>
                  <TableHeadCells
                    order={order}
                    orderBy={orderBy}
                    createSortHandler={createSortHandler}
                    headCells={filteredHeadCells.leftCells}
                    sticky
                    checkedItems={checkedItems}
                    onCheckedItems={onCheckedItems}
                    total={total}
                  />
                </tr>
              </thead>
            </table>
          </Stack>
        </TableCell>
      )}

      <TableHeadCells
        order={order}
        orderBy={orderBy}
        createSortHandler={createSortHandler}
        headCells={filteredHeadCells.cells}
        checkedItems={checkedItems}
        onCheckedItems={onCheckedItems}
        total={total}
      />

      {!!filteredHeadCells.rightCells.length && (
        <TableCell
          size={"small"}
          sx={{
            right: 0,
            position: "sticky",
            background: palette.grayscale["100"],
            zIndex: 2,
            padding: 0,
            width: sumBy(filteredHeadCells.rightCells, "width")
          }}
        >
          <Stack
            justifyContent={"center"}
            sx={{
              boxShadow: `-1px 0px 0px 0px ${palette.grayscale["200"]}`,
              height: "100%"
            }}
          >
            <table width={"100%"}>
              <thead>
                <tr>
                  <TableHeadCells
                    order={order}
                    orderBy={orderBy}
                    createSortHandler={createSortHandler}
                    headCells={filteredHeadCells.rightCells}
                    sticky
                    checkedItems={checkedItems}
                    onCheckedItems={onCheckedItems}
                    total={total}
                  />
                </tr>
              </thead>
            </table>
          </Stack>
        </TableCell>
      )}
    </TableRow>
  );
};

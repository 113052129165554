import React from "react";
import { rolesDescription, rolesPermissions } from "./constants";
import { greenAlert, palette, redAlert } from "settings";

import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";

export const RolesDescription = ({ onClose, open }) => {
  return (
    <Drawer open={open} onClose={onClose}>
      <Stack sx={{ height: "100%" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            p: 3,
            borderBottom: `1px solid ${palette.grayscale["200"]}`
          }}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
            <svg width={24} height={24} color={palette.purple["500"]}>
              <use href="#user-group" />
            </svg>
            <Typography
              variant={"subLead"}
              fontWeight={700}
              color={"purple.900"}
            >
              User Roles
            </Typography>
          </Stack>
          <IconButton size={"small"} onClick={onClose}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use href="#close" />
            </svg>
          </IconButton>
        </Stack>
        <Box
          sx={{
            p: 3,
            flex: "0 1 100%",
            overflowY: "auto",
            height: "100%"
          }}
        >
          <TableContainer component={Paper}>
            <Table
              sx={{
                "& .MuiTableCell-head": {
                  height: "32px",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: palette.grayscale[300]
                },
                "& .MuiTableCell-body": {
                  height: "40px",
                  color: palette.grayscale[800],
                  "&:not(:first-child)": {
                    lineHeight: 1
                  }
                }
              }}
              aria-label="roles table"
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: "8px 8px 8px 16px" }}>
                    Features
                  </TableCell>
                  <TableCell align="center" sx={{ padding: "8px" }}>
                    Owner
                  </TableCell>
                  <TableCell align="center" sx={{ padding: "8px" }}>
                    Admin
                  </TableCell>
                  <TableCell align="center" sx={{ padding: "8px" }}>
                    Manager
                  </TableCell>
                  <TableCell align="center" sx={{ padding: "8px" }}>
                    Agent
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rolesPermissions.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        padding: "10px 8px 10px 16px"
                      }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "10px 8px"
                      }}
                    >
                      <svg
                        width={24}
                        height={24}
                        stroke={row.owner ? greenAlert : redAlert}
                      >
                        <use
                          xlinkHref={row.owner ? "#accept-outline" : "#close"}
                        />
                      </svg>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "0 8px"
                      }}
                    >
                      <svg
                        width={24}
                        height={24}
                        stroke={row.admin ? greenAlert : redAlert}
                      >
                        <use
                          xlinkHref={row.admin ? "#accept-outline" : "#close"}
                        />
                      </svg>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "0 8px"
                      }}
                    >
                      <svg
                        width={24}
                        height={24}
                        stroke={row.manager ? greenAlert : redAlert}
                      >
                        <use
                          xlinkHref={row.manager ? "#accept-outline" : "#close"}
                        />
                      </svg>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "0 8px"
                      }}
                    >
                      <svg
                        width={24}
                        height={24}
                        stroke={row.agent ? greenAlert : redAlert}
                      >
                        <use
                          xlinkHref={row.agent ? "#accept-outline" : "#close"}
                        />
                      </svg>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box mt={2}>
            {rolesDescription.map(({ title, description }) => (
              <Box
                key={title}
                sx={{
                  pt: 1,
                  pb: "4px",
                  borderBottom: `1px solid ${palette.gray[200]}`
                }}
              >
                <Typography
                  component={"span"}
                  variant={"smallTitle"}
                  color={"grayscale.500"}
                  fontWeight={700}
                >
                  {title}
                </Typography>
                <Typography
                  component={"span"}
                  variant={"smallTitle"}
                  color={"grayscale.500"}
                >
                  {` – ${description}`}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Stack>
    </Drawer>
  );
};

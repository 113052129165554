import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { format, getHours, getMinutes, setHours, setMinutes } from "date-fns";
import { useFormContext } from "react-hook-form";
import { CustomTabPanel } from "components/shared";
import { useAuth } from "components/AuthProvider";
import { displayFullMoney } from "helpers/money";
import { createApiCall } from "helpers/api";
import { CAMPAIGNS_PAGE, palette, redAlert } from "settings";
import { numberFormat } from "helpers/math";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";

export const Step5 = ({ activeStep, setStep, campaign }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { balance, getBalance } = useAuth();
  const navigate = useNavigate();
  const methods = useFormContext();
  const { getValues } = methods;
  const formData = getValues();
  const [loading, setLoading] = useState(false);

  const enoughMoney = useMemo(() => {
    return campaign && campaign?.totalValue <= balance?.data?.availableBalance;
  }, [campaign, balance]);

  const canSchedule = useMemo(() => {
    return (
      campaign &&
      campaign.totalValue &&
      campaign.brandsCount &&
      (campaign.tagsCount || campaign.customersCount) &&
      (campaign.deliveryMethod === "SMS" || !!campaign.message) &&
      campaign.duration?.start &&
      campaign.duration?.end
    );
  }, [campaign]);

  const addFunds = () => {
    navigate(null, { state: { showAddFundsBar: true } });
  };

  const onEdit = (index) => setStep(index);

  const scheduleCampaign = () => {
    setLoading(true);
    createApiCall({
      method: "PATCH",
      url: `campaigns/status`,
      data: {
        campaign_uuid: campaign?.uuid,
        status: "PENDING"
      }
    }).then(({ data, status }) => {
      if (status === 200) {
        enqueueSnackbar(
          `A campaign ${campaign?.name} was scheduled successfully.`,
          {
            variant: "success"
          }
        );
        getBalance();
        navigate(CAMPAIGNS_PAGE);
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  };

  const startCampaign = () => {
    setLoading(true);
    createApiCall({
      method: "PATCH",
      url: `campaigns/status`,
      data: {
        campaign_uuid: campaign?.uuid,
        status: "ACTIVE"
      }
    }).then(({ data, status }) => {
      if (status === 200) {
        enqueueSnackbar(
          `A campaign ${campaign?.name} was started successfully.`,
          {
            variant: "success"
          }
        );
        getBalance();
        navigate(CAMPAIGNS_PAGE);
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  };

  const voidCampaign = () => {
    setLoading(true);
    createApiCall({
      method: "PATCH",
      url: `campaigns/status`,
      data: {
        campaign_uuid: campaign?.uuid,
        status: "VOID"
      }
    }).then(({ data, status }) => {
      if (status === 200) {
        enqueueSnackbar(
          `A campaign ${campaign?.name} was voided successfully.`,
          {
            variant: "success"
          }
        );
        getBalance();
        navigate(CAMPAIGNS_PAGE);
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  };

  const EditButton = ({ step }) => (
    <IconButton
      size={"small"}
      onClick={() => onEdit(step)}
      sx={{
        color: palette.grayscale["400"]
      }}
    >
      <svg width={24} height={24}>
        <use href="#edit-pencil" />
      </svg>
    </IconButton>
  );

  return (
    <CustomTabPanel value={activeStep} index={4}>
      <Grid container>
        <Grid
          item
          p={{ xs: "24px 16px", md: "24px 12px 24px 32px" }}
          xs={12}
          md={8}
        >
          <Box
            sx={{
              pb: 4,
              borderBottom: `1px solid ${palette.grayscale["200"]} `
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mb={2}
            >
              <Typography
                variant={"subLead"}
                fontWeight={700}
                color={"grayscale.800"}
              >
                Name
              </Typography>
              <EditButton step={0} />
            </Stack>

            <Typography
              variant={"body1"}
              fontWeight={"bold"}
              color={"grayscale.800"}
            >
              {formData?.name || "Untitled"}
            </Typography>
          </Box>

          <Box
            sx={{
              pb: 4,
              pt: 4,
              borderBottom: `1px solid ${palette.grayscale["200"]} `
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              mb={2}
              justifyContent={"space-between"}
            >
              <Typography
                variant={"subLead"}
                fontWeight={700}
                color={"grayscale.800"}
              >
                Recipients
              </Typography>
              <EditButton step={1} />
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant={"smallTitle"} color={"grayscale.400"}>
                  Tags
                </Typography>
                <Typography
                  variant={"body1"}
                  fontWeight={"bold"}
                  color={"grayscale.800"}
                >
                  {formData?.tagsCount || 0}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant={"smallTitle"} color={"grayscale.400"}>
                  {campaign.deliveryMethod === "SMS"
                    ? "Phone Numbers"
                    : "Emails"}
                </Typography>
                <Typography
                  variant={"body1"}
                  fontWeight={"bold"}
                  color={"grayscale.800"}
                >
                  {numberFormat(formData?.customersCount || 0)}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              pb: 1,
              pt: 4,
              borderBottom: `1px solid ${palette.grayscale["200"]} `
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              mb={2}
              justifyContent={"space-between"}
            >
              <Typography
                variant={"subLead"}
                fontWeight={700}
                color={"grayscale.800"}
              >
                Gift card
              </Typography>
              <EditButton step={2} />
            </Stack>

            <Grid container mb={2} spacing={2}>
              <Grid item xs={6}>
                <Typography variant={"smallTitle"} color={"grayscale.400"}>
                  Amount
                </Typography>
                <Typography
                  variant={"body1"}
                  fontWeight={"bold"}
                  color={"grayscale.800"}
                >
                  {displayFullMoney(campaign?.codeValue || 0)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant={"smallTitle"} color={"grayscale.400"}>
                  Brands
                </Typography>
                <Typography
                  variant={"body1"}
                  fontWeight={"bold"}
                  color={"grayscale.800"}
                >
                  {numberFormat(formData?.brandsCount || 0)}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              pb: 1,
              pt: 4
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mb={2}
            >
              <Typography
                variant={"subLead"}
                fontWeight={700}
                color={"grayscale.800"}
              >
                Details
              </Typography>
              <EditButton step={3} />
            </Stack>

            <Grid container mb={2} spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant={"smallTitle"} color={"grayscale.400"}>
                  Delivery date
                </Typography>
                <Typography
                  variant={"body1"}
                  fontWeight={"bold"}
                  color={"grayscale.800"}
                >
                  {formData.delivery_day
                    ? format(
                        setHours(
                          setMinutes(
                            new Date(formData.delivery_day),
                            getMinutes(formData.delivery_time)
                          ),
                          getHours(formData.delivery_time)
                        ),
                        "MMM dd yyyy, HH:mm z"
                      )
                    : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant={"smallTitle"} color={"grayscale.400"}>
                  Redemption code expiration
                </Typography>
                <Typography
                  variant={"body1"}
                  fontWeight={"bold"}
                  color={"grayscale.800"}
                >
                  {formData?.expiration_day
                    ? format(
                        setHours(
                          setMinutes(
                            new Date(formData.expiration_day),
                            getMinutes(formData.delivery_time)
                          ),
                          getHours(formData.delivery_time)
                        ),
                        "MMM dd yyyy, HH:mm z"
                      )
                    : "N/A"}
                </Typography>
              </Grid>
            </Grid>
            {campaign.deliveryMethod === "EMAIL" && (
              <Box>
                <Typography
                  variant={"smallTitle"}
                  color={"grayscale.400"}
                  mb={"4px"}
                >
                  Message
                </Typography>
                <Typography
                  variant={"body1"}
                  fontWeight={"bold"}
                  color={"grayscale.800"}
                  sx={{
                    wordWrap: "break-word"
                  }}
                >
                  {formData?.message || "N/A"}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid
          item
          p={"24px"}
          xs={12}
          md={4}
          sx={{
            borderLeft: `1px solid ${palette.grayscale["200"]} `
          }}
        >
          <Typography
            variant={"subLead"}
            fontWeight={700}
            mb={2}
            color={"grayscale.800"}
          >
            Summary
          </Typography>
          <Stack gap={2}>
            <Box
              sx={{
                pb: 2,
                borderBottom: `1px solid ${palette.grayscale["200"]} `
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={2}
                mb={2}
              >
                <Typography variant={"smallTitle"} color={"grayscale.400"}>
                  Offer amount
                </Typography>
                <Typography
                  variant={"subLead"}
                  color={"grayscale.800"}
                  fontWeight={700}
                >
                  {displayFullMoney(campaign?.codeValue || 0)}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={2}
                mb={1}
              >
                <Typography variant={"smallTitle"} color={"grayscale.400"}>
                  Recipients
                </Typography>
                <Typography
                  variant={"subLead"}
                  color={"grayscale.800"}
                  fontWeight={700}
                >
                  x{numberFormat(formData?.customersCount || 0)}
                </Typography>
              </Stack>
            </Box>
            <Box>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={2}
                mb={3}
              >
                <Typography variant={"smallTitle"} color={"grayscale.400"}>
                  Total amount
                </Typography>
                <Typography
                  variant={"h4"}
                  color={"grayscale.800"}
                  fontWeight={700}
                >
                  {displayFullMoney(campaign?.totalValue || 0)}
                </Typography>
              </Stack>
            </Box>
            {!enoughMoney && (
              <>
                <Typography
                  component={Stack}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"row"}
                  gap={"4px"}
                  variant={"leadCaption"}
                  color={redAlert}
                  fontWeight={600}
                  mb={3}
                >
                  <svg width={16} height={16} stroke={redAlert}>
                    <use xlinkHref={"#warning-sm"} />
                  </svg>
                  Current balance is{" "}
                  {displayFullMoney(balance?.data?.availableBalance || 0)}/
                  {displayFullMoney(balance?.data?.pendingBalance || 0)}
                </Typography>
                <Button mb={2} variant={"outlinedPrimary"} onClick={addFunds}>
                  Deposit funds
                </Button>
              </>
            )}
            {campaign?.status === "DRAFT" && (
              <LoadingButton
                loading={loading}
                variant={"contained"}
                disabled={!enoughMoney || !canSchedule}
                onClick={scheduleCampaign}
              >
                Schedule Campaign
              </LoadingButton>
            )}
            {campaign?.status === "PENDING" && (
              <LoadingButton
                variant={"contained"}
                loading={loading}
                onClick={startCampaign}
              >
                Start Campaign Now
              </LoadingButton>
            )}
            {campaign?.status === "ACTIVE" && (
              <LoadingButton
                variant={"contained"}
                loading={loading}
                onClick={voidCampaign}
              >
                Void Campaign
              </LoadingButton>
            )}
          </Stack>
        </Grid>
      </Grid>
    </CustomTabPanel>
  );
};

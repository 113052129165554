import React from "react";
import { palette, sidebarWidth } from "settings";

import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

export const StatusKYCModal = ({ open, submitted }) => {
  return (
    <Dialog
      open={open}
      sx={{
        background: "rgba(217,217,217,0.7)",
        backdropFilter: "blur(2px)",
        inset: 0,
        top: { xs: 70, md: 100 },
        left: { xs: 0, md: sidebarWidth }
      }}
    >
      {submitted && (
        <DialogTitle
          variant={"smallTitle"}
          fontWeight={700}
          textAlign={"center"}
          color={palette.primary.main}
        >
          Your application has been submitted
        </DialogTitle>
      )}
      <DialogContent>
        <Typography
          variant={"smallTitle"}
          color={"grayscale.400"}
          textAlign={"center"}
          my={2}
        >
          {submitted
            ? "Thank you for submitting your KYC application. Please look out for an email confirming your application has been approved."
            : "Your account KYC application is under review. Please look out for an email confirming your application has been approved."}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

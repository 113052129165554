import React from "react";
import { palette, redAlert } from "settings";

import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

export const UploadFile = ({ file, status = "loading", index, onRemove }) => (
  <Stack
    key={file.name + file.lastModified}
    direction={"row"}
    alignItems={"center"}
    justifyContent={"space-between"}
    sx={{ gap: "8px" }}
  >
    <Stack direction={"row"} alignItems={"center"} spacing={1}>
      <svg
        color={status === "error" ? redAlert : palette.grayscale["700"]}
        width={24}
        height={24}
        style={{ minWidth: 24 }}
      >
        {status === "loading" && <use xlinkHref={"#thinking"} />}
        {status === "success" && <use xlinkHref={"#uploadFile"} />}
        {status === "error" && <use xlinkHref={"#exclamation"} />}
      </svg>
      <Typography
        variant={"smallTitle"}
        sx={{ color: status === "error" ? redAlert : palette.grayscale[800] }}
      >
        {file.name}
      </Typography>
    </Stack>
    <Stack direction={"row"} alignItems={"center"} spacing={2}>
      <IconButton size={"small"} onClick={() => onRemove(index)}>
        <svg
          width={24}
          height={24}
          stroke={palette.grayscale["700"]}
          style={{ minWidth: 24 }}
        >
          <use href="#close" />
        </svg>
      </IconButton>
    </Stack>
  </Stack>
);

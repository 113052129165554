import React from "react";
import { useForm } from "react-hook-form";
import { midnightBlue, palette } from "settings";
import { FieldRangeDatePickerNew } from "components/fields";

import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

const FilterModal = ({ open, onClose, onFilter }) => {
  const methods = useForm();
  const { handleSubmit } = methods;

  const onSubmit = handleSubmit((data) => {
    onFilter(data);
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"md"}>
      <DialogTitle sx={{ padding: "27px 24px" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <svg
              width={24}
              height={24}
              stroke={palette.primary.main}
              style={{ minWidth: "24px" }}
            >
              <use href="#calendar" />
            </svg>
            <Typography
              variant={"subLead"}
              fontWeight={700}
              sx={{ ml: "12px" }}
              color={midnightBlue}
            >
              Set custom timeframe
            </Typography>
          </Stack>

          <IconButton size={"small"} onClick={onClose}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use href="#close" />
            </svg>
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ mt: 3 }}>
        <form onSubmit={onSubmit} noValidate autoComplete="off">
          <FieldRangeDatePickerNew {...methods} />
          <Button type={"submit"} fullWidth sx={{ mt: 3 }}>
            Continue
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default FilterModal;

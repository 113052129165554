import React, { useCallback, useEffect, useState } from "react";
import debounce from "lodash/debounce";
import { useSnackbar } from "notistack";
import { createApiCall } from "helpers/api";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { palette } from "../../settings";

export const FieldBrandsAutocomplete = ({
  watch,
  setValue,
  formState: { errors },
  label = "Brand",
  fieldName = "brands",
  placeholder = ""
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const value = watch(fieldName);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getBrands();
  }, []);

  const getBrands = (name = "") => {
    setLoading(true);
    setOptions([]);
    const search = name ? `&name=${name}` : "";
    createApiCall({
      url: `brand-cms/brands?pagination.sortBy.field=name&pagination.sortBy.order=ASC&pagination.pageLimit=20&pagination.page=0${search}`
    }).then(({ status, data }) => {
      if (status === 200) {
        setPage(1);
        setOptions(data.brands);
        setTotal(data.pagination.totalResults);
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  };

  const debounceGetBrands = useCallback(debounce(getBrands, 500), []);

  const loadMore = () => {
    setLoading(true);
    const search = inputValue ? `&name=${inputValue}` : "";
    createApiCall({
      url: `brand-cms/brands?pagination.sortBy.field=name&pagination.sortBy.order=ASC&pagination.pageLimit=20&pagination.page=${page}${search}`
    }).then(({ status, data }) => {
      if (status === 200) {
        setPage(page + 1);
        setOptions([...options, ...data.brands]);
        setTotal(data.pagination.totalResults);
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  };

  return (
    <FormControl error={!!errors[fieldName]} sx={{ width: "100%" }}>
      <Autocomplete
        loading={loading}
        ChipProps={{
          variant: "squared-secondary"
        }}
        ListboxProps={{
          onScroll: (event) => {
            const listboxNode = event.currentTarget;
            if (
              listboxNode.scrollTop + listboxNode.clientHeight ===
              listboxNode.scrollHeight
            ) {
              if (!loading && options.length < total) {
                loadMore();
              }
            }
          }
        }}
        disableCloseOnSelect
        value={value}
        onChange={(event, newValue) => {
          setValue(fieldName, newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newValue) => {
          debounceGetBrands(newValue);
          setInputValue(newValue);
        }}
        multiple
        limitTags={4}
        id={fieldName}
        options={options}
        getOptionLabel={(option) => option.name}
        popupIcon={
          <svg width={24} height={24} stroke={palette.primary.main}>
            <use href="#cheveron-down" />
          </svg>
        }
        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={"filled"}
            label={label}
            placeholder={placeholder}
            sx={{
              ".MuiFilledInput-root": {
                paddingTop: "20px",
                paddingBottom: "10px",
                "&:before": {
                  visibility: "hidden"
                }
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />
      {errors[fieldName] && (
        <FormHelperText>{errors[fieldName].message}</FormHelperText>
      )}
    </FormControl>
  );
};

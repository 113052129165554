import React, { useEffect, useMemo, useState } from "react";
import valid from "card-validator";
import InputMask from "react-input-mask";
import { Controller } from "react-hook-form";
import {
  fieldCannotBeEmpty,
  invalidFormat,
  midnightBlue,
  palette
} from "settings";

import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";

export const FieldCardCode = ({
  control,
  watch,
  setValue,
  formState: { errors },
  placeholder = "CVV",
  label = "",
  fieldName = "code",
  cardFieldName = "cardNumber",
  size = "small"
}) => {
  const cardFieldValue = watch(cardFieldName);
  const [showCode, setShowCode] = useState(false);

  const cardValid = useMemo(() => {
    return valid.number(cardFieldValue?.replace(/[ \-()+_]/g, ""), {
      luhnValidateUnionPay: true
    });
  }, [cardFieldValue]);

  const mask = useMemo(() => {
    if (cardValid?.card?.code?.size === 4) {
      return [/\d/, /\d/, /\d/, /\d/];
    }
    return [/\d/, /\d/, /\d/];
  }, [cardValid?.card?.code?.size]);

  useEffect(() => {
    setValue(fieldName, "");
  }, [cardValid?.card?.code?.size]);

  const toggleVisibility = () => {
    setShowCode(!showCode);
  };

  return (
    <FormControl variant="filled" error={!!errors[fieldName]} size={size}>
      {(cardValid?.card?.code?.name || label) && (
        <InputLabel sx={{ mb: "6px" }}>
          {cardValid?.card?.code?.name || label}
        </InputLabel>
      )}

      <Controller
        name={fieldName}
        control={control}
        rules={{
          required: fieldCannotBeEmpty,
          validate: {
            codeValid: (v) =>
              valid.cvv(v?.trim(), cardValid?.card?.code?.size || 3).isValid ||
              invalidFormat
          }
        }}
        render={({ field: { onChange, value, name, ref } }) => (
          <InputMask
            mask={mask}
            maskPlaceholder={null}
            onChange={onChange}
            value={value}
          >
            <FilledInput
              name={name}
              fullWidth
              disableUnderline
              placeholder={placeholder}
              inputRef={ref}
              type={showCode ? "text" : "password"}
              endAdornment={
                <InputAdornment
                  position="end"
                  sx={{
                    cursor: "pointer",
                    color: palette.grayscale["800"]
                  }}
                  onClick={toggleVisibility}
                >
                  <svg width={20} height={20} fill={midnightBlue}>
                    <use xlinkHref={`#${showCode ? "eye" : "eye-off"}`} />
                  </svg>
                </InputAdornment>
              }
            />
          </InputMask>
        )}
      />
      {errors[fieldName] && (
        <FormHelperText>{errors[fieldName].message}</FormHelperText>
      )}
    </FormControl>
  );
};

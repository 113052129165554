import React, { useMemo, useState } from "react";
import { useSwipeable } from "react-swipeable";
import _map from "lodash/map";
import _sortBy from "lodash/sortBy";
import _first from "lodash/first";
import _last from "lodash/last";
import {
  eachDayOfInterval,
  eachWeekOfInterval,
  eachMonthOfInterval,
  getTime,
  format,
  differenceInDays,
  differenceInYears,
  differenceInMonths,
  subDays,
  addDays,
  isAfter,
  isBefore
} from "date-fns";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer
} from "recharts";
import ReferenceAreaLabel from "./ReferenceAreaLabel";
import CampaignInfo from "./CampaignInfo";
import { palette } from "settings";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

const getTimeOfEachDay = (interval = []) => _map(interval, (o) => o.getTime());

const CampaignTimeline = ({ campaigns, activePeriod }) => {
  const {
    param: { preset }
  } = activePeriod;

  const [isSwiping, setIsSwiping] = useState(false);
  const [campaign, setCampaign] = useState(null);
  const [domain, setDomain] = useState([
    new Date(preset.start).getTime(),
    new Date(preset.end).getTime()
  ]);

  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedLeft: () => {
      nextPage();
    },
    onSwipedRight: () => {
      prevPage();
    },
    onSwipeStart: () => {
      setIsSwiping(true);
    },
    onSwiped: () => {
      setIsSwiping(false);
    }
  });

  const campaignsWithTimestamp = useMemo(() => {
    if (!campaigns?.length) return [];
    return _map(campaigns, (item) => ({
      ...item,
      start: getTime(new Date(item.dateRange.createdAtFrom)),
      end: getTime(new Date(item.dateRange.createdAtTo))
    }));
  }, [campaigns]);

  const campaignsData = useMemo(() => {
    const sortedCampaigns = _sortBy(campaignsWithTimestamp, "start");
    return _map(sortedCampaigns, (item, i) => ({
      ...item,
      count: i * 100 + 50
    }));
  }, [campaignsWithTimestamp]);

  const period = useMemo(() => {
    setDomain([
      new Date(preset.start).getTime(),
      new Date(preset.end).getTime()
    ]);
    const sortedCampaigns = _sortBy(campaignsWithTimestamp, "end");
    const firstStartCampaignDate = new Date(
      _first(campaignsData)?.dateRange?.createdAtFrom
    );
    const latestEndCampaignDate = new Date(
      _last(sortedCampaigns)?.dateRange?.createdAtTo
    );
    const firstTick = isBefore(firstStartCampaignDate, preset.start)
      ? firstStartCampaignDate
      : preset.start;
    const lastTick = isAfter(latestEndCampaignDate, preset.end)
      ? latestEndCampaignDate
      : preset.end;

    if (differenceInYears(preset.end, preset.start) >= 1) {
      return {
        name: "MONTH",
        format: "MMM",
        ticks: getTimeOfEachDay(
          eachMonthOfInterval({
            start: firstTick,
            end: lastTick
          })
        )
      };
    }
    if (differenceInMonths(preset.end, preset.start) >= 1) {
      return {
        name: "WEEK",
        format: "MM/dd/yyyy",
        ticks: getTimeOfEachDay(
          eachWeekOfInterval({
            start: firstTick,
            end: lastTick
          })
        )
      };
    }
    return {
      name: "DAY",
      format: "MM/dd/yyyy",
      ticks: getTimeOfEachDay(
        eachDayOfInterval({
          start: firstTick,
          end: lastTick
        })
      )
    };
  }, [campaignsData, campaignsWithTimestamp, preset]);

  const onOpenCampaign = (campaignData) => {
    setCampaign(campaignData);
  };

  const prevPage = () => {
    const diff = differenceInDays(preset.end, preset.start);
    const newDomainMin = subDays(domain[0], diff);
    const newDomainMax = subDays(domain[1], diff);
    const firstTick = period.ticks[0];
    const isDomainMinInRange = isBefore(new Date(firstTick), newDomainMin);
    if (isDomainMinInRange) {
      setDomain([newDomainMin.getTime(), newDomainMax.getTime()]);
      return;
    }
    setDomain([firstTick, addDays(new Date(firstTick), diff).getTime()]);
  };

  const nextPage = () => {
    const diff = differenceInDays(preset.end, preset.start);
    const newDomainMin = addDays(domain[0], diff);
    const newDomainMax = addDays(domain[1], diff);
    const lastTick = period.ticks[period.ticks.length - 1];
    const isDomainMaxInRange = isAfter(new Date(lastTick), newDomainMax);
    if (isDomainMaxInRange) {
      setDomain([newDomainMin.getTime(), newDomainMax.getTime()]);
      return;
    }
    setDomain([subDays(lastTick, diff).getTime(), lastTick]);
  };

  return (
    <Paper
      elevation={2}
      sx={{
        position: "relative",
        height: 490,
        padding: { xs: 2, md: 3 }
      }}
    >
      <Stack
        height={32}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant={"subLead"} fontWeight={700}>
          Campaigns
        </Typography>
        <Stack direction={"row"} alignItems={"center"}>
          <IconButton size={"small"} onClick={prevPage}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use xlinkHref={"#cheveron-left"} />
            </svg>
          </IconButton>
          <IconButton size={"small"} onClick={nextPage}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use xlinkHref={"#cheveron-right"} />
            </svg>
          </IconButton>
        </Stack>
      </Stack>

      <Box
        {...handlers}
        sx={{
          cursor: "ew-resize",
          position: "relative",
          ":after": {
            content: '""',
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            zIndex: 1
          }
        }}
      >
        <ResponsiveContainer width={"100%"} height={32}>
          <ScatterChart margin={{ top: 0, right: 50, bottom: 0, left: 50 }}>
            <XAxis
              xAxisId={"swipe-start"}
              dataKey={"start"}
              allowDataOverflow
              includeHidden
              domain={domain}
              name={"Date"}
              type={"number"}
              orientation={"top"}
              ticks={period.ticks}
              tickFormatter={(tick) => format(new Date(tick), period.format)}
              axisLine={false}
              tickLine={false}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </Box>
      <Box
        sx={{
          height: "calc(100% - 64px)",
          overflowY: "auto",
          "::-webkit-scrollbar": {
            width: 7,
            webkitAppearance: "none"
          },
          "::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
            backgroundColor: "rgba(0,0,0,.5)",
            webkitBoxShadow: "0 0 1px rgba(255,255,255,.5)"
          }
        }}
      >
        {campaignsData?.length ? (
          <Box
            {...handlers}
            height={(campaignsData?.length || 0) * 120 + 200}
            sx={[
              {
                cursor: "ew-resize",
                position: "relative",
                userSelect: "none"
              },
              isSwiping && {
                ":after": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 1
                }
              }
            ]}
          >
            <ResponsiveContainer width={"100%"} height={"100%"}>
              <ScatterChart margin={{ top: 0, right: 50, bottom: 0, left: 50 }}>
                <XAxis
                  dataKey={"start"}
                  allowDataOverflow
                  includeHidden
                  domain={domain}
                  name={"Date"}
                  type={"number"}
                  orientation={"top"}
                  ticks={period.ticks}
                  tickFormatter={(tick) =>
                    format(new Date(tick), period.format)
                  }
                  axisLine={false}
                  tickLine={false}
                  hide
                />
                <YAxis
                  dataKey={"count"}
                  name={"Count"}
                  domain={[0, "dataMax + 100"]}
                  reversed
                  hide
                />
                <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                <Scatter
                  data={campaignsData}
                  line={false}
                  shape={() => null}
                  name={"Values"}
                />
                <ReferenceLine
                  x={new Date().getTime()}
                  isOverflow={"extendDomain"}
                  stroke={"rgba(80, 32, 247, .2)"}
                  strokeWidth={2}
                />

                {campaignsData.map((item) => {
                  const isStartBeforeRange =
                    isBefore(new Date(item.start), new Date(domain[0])) &&
                    isAfter(new Date(item.end), new Date(domain[0]));

                  return (
                    <ReferenceArea
                      key={item.uuid}
                      x1={isStartBeforeRange ? domain[0] : item.start}
                      x2={item.end}
                      y1={item.count}
                      y2={item.count + 1}
                      ifOverflow={"visible"}
                      label={(props) => (
                        <ReferenceAreaLabel
                          {...props}
                          isStartBeforeRange={isStartBeforeRange}
                          campaign={item}
                          period={period.name}
                          onOpen={onOpenCampaign}
                        />
                      )}
                    />
                  );
                })}
              </ScatterChart>
            </ResponsiveContainer>
          </Box>
        ) : (
          <Typography variant={"body1"} color={"grayscale.800"}>
            No campaigns found
          </Typography>
        )}

        {campaign && (
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              position: "absolute",
              zIndex: 1,
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              background: "rgba(217, 217, 217, .7)"
            }}
          >
            <CampaignInfo
              campaign={campaign}
              onClose={() => setCampaign(null)}
            />
          </Stack>
        )}
      </Box>
    </Paper>
  );
};

export default CampaignTimeline;

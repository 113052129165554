import React from "react";
import { createTheme } from "@mui/material/styles";
import { normalize } from "./normalize";
import { breakpoints } from "./breakpoints";
import { fonts } from "./fonts";
import { palette, redAlert, royalBlue } from "./palette";
import { forwardRef } from "react";
import { Link } from "react-router-dom";

const LinkBehavior = forwardRef((props, ref) => {
  const { href, ...rest } = props;
  LinkBehavior.displayName = "LinkBehavior";
  return <Link ref={ref} to={href} {...rest} />;
});

export const theme = createTheme({
  typography: {
    fontFamily: fonts.averta,
    h1: {
      fontWeight: 700,
      fontSize: 40,
      lineHeight: "117%",
      letterSpacing: "-0.01em",
      color: royalBlue,
      [`@media(min-width:${breakpoints.sm}px)`]: {
        fontSize: 48
      }
    },
    h2: {
      fontWeight: 600,
      fontSize: 40,
      lineHeight: "120%",
      letterSpacing: "-0.01em",
      color: royalBlue
    },
    h3: {
      fontWeight: 300,
      fontSize: 36,
      lineHeight: "128%",
      color: royalBlue
    },
    h4: {
      fontWeight: 400,
      fontSize: 32,
      lineHeight: "138%",
      color: royalBlue
    },
    h5: {
      fontWeight: 400,
      fontSize: 24,
      lineHeight: "133%",
      color: royalBlue,
      [`@media(min-width:${breakpoints.md}px)`]: {
        fontSize: 28,
        lineHeight: "143%"
      }
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "150%",
      color: palette.grayscale["500"]
    },
    lead: {
      fontWeight: 400,
      fontSize: 24,
      lineHeight: "133%",
      color: royalBlue
    },
    subLead: {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: "133%",
      color: royalBlue
    },
    bigTitle: {
      fontWeight: 700,
      fontSize: 40,
      lineHeight: "129%",
      letterSpacing: "-0.01em",
      color: royalBlue,
      [`@media(min-width:${breakpoints.sm}px)`]: {
        fontSize: 56
      }
    },
    smallTitle: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "157%",
      letterSpacing: "0.005em",
      color: palette.grayscale["500"]
    },
    eyebrow: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: "150%",
      letterSpacing: "0.08em",
      textTransform: "uppercase",
      color: royalBlue
    },
    smallEyebrow: {
      fontWeight: 400,
      fontSize: 10,
      lineHeight: "180%",
      letterSpacing: "0.08em",
      textTransform: "uppercase",
      color: royalBlue
    },
    titleCaption: {
      fontWeight: 400,
      fontSize: 11,
      lineHeight: "145%",
      letterSpacing: "0.005em",
      color: royalBlue
    },
    leadCaption: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: "133%",
      letterSpacing: "0.005em",
      color: royalBlue
    }
  },
  breakpoints: {
    values: breakpoints
  },
  palette,
  components: {
    MuiCssBaseline: {
      styleOverrides: normalize
    },
    MuiTypography: {
      defaultProps: {
        variant: "body1",
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          body1: "p",
          lead: "p",
          subLead: "p",
          smallTitle: "p",
          eyebrow: "p",
          smallEyebrow: "p",
          titleCaption: "p",
          leadCaption: "p"
        }
      }
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior
      }
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    },
    MuiButton: {
      defaultProps: {
        variant: "contained"
      },
      styleOverrides: {
        root: {
          fontWeight: 900,
          fontSize: 16,
          borderRadius: 75,
          textTransform: "none",
          whiteSpace: "nowrap"
        },
        sizeMedium: {
          lineHeight: 0,
          padding: "28px 24px",
          [`@media(max-width:${breakpoints.md}px)`]: {
            padding: "14px 32px",
            lineHeight: 1.75
          }
        },
        contained: {
          stroke: "#fff",
          fill: "#fff",
          "&.Mui-disabled": {
            background: palette.grayscale["200"],
            color: "#fff",
            stroke: "#fff",
            fill: "#fff"
          }
        },
        containedSecondary: {
          color: "#fff"
        },
        outlinedPrimary: {
          stroke: palette.purple["500"],
          fill: palette.purple["500"],
          color: palette.purple["500"],
          border: `1px solid ${palette.purple["500"]}`,
          "&:hover": {
            backgroundColor: "rgba(80, 32, 247, 0.04)"
          },
          "&.Mui-disabled": {
            color: palette.grayscale["300"],
            border: `1px solid ${palette.grayscale["300"]}`,
            stroke: palette.grayscale["300"],
            fill: palette.grayscale["300"]
          }
        },
        outlinedSecondary: {
          stroke: palette.seaGreen["500"],
          fill: palette.seaGreen["500"],
          "&.Mui-disabled": {
            color: palette.grayscale["300"],
            stroke: palette.grayscale["300"],
            fill: palette.grayscale["300"]
          }
        },
        outlinedGray: {
          stroke: palette.purple["500"],
          fill: palette.purple["500"],
          color: palette.grayscale["400"],
          border: `1px solid ${palette.grayscale["400"]}`,
          "&.Mui-disabled": {
            color: palette.grayscale["300"],
            stroke: palette.grayscale["300"],
            fill: palette.grayscale["300"]
          }
        }
      },
      variants: [
        {
          props: { variant: "text" },
          style: {
            padding: 0,
            lineHeight: 1,
            "&:hover": {
              background: "transparent"
            }
          }
        }
      ]
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true
      },
      styleOverrides: {
        root: {
          "&.error": {
            textTransform: "uppercase"
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        ".MuiInputLabel-root": {
          textTransform: "uppercase"
        },
        root: {
          "&.filled-rounded .MuiFilledInput-root": {
            borderRadius: 23,
            paddingTop: "6px",
            paddingBottom: "6px",
            background: palette.grayscale.contrastText,
            border: `1px solid ${palette.grayscale[200]}`,
            "& .MuiFilledInput-input": {
              paddingTop: "6px",
              paddingBottom: "6px",
              paddingLeft: 0
            },
            "&:before": {
              visibility: "hidden"
            }
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          color: palette.grayscale["800"],
          "&.Mui-error": {
            color: palette.grayscale["800"]
          },
          "&.Mui-focused": {
            color: palette.purple["500"]
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        filled: {
          transform: "translate(24px, 23px) scale(1)",
          "&.MuiInputLabel-shrink": {
            transform: "translate(24px, 7px) scale(0.75)"
          }
        },
        shrink: {
          transform: "translate(24px, 7px) scale(0.75)"
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          ".MuiTypography-root": {
            lineHeight: 1.5
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 11,
          lineHeight: "145%",
          letterSpacing: "0.005em",
          marginLeft: 0,
          marginTop: 6,
          "&.Mui-error": {
            color: redAlert
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: palette.grayscale[100],
          border: `1px solid ${palette.gray[200]}`,
          borderRadius: 4,
          background: palette.grayscale["100"],
          "&.Mui-error": {
            boxShadow: `inset 0px 0px 0px 2px ${redAlert}`
          }
        },
        input: {
          paddingTop: 31,
          paddingBottom: 16,
          paddingLeft: 24
        },
        sizeSmall: {
          borderRadius: 6,
          input: {
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 20
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 50,
          height: 32,
          borderRadius: 54,
          padding: 0,
          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 4,
            transitionDuration: "300ms",
            "&.Mui-checked": {
              transform: "translateX(18px)",
              color: "#fff",
              "& + .MuiSwitch-track": {
                opacity: 1,
                border: 0
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5
              }
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              color: "#33cf4d",
              border: "6px solid #fff"
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.3
            }
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 24,
            height: 24
          },
          "& .MuiSwitch-track": {
            borderRadius: 32 / 2,
            backgroundColor: palette.grayscale["200"],
            opacity: 1
          }
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          maxHeight: "500px",
          "& .MuiMenuItem-root": {
            paddingTop: "8px",
            paddingBottom: "8px"
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: palette.grayscale[100],
          border: `1px solid ${palette.gray[200]}`,
          padding: "7px 0",
          height: "70px",
          "&.MuiInputBase-sizeSmall": {
            height: "48px"
          },
          "&.Mui-error": {
            boxShadow: `inset 0px 0px 0px 2px ${redAlert}`
          },
          "& fieldset": {
            border: "none"
          }
        },
        select: {
          paddingTop: 31,
          paddingLeft: 24,
          paddingBottom: 16,
          "&.MuiInputBase-inputSizeSmall": {
            paddingTop: 12,
            paddingLeft: 20,
            paddingBottom: 12
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiInputBase-root": {
            paddingLeft: "20px"
          },
          ".MuiFilledInput-root .MuiFilledInput-input": {
            paddingTop: 6,
            paddingBottom: 6
          }
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        icon: (
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1"
              y="1"
              width="22"
              height="22"
              rx="3"
              fill="white"
              stroke="#D9D9D9"
              strokeWidth="2"
            />
            <line
              x1="5.70711"
              y1="12.2929"
              x2="9.70711"
              y2="16.2929"
              stroke="white"
              strokeWidth="2"
            />
            <line
              x1="19.7071"
              y1="7.70711"
              x2="9.70711"
              y2="17.7071"
              stroke="white"
              strokeWidth="2"
            />
          </svg>
        ),
        checkedIcon: (
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1"
              y="1"
              width="22"
              height="22"
              rx="3"
              fill="white"
              stroke="#5020F7"
              strokeWidth="2"
            />
            <line
              x1="5.70711"
              y1="12.2929"
              x2="9.70711"
              y2="16.2929"
              stroke="#CCFAE8"
              strokeWidth="2"
            />
            <line
              x1="19.7071"
              y1="7.70711"
              x2="9.70711"
              y2="17.7071"
              stroke="#CCFAE8"
              strokeWidth="2"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.10553 17.9633C9.00853 17.9103 8.91902 17.8403 8.84161 17.7539C8.82505 17.7355 8.80929 17.7166 8.79433 17.6973L5.21797 13.2091C4.87379 12.7772 4.94492 12.148 5.37685 11.8038C5.80878 11.4596 6.43793 11.5308 6.78211 11.9627L9.66693 15.583L17.8753 7.29626C18.264 6.90389 18.8972 6.90088 19.2895 7.28954C19.6819 7.67821 19.6849 8.31137 19.2963 8.70374L10.3069 17.7789C10.29 17.7965 10.2723 17.8136 10.2538 17.8301C10.1676 17.9077 10.071 17.9676 9.9689 18.0099C9.69129 18.1248 9.37224 18.1095 9.10553 17.9633Z"
              fill="#5020F7"
            />
          </svg>
        ),
        indeterminateIcon: (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1"
              y="1"
              width="22"
              height="22"
              rx="3"
              fill="white"
              stroke="#5020F7"
              strokeWidth="2"
            />
            <line
              x1="5.70711"
              y1="12.2929"
              x2="9.70711"
              y2="16.2929"
              stroke="white"
              strokeWidth="2"
            />
            <line
              x1="19.7071"
              y1="7.70711"
              x2="9.70711"
              y2="17.7071"
              stroke="white"
              strokeWidth="2"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H6C5.44772 13 5 12.5523 5 12Z"
              fill="#5020F7"
            />
          </svg>
        )
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            svg: {
              width: 20,
              height: 20
            }
          }
        },
        {
          props: { size: "medium" },
          style: {
            svg: {
              width: 24,
              height: 24
            }
          }
        }
      ]
    },
    MuiRadio: {
      defaultProps: {
        icon: (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1"
              y="1"
              width="22"
              height="22"
              rx="11"
              fill="white"
              stroke="#D9D9D9"
              strokeWidth="2"
            />
          </svg>
        ),
        checkedIcon: (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1"
              y="1"
              width="22"
              height="22"
              rx="11"
              fill="#CCFAE8"
              stroke="#5020F7"
              strokeWidth="2"
            />
            <circle cx="12" cy="12" r="6" fill="#5020F7" />
          </svg>
        )
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            svg: {
              width: 20,
              height: 20
            }
          }
        },
        {
          props: { size: "medium" },
          style: {
            svg: {
              width: 24,
              height: 24
            }
          }
        }
      ]
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true
      },
      styleOverrides: {
        root: {
          ".MuiBackdrop-root": {
            background: "rgba(217,217,217,0.7)"
          }
        },
        paper: {
          borderRadius: 8,
          margin: 24,
          [`@media(min-width:${breakpoints.md}px)`]: {
            margin: 32
          },
          "&.MuiDialog-paperFullWidth": {
            width: "100%"
          }
        },
        paperWidthSm: {
          maxWidth: 420
        },
        paperWidthMd: {
          maxWidth: 480
        }
      }
    },
    MuiDialogTitle: {
      defaultProps: {
        component: "div"
      },
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${palette.grayscale["100"]}`
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: "center",
          minWidth: 375
        },
        paper: {
          padding: "5px 12px"
        },
        filledSuccess: {
          background: palette.seaGreen["100"],
          ".MuiAlert-icon": {
            color: palette.seaGreen["700"]
          }
        },
        filledError: {
          background: palette.vibrantYellow["100"],
          ".MuiAlert-icon": {
            color: redAlert
          }
        },
        filledInfo: {
          background: "#D9FCFE",
          ".MuiAlert-icon": {
            color: "#018189"
          }
        },
        action: {
          paddingTop: 0
        },
        icon: {
          marginRight: 6,
          ".MuiSvgIcon-root": {
            width: 24,
            height: 24
          }
        },
        message: {
          padding: "6px 0"
        }
      }
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          margin: 0,
          fontWeight: 700
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: 290,
          borderRadius: 16,
          background: palette.primary.main,
          padding: "16px 24px",
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 1.5,
          textAlign: "center"
        },
        tooltipPlacementBottom: {
          "&.MuiTooltip-tooltipArrow": {
            marginTop: "14px !important"
          },
          marginTop: "0px !important"
        },
        tooltipPlacementTop: {
          marginBottom: "8px !important",
          "& .MuiTooltip-arrow": {
            marginBottom: "-10px !important"
          }
        },
        arrow: {
          color: palette.primary.main,
          fontSize: "20px",
          "&:before": {
            borderRadius: "2px",
            color: palette.primary.main
          }
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          zIndex: "2 !important",
          background: palette.grayscale["100"],
          ".MuiTableCell-root": {
            fontWeight: 400,
            lineHeight: 1.33
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          lineHeight: 1.14,
          borderColor: palette.grayscale["200"]
        },
        sizeSmall: {
          fontSize: 12,
          lineHeight: 1.33,
          padding: "8px 16px",
          height: 32
        },
        sizeMedium: {
          fontSize: 14,
          height: 48,
          lineHeight: 1.57,
          padding: "8px 16px"
        }
      }
    },
    MuiTableSortLabel: {
      defaultProps: {
        hideSortIcon: true
      },
      styleOverrides: {
        root: {
          fontWeight: 400,
          color: palette.grayscale["300"],
          "&.Mui-active": {
            color: palette.grayscale["800"]
          }
        }
      }
    },
    MuiChip: {
      defaultProps: {
        deleteIcon: (
          <svg
            width={12}
            height={12}
            stroke={palette.grayscale["800"]}
            style={{ minWidth: "12px" }}
          >
            <use href="#close" />
          </svg>
        )
      },
      styleOverrides: {},
      variants: [
        {
          props: { size: "small" },
          style: {
            fontSize: 11,
            lineHeight: 1,
            fontWeight: 700
          }
        },
        {
          props: { variant: "squared-secondary" },
          style: {
            borderRadius: "3px",
            fontWeight: 600,
            fontSize: "12px",
            height: "22px",
            color: palette.grayscale["800"],
            border: `1px solid ${palette.grayscale["200"]}`,
            background: palette.grayscale.contrastText,
            "& .MuiChip-label": {
              paddingLeft: "6px"
            }
          }
        }
      ]
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
          background: "transparent",
          "&:before": {
            opacity: 0
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: 56
        }
      },
      defaultProps: {
        expandIcon: (
          <svg width={24} height={24} stroke={palette.grayscale["800"]}>
            <use xlinkHref={"#cheveron-down"} />
          </svg>
        )
      }
    },
    MuiDrawer: {
      defaultProps: {
        BackdropProps: {
          invisible: true
        },
        anchor: "right",
        variant: "permanent-header",
        size: "sm"
      },
      styleOverrides: {
        paperAnchorRight: {
          boxShadow: "-10px 0 20px rgba(0, 0, 0, 0.25)"
        }
      },
      variants: [
        {
          props: { variant: "permanent-header" },
          style: {
            top: 100,
            "& .MuiBackdrop-root": {
              top: 100
            },
            "& > .MuiPaper-root": {
              top: 0,
              height: "100%",
              [`@media(min-width:${breakpoints.md}px)`]: {
                height: "calc(100% - 100px)",
                top: 100
              },
              "&.MuiDrawer-paperAnchorRight": {
                boxShadow: "-10px 0px 20px rgba(0, 0, 0, 0.25)",
                borderLeft: "none"
              }
            }
          }
        },
        {
          props: { size: "sm" },
          style: {
            "& > .MuiDrawer-paper": {
              width: "100%",
              [`@media(min-width:${breakpoints.md}px)`]: {
                width: 480
              }
            }
          }
        },
        {
          props: { size: "md" },
          style: {
            "& > .MuiDrawer-paper": {
              width: "100%",
              [`@media(min-width:${breakpoints.md}px)`]: {
                width: 580
              }
            }
          }
        }
      ]
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          gap: "16px",
          padding: "12px 16px"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 14,
          lineHeight: 1.57,
          fontWeight: 700,
          minWidth: "fit-content",
          minHeight: "auto",
          letterSpacing: "0.07px",
          padding: "0 2px",
          textTransform: "capitalize",
          color: palette.grayscale["800"]
        }
      }
    }
  }
});

module.exports = Object.freeze({
  host: `https://${
    window.location.host.includes("sandbox")
      ? "sandbox-commerce-api"
      : "commerce-api"
  }.${
    window.location.host.includes("raise")
      ? window.location.host.split(".")[1]
      : "raisestaging"
  }.com`
});

import React, { useEffect, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { useSnackbar } from "notistack";
import { palette } from "settings";
import { createApiCall } from "helpers/api";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import MuiLink from "@mui/material/Link";
import CircularProgress from "@mui/material/CircularProgress";

const PORTAL_CATEGORY_ID = 15879578489108;

export const SupportList = ({
  subdomain = "raisepartners",
  setOpenForm,
  categoryId = PORTAL_CATEGORY_ID
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [pageInfo, setPageInfo] = useState({ page: 1 });
  const [supportList, setSupportList] = useState([]);

  const handleChange = (panel) => (event, isExpanded) =>
    setExpanded(isExpanded ? panel : false);

  useEffect(() => {
    setLoading(true);
    createApiCall({
      url: `https://${subdomain}.zendesk.com/api/v2/help_center/en-us/categories/${categoryId}/articles?page=${pageInfo.page}`,
      prefix: ""
    }).then(({ data, status }) => {
      if (status === 200) {
        const { articles = [], page, page_count, next_page } = data;
        setPageInfo({ page, next_page, page_count });
        setSupportList(articles);
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  }, []);

  const onLoadMore = () => {
    setLoading(true);
    createApiCall({
      url: `https://${subdomain}.zendesk.com/api/v2/help_center/en-us/categories/${categoryId}/articles?page=${
        pageInfo.page + 1
      }`,
      prefix: ""
    }).then(({ data, status }) => {
      if (status === 200) {
        const { articles = [], page, page_count, next_page } = data;
        setPageInfo({ page, next_page, page_count });
        setSupportList([...supportList, ...articles]);
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  };

  const AccordionContent = (item) => {
    const { title, body } = item;
    return (
      <Accordion
        expanded={expanded === title}
        onChange={handleChange(title)}
        square
      >
        <AccordionSummary
          aria-controls={`${title}-content}`}
          id={`${title}-header`}
        >
          <Typography variant={"subLead"} fontWeight={700} color={"#000000"}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 0 }}>
          <Box>
            <Typography
              variant={"smallTitle"}
              dangerouslySetInnerHTML={{ __html: body }}
            />
            <MuiLink
              component={"button"}
              type={"button"}
              variant={"smallTitle"}
              onClick={() => setOpenForm(true)}
              sx={{ mt: "8px", width: "fit-content" }}
              color={palette.primary.main}
            >
              This didn’t help.
            </MuiLink>
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  };

  if (!loading && !supportList.length) {
    return (
      <Typography
        variant={"lead"}
        fontWeight={700}
        color={palette.primary.main}
        textAlign={"center"}
      >
        No Data Found
      </Typography>
    );
  }

  return (
    <Virtuoso
      useWindowScroll
      context={{ pageInfo }}
      data={supportList}
      endReached={() => {
        if (pageInfo.next_page) {
          onLoadMore();
        }
      }}
      itemContent={(_index, item) => AccordionContent(item)}
      components={{
        Footer: () => {
          if (loading) {
            return (
              <Stack alignItems={"center"} py={1}>
                <CircularProgress />
              </Stack>
            );
          }
        }
      }}
    />
  );
};

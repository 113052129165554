import React from "react";
import valid from "card-validator";
import InputMask from "react-input-mask";
import { Controller } from "react-hook-form";
import { fieldCannotBeEmpty, invalidFormat } from "settings";

import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";

export const FieldCardExpirationDate = ({
  control,
  formState: { errors },
  label = "",
  placeholder = "MM/YY",
  fieldName = "expirationDate",
  size = "small"
}) => {
  return (
    <FormControl variant="filled" error={!!errors[fieldName]} size={size}>
      {label && <InputLabel sx={{ mb: "6px" }}>{label}</InputLabel>}
      <Controller
        name={fieldName}
        control={control}
        rules={{
          required: fieldCannotBeEmpty,
          validate: {
            dateValid: (v) => valid.expirationDate(v).isValid || invalidFormat
          }
        }}
        render={({ field: { onChange, value, name, ref } }) => (
          <InputMask
            mask={"99/99"}
            maskPlaceholder={null}
            onChange={onChange}
            value={value}
          >
            <FilledInput
              name={name}
              placeholder={placeholder}
              fullWidth
              disableUnderline
              inputRef={ref}
            />
          </InputMask>
        )}
      />
      {errors[fieldName] && (
        <FormHelperText>{errors[fieldName].message}</FormHelperText>
      )}
    </FormControl>
  );
};

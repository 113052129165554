import React from "react";
import { NumericFormat } from "react-number-format";
import { fieldCannotBeEmpty } from "settings";

import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

export const FieldMoney = ({
  register,
  setValue,
  formState: { errors },
  fieldName = "name",
  placeholder = "",
  size = "medium",
  label,
  validation = {
    required: fieldCannotBeEmpty
  }
}) => (
  <FormControl variant="filled" error={!!errors[fieldName]} size={size}>
    {label && (
      <InputLabel sx={{ mb: "6px" }}>
        {label}
        {!validation?.required && (
          <Typography component={"span"} color={"grayscale.400"}>
            {" "}
            (optional)
          </Typography>
        )}
      </InputLabel>
    )}
    <NumericFormat
      customInput={TextField}
      variant="filled"
      thousandSeparator={true}
      decimalScale={2}
      onValueChange={({ floatValue }) => {
        setValue(fieldName, floatValue);
      }}
      prefix={"$"}
      InputProps={{ disableUnderline: true, size }}
      placeholder={placeholder}
      {...register(fieldName, validation)}
    />
    {!!errors[fieldName] && (
      <FormHelperText>{errors[fieldName].message}</FormHelperText>
    )}
  </FormControl>
);

import React, { useState } from "react";
import { TableOptions } from "./TableOptions";
import { TableOptionsMenu } from "./TableOptionsMenu";
import { Options } from "./tableCellTypes";
import { theme } from "settings";

import useMediaQuery from "@mui/material/useMediaQuery";

export const ToolbarActions = ({ options = [] }) => {
  const isTabletOrSmall = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = useState(null);

  const showMobileActions = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const closeMobileActions = () => {
    setAnchorEl(null);
  };

  if (isTabletOrSmall) {
    return (
      <>
        <Options
          data={null}
          onClick={showMobileActions}
          sx={{
            borderRadius: "12px 12px 0 0",
            background: anchorEl ? "#fff" : "transparent",
            transitionDuration: anchorEl ? "500ms" : "150ms",
            zIndex: 1,
            color: (theme) => (anchorEl ? theme.palette.grayscale[800] : "#fff")
          }}
        />
        <TableOptions
          anchorEl={anchorEl}
          onClose={closeMobileActions}
          sx={{
            ".MuiPaper-root": {
              borderRadius: "12px",
              marginLeft: "0.01em",
              borderTopRightRadius: 0
            }
          }}
        >
          <TableOptionsMenu options={options} onClose={closeMobileActions} />
        </TableOptions>
      </>
    );
  }

  return (
    <>
      <TableOptionsMenu options={options} />
    </>
  );
};

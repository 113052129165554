import React, { useMemo, useRef } from "react";
import {
  fieldCannotBeEmpty,
  palette,
  passwordDontMatch,
  passwordMin8,
  passwordRex,
  passwordValidationText
} from "settings";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FilledInput from "@mui/material/FilledInput";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";

export const NewPasswordField = ({
  register,
  formState: { errors, touchedFields },
  watch,
  clearErrors,
  setError
}) => {
  const repeatPassword = watch("repeatPassword");
  const newPassword = useRef({});
  if (watch) {
    newPassword.current = watch("newPassword", "");
  }

  const helperError = useMemo(() => {
    const pass = newPassword.current;
    return {
      minLen: pass?.length >= 8 ? "success" : "error",
      pattern:
        !/^(?!^.*[A-Z]+.*$)/g.test(pass) && !/^(?!^.*[a-z]+.*$)/g.test(pass)
          ? "success"
          : "error",
      number: !/^(?!^.*[0-9]+.*$)/g.test(pass) ? "success" : "error",
      symbol: !/^(?!^.*[!@#$%^&*()|_+\-={}\[\]\\\/<>?,.:"';~`]+.*$)/g.test(pass)
        ? "success"
        : "error"
    };
  }, [newPassword.current, touchedFields.newPassword]);

  return (
    <>
      <Box>
        <Typography color={"purple.500"} sx={{ fontSize: 15, mb: "3px" }}>
          Create password
        </Typography>
        <FormControl variant="filled" error={!!errors.newPassword}>
          <FilledInput
            type={"password"}
            fullWidth
            size={"small"}
            disableUnderline
            placeholder={""}
            {...register("newPassword", {
              required: fieldCannotBeEmpty,
              minLength: {
                value: 8,
                message: passwordMin8
              },
              maxLength: {
                value: 32,
                message: passwordValidationText
              },
              pattern: {
                value: passwordRex,
                message: passwordValidationText
              },
              validate: (value) => {
                if (value === repeatPassword) {
                  clearErrors("repeatPassword");
                } else {
                  if (touchedFields.repeatPassword) {
                    setError("repeatPassword", {
                      type: "validate",
                      message: passwordDontMatch
                    });
                  }
                }
                return true;
              }
            })}
          />
          {errors.newPassword && (
            <FormHelperText>{errors.newPassword.message}</FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box>
        <Typography color={"purple.500"} sx={{ fontSize: 15, mb: "3px" }}>
          Confirm password
        </Typography>
        <FormControl variant="filled" error={!!errors.repeatPassword}>
          <FilledInput
            type={"password"}
            fullWidth
            size={"small"}
            disableUnderline
            placeholder={""}
            {...register("repeatPassword", {
              required: fieldCannotBeEmpty,
              validate: (value) =>
                value === newPassword.current || passwordDontMatch
            })}
          />
          {errors.repeatPassword && (
            <FormHelperText>{errors.repeatPassword.message}</FormHelperText>
          )}
        </FormControl>
      </Box>

      <Box mt={1}>
        <Typography
          variant={"leadCaption"}
          color={"grayscale.800"}
          mb={1}
          sx={{ fontSize: 13 }}
        >
          Passwords need a minimum of :
        </Typography>
        <Grid container spacing={0.5}>
          <Grid
            item
            xs={6}
            container
            alignItems={"center"}
            flexWrap={"nowrap"}
            gap={"8px"}
          >
            <Box
              height={24}
              sx={[
                {
                  stroke: palette.purple["200"]
                },
                helperError.minLen === "success" && {
                  stroke: palette.purple["500"]
                },
                helperError.minLen === "error" &&
                  touchedFields.newPassword && {
                    stroke: palette.vibrantRed["500"]
                  }
              ]}
            >
              <svg width={24} height={24} style={{ minWidth: 24 }}>
                <use href="#check" />
              </svg>
            </Box>
            <Typography variant={"leadCaption"} color={"grayscale.800"}>
              8 characters
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            container
            alignItems={"center"}
            flexWrap={"nowrap"}
            gap={"8px"}
          >
            <Box
              height={24}
              sx={[
                {
                  stroke: palette.purple["200"]
                },
                helperError.number === "success" && {
                  stroke: palette.purple["500"]
                },
                helperError.number === "error" &&
                  touchedFields.newPassword && {
                    stroke: palette.vibrantRed["500"]
                  }
              ]}
            >
              <svg width={24} height={24} style={{ minWidth: 24 }}>
                <use href="#check" />
              </svg>
            </Box>
            <Typography variant={"leadCaption"} color={"grayscale.800"}>
              At least 1 number
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            container
            alignItems={"center"}
            flexWrap={"nowrap"}
            gap={"8px"}
          >
            <Box
              height={24}
              sx={[
                {
                  stroke: palette.purple["200"]
                },
                helperError.pattern === "success" && {
                  stroke: palette.purple["500"]
                },
                helperError.pattern === "error" &&
                  touchedFields.newPassword && {
                    stroke: palette.vibrantRed["500"]
                  }
              ]}
            >
              <svg width={24} height={24} style={{ minWidth: 24 }}>
                <use href="#check" />
              </svg>
            </Box>
            <Typography variant={"leadCaption"} color={"grayscale.800"}>
              At least 1 capital letter
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            container
            alignItems={"center"}
            flexWrap={"nowrap"}
            gap={"8px"}
          >
            <Box
              height={24}
              sx={[
                {
                  stroke: palette.purple["200"]
                },
                helperError.symbol === "success" && {
                  stroke: palette.purple["500"]
                },
                helperError.symbol === "error" &&
                  touchedFields.newPassword && {
                    stroke: palette.vibrantRed["500"]
                  }
              ]}
            >
              <svg width={24} height={24} style={{ minWidth: 24 }}>
                <use href="#check" />
              </svg>
            </Box>
            <Typography variant={"leadCaption"} color={"grayscale.800"}>
              At least 1 special character
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { SupportList } from "components/pages/portal/support";
import { palette, royalBlue } from "settings";
import { GetInTouchBar } from "components/shared";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const SupportPage = () => {
  const [openForm, setOpenForm] = useState(false);

  return (
    <>
      <Helmet>
        <title>Support</title>
      </Helmet>
      <Box pb={5}>
        <Typography
          variant={"h5"}
          component={"h2"}
          mb={1}
          color={royalBlue}
          fontWeight={700}
        >
          What would you like to know?
        </Typography>
        <Typography variant={"lead"} color={palette.grayscale["600"]} mb={2}>
          Some of the most frequently asked questions.
        </Typography>
        <Paper
          elevation={2}
          sx={{ p: { xs: "12px 8px", md: "20px 16px" }, borderRadius: "16px" }}
        >
          <SupportList setOpenForm={setOpenForm} />
        </Paper>
      </Box>
      <GetInTouchBar open={openForm} setOpen={setOpenForm} />
    </>
  );
};

export default SupportPage;

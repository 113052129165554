import _filter from "lodash/filter";
import _some from "lodash/some";

export const filterRecipients = (
  activeCategory = "lastName",
  initialArray,
  searchValue
) => {
  let newItems = [];
  if (activeCategory) {
    switch (activeCategory) {
      case "name": {
        if (!searchValue) {
          return (newItems = [...initialArray]);
        }
        const [firstName = "", lastName = ""] = searchValue
          .toLowerCase()
          .split(" ");
        if (!firstName && lastName) {
          newItems = _filter(initialArray, (el) =>
            el["lastName"].toLowerCase().includes(lastName.trim())
          );
        }
        if (!lastName && firstName) {
          newItems = _filter(initialArray, (el) =>
            el["firstName"].toLowerCase().includes(firstName.trim())
          );
        }
        if (firstName && lastName) {
          newItems = _filter(
            initialArray,
            (el) =>
              el["firstName"].toLowerCase().includes(firstName.trim()) &&
              el["lastName"].toLowerCase().includes(lastName.trim())
          );
        }
        break;
      }
      case "tag": {
        newItems = _filter(initialArray, (obj) =>
          _some(obj.tags, (tag) =>
            tag?.name?.toLowerCase().includes(searchValue?.toLowerCase())
          )
        );
        break;
      }
      case "phoneNumber": {
        newItems = _filter(
          initialArray,
          (el) => el.phoneNumber?.length && el.phoneNumber.includes(searchValue)
        );
        break;
      }
      default: {
        newItems = _filter(initialArray, (el) =>
          el[activeCategory]?.toLowerCase().includes(searchValue?.toLowerCase())
        );
        break;
      }
    }
  } else newItems = [...initialArray];

  return newItems;
};

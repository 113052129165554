import React, { useCallback } from "react";
import { usePlaidLink } from "react-plaid-link";
import { useSnackbar } from "notistack";
import { useAuth } from "components/AuthProvider";
import { midnightBlue, palette } from "settings";
import { createApiCall } from "helpers/api";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const PlaidLink = ({
  token = null,
  style = {},
  fetchPaymentMethods,
  setPlaidLoading
}) => {
  const { session } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const onSuccess = useCallback((publicToken, metadata) => {
    setPlaidLoading(true);
    createApiCall({
      url: "payment-methods",
      method: "POST",
      data: {
        client_uuid: session.clientUuid,
        nonce: publicToken,
        kind: "ACH",
        plaid_account_id: metadata?.account_id
      }
    }).then(({ status, data }) => {
      if (status === 200) {
        enqueueSnackbar(
          `The Bank Account **** ${metadata?.account?.mask} was successfully added.`,
          {
            variant: "success"
          }
        );
        fetchPaymentMethods(data?.uuid);
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setPlaidLoading(false);
    });
  }, []);

  const config = {
    token,
    onSuccess
  };

  const { open, ready } = usePlaidLink(config);

  const onOpenPlaid = () => {
    enqueueSnackbar(
      "You are redirecting to another service to add a bank account...",
      {
        variant: "info"
      }
    );
    open();
  };

  return (
    <Box
      component={"button"}
      type={"button"}
      disabled={!ready}
      width={"100%"}
      sx={[
        style,
        {
          "&:disabled": {
            cursor: "not-allowed",
            opacity: 0.3
          }
        }
      ]}
      onClick={onOpenPlaid}
    >
      <Stack
        gap={"16px"}
        direction={"row"}
        alignItems={"center"}
        width={"100%"}
        sx={{
          textAlign: "left"
        }}
      >
        <Stack sx={{ width: "44px", alignItems: "center" }}>
          <svg
            width={32}
            height={32}
            style={{ minWidth: 32, color: midnightBlue }}
          >
            <use xlinkHref={"#plus-ico"} />
          </svg>
        </Stack>
        <Box sx={{ width: "100%" }}>
          <Stack
            justifyContent={"space-between"}
            direction={"row"}
            alignItems={"flex-start"}
            gap={1}
          >
            <Typography
              variant={"body1"}
              fontWeight={600}
              color={"grayscale.800"}
              lineHeight={1.38}
            >
              A bank account
            </Typography>
            <Typography
              sx={{ ml: "auto", textAlign: "right", whiteSpace: "nowrap" }}
              variant={"body1"}
              fontWeight={600}
              color={palette.vibrantBlue[500]}
              lineHeight={1.38}
            >
              No Fee
            </Typography>
          </Stack>
          <Typography
            sx={{ flexGrow: "100%" }}
            variant={"body1"}
            color={palette.grayscale[300]}
            lineHeight={1.38}
          >
            Payment proceeds in 1-2 business days
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

import React, { useEffect, useMemo, useState } from "react";
import _filter from "lodash/filter";
import _map from "lodash/map";
import _every from "lodash/every";
import { useForm } from "react-hook-form";
import { UploadFile } from "./UploadFile";
import { useSnackbar } from "notistack";
import { FieldTagsAutocomplete, UploadDropzone } from "components/fields";
import { palette } from "settings";
import { getBase64 } from "helpers/getBase64";
import { createApiCall } from "helpers/api";
import { stopPropagate } from "helpers/stopPropagate";

import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const defaultValues = {
  recipientFile: []
  // tags: []
};

export const UploadRecipient = ({ onClose, open }) => {
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm();

  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const { watch, setValue, handleSubmit, reset } = methods;
  const files = watch("recipientFile") || [];

  const isFilesUploaded = useMemo(() => {
    return !!fileList.length && _every(fileList, { status: "success" });
  }, [fileList]);

  useEffect(() => {
    if (open) {
      setFileList([]);
      setValue("recipientFile", []);
      reset(defaultValues);
    }
  }, [open]);

  const onDropAcceptedFiles = (acceptedFiles) => {
    setFileList([
      ..._map(acceptedFiles, (file) => ({
        file,
        status: "success"
      }))
    ]);
  };

  const onSubmit = handleSubmit(async ({ tags }) => {
    setLoading(true);
    const { file } = fileList[0];
    const base64 = await getBase64(file);
    const data = {
      csv_data: base64.split(",")[1],
      csv_upload: "TRUE"
    };
    if (tags?.length) {
      data.client_tag_uuids = _map(tags, (o) => o.clientTagUuid);
    }
    createApiCall({
      method: "POST",
      url: `customers`,
      data
    }).then(({ status }) => {
      if (status === 200) {
        enqueueSnackbar(
          "Recipients from your file were uploaded successfully.",
          {
            variant: "success"
          }
        );
        onClose(true);
      } else {
        setFileList([
          {
            ...fileList[0],
            status: "error"
          }
        ]);
        enqueueSnackbar(
          "There’s a problem processing your file, please try again.",
          { variant: "error" }
        );
      }
      setLoading(false);
    });
  });

  const onRemove = (index) => {
    setValue(
      "recipientFile",
      _filter(files, (_, i) => i !== index)
    );
    setFileList(_filter(fileList, (_, i) => i !== index));
  };

  return (
    <Drawer open={open} onClose={() => onClose(isFilesUploaded)}>
      <Stack sx={{ height: "100%" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            p: 3,
            borderBottom: `1px solid ${palette.grayscale["200"]}`
          }}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
            <svg width={24} height={24} stroke={palette.purple["500"]}>
              <use href="#upload" />
            </svg>
            <Typography
              variant={"subLead"}
              fontWeight={700}
              color={"purple.900"}
            >
              Upload recipients list (.CSV)
            </Typography>
          </Stack>
          <IconButton size={"small"} onClick={() => onClose(isFilesUploaded)}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use href="#close" />
            </svg>
          </IconButton>
        </Stack>
        <Stack
          component={"form"}
          onSubmit={stopPropagate(onSubmit)}
          noValidate
          gap={"16px"}
          sx={{
            p: 3,
            flex: "0 1 100%",
            overflowY: "auto",
            height: "100%"
          }}
        >
          <Box>
            <UploadDropzone
              {...methods}
              fieldName={"recipientFile"}
              required={true}
              onDropAcceptedFiles={onDropAcceptedFiles}
              accepted={{
                "text/csv": [],
                "application/csv": [],
                "application/x-csv": []
              }}
            />
          </Box>
          <Box>
            {!!fileList.length && (
              <Typography fontWeight={700} mb={"8px"}>
                Uploaded lists
              </Typography>
            )}
            {fileList.map(({ file, status }, index) => (
              <UploadFile
                key={file.name + file.lastModified}
                file={file}
                status={status}
                index={index}
                onRemove={onRemove}
              />
            ))}
          </Box>
          {isFilesUploaded && (
            <Box>
              <Typography fontWeight={700} mb={"8px"}>
                Select tags to mark list(s)
              </Typography>

              <FieldTagsAutocomplete {...methods} rules={{}} />
            </Box>
          )}

          <Button
            sx={{ mt: "auto" }}
            fullWidth
            variant={"contained"}
            disabled={loading || !isFilesUploaded}
            type={"submit"}
          >
            Add
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
};

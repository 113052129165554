import React from "react";
import { greenAlert, palette } from "settings";
import Typography from "@mui/material/Typography";

export const DisabledStatus = ({ data }) => {
  return (
    <Typography
      variant={"smallTitle"}
      component={"span"}
      sx={[
        data === "FALSE" && {
          color: greenAlert
        },
        data === "TRUE" && {
          color: palette.vibrantRed["300"]
        },
        {
          textTransform: "capitalize"
        }
      ]}
    >
      {data === "FALSE" && "Active"}
      {data === "TRUE" && "Inactive"}
      {!data && "-"}
    </Typography>
  );
};

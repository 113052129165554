import React from "react";
import DashboardPage from "pages/portal/dashboard";
import TransactionsPage from "pages/portal/transactions";
import CommissionReports from "pages/portal/commissionReports";
import UsersPage from "pages/portal/users";
import SupportPage from "pages/portal/support";
import AccountKYC from "pages/portal/accountKYC";
import Recipients from "pages/portal/recipients";
import CampaignsListPage from "pages/portal/campaigns";
import CampaignPage from "pages/portal/campaigns/campaign";
import CampaignForm from "pages/portal/campaigns/campaignForm";

export const DASHBOARD_PAGE = "/business/dashboard";
export const TRANSACTIONS_PAGE = "/business/transactions";
export const COMMISSIONS_PAGE = "/business/commissions";
export const RECIPIENTS_PAGE = "/business/recipients";
export const USERS_PAGE = "/business/users";
export const SUPPORT_PAGE = "/business/support";
export const KYC_PAGE = "/business/KYC";
export const CAMPAIGNS_PAGE = "/business/campaigns";
export const CAMPAIGN_PAGE = (uuid: string) => `/business/campaigns/${uuid}`;
export const EDIT_CAMPAIGN_PAGE = (uuid: string) =>
  `/business/campaigns/${uuid}/edit`;
export const CREATE_CAMPAIGN_PAGE = "/business/campaigns/create";

const routes = [
  {
    id: 1,
    element: <DashboardPage />,
    path: DASHBOARD_PAGE,
    isPublic: false,
    onlyApiClient: false
  },
  {
    id: 2,
    element: <TransactionsPage />,
    path: TRANSACTIONS_PAGE,
    isPublic: false,
    onlyApiClient: true
  },
  {
    id: 3,
    element: <CommissionReports />,
    path: COMMISSIONS_PAGE,
    isPublic: false,
    onlyApiClient: true
  },
  {
    id: 4,
    element: <Recipients />,
    path: RECIPIENTS_PAGE,
    isPublic: false,
    onlyApiClient: false,
    onlyCampaignAPIEnabled: true
  },
  {
    id: 5,
    element: <UsersPage />,
    path: USERS_PAGE,
    isPublic: false,
    onlyApiClient: false,
    deniedRoles: ["CLIENT_AGENT"]
  },
  {
    id: 6,
    element: <SupportPage />,
    path: SUPPORT_PAGE,
    isPublic: false,
    onlyApiClient: false,
    onlyVerifiedUser: false
  },
  {
    id: 7,
    element: <AccountKYC />,
    path: KYC_PAGE,
    isPublic: false,
    onlyApiClient: false,
    onlyVerifiedUser: false
  },
  {
    id: 8,
    element: <CampaignsListPage />,
    path: CAMPAIGNS_PAGE,
    isPublic: false,
    onlyApiClient: false,
    onlyCampaignAPIEnabled: true
  },
  {
    id: 9,
    element: <CampaignPage />,
    path: CAMPAIGN_PAGE(":uuid"),
    isPublic: false,
    onlyApiClient: false,
    onlyCampaignAPIEnabled: true
  },
  {
    id: 10,
    element: <CampaignForm />,
    path: CREATE_CAMPAIGN_PAGE,
    isPublic: false,
    onlyApiClient: false,
    onlyCampaignAPIEnabled: true
  },
  {
    id: 11,
    element: <CampaignForm />,
    path: EDIT_CAMPAIGN_PAGE(":uuid"),
    isPublic: false,
    onlyApiClient: false,
    onlyCampaignAPIEnabled: true
  }
];

export default routes;

import React from "react";
import { BrandsCarousel, BrandsLogo } from "../brands";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

const TopBrands = ({ brands, size }) => {
  return (
    <Paper
      elevation={2}
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: 2,
        padding: 3
      }}
    >
      <Typography
        mb={2}
        variant={"subLead"}
        fontWeight={700}
        color={"grayscale.800"}
      >
        Top Brands
      </Typography>
      {!brands?.length ? (
        <Typography variant={"body1"} color={"grayscale.800"}>
          No brands found
        </Typography>
      ) : (
        <Stack sx={{ margin: "0 -10px" }}>
          {brands.length < 12 ? (
            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={{ overflowX: "auto", pb: 1.5 }}
              width={"100%"}
            >
              {brands.map((element) => (
                <Box
                  key={element.uuid}
                  sx={{
                    px: { xs: size < 60 ? 1 : 2.5, md: size < 60 ? 1 : 3 }
                  }}
                >
                  <BrandsLogo
                    data={element}
                    size={size}
                    hideText={true}
                    onRemove={false}
                    onSelect={false}
                    status={{
                      showAmount: true
                    }}
                  />
                </Box>
              ))}
            </Stack>
          ) : (
            <BrandsCarousel
              items={brands}
              infinite={true}
              size={size}
              hideText={true}
              showAmount={true}
            />
          )}
        </Stack>
      )}
    </Paper>
  );
};
export default TopBrands;

import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Virtuoso } from "react-virtuoso";
import { useSnackbar } from "notistack";
import { useAuth } from "components/AuthProvider";
import { ConfirmModal } from "components/shared";
import { FieldText } from "components/fields";
import AddTagForm from "./AddTagForm";
import TagsLoader from "./TagsLoader";
import { palette } from "settings";
import { createApiCall } from "helpers/api";

import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";

const PAGE_LIMIT = 20;

export const ManageTags = ({ open, onClose }) => {
  const { session } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm();

  const { handleSubmit, setValue, watch } = methods;
  const tagValue = watch("name");

  const [addTagMode, setAddTagMode] = useState(false);
  const [activeTag, setActiveTag] = useState(null);
  const [tags, setTags] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState(null);

  const isLastPage = useMemo(() => {
    return total < (page + 1) * PAGE_LIMIT;
  }, [page, total]);

  useEffect(() => {
    if (open) {
      getTags(0, true);
    }
  }, [open]);

  useEffect(() => {
    if (activeTag) {
      setValue("name", activeTag.name);
    }
  }, [activeTag]);

  const onSubmit = handleSubmit(({ name }) => {
    setSubmitting(true);
    createApiCall({
      method: "POST",
      url: `tags`,
      data: {
        client_tag_uuid: activeTag.clientTagUuid,
        client_uuid: session?.clientUuid,
        name
      }
    }).then(({ status, data }) => {
      if (status === 200) {
        getTags(0, true);
        setActiveTag(null);

        enqueueSnackbar("A tag was updated successfully.", {
          variant: "success"
        });
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setSubmitting(false);
    });
  });

  const getTags = (currentPage = 0, reset = false) => {
    setLoading(true);
    if (reset) setTags([]);
    createApiCall({
      url: `tags/${session?.clientUuid}?disabled=FALSE&pagination.sortBy.field=created_at&pagination.sortBy.order=DESC&pagination.pageLimit=${PAGE_LIMIT}&pagination.page=${currentPage}`
    }).then(({ status, data }) => {
      if (status === 200) {
        setPage(currentPage);
        setTags(reset ? [...data.tags] : [...tags, ...data.tags]);
        setTotal(data.pagination.totalResults);
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  };

  const onRemoveTag = (tag) => {
    setConfirmModalData({
      open: true,
      data: {
        action: "delete",
        tag
      },
      title: "Are you sure that you want to delete?",
      description: `Tag "${tag.name.replace(
        /_/g,
        " "
      )}" will be removed from the system.`
    });
  };

  const onConfirmRemoveTag = ({ tag }) => {
    setSubmitting(true);
    createApiCall({
      method: "PATCH",
      url: `tags`,
      data: {
        client_tag_uuid: tag.clientTagUuid,
        disabled: "TRUE"
      }
    }).then(({ status, data }) => {
      if (status === 200) {
        getTags(0, true);

        enqueueSnackbar("A tag was deleted successfully.", {
          variant: "success"
        });
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setConfirmModalData(null);
      setSubmitting(false);
    });
  };

  const onAddTag = () => {
    setAddTagMode(false);
    getTags(0, true);
    enqueueSnackbar("A tag was added successfully.", {
      variant: "success"
    });
  };

  return (
    <>
      <Drawer open={open} onClose={() => onClose(true)}>
        <Stack sx={{ height: "100%" }} justifyContent={"space-between"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              p: 3,
              borderBottom: `1px solid ${palette.grayscale["200"]}`
            }}
          >
            <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
              <svg width={24} height={24} stroke={palette.purple["500"]}>
                <use href="#tags" />
              </svg>
              <Typography
                variant={"subLead"}
                fontWeight={700}
                color={"purple.900"}
              >
                Manage tags
              </Typography>
            </Stack>
            <IconButton size={"small"} onClick={() => onClose(true)}>
              <svg width={24} height={24} stroke={palette.grayscale["700"]}>
                <use href="#close" />
              </svg>
            </IconButton>
          </Stack>

          <Box
            sx={{
              p: "24px 24px 0",
              flex: "0 1 100%",
              overflowY: "auto",
              height: "100%"
            }}
          >
            {loading && !tags.length && <TagsLoader />}

            {!!tags.length && (
              <Virtuoso
                style={{ height: "100%" }}
                data={tags}
                endReached={() => {
                  if (!isLastPage) {
                    getTags(page + 1);
                  }
                }}
                components={{
                  Footer: () => {
                    if (loading) {
                      return <LinearProgress />;
                    }
                  }
                }}
                itemContent={(index, tag) => (
                  <Stack
                    key={tag.clientTagUuid}
                    sx={{
                      padding: "7px 0 6px",
                      borderBottom: `1px solid ${palette.grayscale[200]}`
                    }}
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    {tag.clientTagUuid === activeTag?.clientTagUuid ? (
                      <Box
                        component={"form"}
                        onSubmit={onSubmit}
                        width={"100%"}
                      >
                        <FieldText
                          {...methods}
                          autoFocus
                          label={"Name"}
                          fieldName={"name"}
                        />

                        <Stack
                          sx={{ m: "16px 0" }}
                          direction={"row"}
                          alignItems={"center"}
                          gap={"8px"}
                          justifyContent={"space-between"}
                        >
                          <Button
                            disabled={submitting}
                            variant={"outlinedPrimary"}
                            fullWidth
                            onClick={() => {
                              setActiveTag(null);
                            }}
                          >
                            Cancel
                          </Button>
                          <LoadingButton
                            loading={submitting}
                            fullWidth
                            variant={"contained"}
                            type={"submit"}
                            disabled={!tagValue || tagValue === activeTag.name}
                          >
                            Edit
                          </LoadingButton>
                        </Stack>
                      </Box>
                    ) : (
                      <>
                        <Chip
                          variant="squared-secondary"
                          label={tag.name?.replace(/_/g, " ") || "-"}
                        />
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          gap={"8px"}
                        >
                          <IconButton
                            size={"small"}
                            onClick={() => setActiveTag(tag)}
                            sx={{
                              color: palette.grayscale["700"],
                              display: "none" // hidden for MVP version
                            }}
                          >
                            <svg
                              width={24}
                              height={24}
                              style={{ minWidth: 24 }}
                            >
                              <use href="#edit-pencil" />
                            </svg>
                          </IconButton>
                          <IconButton
                            size={"small"}
                            onClick={() => onRemoveTag(tag)}
                          >
                            <svg
                              width={24}
                              height={24}
                              stroke={palette.grayscale["700"]}
                              style={{ minWidth: 24 }}
                            >
                              <use href="#trash" />
                            </svg>
                          </IconButton>
                        </Stack>
                      </>
                    )}
                  </Stack>
                )}
              />
            )}

            {!tags.length && !loading && (
              <Stack>
                <Typography
                  variant={"leadCaption"}
                  color={"grayscale.300"}
                  fontWeight={600}
                  textAlign={"center"}
                >
                  There are no tags yet.
                  <br />
                  Please add tags to categorise recipients for future campaigns
                  below.
                </Typography>
              </Stack>
            )}
          </Box>
          <Box p={"16px 24px 24px"}>
            {addTagMode ? (
              <AddTagForm
                onClose={() => setAddTagMode(false)}
                onAddTag={onAddTag}
              />
            ) : (
              <Button
                fullWidth
                variant={"contained"}
                onClick={() => setAddTagMode(true)}
              >
                Add New
              </Button>
            )}
          </Box>
        </Stack>
      </Drawer>
      <ConfirmModal
        {...confirmModalData}
        loading={submitting}
        open={!!confirmModalData}
        onClose={() => setConfirmModalData(null)}
        onConfirm={onConfirmRemoveTag}
      />
    </>
  );
};

import React, { useMemo, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import _includes from "lodash/includes";
import { differenceInDays, format, isToday, subDays } from "date-fns";
import { createApiCall } from "helpers/api";
import { downloadFile } from "helpers/downloadBinary";
import { SearchInput } from "components/fields";
import {
  headCells,
  defaultFilters,
  DEFAULT_ORDER,
  DEFAULT_ORDER_BY,
  defaultCommissionsState,
  collectFilter
} from "components/pages/portal/commission";
import { midnightBlue, theme } from "settings";
import { TimeframeModal } from "components/pages/portal";
import { InfinityTable, ToolbarActions } from "components/shared";
import { useSnackbar } from "notistack";
import { useAuth } from "components/AuthProvider";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import useMediaQuery from "@mui/material/useMediaQuery";

const CommissionReports = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useAuth();

  const [openTimeModal, setOpenTimeModal] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loading, setLoading] = useState(true);
  const [commissions, setCommissions] = useState(defaultCommissionsState);
  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [orderBy, setOrderBy] = useState(DEFAULT_ORDER_BY);
  const [filter, setFilter] = useState(defaultFilters);

  const isTabletOrSmall = useMediaQuery(theme.breakpoints.down("md"));

  const isDefaultFilter = useMemo(() => {
    const {
      date_range: { start, end }
    } = filter;
    return isToday(end) && differenceInDays(end, start) === 30;
  }, [filter]);

  const formatDateRange = useMemo(() => {
    const from = filter?.date_range?.start
      ? format(filter?.date_range?.start, "MM/dd/yyyy")
      : "";
    const to = filter?.date_range?.end
      ? format(filter?.date_range?.end, "MM/dd/yyyy")
      : "";
    return { from, to };
  }, [filter?.date_range]);

  useEffect(() => {
    if (session?.clientUuid) {
      setLoading(true);

      createApiCall({
        method: "POST",
        url: `analytics/commission-report`,
        data: collectFilter(filter, session?.clientUuid, order, orderBy)
      }).then(({ data, status }) => {
        if (status === 200) {
          setCommissions({
            pagination: data.pagination,
            details: filter.pagination.page
              ? [...commissions.details, ...data.details]
              : [...data.details]
          });
        } else {
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
        }
        setLoading(false);
      });
    }
  }, [filter, order, orderBy, session?.clientUuid]);

  const onLoadMore = () => {
    setFilter({
      ...filter,
      pagination: {
        ...filter.pagination,
        page: filter.pagination.page + 1
      }
    });
  };

  const searchHandler = (brand_name) => {
    setCommissions(defaultCommissionsState);
    setFilter({
      ...defaultFilters,
      ...filter,
      brand_name
    });
  };

  const searchHandlerFilters = (newParam) => {
    setCommissions(defaultCommissionsState);
    setFilter({
      ...defaultFilters,
      ...filter,
      ...newParam
    });
  };

  const onFilter = (data) => {
    setCommissions(defaultCommissionsState);
    const newFilterParams = {
      date_range: {
        start: data.startDate || subDays(new Date(), 30),
        end: data.endDate || new Date()
      }
    };
    setFilter({
      ...defaultFilters,
      ...newFilterParams
    });
    setOpenTimeModal(false);
  };

  const clearFilter = () => {
    setCommissions(defaultCommissionsState);
    setFilter(defaultFilters);
  };

  const onExport = () => {
    if (session.clientUuid) {
      setLoadingFile(true);
      createApiCall({
        method: "POST",
        url: `analytics/commission-report-csv`,
        data: collectFilter(filter, session?.clientUuid, order, orderBy)
      }).then(async ({ data, status }) => {
        if (status === 200) {
          const {
            date_range: { start, end }
          } = filter;
          downloadFile(
            data.report,
            `Commissions report for ${format(start, "MM/dd/yyyy")} - ${format(
              end,
              "MM/dd/yyyy"
            )}.csv`
          );
        } else {
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
        }
        setLoadingFile(false);
      });
    }
  };

  const sortHandler = (id, newOrder) => {
    setCommissions(defaultCommissionsState);
    setFilter({
      ...filter,
      pagination: defaultFilters.pagination
    });
    setOrderBy(id);
    setOrder(newOrder);
  };

  const onOpenFilter = () => {
    setOpenTimeModal(true);
  };

  const menuOptions = useMemo(
    () => [
      {
        id: 0,
        isHidden: _includes(session?.roles, "CLIENT_AGENT"),
        icon: "export",
        loading: loadingFile,
        text: "Download .CSV",
        onClick: onExport
      },
      {
        id: 1,
        icon: "filter-new",
        text: "Filter",
        onClick: onOpenFilter
      }
    ],
    [loadingFile, session]
  );

  return (
    <div>
      <Helmet>
        <title>Commission Reports</title>
      </Helmet>
      <TimeframeModal
        open={openTimeModal}
        onClose={() => setOpenTimeModal(false)}
        filterParams={filter}
        defaultValues={{
          startDate: filter.date_range.start,
          endDate: filter.date_range.end
        }}
        onFilter={onFilter}
      />
      <Box sx={{ minHeight: "100%" }}>
        <Stack justifyContent={"center"} sx={{ minHeight: 56 }} mb={1}>
          <Typography variant={"h5"} color={midnightBlue}>
            Commission
          </Typography>
        </Stack>
        <Box sx={{ width: "100%" }} mb={3}>
          <SearchInput
            multiple={false}
            placeholder={"Search for brand"}
            selectedItems={filter?.brand_name || ""}
            setSelectedItems={searchHandler}
          />
          <Stack direction={"row"} gap={"0 10px"}>
            {!!filter?.brand_name && (
              <Chip
                variant="squared-secondary"
                sx={{
                  mt: "10px"
                }}
                tabIndex={-1}
                label={filter.brand_name}
                onDelete={() =>
                  searchHandlerFilters({
                    brand_name: ""
                  })
                }
              />
            )}
            {!isDefaultFilter && formatDateRange.from && formatDateRange.to && (
              <Chip
                variant="squared-secondary"
                sx={{
                  mt: "10px"
                }}
                tabIndex={-1}
                label={`From: ${formatDateRange.from} To ${formatDateRange.to}`}
                onDelete={() =>
                  searchHandlerFilters({
                    date_range: {
                      start: subDays(new Date(), 30),
                      end: new Date()
                    }
                  })
                }
              />
            )}
          </Stack>
        </Box>

        <InfinityTable
          headCells={headCells}
          loading={loading}
          data={commissions?.details || []}
          total={commissions?.pagination?.totalResults || 0}
          order={order}
          orderBy={orderBy}
          createSortHandler={sortHandler}
          onLoadMore={onLoadMore}
          toolbarTitle={`Your Commission Report ${
            commissions?.pagination?.totalResults
              ? "(" + commissions?.pagination?.totalResults + ")"
              : ""
          }`}
          toolbarActions={
            <>
              {!isTabletOrSmall && (
                <>
                  {isDefaultFilter ? (
                    <Typography variant={"smallTitle"} color={"#fff"}>
                      Past 30 days
                    </Typography>
                  ) : (
                    <Link
                      component={"button"}
                      underline={"none"}
                      variant={"smallTitle"}
                      sx={{
                        color: "#fff",
                        lineHeight: 1,
                        display: "flex",
                        alignItems: "center"
                      }}
                      onClick={clearFilter}
                    >
                      <svg width={24} height={24} stroke={"#fff"}>
                        <use href="#close" />
                      </svg>
                      Clear Filter
                    </Link>
                  )}
                </>
              )}
              <ToolbarActions options={menuOptions} />
            </>
          }
        />
      </Box>
    </div>
  );
};
export default CommissionReports;

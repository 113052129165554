import React, { useEffect } from "react";
import { displayFullMoney } from "helpers/money";
import { palette } from "settings";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const Summary = ({ fee, amountValue, onClose }) => {
  useEffect(() => {
    if (!amountValue) {
      onClose();
    }
  }, [amountValue]);

  return (
    <Box>
      <Typography
        mb={"8px"}
        variant={"subLead"}
        sx={{ fontWeight: 700, color: "black" }}
      >
        Summary
      </Typography>
      <Box
        sx={{
          p: "24px",
          background: palette.grayscale[100],
          borderRadius: "8px"
        }}
      >
        <Stack
          direction={"row"}
          gap={"24px"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
        >
          <Typography
            variant={"body1"}
            color={palette.grayscale[300]}
            fontWeight={600}
          >
            Funds amount
          </Typography>
          <Typography
            variant={"body1"}
            color={palette.grayscale[500]}
            fontWeight={600}
          >
            {displayFullMoney(amountValue)}
          </Typography>
        </Stack>
        {fee && (
          <Stack
            direction={"row"}
            gap={"24px"}
            mt={"24px"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
          >
            <Typography
              variant={"body1"}
              color={palette.grayscale[300]}
              fontWeight={600}
            >
              {fee}% Fee
            </Typography>
            <Typography
              variant={"body1"}
              color={palette.grayscale[500]}
              fontWeight={600}
            >
              {displayFullMoney(Math.ceil((amountValue / 100) * fee))}
            </Typography>
          </Stack>
        )}
        <Divider sx={{ my: 3 }} />
        <Stack
          direction={"row"}
          gap={"16px"}
          mb={"16px"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            variant={"subLead"}
            fontWeight={400}
            color={"grayscale.800"}
          >
            Total
          </Typography>
          <Typography
            variant={"subLead"}
            fontWeight={700}
            color={"grayscale.800"}
          >
            {fee
              ? displayFullMoney(
                  amountValue + Math.ceil((amountValue / 100) * fee)
                )
              : displayFullMoney(amountValue)}
          </Typography>
        </Stack>
        <Button variant={"contained"} fullWidth onClick={onClose}>
          Done
        </Button>
      </Box>
    </Box>
  );
};

export default Summary;

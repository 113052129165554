import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useAuth } from "components/AuthProvider";
import { RecipientForm } from "components/pages/portal/recipients/RecipientForm";
import AvailableBalance from "./AvailableBalance";
import CommissionsChart from "./CommissionsChart";
import FilterModal from "./FilterModal";
import OverviewNew, { periodsList } from "./OverviewNew";
import TopBrands from "./TopBrands";
import CampaignTimeline from "./CampaignTimeline";
import { createApiCall } from "helpers/api";

import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

const defaultPurchaseDate = periodsList[0];

const Dashboard = () => {
  const { session, isCampaignEnabled, isApiEnabled } = useAuth();
  //103542 - story id to this fix
  const isPortalClient =
    session?.clientUuid === "d7863e97-9b6b-41fb-bc28-189320017961" ||
    !isApiEnabled;

  const { enqueueSnackbar } = useSnackbar();
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openRecipient, setOpenRecipient] = useState(false);
  const [activePeriod, setActivePeriod] = useState(defaultPurchaseDate);

  const [dashboardInfo, setDashboardInfo] = useState(null);

  useEffect(() => {
    if (
      session?.clientUuid &&
      activePeriod.param.preset.start &&
      activePeriod.param.preset.end
    ) {
      getClientDashboard();
    }
  }, [activePeriod, session?.clientUuid]);

  const handleChangePeriod = (newValue) => {
    setActivePeriod(periodsList.find((el) => el.value === newValue));
  };

  const getClientDashboard = () => {
    const startDay = new Date(activePeriod.param.preset.start).toISOString();
    const endDay = new Date(activePeriod.param.preset.end).toISOString();
    setLoading(true);
    createApiCall({
      url: `client-dashboard?client_uuid=${session.clientUuid}&dateRange.createdAtFrom=${startDay}&dateRange.createdAtTo=${endDay}`
    }).then(({ data, status }) => {
      if (status === 200 && data) {
        setDashboardInfo({
          ...data,
          brands: data?.brands?.sort((a, b) => b.amount - a.amount)
        });
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  };

  const onFilter = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      setActivePeriod({
        ...periodsList.find((item) => item.value === "custom"),
        param: { preset: { end: endDate, start: startDate } }
      });
      setOpenFilter(false);
    } else {
      enqueueSnackbar("Please select valid timeframe", { variant: "error" });
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <OverviewNew
          loading={loading}
          isPortalClient={isPortalClient}
          isCampaignEnabled={isCampaignEnabled}
          dashboardInfo={dashboardInfo}
          activePeriod={activePeriod}
          onChangePeriod={handleChangePeriod}
          setOpenFilter={setOpenFilter}
          setOpenRecipient={setOpenRecipient}
        />
      </Grid>

      {!isPortalClient && isCampaignEnabled && (
        <Grid item xs={12}>
          {loading ? (
            <Skeleton variant="rounded" height={150} width={"100%"} />
          ) : (
            <TopBrands brands={dashboardInfo?.brands} />
          )}
        </Grid>
      )}

      <Grid item container spacing={3} alignItems={"stretch"}>
        <Grid item xs={12} lg={4}>
          <AvailableBalance
            isCampaignEnabled={isCampaignEnabled}
            balance={dashboardInfo?.balance}
            loading={loading}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          {loading ? (
            <Skeleton variant="rounded" height={400} width={"100%"} />
          ) : !isCampaignEnabled ? (
            <CommissionsChart data={dashboardInfo?.commissions?.denomData} />
          ) : (
            <CampaignTimeline
              campaigns={dashboardInfo?.campaigns}
              activePeriod={activePeriod}
            />
          )}
        </Grid>
      </Grid>
      {!isPortalClient && isCampaignEnabled && (
        <Grid item xs={12}>
          {loading ? (
            <Skeleton variant="rounded" height={400} width={"100%"} />
          ) : (
            <CommissionsChart data={dashboardInfo?.commissions?.denomData} />
          )}
        </Grid>
      )}

      <FilterModal
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        onFilter={onFilter}
      />
      <RecipientForm
        open={openRecipient}
        recipientUuid={""}
        onClose={() => setOpenRecipient(false)}
      />
    </Grid>
  );
};

export default Dashboard;

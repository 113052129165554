import React from "react";
import Radio from "@mui/material/Radio";

export const RadioAsCheckbox = (props) => (
  <Radio
    {...props}
    icon={
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="1"
          y="1"
          width="22"
          height="22"
          rx="3"
          fill="white"
          stroke="#D9D9D9"
          strokeWidth="2"
        />
        <line
          x1="5.70711"
          y1="12.2929"
          x2="9.70711"
          y2="16.2929"
          stroke="white"
          strokeWidth="2"
        />
        <line
          x1="19.7071"
          y1="7.70711"
          x2="9.70711"
          y2="17.7071"
          stroke="white"
          strokeWidth="2"
        />
      </svg>
    }
    checkedIcon={
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="1"
          y="1"
          width="22"
          height="22"
          rx="3"
          fill="#CCFAE8"
          stroke="#5020F7"
          strokeWidth="2"
        />
        <line
          x1="5.70711"
          y1="12.2929"
          x2="9.70711"
          y2="16.2929"
          stroke="#CCFAE8"
          strokeWidth="2"
        />
        <line
          x1="19.7071"
          y1="7.70711"
          x2="9.70711"
          y2="17.7071"
          stroke="#CCFAE8"
          strokeWidth="2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.10553 17.9633C9.00853 17.9103 8.91902 17.8403 8.84161 17.7539C8.82505 17.7355 8.80929 17.7166 8.79433 17.6973L5.21797 13.2091C4.87379 12.7772 4.94492 12.148 5.37685 11.8038C5.80878 11.4596 6.43793 11.5308 6.78211 11.9627L9.66693 15.583L17.8753 7.29626C18.264 6.90389 18.8972 6.90088 19.2895 7.28954C19.6819 7.67821 19.6849 8.31137 19.2963 8.70374L10.3069 17.7789C10.29 17.7965 10.2723 17.8136 10.2538 17.8301C10.1676 17.9077 10.071 17.9676 9.9689 18.0099C9.69129 18.1248 9.37224 18.1095 9.10553 17.9633Z"
          fill="#5020F7"
        />
      </svg>
    }
  />
);

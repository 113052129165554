import React, { useEffect, useState } from "react";
import { midnightBlue, palette } from "settings";
import { useDebounce } from "hooks/useDebounce";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

export const SearchInput = ({
  placeholder = "",
  multiple = true,
  label,
  selectedItems,
  setSelectedItems
}) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (!selectedItems.length) {
      setInputValue("");
    }
    if (!multiple && selectedItems.length) {
      setInputValue(selectedItems);
    }
  }, [selectedItems]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    if (!multiple) debouncedOnChange();
  };

  const handleKeyDown = (event, isAcceptEvent = false) => {
    if (multiple && (event.key === "Enter" || isAcceptEvent === true)) {
      const duplicatedValues = [...selectedItems].indexOf(inputValue.trim());

      if (duplicatedValues !== -1 || !inputValue.replace(/\s/g, "").length) {
        setInputValue("");
        return;
      }
      setSelectedItems([...selectedItems, inputValue.trim()]);
      setInputValue("");
    }
  };

  const debouncedOnChange = useDebounce(() => {
    setSelectedItems(inputValue);
  });

  return (
    <FormControl variant="filled">
      {label && <InputLabel sx={{ mb: "6px" }}>{label}</InputLabel>}
      <TextField
        variant={"standard"}
        type={"text"}
        fullWidth
        autoFocus={false}
        placeholder={placeholder}
        InputProps={{
          sx: {
            paddingBottom: "12px",
            fontSize: "18px",
            minHeight: "50px"
          },
          startAdornment: (
            <InputAdornment position="start">
              <svg width={14} height={14} stroke={palette.grayscale["300"]}>
                <use xlinkHref={"#search"} />
              </svg>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="start"
              onClick={(e) => handleKeyDown(e, true)}
              sx={{
                cursor: "pointer"
              }}
            >
              <svg width={20} height={20} fill={midnightBlue}>
                <use xlinkHref={"#arrow-right"} />
              </svg>
            </InputAdornment>
          ),
          onChange: handleInputChange,
          onKeyDown: handleKeyDown
        }}
        value={inputValue}
      />
    </FormControl>
  );
};

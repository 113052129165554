export const headCells = [
  {
    id: "firstName",
    label: "First Name",
    width: 170
  },
  {
    id: "lastName",
    label: "Last Name",
    width: 170
  },
  {
    id: "email",
    label: "Email",
    width: 230
  },
  {
    id: "phoneNumber",
    label: "Phone Number",
    width: 170
  },
  {
    id: "active",
    label: "Status",
    width: 150,
    type: "boolean-status"
  },
  {
    id: "roleNames",
    label: "Role",
    width: 140,
    type: "role"
  },
  {
    id: "edit",
    label: "",
    width: 60,
    type: "node",
    children: (
      <svg width={24} height={24} fill={"#181818"}>
        <use xlinkHref={"#cheveron-right"} />
      </svg>
    )
  }
];

export const DEFAULT_ORDER = "desc";
export const DEFAULT_ORDER_BY = "created_at";

export const defaultData = {
  users: [],
  pagination: {}
};

export const defaultFilters = {
  filterParams: {},
  pagination: {
    pageLimit: 20,
    page: 0
  }
};

export const rolesDescription = [
  {
    title: "Owner",
    description:
      "is the primary account creator with full access to all features and settings."
  },
  {
    title: "Admin",
    description:
      "has full access, including the capability to add and manage users, as well as add and manage payment methods."
  },
  {
    title: "Manager",
    description:
      "has access to creating and managing campaigns, recipient lists, and is able to deposit funds. They cannot add or delete payment methods or add user roles above the manager level."
  },
  {
    title: "Agent",
    description:
      "has access to the dashboard and the ability to create and manage campaigns and recipient lists. They cannot deposit funds, add/manage payment methods, or update user roles above the agent level."
  }
];

export const rolesPermissions = [
  {
    name: "Dashboard",
    owner: true,
    admin: true,
    manager: true,
    agent: true
  },
  {
    name: "Campaigns",
    owner: true,
    admin: true,
    manager: true,
    agent: true
  },
  {
    name: "Recipient list",
    owner: true,
    admin: true,
    manager: true,
    agent: true
  },
  {
    name: "View Balance",
    owner: true,
    admin: true,
    manager: true,
    agent: true
  },
  {
    name: "Deposit funds",
    owner: true,
    admin: true,
    manager: true,
    agent: false
  },
  {
    name: "Payment methods",
    owner: true,
    admin: true,
    manager: false,
    agent: false
  },
  {
    name: "Manage Users",
    owner: true,
    admin: true,
    manager: false,
    agent: false
  }
];

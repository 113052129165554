import React, { useEffect, useMemo, useState } from "react";
import {
  fieldCannotBeEmpty,
  incorrectFormat,
  palette,
  pleaseFillInput
} from "settings";
import { amountFilters } from "../pages/portal/campaign/constant";
import { FieldText } from "./Text";

import _findIndex from "lodash/findIndex";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormHelperText from "@mui/material/FormHelperText";

export const AmountField = ({
  fieldName = "confirmed_amount",
  rules = {
    required: fieldCannotBeEmpty
  },
  ...methods
}) => {
  const {
    register,
    setValue,
    watch,
    trigger,
    formState: { errors }
  } = methods;

  const customAmountField = watch("custom_amount") || 0;
  const amountField = watch("amount_field") || 0;
  const confirmedAmount = watch("confirmed_amount") || 0;

  const [anchorEl, setAnchorEl] = useState(null);

  const currentAmount = useMemo(() => {
    return amountField || customAmountField || 0;
  }, [customAmountField, amountField]);

  useEffect(() => {
    if (currentAmount) {
      setValue("confirmed_amount", currentAmount, { shouldValidate: true });
    }
  }, []);

  const handleConfirmAmount = () => {
    setAnchorEl(false);
    setValue("confirmed_amount", currentAmount, { shouldValidate: true });
  };

  const handleResetAmount = () => {
    setAnchorEl(false);
    trigger("confirmed_amount");
    if (
      _findIndex(amountFilters, (el) => el.value === confirmedAmount) === -1
    ) {
      setValue("custom_amount", confirmedAmount, { shouldValidate: true });
      setValue("amount_field", null, { shouldValidate: true });
    } else {
      setValue("custom_amount", null, { shouldValidate: true });
      setValue("amount_field", confirmedAmount, { shouldValidate: true });
    }
  };
  return (
    <FormControl error={!!errors[fieldName]} sx={{ width: "100%" }}>
      <TextField
        {...register(fieldName, {
          required: fieldCannotBeEmpty,
          validate: (value) => {
            if (!amountField && (value < 5 || value > 2000)) {
              return incorrectFormat;
            }
            if (!amountField && !value) {
              return pleaseFillInput;
            }
          }
        })}
        variant="outlined"
        focused={!!anchorEl}
        fullWidth
        onClick={(event) =>
          setAnchorEl(anchorEl ? null : event.target.parentNode)
        }
        label={+confirmedAmount > 0 ? null : "Select an amount"}
        value={+confirmedAmount > 0 ? confirmedAmount : ""}
        InputLabelProps={{
          shrink: false,
          style: {
            transform: "translate(24px, 23px) scale(1)"
          }
        }}
        InputProps={{
          readOnly: true,
          style: {
            fontWeight: 700
          },
          startAdornment: (
            <InputAdornment position="end">
              {+confirmedAmount > 0 && (
                <Typography
                  sx={{
                    fontWeight: 700
                  }}
                >
                  $
                </Typography>
              )}
            </InputAdornment>
          ),

          endAdornment: (
            <IconButton
              size={"small"}
              sx={{
                transition: "all 0.3s",
                transform: anchorEl ? "rotate(180deg)" : "none"
              }}
            >
              <svg width={24} height={24} stroke={palette.primary.main}>
                <use href="#cheveron-down" />
              </svg>
            </IconButton>
          )
        }}
        inputProps={{
          readOnly: true
        }}
        sx={{
          "& .MuiOutlinedInput-input": {
            paddingTop: "24px",
            paddingLeft: 0,
            paddingBottom: "24px"
          }
        }}
      />

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleResetAmount}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        PaperProps={{
          style: {
            marginTop: "8px",
            padding: "24px",
            width: anchorEl?.clientWidth || "auto%"
          }
        }}
      >
        <ClickAwayListener onClickAway={handleResetAmount}>
          <Box sx={{ position: "relative" }}>
            <Grid container columnSpacing={"20px"} rowSpacing={"16px"}>
              {amountFilters.map((el) => (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={2}
                  alignItems={"center"}
                  justifyContent={"center"}
                  key={el.id}
                >
                  <Box
                    onClick={() => {
                      setValue("custom_amount", "", {
                        shouldValidate: true
                      });
                      setValue("amount_field", el.value);
                    }}
                    sx={{
                      width: "100%",
                      textAlign: "center",
                      fontWeight: 600,
                      cursor: "pointer",
                      height: "70px",
                      padding: "24px",
                      borderRadius: "4px",
                      border:
                        amountField === el.value
                          ? `2px solid ${palette.primary.main}`
                          : `1px solid ${palette.grayscale[200]}`
                    }}
                  >
                    ${el.value}
                  </Box>
                </Grid>
              ))}
              <Grid
                item
                xs={12}
                sx={{
                  "& .MuiInputBase-root.Mui-focused:not(.Mui-error)": {
                    boxShadow: `inset 0 0 0 2px ${palette.purple["500"]}`
                  }
                }}
              >
                <Box>
                  <FieldText
                    {...methods}
                    startAdornment={
                      customAmountField ? (
                        <Typography
                          variant={"body1"}
                          fontWeight={600}
                          mt={2}
                          sx={{
                            position: "relative",
                            left: "12px"
                          }}
                          color={"grayscale.800"}
                        >
                          $
                        </Typography>
                      ) : null
                    }
                    label={"Enter custom amount"}
                    fieldName={"custom_amount"}
                    showOptional={false}
                    type={"number"}
                    validation={{}}
                  />
                  <input hidden={true} {...register("amount_field")} />
                </Box>
              </Grid>
            </Grid>
            <Stack
              mt={2}
              gap={2}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Button variant={"outlinedPrimary"} onClick={handleResetAmount}>
                Close
              </Button>
              <Button onClick={handleConfirmAmount}>Confirm</Button>
            </Stack>
          </Box>
        </ClickAwayListener>
      </Popover>
      {errors[fieldName] && (
        <FormHelperText>{errors[fieldName].message}</FormHelperText>
      )}
    </FormControl>
  );
};

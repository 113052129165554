import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { getCookie, removeCookie, setCookie } from "helpers/cookies";
import { createApiCall } from "helpers/api";
import { useSnackbar } from "notistack";
import { hasAccessByRole } from "settings";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [token, setToken] = useState(getCookie("token"));
  const [session, setSession] = useState(null);
  const [kyc, setKyc] = useState(null);
  const [config, setConfig] = useState(null);
  const [balance, setBalance] = useState({ data: null, loading: true });

  useEffect(() => {
    if (token) {
      createApiCall({
        url: `session`
      }).then(({ data, status }) => {
        if (status === 200) {
          setSession(data?.session);
        } else {
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
          setSession(null);
        }
      });
    }
  }, [token]);

  useEffect(() => {
    if (session?.clientUuid) {
      createApiCall({
        url: `client-configs/${session.clientUuid}`
      }).then(({ data, status }) => {
        if (data?.config && status === 200) {
          setConfig(data.config);
        } else {
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
        }
      });
      getBalance();
    }
  }, [session]);

  useEffect(() => {
    if (
      config?.kyc?.enabled === "TRUE" &&
      hasAccessByRole(session?.roles, ["CLIENT_OWNER"])
    ) {
      createApiCall({
        url: `kyc/verification?client_uuid=${session.clientUuid}`
      }).then(({ data, status }) => {
        if (data && status === 200) {
          setKyc(data.verification);
        } else {
          setKyc({
            status: "STATUS_UNKNOWN"
          });
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
        }
      });
    }
  }, [config]);

  const isCampaignEnabled = useMemo(
    () => config?.campaigns?.enabled === "TRUE",
    [config]
  );
  const isApiEnabled = useMemo(() => config?.api?.enabled === "TRUE", [config]);

  const isCampaignApiClient = useMemo(
    () => isCampaignEnabled && isApiEnabled,
    [isCampaignEnabled, isApiEnabled]
  );

  const getBalance = useCallback(() => {
    if (session?.clientUuid) {
      if (!balance.data) setBalance({ data: null, loading: true });
      createApiCall({
        url: `business-client-fund/balance?client_uuid=${session.clientUuid}`
      }).then(({ status, data }) => {
        if (status === 200) {
          setBalance({ data: data?.balance || null, loading: false });
        } else {
          setBalance({ loading: false });
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
        }
      });
    }
  }, [session, balance]);

  const login = async (newToken) => {
    setCookie("token", newToken);
    setToken(newToken);
  };

  const logout = () => {
    setToken(null);
    removeCookie("token");
  };

  const value = {
    session,
    balance,
    getBalance,
    kyc,
    setKyc,
    token,
    config,
    isCampaignApiClient,
    isCampaignEnabled,
    isApiEnabled,
    login,
    logout
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

/**
 *
 * @returns {{
 * setKyc: *,
 * balance: *,
 * getBalance: *,
 * logout: *,
 * config: *,
 * isCampaignApiClient: boolean,
 * isCampaignEnabled: boolean,
 * isApiEnabled: boolean,
 * kyc: ({ status: (string) }|*),
 * session: ({
 *  clientUuid:string,
 *  email:string,
 *  roles:Array,
 *  userName:string,
 *  uuid:string,
 *  braintreeCustomerId:string,
 *  braintreeToken:string
 *  kycStatus:string,
 * }|*),
 * login: *,
 * token: (string|*)
 * }}
 */
export const useAuth = () => {
  const ctx = useContext(AuthContext);
  return {
    session: ctx?.session,
    balance: ctx?.balance,
    getBalance: ctx?.getBalance,
    kyc: ctx?.kyc,
    setKyc: ctx?.setKyc,
    config: ctx?.config,
    isCampaignApiClient: ctx?.isCampaignApiClient,
    isCampaignEnabled: ctx?.isCampaignEnabled,
    isApiEnabled: ctx?.isApiEnabled,
    token: ctx?.token,
    login: ctx?.login,
    logout: ctx?.logout
  };
};

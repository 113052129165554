import React, { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  getHours,
  getMinutes,
  setHours,
  setMinutes,
  isToday,
  subMonths,
  subDays,
  addMonths,
  addDays,
  getDate,
  isAfter,
  isBefore,
  differenceInMonths,
  differenceInCalendarMonths
} from "date-fns";

import { TestPreviewOptionModal } from "components/pages/portal/modal";
import { CustomTabPanel } from "components/shared";
import { FieldDatePicker, FieldTextarea } from "components/fields";
import { PreviewMail } from "./PreviewMail";
import {
  chooseDateInFuture,
  chooseTimeInFuture,
  fieldCannotBeEmpty,
  manyCharacters,
  range1DayTo6Months,
  palette,
  theme
} from "settings";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";

export const Step4 = ({ activeStep, deliveryMethod }) => {
  const isTabletOrSmall = useMediaQuery(theme.breakpoints.down("md"));

  const methods = useFormContext();
  const { watch, getValues, trigger, setValue } = methods;
  const formData = getValues();
  const [showPreview, setShowPreview] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);
  const message = watch("message");
  const delivery_time = watch("delivery_time");
  const delivery_day = watch("delivery_day");
  const expiration_day = watch("expiration_day");

  const defaultText =
    deliveryMethod !== "EMAIL"
      ? `${formData.clientName || "Customer"} has sent you a $${
          formData?.amount_field || formData?.gift_card || 0
        } gift!`
      : "";

  useEffect(() => {
    if (defaultText && !message) {
      setValue("message", defaultText);
    }
  }, []);

  const minDeliveryTime = useMemo(() => {
    const d = new Date();
    if (isToday(delivery_day)) {
      return setHours(setMinutes(d, getMinutes(d)), getHours(d));
    }
    return setHours(setMinutes(d, 0), 0);
  }, [delivery_day]);

  const deliveryDateRange = useMemo(() => {
    const d = new Date();
    return {
      min:
        differenceInMonths(expiration_day, d) >= 6
          ? subMonths(expiration_day, 6)
          : d,
      max: expiration_day ? subDays(expiration_day, 1) : null
    };
  }, [expiration_day]);

  const expirationDateRange = useMemo(() => {
    const d = new Date();
    return {
      min: delivery_day ? addDays(delivery_day, 1) : addDays(d, 1),
      max: delivery_day ? addMonths(delivery_day, 6) : null
    };
  }, [delivery_day]);

  useEffect(() => {
    if (delivery_day && delivery_time) {
      trigger("delivery_time");
    }
  }, [delivery_day, delivery_time]);

  const onPreview = () =>
    deliveryMethod === "EMAIL" ? setShowPreview(true) : setShowTestModal(true);

  const onScheduleNow = () => {
    const d = new Date();
    setValue("delivery_day", d);
    setValue(
      "delivery_time",
      setHours(setMinutes(d, getMinutes(d) + 1), getHours(d))
    );
  };
  const handleCloseModal = () => {
    setShowPreview(false);
    setShowTestModal(false);
  };

  return (
    <CustomTabPanel value={activeStep} index={3}>
      <PreviewMail
        open={showPreview}
        onClose={() => setShowPreview(false)}
        data={formData}
        onShowTestModal={setShowTestModal}
      />
      <TestPreviewOptionModal
        open={showTestModal}
        onClose={handleCloseModal}
        uuid={formData.uuid}
        deliveryMethod={deliveryMethod}
      />

      <Box py={3} px={{ xs: 2, md: 3 }}>
        <Box mb={4}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={1}
          >
            <Typography
              variant={"subLead"}
              fontWeight={700}
              color={"grayscale.800"}
            >
              Message
            </Typography>
            <Button
              disableRipple
              variant={"text"}
              sx={{
                padding: { xs: 0 }
              }}
              onClick={onPreview}
              startIcon={
                <svg
                  width={24}
                  height={24}
                  stroke={palette.primary.main}
                  style={{ minWidth: 24 }}
                >
                  <use
                    xlinkHref={
                      deliveryMethod === "EMAIL" ? "#eye-sm" : "#sms-ico"
                    }
                  />
                </svg>
              }
            >
              {deliveryMethod === "EMAIL" ? "Preview email" : "Send test SMS"}
            </Button>
          </Stack>
          <FieldTextarea
            {...methods}
            fieldName={"message"}
            defaultValue={defaultText}
            validation={{
              required: fieldCannotBeEmpty,
              maxLength: {
                value: 300,
                message: manyCharacters
              }
            }}
            rows={isTabletOrSmall ? 6 : 4}
            label={""}
            placeholder={""}
            endAdornment={
              <Stack
                direction="row"
                alignItems={"center"}
                sx={{ padding: "38px 0 8px", marginTop: "auto" }}
              >
                <Typography variant={"leadCaption"} color={"grayscale.400"}>
                  {message?.length || 0}
                </Typography>
                <Typography variant={"leadCaption"} color={"grayscale.400"}>
                  /300
                </Typography>
              </Stack>
            }
          />
        </Box>
        <Box mb={4}>
          <Stack
            direction={"row"}
            gap={1}
            justifyContent={"space-between"}
            alignItems={"center"}
            mb={1}
          >
            <Typography
              variant={"subLead"}
              fontWeight={700}
              color={"grayscale.800"}
            >
              Delivery
            </Typography>

            <Button
              onClick={onScheduleNow}
              disableRipple
              variant={"text"}
              sx={{
                padding: { xs: 0 }
              }}
            >
              Schedule now
            </Button>
          </Stack>

          <Grid container spacing={{ xs: 2, md: "20px" }}>
            <Grid item xs={6} sm={6}>
              <FieldDatePicker
                {...methods}
                label={"Date"}
                placeholderText={""}
                fieldName={"delivery_day"}
                minDate={deliveryDateRange.min}
                maxDate={deliveryDateRange.max}
                validation={{
                  required: fieldCannotBeEmpty,
                  validate: (value) => {
                    if (isBefore(value, subDays(new Date(), 1))) {
                      return chooseDateInFuture;
                    }
                    if (!expiration_day) {
                      return true;
                    }
                    if (
                      differenceInCalendarMonths(expiration_day, value) === 6
                    ) {
                      return (
                        getDate(expiration_day) <= getDate(value) ||
                        range1DayTo6Months
                      );
                    } else if (
                      differenceInCalendarMonths(expiration_day, value) > 6
                    ) {
                      return range1DayTo6Months;
                    }
                    return true;
                  }
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <FieldDatePicker
                {...methods}
                label={"Time"}
                portalId={"delivery-time"}
                placeholderText={""}
                fieldName={"delivery_time"}
                showTimeSelect={true}
                showTimeSelectOnly={true}
                minTime={minDeliveryTime}
                maxTime={setHours(setMinutes(new Date(), 59), 23)}
                timeIntervals={60}
                dateFormat="h:mm a"
                validation={{
                  required: fieldCannotBeEmpty,
                  validate: (value) => {
                    if (isToday(delivery_day)) {
                      return (
                        isAfter(value, minDeliveryTime) || chooseTimeInFuture
                      );
                    }
                    return true;
                  }
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={"20px"}>
            <Grid item xs={12} sm={6}>
              <Stack
                direction={{
                  xl: "row"
                }}
                alignItems={{
                  xl: "center"
                }}
                justifyContent={"space-between"}
                mb={1}
                spacing={1}
              >
                <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                  <Typography
                    variant={"subLead"}
                    fontWeight={700}
                    color={"grayscale.800"}
                  >
                    Redemption code expiration
                  </Typography>
                  <Tooltip
                    title={
                      "The Redemption expiration date is the time when the offer expires. 100% of the amount of unredeemed codes is credited back to your available balance after this date."
                    }
                    componentsProps={{
                      tooltip: {
                        sx: {
                          padding: "4px 12px",
                          fontSize: 12
                        }
                      }
                    }}
                    placement="top"
                    variant={"small"}
                  >
                    <svg width={24} height={24} color={palette.purple["500"]}>
                      <use href="#information-circle" />
                    </svg>
                  </Tooltip>
                </Stack>
              </Stack>

              <FieldDatePicker
                {...methods}
                label={"Date"}
                placeholderText={""}
                fieldName={"expiration_day"}
                minDate={expirationDateRange.min}
                maxDate={expirationDateRange.max}
                validation={{
                  required: fieldCannotBeEmpty,
                  validate: (value) => {
                    if (!delivery_day) {
                      return true;
                    }
                    if (differenceInCalendarMonths(value, delivery_day) === 6) {
                      return (
                        getDate(value) <= getDate(delivery_day) ||
                        range1DayTo6Months
                      );
                    } else if (
                      differenceInCalendarMonths(value, delivery_day) > 6
                    ) {
                      return range1DayTo6Months;
                    }
                    return true;
                  }
                }}
              />
              <Box mt={1}>
                <Typography variant={"titleCaption"} color={"grayscale.300"}>
                  Choose a date range from 1 day to 6 months
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </CustomTabPanel>
  );
};

import React from "react";
import { fieldCannotBeEmpty } from "settings";

import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import FilledInput from "@mui/material/FilledInput";
import Typography from "@mui/material/Typography";

export const FieldText = ({
  register,
  formState: { errors },
  fieldName = "name",
  placeholder = "",
  label,
  autoFocus = false,
  validation = {
    required: fieldCannotBeEmpty
  },
  type = "text",
  size = "medium",
  showOptional = true,
  endAdornment = null,
  startAdornment = null
}) => {
  const typeProps =
    type === "number"
      ? {
          inputMode: "numeric",
          pattern: "[0-9]*"
        }
      : {};
  return (
    <FormControl variant="filled" error={!!errors[fieldName]} size={size}>
      {label && (
        <InputLabel sx={{ mb: "6px" }}>
          {label}
          {!validation?.required && showOptional && (
            <Typography component={"span"} color={"grayscale.400"}>
              {" "}
              (optional)
            </Typography>
          )}
        </InputLabel>
      )}
      <FilledInput
        type={type}
        fullWidth
        disableUnderline
        autoFocus={autoFocus}
        inputProps={{ ...typeProps }}
        placeholder={placeholder}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        {...register(fieldName, validation)}
        onWheel={(e) => type === "number" && e.target.blur()}
      />
      {!!errors[fieldName] && (
        <FormHelperText>{errors[fieldName].message}</FormHelperText>
      )}
    </FormControl>
  );
};

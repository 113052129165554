import React, { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSnackbar } from "notistack";
import _filter from "lodash/filter";
import _some from "lodash/some";
import _pullAllBy from "lodash/pullAllBy";
import _unionBy from "lodash/unionBy";
import _differenceBy from "lodash/differenceBy";

import { useAuth } from "components/AuthProvider";
import { CustomTabPanel, InfinityTable } from "components/shared";
import { palette } from "settings";
import { SelectedRecipients } from "./SelectedRecipients";
import {
  UploadRecipient,
  RecipientForm,
  headCells,
  defaultFilters,
  collectFilter,
  defaultRecipientsState
} from "components/pages/portal/recipients";
import { CategorySearchAutocomplete } from "components/fields";
import { createApiCall } from "helpers/api";
import { encodeObjectToQueryString } from "helpers/queryStrings";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

export const Step2 = ({
  activeStep,
  campaign,
  campaignUuid,
  deliveryMethod = "ALL",
  confirmSelectedRecipients,
  openSelected,
  setOpenSelected,
  handleSaveDraft
}) => {
  const { session } = useAuth();

  const { enqueueSnackbar } = useSnackbar();
  const methods = useFormContext();
  const { watch, setValue } = methods;
  const [loading, setLoading] = useState(true);
  const [loadingSelected, setLoadingSelected] = useState(true);
  const [showUpload, setShowUpload] = useState(false);
  const [showAddRecipient, setShowAddRecipient] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [recipients, setRecipients] = useState(defaultRecipientsState);
  const [filter, setFilter] = useState(defaultFilters);
  const [activeRecipient, setActiveRecipient] = useState(null);
  const searchOption = watch("search_category_option");
  const customersSelected = watch("customersSelected") || {
    all: false,
    items: []
  };

  const toolbarTitle = useMemo(() => {
    if (loading) {
      return `List`;
    }
    if (customersSelected?.items?.length) {
      return `Selected (${customersSelected.items.length} of ${recipients.pagination.totalResults})`;
    }
    return `List (${recipients.pagination.totalResults || 0})`;
  }, [recipients, customersSelected, loading]);

  useEffect(() => {
    fetchCampaignRecipients();
  }, []);

  useEffect(() => {
    if (!loadingSelected) {
      getRecipients();
    }
  }, [campaignUuid, filter, session?.clientUuid, loadingSelected]);

  useEffect(() => {
    const newFilter = { ...defaultFilters };
    if ((!searchOption || !searchOption?.length) && !searchOption?.uuid) {
      return setFilter(defaultFilters);
    }
    if (activeCategory?.id === "name") {
      const firstName = searchOption?.firstName || searchOption?.split(" ")[0];
      const lastName = searchOption?.lastName || searchOption?.split(" ")[1];
      if (lastName) {
        newFilter.lastName = lastName.trim();
      }
      if (firstName) {
        newFilter.firstName = firstName.trim();
      }
    }
    if (activeCategory?.id === "tag" && searchOption?.uuid) {
      newFilter.client_tag_uuids = [searchOption.uuid];
    }
    if (activeCategory?.id === "phoneNumber") {
      newFilter.phoneNumber =
        searchOption?.phoneNumber ||
        searchOption?.replace(/[ \-()+_]/g, "") ||
        "";
    }
    if (activeCategory?.id === "email") {
      newFilter.email = searchOption?.email || searchOption.trim() || "";
    }
    setFilter(newFilter);
  }, [searchOption]);

  const fetchCampaignRecipients = () => {
    if (campaignUuid && !!campaign?.customersCount) {
      setLoadingSelected(true);
      createApiCall({
        // BE can't filter and paginate with both params 'onCampaign=TRUE&campaign_uuid'
        url: `customers/${session?.clientUuid}?include_tags=TRUE&onCampaign=TRUE&campaign_uuid=${campaignUuid}`
      }).then(({ data, status }) => {
        if (status === 200 && data?.customers) {
          const { customers } = data;
          setValue("campaignCustomers", customers);
          setValue("customersSelected", {
            all: false,
            items: customers
          });
        } else {
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
        }
        setLoadingSelected(false);
      });
    } else setLoadingSelected(false);
  };

  const getRecipients = () => {
    if (session?.clientUuid) {
      setLoading(true);
      return createApiCall({
        url: `customers/${session?.clientUuid}?${encodeObjectToQueryString(
          collectFilter(filter, "ASC", "created_at")
        )}&include_tags=TRUE&delivery_flag=${deliveryMethod}`
      }).then(({ data, status }) => {
        if (status === 200) {
          setValue("customersSelected", {
            all: false,
            items: [...customersSelected.items]
          });
          if (filter.pagination.page === 0) {
            setRecipients({
              pagination: data.pagination,
              customers: data.customers
            });
          } else {
            setRecipients({
              pagination: data.pagination,
              customers: [...recipients.customers, ...data.customers]
            });
          }
        } else {
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
        }
        setLoading(false);
      });
    }
  };

  const onLoadMore = () => {
    setFilter({
      ...filter,
      pagination: {
        ...filter.pagination,
        page: filter.pagination.page + 1
      }
    });

    setValue("customersSelected", {
      all: false,
      items: [...customersSelected.items]
    });
  };

  const onCloseAddRecipients = (reload = false) => {
    setShowUpload(false);
    setActiveRecipient(null);
    setShowAddRecipient(false);
    if (reload) {
      fetchCampaignRecipients();
      getRecipients();
    }
  };

  const handleDelete = (rowData) => {
    setValue("customersSelected", {
      all: false,
      items: _filter(customersSelected.items, (el) => el.uuid !== rowData.uuid)
    });
  };

  const onCheckedItems = (checkedEl) => {
    if (checkedEl === "all" && customersSelected.all) {
      setValue("customersSelected", {
        all: false,
        items: _pullAllBy(
          [...customersSelected.items],
          recipients?.customers,
          "uuid"
        )
      });
    } else if (checkedEl === "all") {
      setValue("customersSelected", {
        all: true,
        items: _unionBy(
          [...customersSelected.items, ...recipients?.customers],
          "uuid"
        )
      });
    } else {
      const isExist = _some(customersSelected.items, ["uuid", checkedEl.uuid]);
      const items = isExist
        ? _filter(
            [...customersSelected.items],
            (el) => el.uuid !== checkedEl.uuid
          )
        : [...customersSelected.items, checkedEl];
      setValue("customersSelected", {
        all: !_differenceBy([...recipients?.customers], [...items], "uuid")
          .length,
        items
      });
    }
  };

  const CustomEmptyState = () => {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={headCells.length} sx={{ border: "none" }}>
            <Stack
              p={4}
              width={"100%"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)"
              }}
            >
              <svg width={48} height={48} stroke={palette.primary.main}>
                <use xlinkHref={"#human-add-ico"} />
              </svg>
              <Typography
                mt={1}
                mb={1.5}
                variant={"smallTitle"}
                fontWeight={600}
                textAlign={"center"}
                color={"grayscale.300"}
                sx={{ maxWidth: 385, mx: "auto" }}
              >
                Uh oh! Looks like you haven’t added any recipients yet. Please
                add people you would like to reward individually or by uploading
                them via a .csv file
              </Typography>
              <Stack
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={2}
              >
                <Button
                  disableRipple
                  variant={"text"}
                  sx={{ padding: { xs: "0" } }}
                  onClick={() => setShowUpload(true)}
                >
                  Upload CSV File
                </Button>
                <Stack
                  direction="row"
                  alignItems={"center"}
                  justifyContent={"center"}
                  variant={"h3"}
                  component={"p"}
                  color={"grayscale.300"}
                  sx={{
                    lineHeight: 0,
                    "&:before": {
                      width: 8,
                      content: '"•"',
                      display: "block"
                    }
                  }}
                />
                <Button
                  disableRipple
                  variant={"text"}
                  sx={{ padding: { xs: "0" } }}
                  onClick={() => setShowAddRecipient(true)}
                >
                  Add recipient
                </Button>
              </Stack>
            </Stack>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const handleOpenRecipient = (el) => {
    setShowAddRecipient(true);
    setActiveRecipient(el.uuid);
  };

  return (
    <CustomTabPanel value={activeStep} index={1}>
      <SelectedRecipients
        onClose={() => setOpenSelected(!openSelected)}
        open={openSelected}
        handleSubmit={handleSaveDraft}
        selectedItems={customersSelected}
        campaignUuid={campaignUuid}
        deliveryMethod={deliveryMethod}
        confirmSelectedRecipients={confirmSelectedRecipients}
        onDelete={handleDelete}
      />
      <UploadRecipient onClose={onCloseAddRecipients} open={showUpload} />
      <RecipientForm
        recipientUuid={activeRecipient}
        open={showAddRecipient}
        onClose={onCloseAddRecipients}
      />
      <Box
        sx={{
          p: {
            xs: "24px 8px 8px",
            md: "32px 24px"
          }
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Stack gap={2}>
            <CategorySearchAutocomplete
              {...methods}
              activeCategory={activeCategory}
              setActiveCategory={setActiveCategory}
              campaignUuid={campaignUuid}
              deliveryMethod={deliveryMethod}
            />
            <InfinityTable
              headCells={headCells}
              loading={loadingSelected || loading}
              data={recipients?.customers || []}
              total={recipients?.pagination?.totalResults || 0}
              order={"asc"}
              orderBy={activeCategory?.id || "lastName"}
              onCheckedItems={onCheckedItems}
              checkedItems={customersSelected}
              onRowClick={handleOpenRecipient}
              onLoadMore={onLoadMore}
              elevation={0}
              toolbarTitle={toolbarTitle}
              toolbarSpacing={3}
              toolbarActions={
                <>
                  <IconButton
                    size={"small"}
                    onClick={() => setShowUpload(true)}
                  >
                    <svg width={24} height={24} stroke={"#fff"}>
                      <use href="#upload-new" />
                    </svg>
                  </IconButton>
                  <IconButton
                    size={"small"}
                    onClick={() => setShowAddRecipient(true)}
                  >
                    <svg width={24} height={24} color={"#fff"}>
                      <use href="#add-user" />
                    </svg>
                  </IconButton>
                </>
              }
              emptyStateComponent={<CustomEmptyState />}
            />
          </Stack>
        </Box>
      </Box>
    </CustomTabPanel>
  );
};

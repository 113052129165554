import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { NewPasswordField } from "components/fields";
import { LOGIN_PAGE, royalBlue } from "settings";
import { createApiCall } from "helpers/api";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Paper from "@mui/material/Paper";

export const CreatePasswordForm = ({ reset }) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const methods = useForm();
  const { handleSubmit } = methods;
  const [loading, setLoading] = useState(false);

  const onSubmit = handleSubmit(({ newPassword }) => {
    setLoading(true);
    createApiCall({
      url: "password-reset",
      method: "PUT",
      data: {
        password: newPassword,
        token
      }
    })
      .then(({ status }) => {
        setLoading(false);
        if (status === 200) {
          enqueueSnackbar(
            `Password has been successfully ${reset ? "updated" : "created"}`,
            {
              variant: "success"
            }
          );
          navigate(LOGIN_PAGE);
        }
      })
      .catch(({ data }) => {
        setLoading(false);
        if (data?.code?.code === "ErrCodeTokenExpired") {
          enqueueSnackbar("Reset link has been already used or expired", {
            variant: "error"
          });
        } else {
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
        }
      });
  });

  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: "18px",
        zIndex: 1,
        width: "100%",
        maxWidth: { xs: "100%", sm: "815px" },
        margin: "0 auto",
        padding: { xs: "24px 16px", md: "24px 160px" }
      }}
    >
      <Stack gap={1}>
        <Typography
          variant={"h2"}
          textAlign={"center"}
          color={royalBlue}
          fontWeight={700}
          sx={{ fontSize: { xs: 28, md: 40 } }}
        >
          {reset ? "Reset your password." : "Create your password."}
        </Typography>
        <Typography
          mx={"auto"}
          variant={"subLead"}
          color={royalBlue}
          mb={1}
          textAlign={"center"}
          sx={{
            fontSize: { xs: 17, md: 18 },
            maxWidth: { xs: 290, md: 350 }
          }}
        >
          We're excited to have you on board! To finish setting up your account,
          please create a strong password.
        </Typography>
        <form onSubmit={onSubmit} noValidate>
          <Stack gap={"6px"}>
            <NewPasswordField {...methods} />
            <LoadingButton
              sx={{ mt: 3 }}
              loading={loading}
              fullWidth
              variant={"contained"}
              type={"submit"}
            >
              Continue
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Paper>
  );
};

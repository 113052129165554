export const defaultBrands = [
  {
    id: 1,
    attributes: {
      brand_name: "Brand name1",
      icon_url: "",
      amount: 50,
      img: "",
      desc: "test desc",
      brand_details:
        "Treat yourself - or someone else - to something special at Starbucks with a Starbucks card, which you can use towards premium coffee, tea, refreshers, lunch, pastries and more. And if you register your card with the Starbucks Rewards™ loyalty program (starbucks.com/rewards), you can earn even more free food and drinks. The Starbucks word mark and the Starbucks Logo are trademarks of Starbucks Corporation. Starbucks is also the owner of the Copyrights in the Starbucks Logo and the Starbucks Card designs. All rights reserved. Starbucks is not a participating partner or sponsor in this offer."
    }
  },
  {
    id: 2,
    attributes: {
      brand_name: "Brand name2",
      icon_url: "",
      amount: 100,
      img: "",
      desc: "test desc",
      brand_details:
        "Treat yourself - or someone else - to something special at Starbucks with a Starbucks card, which you can use towards premium coffee, tea, refreshers, lunch, pastries and more. And if you register your card with the Starbucks Rewards™ loyalty program (starbucks.com/rewards), you can earn even more free food and drinks. The Starbucks word mark and the Starbucks Logo are trademarks of Starbucks Corporation. Starbucks is also the owner of the Copyrights in the Starbucks Logo and the Starbucks Card designs. All rights reserved. Starbucks is not a participating partner or sponsor in this offer."
    }
  },
  {
    id: 3,
    attributes: {
      brand_name: "Brand name3",
      icon_url: "",
      amount: 150,
      desc: "test desc",
      brand_details:
        "Treat yourself - or someone else - to something special at Starbucks with a Starbucks card, which you can use towards premium coffee, tea, refreshers, lunch, pastries and more. And if you register your card with the Starbucks Rewards™ loyalty program (starbucks.com/rewards), you can earn even more free food and drinks. The Starbucks word mark and the Starbucks Logo are trademarks of Starbucks Corporation. Starbucks is also the owner of the Copyrights in the Starbucks Logo and the Starbucks Card designs. All rights reserved. Starbucks is not a participating partner or sponsor in this offer."
    }
  },
  {
    id: 4,
    attributes: {
      brand_name: "Brand name4",
      icon_url: "",
      amount: 25,
      desc: "test desc",
      brand_details:
        "Treat yourself - or someone else - to something special at Starbucks with a Starbucks card, which you can use towards premium coffee, tea, refreshers, lunch, pastries and more. And if you register your card with the Starbucks Rewards™ loyalty program (starbucks.com/rewards), you can earn even more free food and drinks. The Starbucks word mark and the Starbucks Logo are trademarks of Starbucks Corporation. Starbucks is also the owner of the Copyrights in the Starbucks Logo and the Starbucks Card designs. All rights reserved. Starbucks is not a participating partner or sponsor in this offer."
    }
  },
  {
    id: 5,
    attributes: {
      brand_name: "Test5",
      icon_url: "",
      amount: 10,
      desc: "test desc",
      brand_details:
        "Treat yourself - or someone else - to something special at Starbucks with a Starbucks card, which you can use towards premium coffee, tea, refreshers, lunch, pastries and more. And if you register your card with the Starbucks Rewards™ loyalty program (starbucks.com/rewards), you can earn even more free food and drinks. The Starbucks word mark and the Starbucks Logo are trademarks of Starbucks Corporation. Starbucks is also the owner of the Copyrights in the Starbucks Logo and the Starbucks Card designs. All rights reserved. Starbucks is not a participating partner or sponsor in this offer."
    }
  },
  {
    id: 6,
    attributes: {
      brand_name: "Test6",
      icon_url: "",
      amount: 5,
      desc: "test desc",
      brand_details:
        "Treat yourself - or someone else - to something special at Starbucks with a Starbucks card, which you can use towards premium coffee, tea, refreshers, lunch, pastries and more. And if you register your card with the Starbucks Rewards™ loyalty program (starbucks.com/rewards), you can earn even more free food and drinks. The Starbucks word mark and the Starbucks Logo are trademarks of Starbucks Corporation. Starbucks is also the owner of the Copyrights in the Starbucks Logo and the Starbucks Card designs. All rights reserved. Starbucks is not a participating partner or sponsor in this offer."
    }
  },
  {
    id: 7,
    attributes: {
      brand_name: "Brand name test7",
      icon_url: "",
      amount: 50,
      desc: "test desc",
      brand_details:
        "Treat yourself - or someone else - to something special at Starbucks with a Starbucks card, which you can use towards premium coffee, tea, refreshers, lunch, pastries and more. And if you register your card with the Starbucks Rewards™ loyalty program (starbucks.com/rewards), you can earn even more free food and drinks. The Starbucks word mark and the Starbucks Logo are trademarks of Starbucks Corporation. Starbucks is also the owner of the Copyrights in the Starbucks Logo and the Starbucks Card designs. All rights reserved. Starbucks is not a participating partner or sponsor in this offer."
    }
  },
  {
    id: 8,
    attributes: {
      brand_name: "Brand name test8",
      icon_url: "",
      amount: 500,
      desc: "test desc",
      brand_details:
        "Treat yourself - or someone else - to something special at Starbucks with a Starbucks card, which you can use towards premium coffee, tea, refreshers, lunch, pastries and more. And if you register your card with the Starbucks Rewards™ loyalty program (starbucks.com/rewards), you can earn even more free food and drinks. The Starbucks word mark and the Starbucks Logo are trademarks of Starbucks Corporation. Starbucks is also the owner of the Copyrights in the Starbucks Logo and the Starbucks Card designs. All rights reserved. Starbucks is not a participating partner or sponsor in this offer."
    }
  },
  {
    id: 9,
    attributes: {
      brand_name: "Brand name test9",
      icon_url: "",
      amount: 50,
      desc: "test desc",
      brand_details:
        "Treat yourself - or someone else - to something special at Starbucks with a Starbucks card, which you can use towards premium coffee, tea, refreshers, lunch, pastries and more. And if you register your card with the Starbucks Rewards™ loyalty program (starbucks.com/rewards), you can earn even more free food and drinks. The Starbucks word mark and the Starbucks Logo are trademarks of Starbucks Corporation. Starbucks is also the owner of the Copyrights in the Starbucks Logo and the Starbucks Card designs. All rights reserved. Starbucks is not a participating partner or sponsor in this offer."
    }
  }
];

export const pageSize = 42;
export const initialParams = {
  "page[number]": 0,
  "page[size]": pageSize,
  uuids: null,
  category: "all",
  character: null,
  query: null,
  "sort_by.Order": "asc"
};

export const defaultBrandsFilter = {
  filter: {
    denomination: null,
    first_letter: null,
    category: [],
    name: null
  },
  pagination: {
    page: 0,
    pageLimit: 30,
    "sortBy.order": "ASC"
  }
};
export const defaultBrandsParams = {
  brands: [],
  pagination: {
    totalResults: 0
  }
};

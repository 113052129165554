import React, { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CustomTabPanel } from "components/shared";
import { amountFilters } from "./constant";
import { AmountField, BrandSearchAutocomplete } from "components/fields";
import { palette, redAlert, theme } from "settings";
import {
  BrandsCarousel,
  defaultBrandsParams,
  defaultBrandsFilter
} from "components/pages/portal/brands";
import { Brands } from "components/pages/portal/campaigns";
import { SelectedBrands } from "components/pages/portal/campaign";

import _findIndex from "lodash/findIndex";
import _difference from "lodash/difference";
import _map from "lodash/map";
import _some from "lodash/some";

import { createApiCall } from "helpers/api";
import { enqueueSnackbar } from "notistack";
import { encodeObjectToQueryString } from "helpers/queryStrings";
import { displayFullMoney } from "helpers/money";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";
import useMediaQuery from "@mui/material/useMediaQuery";

export const Step3 = ({
  activeStep,
  campaignUuid,
  openSelected,
  setOpenSelected,
  confirmSelectedBrands,
  handleSaveDraft
}) => {
  const isTabletOrSmall = useMediaQuery(theme.breakpoints.down("md"));

  const methods = useFormContext();
  const {
    register,
    trigger,
    watch,
    setValue,
    formState: { errors }
  } = methods;

  const customAmountField = watch("custom_amount") || 0;
  const amountField = watch("amount_field") || 0;
  const confirmedAmount = watch("confirmed_amount") || 0;
  const customersCount = watch("customersCount") || 0;
  const checkedBrandsField = watch("checked_brands") || [];

  const [loading, setLoading] = useState(false);
  const [loadingPopularBrands, setLoadingPopularBrands] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [brands, setBrands] = useState(defaultBrandsParams);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [popularBrands, setPopularBrands] = useState([]);
  const [brandsFilter, setBrandsFilter] = useState({
    ...defaultBrandsFilter,
    filter: {
      ...defaultBrandsFilter.filter,
      denomination: confirmedAmount ? confirmedAmount * 100 : null
    }
  });

  const uniqueBrands = useMemo(() => {
    return _difference(
      _map(brands?.brands || [], (el) => el.uuid || el) || [],
      _map(checkedBrandsField, (el) => el.uuid || el) || []
    );
  }, [checkedBrandsField, popularBrands, brands?.brands]);

  useEffect(() => {
    if (amountField) {
      const index = _findIndex(amountFilters, (el) => el.value === amountField);
      if (index === -1) {
        return setValue("custom_amount", amountField, { shouldValidate: true });
      } else {
        setValue("custom_amount", "");
      }
    }
  }, []);

  useEffect(() => {
    trigger("checked_brands");
    trigger("custom_amount");
  }, [customAmountField, amountField]);

  useEffect(() => {
    if (customAmountField) {
      setValue("amount_field", null, { shouldValidate: true });
    }
  }, [customAmountField]);

  useEffect(() => {
    if (campaignUuid) {
      createApiCall({
        url: `campaigns/brands?filter.campaign_uuid=${campaignUuid}&pagination.pageLimit=10000`
      }).then(({ data, status }) => {
        if (status === 200 && data?.brands) {
          setValue(
            "campaign_brandUuids",
            _map(data.brands, (el) => el.uuid)
          );
          setValue("checked_brands", data.brands || [], {
            shouldValidate: false
          });
        } else {
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
        }
        trigger("checked_brands");
      });
    }
  }, [campaignUuid]);

  useEffect(() => {
    if (!brands?.brands?.length) {
      setLoading(true);
    }
    setDisabled(true);

    createApiCall({
      url: `campaigns/brands?${encodeObjectToQueryString(brandsFilter, true)}`
    }).then(({ data, status }) => {
      if (status === 200) {
        setBrands(
          brandsFilter?.pagination.page === 0
            ? data
            : {
                ...data,
                brands: [...brands.brands, ...data.brands]
              }
        );
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setDisabled(false);
      setLoading(false);
    });
  }, [brandsFilter]);

  useEffect(() => {
    setLoadingPopularBrands(true);
    const url = `campaigns/brands?pagination.sortBy.field=popularity&pagination.pageLimit=24${
      confirmedAmount ? `&filter.denomination=${confirmedAmount * 100}` : ""
    }`;
    createApiCall({ url }).then(({ data, status }) => {
      if (status === 200) {
        setPopularBrands(data.brands);
      }
      setLoadingPopularBrands(false);
    });
    if (
      Math.round(confirmedAmount * 100) !==
      brandsFilter?.["filter.denomination"]
    ) {
      setBrandsFilter({
        filter: {
          ...brandsFilter.filter,
          denomination: Math.round(confirmedAmount * 100)
        },
        pagination: {
          ...brandsFilter.pagination,
          page: 0
        }
      });
    }
  }, [Number(confirmedAmount)]);

  const handleChangeName = (value) => {
    setBrandsFilter({
      pagination: {
        ...brandsFilter.pagination,
        page: 0
      },
      filter: {
        ...brandsFilter.filter,
        name: value || null
      }
    });
  };

  const handleChangeCategory = (option) => {
    const categories =
      _findIndex(selectedCategories, (el) => el.name === option.name) === -1
        ? [...selectedCategories, option]
        : selectedCategories.filter((el) => el.name !== option.name);

    setSelectedCategories(categories);

    setBrandsFilter({
      ...defaultBrandsFilter,
      filter: {
        ...brandsFilter.filter,
        category: _map(categories, "name")[0] //wait api for multiple
      }
    });
  };

  const handleSelectAll = async () => {
    setLoading(true);
    await createApiCall({
      url: `campaigns/brands?${encodeObjectToQueryString(
        {
          ...brandsFilter,
          pagination: {
            ...brandsFilter.pagination,
            pageLimit: brands.pagination.totalResults
          }
        },
        true
      )}`
    }).then(({ data, status }) => {
      if (status === 200 && data?.brands) {
        setValue("checked_brands", data.brands, {
          shouldValidate: true
        });
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  };

  const onRemoveCheckedBrand = (item) => {
    if (item === "all") {
      setValue("checked_brands", [], {
        shouldValidate: true
      });
    } else
      setValue(
        "checked_brands",
        checkedBrandsField?.filter((el) => el.uuid !== item.uuid),
        {
          shouldValidate: true
        }
      );
  };

  const onSelectBrand = (item) => {
    setValue("checked_brands", [item, ...checkedBrandsField], {
      shouldValidate: true
    });
  };

  const onLoadMore = () =>
    setBrandsFilter({
      ...brandsFilter,
      pagination: {
        ...brandsFilter.pagination,
        page: brandsFilter.pagination.page + 1
      }
    });

  return (
    <CustomTabPanel value={activeStep} index={2}>
      <SelectedBrands
        open={openSelected}
        onClose={() => setOpenSelected(!openSelected)}
        checkedBrands={checkedBrandsField}
        handleSubmit={handleSaveDraft}
        confirmedAmount={confirmedAmount}
        confirmSelectedBrands={confirmSelectedBrands}
        onDelete={onRemoveCheckedBrand}
        errors={errors}
      />
      <Box py={3} px={{ xs: 2, md: 3 }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={{ xs: 2, md: 1 }}
        >
          <Typography
            variant={"subLead"}
            fontWeight={700}
            color={"grayscale.800"}
          >
            Amount
          </Typography>

          <Typography variant={"smallTitle"} color={"grayscale.400"}>
            Campaign amount:
            <Typography
              component={"span"}
              ml={{ xs: 1 / 2, sm: 1 }}
              variant={"subLead"}
              fontWeight={700}
              color={"grayscale.800"}
            >
              ${(customersCount || 0) * Math.round(+confirmedAmount || 0)}
            </Typography>
          </Typography>
        </Stack>

        <Stack width={"100%"}>
          <AmountField {...methods} />

          <Typography
            mt={1}
            variant={"leadCaption"}
            color={"grayscale.400"}
            sx={{ textAlign: "end" }}
          >
            Limit is from $5.00 to $2,000.00
          </Typography>
        </Stack>

        {/*popular brands*/}
        <Box my={5}>
          <Typography
            mb={3}
            variant={"subLead"}
            fontWeight={700}
            color={"grayscale.800"}
          >
            Top Brands
          </Typography>
          {loadingPopularBrands ? (
            <Stack
              p={{ xs: 6, lg: 8 }}
              sx={{ width: "100%" }}
              alignItems={"center"}
            >
              <CircularProgress size={30} />
            </Stack>
          ) : (
            <BrandsCarousel
              items={popularBrands}
              checkedBrands={checkedBrandsField}
              amount={confirmedAmount}
              infinite={true}
              onSelect={onSelectBrand}
            />
          )}
        </Box>

        <Box>
          <Stack
            mb={1}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack
              direction={"row"}
              flexWrap={"wrap"}
              gap={2}
              alignItems={"center"}
            >
              <Typography
                variant={"subLead"}
                fontWeight={700}
                mt={{ xs: 0, md: 1 }}
                color={"grayscale.800"}
              >
                {`Brands (${brands.pagination.totalResults || 0})`}
              </Typography>
              {!isTabletOrSmall && (
                <Stack
                  direction={"row"}
                  flexWrap={"wrap"}
                  rowGap={1}
                  columnGap={2}
                >
                  {confirmedAmount > 0 && (
                    <Chip
                      variant="squared-secondary"
                      sx={{
                        mt: "8px"
                      }}
                      tabIndex={-1}
                      label={displayFullMoney(confirmedAmount * 100)}
                      onDelete={() => {
                        setValue("confirmed_amount", "", {
                          shouldValidate: true
                        });
                        setValue("custom_amount", "", { shouldValidate: true });
                        setValue("amount_field", null, {
                          shouldValidate: true
                        });
                      }}
                    />
                  )}

                  {selectedCategories?.map((el) => (
                    <Chip
                      icon={
                        <svg
                          width={12}
                          height={12}
                          style={{
                            minWidth: 12,
                            color: palette.primary.main,
                            marginLeft: 8,
                            marginRight: 0
                          }}
                        >
                          <use xlinkHref={`#${el.name}`} />
                        </svg>
                      }
                      key={el.name}
                      variant="squared-secondary"
                      sx={{
                        mt: "8px"
                      }}
                      tabIndex={-1}
                      label={el.text}
                      onDelete={() => handleChangeCategory(el)}
                    />
                  ))}
                </Stack>
              )}
            </Stack>

            <Button
              variant={"text"}
              onClick={() => setOpenSelected(true)}
              sx={{
                color: !!errors?.checked_brands
                  ? redAlert
                  : palette.primary.main,
                padding: { xs: "4px 8px" }
              }}
            >
              {`View Selected Brands (${checkedBrandsField.length || 0})`}
            </Button>
          </Stack>

          <Box mt={3}>
            <input
              defaultValue={checkedBrandsField}
              hidden={true}
              {...register("checked_brands", {
                required: "Please select brands",
                validate: (items) => {
                  const invalidItems = _findIndex(items, (el) => {
                    const { fixedLoad, variableLoad } = el;
                    const amountCent = Math.round((confirmedAmount || 0) * 100);
                    if (fixedLoad?.amounts?.length) {
                      return !_some(fixedLoad.amounts, (o) => o === amountCent);
                    } else {
                      return Number(confirmedAmount || 0)
                        ? amountCent < variableLoad?.minimumAmount ||
                            amountCent > variableLoad?.maximumAmount
                        : false;
                    }
                  });
                  if (invalidItems !== -1) {
                    return "Please specify a correct amount to continue";
                  }
                }
              })}
            />
            <BrandSearchAutocomplete
              {...methods}
              selectedCategories={selectedCategories}
              handleChangeCategory={handleChangeCategory}
              handleChangeName={handleChangeName}
            />
            {isTabletOrSmall && (
              <Stack
                mt={"4px"}
                direction={"row"}
                flexWrap={"wrap"}
                rowGap={1}
                columnGap={2}
              >
                {confirmedAmount > 0 && (
                  <Chip
                    variant="squared-secondary"
                    sx={{
                      mt: "8px"
                    }}
                    tabIndex={-1}
                    label={displayFullMoney(confirmedAmount * 100)}
                    onDelete={() => {
                      setValue("confirmed_amount", "", {
                        shouldValidate: true
                      });
                      setValue("custom_amount", "", { shouldValidate: true });
                      setValue("amount_field", null, { shouldValidate: true });
                    }}
                  />
                )}

                {selectedCategories?.map((el) => (
                  <Chip
                    icon={
                      <svg
                        width={12}
                        height={12}
                        style={{
                          minWidth: 12,
                          color: palette.primary.main,
                          marginLeft: 8,
                          marginRight: 0
                        }}
                      >
                        <use xlinkHref={`#${el.name}`} />
                      </svg>
                    }
                    key={el.name}
                    variant="squared-secondary"
                    sx={{
                      mt: "8px"
                    }}
                    tabIndex={-1}
                    label={el.text}
                    onDelete={() => handleChangeCategory(el)}
                  />
                ))}
              </Stack>
            )}
          </Box>
          <Stack direction={"row"} mt={3} alignItems={"stretch"}>
            {loading ? (
              <Stack p={4} sx={{ width: "100%" }} alignItems={"center"}>
                <CircularProgress size={30} />
              </Stack>
            ) : (
              <Brands
                data={brands}
                disabled={disabled}
                uniqueBrands={uniqueBrands}
                onLoadMore={onLoadMore}
                onSelect={onSelectBrand}
                onSelectAll={handleSelectAll}
              />
            )}
          </Stack>
        </Box>
      </Box>
    </CustomTabPanel>
  );
};

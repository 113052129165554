import React from "react";
import { KYC_PAGE, midnightBlue, palette, sidebarWidth } from "settings";

import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

export const WelcomeHelpModal = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        background: "rgba(217,217,217,0.7)",
        backdropFilter: "blur(2px)",
        zIndex: 1,
        top: { xs: "128px", md: "100px" },
        left: { xs: 0, md: sidebarWidth }
      }}
    >
      <DialogTitle>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ height: "35px" }}
        >
          <Typography
            variant={"smallTitle"}
            fontWeight={700}
            color={midnightBlue}
          >
            Welcome to your portal!
          </Typography>

          <IconButton size={"small"} onClick={onClose}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use href="#close" />
            </svg>
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant={"smallTitle"}
          color={"grayscale.400"}
          mt={"10px"}
          mb={"24px"}
        >
          To start creating campaigns you will have to submit an account KYC
          application. During this process we will ask you a few questions about
          your identity, please be sure to have one of the following documents
          ready.
        </Typography>
        <Stack direction={"row"} alignItems={"center"} gap={4}>
          <Stack direction={"row"} alignItems={"center"} gap={"4px"}>
            <svg
              width={24}
              height={24}
              style={{ minWidth: 24 }}
              stroke={palette.grayscale["400"]}
            >
              <use xlinkHref={"#EIN"} />
            </svg>
            <Typography variant={"smallTitle"} color={"grayscale.400"}>
              EIN
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={"4px"}>
            <svg
              width={24}
              height={24}
              style={{ minWidth: 24 }}
              color={palette.grayscale["400"]}
            >
              <use xlinkHref={"#library"} />
            </svg>
            <Typography variant={"smallTitle"} color={"grayscale.400"}>
              Bank Statement
            </Typography>
          </Stack>

          <Stack direction={"row"} alignItems={"center"} gap={"4px"}>
            <svg
              width={24}
              height={24}
              style={{ minWidth: 24 }}
              stroke={palette.grayscale["400"]}
            >
              <use xlinkHref={"#consentForm"} />
            </svg>
            <Typography variant={"smallTitle"} color={"grayscale.400"}>
              Consent Form
            </Typography>
          </Stack>
        </Stack>
        <Button
          component={Link}
          to={KYC_PAGE}
          fullWidth
          sx={{ mt: "24px" }}
          onClick={onClose}
        >
          Start
        </Button>
      </DialogContent>
    </Dialog>
  );
};

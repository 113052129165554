import React from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AuthFooter } from "components/common";
import Stack from "@mui/material/Stack";

export const AuthLayout = () => {
  return (
    <Stack minHeight={"100vh"}>
      <Helmet>
        <link rel="icon" href="/favicon_business.ico" />
      </Helmet>
      <Stack sx={{ flex: 1 }}>
        <Outlet />
      </Stack>
      <AuthFooter />
    </Stack>
  );
};

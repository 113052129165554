import React, { useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import _some from "lodash/some";
import _includes from "lodash/includes";
import _filter from "lodash/filter";
import _find from "lodash/find";
import { createApiCall } from "helpers/api";
import { SearchInput } from "components/fields";
import {
  headCells,
  defaultFilters,
  DEFAULT_ORDER,
  DEFAULT_ORDER_BY,
  defaultRecipientsState,
  collectFilter,
  RecipientForm,
  ManageTags,
  FilterRecipients,
  UploadRecipient,
  ToolbarDescription,
  recipientStatus
} from "components/pages/portal/recipients";
import { midnightBlue } from "settings";
import { InfinityTable, ToolbarActions } from "components/shared";
import { useSnackbar } from "notistack";
import { useAuth } from "components/AuthProvider";
import { encodeObjectToQueryString } from "helpers/queryStrings";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";

const Recipients = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useAuth();
  const [openForm, setOpenForm] = useState(false);
  const [recipient, setRecipient] = useState(null);
  const [filterModal, setFilterModal] = useState(false);
  const [openManageTags, setOpenManageTags] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [checkedItems, setCheckedItems] = useState({ all: false, items: [] });
  const [loading, setLoading] = useState(true);
  const [recipients, setRecipients] = useState(defaultRecipientsState);
  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [orderBy, setOrderBy] = useState(DEFAULT_ORDER_BY);
  const [filter, setFilter] = useState(defaultFilters);

  useEffect(() => {
    getRecipients();
  }, [filter, order, orderBy, session?.clientUuid]);

  const getRecipients = () => {
    if (session?.clientUuid) {
      setLoading(true);
      createApiCall({
        url: `customers/${session?.clientUuid}?${encodeObjectToQueryString(
          collectFilter(filter, order, orderBy)
        )}&include_tags=TRUE`
      }).then(({ data, status }) => {
        if (status === 200) {
          setRecipients({
            pagination: data.pagination,
            customers: [...recipients.customers, ...data.customers]
          });
        } else {
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
        }
        setLoading(false);
      });
    }
  };

  const onLoadMore = () => {
    setFilter({
      ...filter,
      pagination: {
        ...filter.pagination,
        page: filter.pagination.page + 1
      }
    });
  };

  const searchHandler = (email) => {
    setRecipients(defaultRecipientsState);
    setFilter({
      ...filter,
      email,
      pagination: {
        ...filter.pagination,
        page: 0
      }
    });
  };

  const searchHandlerFilters = (newParam) => {
    setRecipients(defaultRecipientsState);
    setFilter({
      ...filter,
      ...newParam,
      pagination: {
        ...filter.pagination,
        page: 0
      }
    });
  };

  const onFilter = (data) => {
    setRecipients(defaultRecipientsState);

    setFilter({
      ...data,
      pagination: {
        ...filter.pagination,
        page: 0
      }
    });
    setFilterModal(false);
  };

  const onExport = () => {
    if (session.clientUuid) {
      setLoadingFile(true);
    }
  };

  const onUpload = () => {
    setShowUpload(true);
  };

  const onDeactivate = () => {
    console.log("onDeactivate", checkedItems);
  };
  const onManageTags = () => {
    setOpenManageTags(true);
  };
  const onShowFilter = () => {
    setFilterModal(true);
  };

  const onCheckedItems = (checked) => {
    if (checked === "all") {
      setCheckedItems({
        all: !checkedItems.all,
        items: []
      });
    } else {
      setCheckedItems({
        all: false,
        items: _some(checkedItems.items, checked)
          ? _filter(checkedItems.items, (o) => o.uuid !== checked.uuid)
          : [...checkedItems.items, checked]
      });
    }
  };

  const sortHandler = (id, newOrder) => {
    setRecipients(defaultRecipientsState);
    setFilter({
      ...filter,
      pagination: {
        ...filter.pagination,
        page: 0
      }
    });
    setOrderBy(id);
    setOrder(newOrder);
  };

  const onOpenForm = (selectedRecipient = null) => {
    setRecipient(selectedRecipient);
    setOpenForm(true);
  };

  const onCloseForm = (reload = false) => {
    setOpenManageTags(false);
    setShowUpload(false);
    setOpenForm(false);
    setRecipient(null);
    if (reload) {
      setRecipients(defaultRecipientsState);
      setFilter({
        ...filter,
        pagination: {
          ...filter.pagination,
          page: 0
        }
      });
      getRecipients();
    }
  };

  const menuOptions = useMemo(
    () => [
      {
        id: 1,
        isHidden: !checkedItems.all && !checkedItems.items?.length,
        icon: "deactivate",
        text: "Deactivate",
        onClick: onDeactivate
      },
      {
        id: 2,
        isHidden: !checkedItems.all && !checkedItems.items?.length,
        divider: true
      },
      {
        id: 3,
        icon: "tags",
        text: "Manage tags",
        onClick: onManageTags
      },
      {
        id: 4,
        isHidden: _includes(session?.roles, "CLIENT_AGENT"),
        icon: "upload-new",
        text: "Upload .CSV",
        onClick: onUpload
      },
      {
        id: 5,
        isHidden: _includes(session?.roles, "CLIENT_AGENT"),
        icon: "export",
        loading: loadingFile,
        text: "Download .CSV",
        onClick: onExport
      },
      {
        id: 6,
        icon: "filter-new",
        text: "Filter",
        onClick: onShowFilter
      }
    ],
    [checkedItems, loadingFile, session]
  );

  return (
    <div>
      <Helmet>
        <title>Recipients</title>
      </Helmet>
      <ManageTags
        onClose={onCloseForm}
        open={!!openManageTags}
        onSubmitted={onManageTags}
      />
      <FilterRecipients
        onClose={() => setFilterModal(false)}
        open={!!filterModal}
        filterParams={filter}
        onFilter={onFilter}
      />
      {showUpload && (
        <UploadRecipient onClose={onCloseForm} open={showUpload} />
      )}
      <Box sx={{ minHeight: "100%" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ minHeight: 56 }}
          mb={1}
        >
          <Typography variant={"h5"} color={midnightBlue}>
            Recipients
          </Typography>
          <Button onClick={() => onOpenForm()}>Add a recipient</Button>
        </Stack>
        <Box sx={{ width: "100%" }} mb={3}>
          <SearchInput
            multiple={false}
            type={"email"}
            placeholder={"Search for email"}
            selectedItems={filter?.email || ""}
            setSelectedItems={searchHandler}
          />
          <Stack direction={"row"} gap={"0 10px"}>
            {!!filter?.email && (
              <Chip
                variant="squared-secondary"
                sx={{
                  mt: "10px"
                }}
                tabIndex={-1}
                label={filter.email}
                onDelete={() =>
                  searchHandlerFilters({
                    email: ""
                  })
                }
              />
            )}
            {!!filter?.firstName && (
              <Chip
                variant="squared-secondary"
                sx={{
                  mt: "10px"
                }}
                tabIndex={-1}
                label={filter.firstName}
                onDelete={() =>
                  searchHandlerFilters({
                    firstName: ""
                  })
                }
              />
            )}
            {!!filter?.lastName && (
              <Chip
                variant="squared-secondary"
                sx={{
                  mt: "10px"
                }}
                tabIndex={-1}
                label={filter.lastName}
                onDelete={() =>
                  searchHandlerFilters({
                    lastName: ""
                  })
                }
              />
            )}
            {filter?.disabled !== recipientStatus[0].value && (
              <Chip
                variant="squared-secondary"
                sx={{
                  mt: "10px"
                }}
                tabIndex={-1}
                label={
                  _find(recipientStatus, { value: filter?.disabled })?.label ||
                  ""
                }
                onDelete={() =>
                  searchHandlerFilters({
                    disabled: recipientStatus[0].value
                  })
                }
              />
            )}
            {!!filter?.tags?.length &&
              filter.tags.map((item) => (
                <Chip
                  key={item.uuid}
                  variant="squared-secondary"
                  sx={{
                    mt: "10px"
                  }}
                  tabIndex={-1}
                  label={item.name || ""}
                  onDelete={() =>
                    searchHandlerFilters({
                      tags: filter.tags.filter((el) => el.uuid !== item.uuid)
                    })
                  }
                />
              ))}
          </Stack>
        </Box>

        <InfinityTable
          headCells={headCells}
          loading={loading}
          data={recipients?.customers || []}
          total={recipients?.pagination?.totalResults || 0}
          order={order}
          orderBy={orderBy}
          createSortHandler={sortHandler}
          onCheckedItems={onCheckedItems}
          checkedItems={checkedItems}
          onRowClick={onOpenForm}
          onLoadMore={onLoadMore}
          toolbarTitle={`List ${
            recipients?.pagination?.totalResults
              ? "(" + recipients?.pagination?.totalResults + ")"
              : ""
          }`}
          ToolbarDescription={
            <ToolbarDescription
              recipients={recipients}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
            />
          }
          toolbarSpacing={3}
          toolbarActions={<ToolbarActions options={menuOptions} />}
        />
      </Box>
      <RecipientForm
        recipientUuid={recipient?.uuid}
        open={openForm}
        onClose={onCloseForm}
      />
    </div>
  );
};

export default Recipients;

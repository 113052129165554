import React, { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { allCategories, palette } from "settings";

import _difference from "lodash/difference";
import { handleMetaEnter } from "helpers/eventHandlers";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import InputAdornment from "@mui/material/InputAdornment";

export const BrandSearchAutocomplete = ({
  control,
  watch,
  formState: { errors },
  label = "",
  fieldName = "brand-search",
  placeholder = "Search brand",
  rules = {},
  handleChangeCategory,
  handleChangeName,
  selectedCategories
}) => {
  const value = watch(fieldName) || null;
  const wrapperRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState(allCategories);

  useEffect(() => {
    setOptions(_difference(allCategories, selectedCategories));
  }, [selectedCategories]);

  const handleCloseAutocomplete = (event, reason) => {
    if (
      reason === "selectOption" ||
      reason === "blur" ||
      reason === "toggleInput"
    ) {
      return;
    }
    setIsOpen(false);
  };

  const handleBlur = (e) => {
    if (
      e.target?.id !== fieldName &&
      !e.target?.classList?.contains("popper-autocomplete") &&
      !e.target?.closest(".popper-autocomplete")
    ) {
      setIsOpen(false);
    }
  };

  const handleInputChange = (_event, newValue) => {
    handleChangeName(newValue);
    setInputValue(newValue);
  };

  const handleCategoryChange = (option) => {
    handleChangeCategory(option);
    setIsOpen(false);
  };

  const handleEnterClick = () => setIsOpen(false);

  const CategoryOption = ({ option }) => (
    <Stack direction={"row"} alignItems={"center"} gap={2}>
      <Chip
        icon={
          <svg
            width={12}
            height={12}
            style={{
              minWidth: 12,
              color: palette.primary.main,
              marginLeft: 8,
              marginRight: 0
            }}
          >
            <use xlinkHref={`#${option.name}`} />
          </svg>
        }
        clickable
        variant="squared-secondary"
        tabIndex={-1}
        label={option.text}
        onClick={() => handleCategoryChange(option)}
        onDelete={() => false}
        sx={{
          "& .MuiChip-label": {
            padding: "0 8px"
          }
        }}
        deleteIcon={
          <svg
            width={16}
            height={16}
            style={{
              width: 12,
              marginRight: 8,
              marginLeft: 0,
              color: palette.grayscale[800]
            }}
          >
            <use xlinkHref={"#small-plus"} />
          </svg>
        }
      />
    </Stack>
  );

  return (
    <FormControl error={!!errors[fieldName]} sx={{ width: "100%" }}>
      <Controller
        name={fieldName}
        rules={rules}
        control={control}
        render={({ field: { onChange } }) => {
          return (
            <ClickAwayListener onClickAway={handleBlur}>
              <Autocomplete
                open={isOpen}
                autoHighlight={false}
                onOpen={() => setIsOpen(true)}
                onClose={handleCloseAutocomplete}
                ListboxProps={{
                  role: "list-box",
                  style: { maxHeight: "auto" }
                }}
                clearOnBlur={false}
                autoSelect={false}
                onChange={(_event, item) => {
                  onChange(item);
                  setIsOpen(false);
                }}
                sx={{
                  "& .MuiFilledInput-root": {
                    paddingRight: "16px !important"
                  }
                }}
                value={value}
                getOptionLabel={(o) => o.name || o.text}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                multiple={false}
                forcePopupIcon={false}
                id={fieldName}
                options={options}
                popupIcon={
                  <svg width={24} height={24} stroke={palette.primary.main}>
                    <use href="#cheveron-down" />
                  </svg>
                }
                PopperComponent={(props) => {
                  return (
                    <Popper
                      {...props}
                      sx={{
                        "& .MuiPaper-root": {
                          marginTop: "6px",
                          backgroundColor: palette.grayscale[100]
                        }
                      }}
                    >
                      <Box className={"popper-autocomplete"} ref={wrapperRef}>
                        <Paper sx={{ padding: 3 }}>
                          <Stack gap={2}>
                            <Typography
                              variant={"body1"}
                              fontWeight={600}
                              color={"grayscale.800"}
                            >
                              Select category or search by name
                            </Typography>
                            <Stack gap={2} direction={"row"} flexWrap={"wrap"}>
                              {options.map((el) => (
                                <CategoryOption key={el.id} option={el} />
                              ))}
                            </Stack>
                          </Stack>
                        </Paper>
                      </Box>
                    </Popper>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={"filled"}
                    label={label}
                    className={"filled-rounded"}
                    placeholder={placeholder}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      startAdornment: (
                        <>
                          <InputAdornment
                            position="start"
                            sx={{
                              width: "auto",
                              height: "auto",
                              marginTop: "0 !important"
                            }}
                          >
                            <svg
                              width={24}
                              height={24}
                              stroke={palette.grayscale["800"]}
                            >
                              <use xlinkHref={"#search-new"} />
                            </svg>
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ cursor: "pointer" }}
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          <svg
                            width={24}
                            height={24}
                            stroke={palette.grayscale[400]}
                            style={{ minWidth: "24px" }}
                          >
                            <use href="#filter" />
                          </svg>
                        </InputAdornment>
                      )
                    }}
                    onKeyDown={(event) =>
                      handleMetaEnter(event, handleEnterClick)
                    }
                  />
                )}
              />
            </ClickAwayListener>
          );
        }}
      />
      {errors[fieldName] && (
        <FormHelperText>{errors[fieldName].message}</FormHelperText>
      )}
    </FormControl>
  );
};

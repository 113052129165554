import React, { useEffect } from "react";
import _forIn from "lodash/forIn";
import _isEqual from "lodash/isEqual";
import _includes from "lodash/includes";
import _filter from "lodash/filter";
import { useForm } from "react-hook-form";

import { manyCharacters, midnightBlue, palette } from "settings";
import {
  campaignsStatus,
  defaultFilters
} from "components/pages/portal/campaigns";
import { FieldRangeDatePicker, FieldText } from "components/fields";

import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";

export const FilterCampaignsModal = ({
  open,
  onClose,
  onFilter,
  filterParams
}) => {
  const methods = useForm();
  const {
    handleSubmit,
    formState: { dirtyFields },
    watch,
    reset,
    setValue
  } = methods;
  const status = watch("status");

  const isDirtyForm =
    Object.keys(dirtyFields)?.length > 0 ||
    !_isEqual(defaultFilters, filterParams);

  useEffect(() => {
    if (filterParams && open) {
      _forIn(filterParams, function (value, key) {
        setValue(key, value || "", {
          shouldDirty: true
        });
      });
    }
  }, [filterParams, open]);

  const onSubmit = handleSubmit((data) => {
    onFilter(data);
  });

  const clearAllHandler = () => {
    onFilter(defaultFilters);
    reset(defaultFilters);
  };

  const handleChangeStatus = ({ target: { value } }) => {
    setValue(
      "status",
      _includes(status, value)
        ? _filter(status, (o) => o !== value)
        : [...status, value]
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"md"}
      scroll={"paper"}
      sx={{
        ".MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.5)"
        },
        ".MuiPaper-root": {
          margin: { xs: 0, sm: 4 },
          borderRadius: { xs: 0, sm: 2 },
          minHeight: { xs: "100vh", sm: "auto" },
          width: { xs: "100%", sm: "auto" }
        }
      }}
    >
      <DialogTitle>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <svg
              width={24}
              height={24}
              stroke={palette.primary.main}
              style={{ minWidth: "24px" }}
            >
              <use href="#filter-new" />
            </svg>
            <Typography
              variant={"subLead"}
              fontWeight={700}
              sx={{ ml: "12px" }}
              color={midnightBlue}
            >
              Filter
            </Typography>
          </Stack>

          <IconButton size={"small"} onClick={onClose}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use href="#close" />
            </svg>
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <form
          style={{ paddingTop: "12px" }}
          onSubmit={onSubmit}
          noValidate
          autoComplete="off"
        >
          <Stack gap={"16px"}>
            <Box>
              <Typography
                variant={"body1"}
                color={palette.grayscale["800"]}
                fontWeight={700}
                mb={"8px"}
              >
                Campaign dates (start and redemption)
              </Typography>
              <FieldRangeDatePicker
                {...methods}
                startFieldName={"startEndRange.start"}
                endFieldName={"startEndRange.end"}
              />
            </Box>
            <Box>
              <Typography
                variant={"body1"}
                color={palette.grayscale["800"]}
                fontWeight={700}
                mb={"8px"}
              >
                Gift card value
              </Typography>
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={"16px"}
                flexWrap={{ xs: "wrap", md: "nowrap" }}
              >
                <FieldText
                  {...methods}
                  fieldName={"codeValueRange.start"}
                  label={"From"}
                  type={"number"}
                  validation={{
                    maxLength: {
                      value: 20,
                      message: manyCharacters
                    }
                  }}
                />
                <FieldText
                  {...methods}
                  fieldName={"codeValueRange.end"}
                  label={"To"}
                  type={"number"}
                  validation={{
                    maxLength: {
                      value: 20,
                      message: manyCharacters
                    }
                  }}
                />
              </Stack>
            </Box>
            <Box>
              <Typography
                variant={"body1"}
                color={palette.grayscale["800"]}
                fontWeight={700}
                mb={"8px"}
              >
                Campaign amount
              </Typography>
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={"16px"}
                flexWrap={{ xs: "wrap", md: "nowrap" }}
              >
                <FieldText
                  {...methods}
                  fieldName={"campaignTotalRange.start"}
                  label={"From"}
                  type={"number"}
                  validation={{
                    maxLength: {
                      value: 20,
                      message: manyCharacters
                    }
                  }}
                />
                <FieldText
                  {...methods}
                  fieldName={"campaignTotalRange.end"}
                  label={"To"}
                  type={"number"}
                  validation={{
                    maxLength: {
                      value: 20,
                      message: manyCharacters
                    }
                  }}
                />
              </Stack>
            </Box>
            <Box>
              <Typography
                variant={"body1"}
                color={palette.grayscale["800"]}
                fontWeight={700}
                mb={"8px"}
              >
                Status
              </Typography>

              <FormControl
                component="fieldset"
                sx={{
                  ".MuiFormControlLabel-root": {
                    minWidth: "33.333%",
                    mr: 0,
                    pr: 2
                  }
                }}
              >
                <FormGroup row>
                  {campaignsStatus.map(({ value, label }) => (
                    <FormControlLabel
                      key={value}
                      control={
                        <Checkbox
                          checked={_includes(status, value)}
                          onChange={handleChangeStatus}
                          value={value}
                          name={"status"}
                        />
                      }
                      label={label}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Box>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: 3, pt: 1, flexDirection: "column", gap: "16px" }}>
        {isDirtyForm && (
          <Button variant={"text"} fontWeight={900} onClick={clearAllHandler}>
            Clear all
          </Button>
        )}
        <Button type={"submit"} fullWidth onClick={onSubmit}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import React from "react";
import InputMask from "react-input-mask";
import { Controller } from "react-hook-form";
import { fieldCannotBeEmpty, phoneIsNotCorrect } from "settings";

import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";

export const validatePhone = (v) => {
  const clearedPhone = v?.replace(/[ \-()+_]/g, "");
  if (!clearedPhone?.length) return true;
  if (clearedPhone?.length >= 11) {
    return true;
  }
  return phoneIsNotCorrect;
};

export const FieldPhone = ({
  control,
  formState: { errors },
  label = "Mobile number",
  fieldName = "phone_number",
  placeholder = "Mobile number",
  showOptional = true,
  validation = {
    required: fieldCannotBeEmpty,
    validate: {
      check: validatePhone
    }
  },
  size = "medium"
}) => (
  <FormControl variant="filled" error={!!errors[fieldName]} size={size}>
    <InputLabel sx={{ mb: "6px" }}>
      {label}
      {!validation?.required && showOptional && (
        <Typography component={"span"} color={"grayscale.400"}>
          {" "}
          (optional)
        </Typography>
      )}
    </InputLabel>
    <Controller
      render={({ field: { onChange, value, name, ref } }) => (
        <InputMask mask="+1 (999) 999-9999" onChange={onChange} value={value}>
          <FilledInput
            name={name}
            fullWidth
            disableUnderline
            placeholder={placeholder}
            inputRef={ref}
          />
        </InputMask>
      )}
      name={fieldName}
      control={control}
      rules={validation}
    />
    {errors[fieldName] && (
      <FormHelperText>{errors[fieldName].message}</FormHelperText>
    )}
  </FormControl>
);

import { asyncApi } from "helpers/api";

export const captchaVerify = async (executeRecaptcha, action) => {
  if (window.location.hostname.includes("localhost")) return true;

  try {
    const token = await executeRecaptcha(action);
    if (!token) return false;

    const captchaVerify = await asyncApi({
      url: "auth/captcha",
      method: "POST",
      prefix: "/commercefront-api/",
      data: { token }
    });
    return captchaVerify?.success || false;
  } catch (e) {
    console.log(e, "err");
    return false;
  }
};

import React, { useState } from "react";
import { palette } from "settings";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const ControlledMenu = ({
  fieldName = "",
  label = "",
  options = [],
  value = null,
  onChange
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event, index) => {
    onChange(options[index]);
    setAnchorEl(null);
  };

  return (
    <div>
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={1}
        onClick={handleClick}
        sx={{
          padding: "8px 12px",
          cursor: "pointer",
          background: palette.grayscale["100"],
          border: `1px solid ${palette.grayscale["200"]}`,
          borderRadius: "4px"
        }}
      >
        <Typography variant={"smallTitle"} color={"primary"} fontWeight={600}>
          {label ? label : value?.label}
        </Typography>
        <ArrowForwardIosIcon
          fontSize={"14"}
          color={"primary"}
          sx={{
            transform: `rotate(${open ? "-" : ""}90deg)`
          }}
        />
      </Stack>
      <StyledMenu
        id={fieldName}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            selected={option.value === value?.value}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option.label}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    background: palette.grayscale["100"],
    border: `1px solid ${palette.grayscale["200"]}`,
    borderRadius: 4,
    marginTop: 8,
    minWidth: 180,
    "& .MuiMenu-list": {
      padding: 0
    },
    "& .MuiMenuItem-root": {
      color: theme.palette.purple[300],
      "&:active, &.Mui-selected, &:hover": {
        backgroundColor: "transparent",
        color: theme.palette.purple[500]
      }
    }
  }
}));

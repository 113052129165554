import React from "react";
import some from "lodash/some";
import {
  Percentage,
  BooleanStatus,
  ChipStatus,
  Status,
  CodeStatus,
  DateCell,
  RoleCell,
  DisabledStatus,
  Options
} from "./tableCellTypes";

import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";

export const RenderCell = ({
  rowData,
  data = "",
  headCell,
  checkedItems,
  onMoreOptions,
  onCheckedItems,
  onAction
}) => {
  const { type, children } = headCell;

  switch (type) {
    case "money":
      return (
        <>
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD"
          }).format(data)}
        </>
      );
    case "cent":
      return (
        <>
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD"
          }).format((data / 100).toFixed(2))}
        </>
      );
    case "percentage":
      return <Percentage data={data} />;
    case "boolean-status":
      return <BooleanStatus data={data} />;
    case "disabled-status":
      return <DisabledStatus data={data} />;
    case "status":
      return <Status data={data} />;
    case "code-status":
      return <CodeStatus data={data} />;
    case "chip-status":
      return <ChipStatus data={data} />;
    case "node":
      return (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          {children}
        </Stack>
      );
    case "action":
      return (
        <Stack
          direction={"row"}
          sx={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            onAction(rowData);
          }}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          {children}
        </Stack>
      );
    case "date":
      return (
        <DateCell
          data={data}
          dateFormat={headCell.dateFormat || "MM/dd/yyyy @ HH:mm:ss a"}
        />
      );
    case "role":
      return <RoleCell data={data} />;
    case "tag":
      return (
        <Stack direction={"row"} flexWrap={"wrap"} gap={"8px"}>
          {data.length
            ? data.map((el) => (
                <Chip
                  key={el?.clientTagUuid}
                  variant="squared-secondary"
                  tabIndex={-1}
                  label={el?.name || "-"}
                />
              ))
            : "-"}
        </Stack>
      );
    case "checkbox":
      const checked =
        checkedItems.all ||
        some(checkedItems?.items, rowData) ||
        some(checkedItems?.items, ["uuid", rowData.uuid]);
      return (
        <Box
          onClick={(e) => {
            e.stopPropagation();
            onCheckedItems(rowData);
          }}
        >
          <Checkbox checked={checked} />
        </Box>
      );
    case "options":
      if (
        rowData?.codeStatus?.toLowerCase() === "void" ||
        rowData?.codeStatus?.toLowerCase() === "redeemed"
      ) {
        return <></>;
      }
      return (
        <Options
          onClick={(e) => onMoreOptions(e, rowData)}
          data={rowData?.id}
          sx={{
            color: (theme) => theme.palette.grayscale[800]
          }}
        />
      );
    case "array-length":
      return data.length || 0;
    default:
      return <>{data || "-"}</>;
  }
};

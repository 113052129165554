export const palette = {
  primary: {
    main: "#5020F7"
  },
  secondary: {
    main: "#01D6A2"
  },
  gray: {
    200: "#DFDFDF",
    contrastText: "#fff"
  },
  grayscale: {
    100: "#F5F5F5",
    200: "#D9D9D9",
    300: "#909090",
    400: "#656565",
    500: "#404040",
    600: "#2C2C2C",
    700: "#202020",
    800: "#181818",
    contrastText: "#fff"
  },
  purple: {
    100: "#DCD2FD",
    200: "#B9A6FC",
    300: "#9679FA",
    400: "#734DF9",
    500: "#5020F7",
    600: "#4620CC",
    700: "#3C1FA0",
    800: "#321F75",
    900: "#281E49",
    main: "#5020F7",
    contrastText: "#fff"
  },
  seaGreen: {
    100: "#CCFAE8",
    200: "#99F5D1",
    300: "#66F0BB",
    400: "#33EBA4",
    500: "#01D6A2",
    600: "#06BE77",
    700: "#0C9661",
    800: "#126E4A",
    900: "#184634",
    main: "#0C9661",
    primary: "#01D6A2",
    contrastText: "#fff"
  },
  vibrantRed: {
    100: "#FAD2D7",
    200: "#F5A5AF",
    300: "#F17786",
    400: "#EC4A5E",
    500: "#E71D36",
    600: "#B9172B",
    700: "#8B1120",
    800: "#5C0C16",
    900: "#2E060B",
    main: "#EC4A5E",
    primary: "#E71D36",
    contrastText: "#fff"
  },
  vibrantOrange: {
    100: "#FEDCD6",
    200: "#FEB9AC",
    300: "#FD9783",
    400: "#FD7459",
    500: "#FC5130",
    600: "#CA4126",
    700: "#97311D",
    800: "#652013",
    900: "#32100A",
    contrastText: "#fff"
  },
  vibrantYellow: {
    100: "#FEF0D2",
    200: "#FDE2A5",
    300: "#FBD378",
    400: "#FAC54B",
    500: "#F9B61E",
    600: "#C79218",
    700: "#956D12",
    800: "#64490C",
    900: "#322406",
    contrastText: "#fff"
  },
  vibrantBlue: {
    100: "#CCCCF8",
    200: "#9999F1",
    300: "#6666EB",
    400: "#3333E4",
    500: "#0000DD",
    600: "#0000B1",
    700: "#000085",
    800: "#000058",
    900: "#00002C",
    main: "#6666EB",
    primary: "#0000DD",
    contrastText: "#fff"
  }
};

export const redAlert = "#FF005C";
export const greenAlert = "#08BC2F";

export const royalBlue = "#01005E";
export const midnightBlue = "#000035";
export const midnightPurple = "#2B0046";
export const lightGray = "#F9F9FE";
export const lightPurple = "#F0F3FB";

import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "components/AuthProvider";
import { hasAccessByRole, midnightBlue, palette, theme } from "settings";
import { displayFullMoney } from "helpers/money";

import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export const HeaderDashboard = ({ openBurger, setOpenBurger }) => {
  const { session, balance, isApiEnabled, isCampaignEnabled } = useAuth();
  const navigate = useNavigate();
  const isTabletOrSmall = useMediaQuery(theme.breakpoints.down("md"));
  const hideBalance = (!isApiEnabled && isCampaignEnabled) && (session?.kycStatus !== "ACCEPTED");

  const onFundClick = () => {
    setOpenBurger(false);
    navigate(null, { state: { showAddFundsBar: true } });
  };

  return (
    <Stack
      component={"header"}
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: (theme) => (openBurger ? theme.zIndex.modal + 2 : 10),
        background: midnightBlue,
        gap: 2,
        flexWrap: { xs: "wrap", md: "nowrap" },
        p: { xs: "16px", md: "35px 24px" },
        height: {
          xs:
            openBurger &&
              (session?.kycStatus === "ACCEPTED")
              ? "128px"
              : "70px",
          md: 100
        }
      }}
    >
      <Link to="/" title={"Raise"}>
        <svg width={94} height={30} fill={"#fff"}>
          <use xlinkHref={"#logo"} />
        </svg>
      </Link>

      {!hideBalance && (
        <Box
          sx={{
            display: { xs: openBurger ? "block" : "none", md: "block" },
            order: { xs: 3, md: 0 },
            flexBasis: { xs: "100%", md: "auto" }
          }}
        >
          <Chip
            label={
              <Stack direction={"row"} alignItems={"center"}>
                {balance?.loading ? (
                  <CircularProgress size={24} />
                ) : (
                  displayFullMoney(balance?.data?.availableBalance || 0)
                )}
              </Stack>
            }
            onDelete={
              hasAccessByRole(session?.roles, [
                "CLIENT_OWNER",
                "CLIENT_ADMIN",
                "CLIENT_MANAGER"
              ])
                ? onFundClick
                : null
            }
            sx={{
              color: midnightBlue,
              background: palette.gray.contrastText,
              justifyContent: "space-between",
              borderRadius: "36px",
              height: { xs: 40, md: 52 },
              padding: { xs: "4px 0 4px 1px", md: "0 8px" },
              minWidth: 150,
              width: { xs: "100%", md: "auto" },
              fontWeight: 600,
              fontSize: 18,
              "& .MuiChip-label": {
                padding: "0 14px",
                width: "100%"
              }
            }}
            icon={
              <Tooltip
                leaveTouchDelay={3000}
                enterTouchDelay={0}
                disableFocusListener
                componentsProps={{
                  tooltip: { sx: { maxWidth: 280, minWidth: 280 } }
                }}
                title={
                  balance?.loading ? (
                    ""
                  ) : (
                    <>
                      <Typography
                        variant={"h4"}
                        color={"white"}
                        fontWeight={600}
                        mb={"4px"}
                      >
                        {displayFullMoney(balance?.data?.availableBalance || 0)}
                      </Typography>
                      <Stack
                        mb={"4px"}
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Typography variant={"smallTitle"} color={"white"}>
                          Pending
                        </Typography>
                        <Typography
                          variant={"smallTitle"}
                          color={"white"}
                          fontWeight={700}
                        >
                          {displayFullMoney(balance?.data?.pendingBalance || 0)}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Typography variant={"smallTitle"} color={"white"}>
                          Reserved
                        </Typography>
                        <Typography
                          variant={"smallTitle"}
                          color={"white"}
                          fontWeight={700}
                        >
                          {displayFullMoney(
                            balance?.data?.reservedBalance || 0
                          )}
                        </Typography>
                      </Stack>
                    </>
                  )
                }
                arrow
                placement="bottom"
              >
                <IconButton
                  size={"small"}
                  sx={{
                    width: { xs: 32, md: 36 },
                    height: { xs: 32, md: 36 },
                    backgroundColor: palette.purple[100],
                    "&:hover, &:focus": {
                      backgroundColor: palette.purple[100]
                    }
                  }}
                >
                  <svg width={24} height={24} color={palette.primary.main}>
                    <use href="#info" />
                  </svg>
                </IconButton>
              </Tooltip>
            }
            deleteIcon={
              <svg
                width={32}
                height={32}
                style={{ minWidth: 32, color: midnightBlue }}
              >
                <use xlinkHref={"#plus-ico"} />
              </svg>
            }
            variant="outlined"
          />
        </Box>
      )}

      {isTabletOrSmall && (
        <IconButton
          edge="start"
          aria-label="open burger"
          onClick={() => setOpenBurger(!openBurger)}
          sx={{
            padding: 1
          }}
        >
          <svg width={24} height={24} stroke={"#fff"}>
            <use xlinkHref={openBurger ? "#close" : "#burger"} />
          </svg>
        </IconButton>
      )}
    </Stack>
  );
};

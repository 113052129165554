import React from "react";
import { useFormContext } from "react-hook-form";
import { CustomTabPanel } from "components/shared";
import { FieldText } from "components/fields";
import { fieldCannotBeEmpty, manyCharacters } from "settings";

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export const Step1 = ({ activeStep }) => {
  const methods = useFormContext();
  const { watch } = methods;
  const name = watch("name");

  return (
    <CustomTabPanel value={activeStep} index={0}>
      <Grid py={4} px={{ xs: 2, md: 4 }} container sx={{ overflow: "hidden" }}>
        <Grid item xs={12} md={7}>
          <Typography
            variant={"subLead"}
            fontWeight={700}
            mb={1}
            color={"vibrantBlue.900"}
          >
            Enter name of the campaign
          </Typography>
          <FieldText
            label={""}
            {...methods}
            validation={{
              required: fieldCannotBeEmpty,
              maxLength: {
                value: 40,
                message: manyCharacters
              }
            }}
            fieldName={"name"}
            endAdornment={
              <Stack
                direction="row"
                alignItems={"center"}
                sx={{ padding: "38px 0 16px 8px" }}
              >
                <Typography variant={"leadCaption"} color={"grayscale.400"}>
                  {name?.length || 0}
                </Typography>
                <Typography variant={"leadCaption"} color={"grayscale.400"}>
                  /40
                </Typography>
              </Stack>
            }
          />
        </Grid>
      </Grid>
    </CustomTabPanel>
  );
};

import "babel-polyfill";
import React, { useEffect, useMemo } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate
} from "react-router-dom";

import map from "lodash/map";
import some from "lodash/some";
import filter from "lodash/filter";

import "../assets/fonts/AvertaStd.css";
import "./date-picker.css";

import {
  DASHBOARD_PAGE,
  KYC_PAGE,
  LOGIN_PAGE,
  routesWithTemplate
} from "settings";
import authRoutes from "settings/routes/auth";
import { getCookie, removeCookie, setCookie } from "helpers/cookies";
import { LayoutDefault } from "layouts/Default";
import { useAuth } from "components/AuthProvider";
import { NotFound } from "./404";

const App = () => {
  const { token, session, kyc, isCampaignEnabled, isApiEnabled } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  //103542 - story id to this fix
  const isPortalClient =
    session?.clientUuid === "d7863e97-9b6b-41fb-bc28-189320017961" ||
    !isApiEnabled;

  const filteredRoutes = useMemo(() => {
    if (session) {
      return map(routesWithTemplate, ({ routes, ...rest }) => ({
        ...rest,
        routes: filter(
          routes,
          (route) =>
            !some(session.roles, (o) =>
              some(route.deniedRoles, (deniedRole) => deniedRole === o)
            )
        )
      }));
    }
    return routesWithTemplate;
  }, [session]);

  useEffect(() => {
    if (pathname.indexOf("/business") !== 0) {
      navigate(LOGIN_PAGE);
    }
    if (!token && !some(map(authRoutes, "path"), (o) => pathname.includes(o))) {
      setCookie("redirect", pathname);
    }
    const redirect = getCookie("redirect");
    if (token && redirect) {
      navigate(redirect);
      removeCookie("redirect");
    }
  }, [pathname, token]);

  return (
    <Routes>
      {filteredRoutes.map((elem) => {
        const { layout: Layout = LayoutDefault, routes, id } = elem;
        return (
          <Route key={id} element={<Layout />}>
            {routes.map((route) => {
              const {
                id,
                path,
                element,
                isPublic,
                free,
                onlyApiClient = false,
                onlyCampaignAPIEnabled = false,
                onlyVerifiedUser = true
              } = route;

              if (token && isPortalClient && onlyApiClient) {
                return <Route key={id} path="*" element={<NotFound />} />;
              }
              if (token && onlyCampaignAPIEnabled && !isCampaignEnabled) {
                return <Route key={id} path="*" element={<NotFound />} />;
              }

              if (isPublic && token && !free) {
                return (
                  <Route
                    key={id}
                    path={path}
                    element={<Navigate to={DASHBOARD_PAGE} />}
                  />
                );
              }
              if (
                token &&
                kyc &&
                kyc.status !== "ACCEPTED" &&
                onlyVerifiedUser
              ) {
                return (
                  <Route
                    key={id}
                    path={path}
                    element={<Navigate to={KYC_PAGE} />}
                  />
                );
              }
              if (!token && !isPublic && !free) {
                return (
                  <Route
                    key={id}
                    path={path}
                    element={<Navigate to={LOGIN_PAGE} />}
                  />
                );
              }
              return <Route key={id} path={path} element={element} />;
            })}
          </Route>
        );
      })}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;

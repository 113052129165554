import React, { useMemo } from "react";
import at from "lodash/at";
import filter from "lodash/filter";
import sumBy from "lodash/sumBy";
import { RenderCell } from "./RenderCell";
import { palette } from "settings";

import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import Stack from "@mui/material/Stack";

export const TableBodyCells = ({
  headCells,
  data,
  sticky,
  checkedItems,
  onCheckedItems,
  onMoreOptions,
  onAction
}) => {
  return headCells.map((headCell) => (
    <TableCell
      key={headCell.id}
      align={headCell.align || "left"}
      sx={[
        {
          width: headCell.width || "auto",
          minWidth: headCell.minWidth || headCell.width || "auto",
          maxWidth: headCell.width || "none"
        },
        sticky && {
          border: "none"
        }
      ]}
    >
      <Typography
        component={"div"}
        className={headCell.lineBreak ? "" : "ellipsis"}
        variant={"smallTitle"}
        color={"grayscale.800"}
      >
        <RenderCell
          data={at(data, headCell.id)[0]}
          rowData={data}
          headCell={headCell}
          onMoreOptions={onMoreOptions}
          checkedItems={checkedItems}
          onCheckedItems={onCheckedItems}
          onAction={onAction}
        />
      </Typography>
    </TableCell>
  ));
};

export const TableBodyRow = ({
  headCells,
  data,
  checkedItems,
  onCheckedItems,
  onMoreOptions,
  onAction
}) => {
  const filteredHeadCells = useMemo(() => {
    return {
      leftCells: filter(headCells, (o) => o.sticky === "left"),
      cells: filter(headCells, (o) => !o.sticky),
      rightCells: filter(headCells, (o) => o.sticky === "right")
    };
  }, [headCells]);

  return (
    <>
      {!!filteredHeadCells.leftCells.length && (
        <TableCell
          sx={{
            left: 0,
            position: "sticky",
            background: "#fff",
            zIndex: 1,
            padding: 0,
            borderBottom: "none",
            width: sumBy(filteredHeadCells.leftCells, "width")
          }}
        >
          <Stack
            justifyContent={"center"}
            sx={{
              borderBottom: `1px solid ${palette.grayscale["200"]}`,
              boxShadow: `1px 0px 0px 0px ${palette.grayscale["200"]}`,
              height: "100%",
              "& td": {
                border: "none"
              }
            }}
          >
            <table width={"100%"}>
              <tbody>
                <tr>
                  <TableBodyCells
                    headCells={filteredHeadCells.leftCells}
                    data={data}
                    onMoreOptions={onMoreOptions}
                    checkedItems={checkedItems}
                    onCheckedItems={onCheckedItems}
                    onAction={onAction}
                    sticky
                  />
                </tr>
              </tbody>
            </table>
          </Stack>
        </TableCell>
      )}

      <TableBodyCells
        headCells={filteredHeadCells.cells}
        data={data}
        onMoreOptions={onMoreOptions}
        checkedItems={checkedItems}
        onCheckedItems={onCheckedItems}
        onAction={onAction}
      />

      {!!filteredHeadCells.rightCells.length && (
        <TableCell
          sx={{
            right: 0,
            position: "sticky",
            background: "#fff",
            zIndex: 1,
            padding: 0,
            borderBottom: "none",
            width: sumBy(filteredHeadCells.rightCells, "width")
          }}
        >
          <Stack
            justifyContent={"center"}
            sx={{
              borderBottom: `1px solid ${palette.grayscale["200"]}`,
              boxShadow: `-1px 0px 0px 0px ${palette.grayscale["200"]}`,
              height: "100%",
              "& td": {
                border: "none"
              }
            }}
          >
            <table width={"100%"}>
              <tbody>
                <tr>
                  <TableBodyCells
                    onMoreOptions={onMoreOptions}
                    headCells={filteredHeadCells.rightCells}
                    data={data}
                    checkedItems={checkedItems}
                    onCheckedItems={onCheckedItems}
                    onAction={onAction}
                    sticky
                  />
                </tr>
              </tbody>
            </table>
          </Stack>
        </TableCell>
      )}
    </>
  );
};

import { subDays } from "date-fns";
import find from "lodash/find";
import { formatToUTC } from "helpers/queryStrings";

export const headCells = [
  {
    id: "transaction.buyer.email",
    label: "Email",
    width: 170
  },
  {
    id: "transaction.buyer.id",
    label: "Customer ID",
    width: 320
  },
  {
    id: "transaction.id",
    label: "Transaction ID",
    width: 200
  },
  {
    id: "transaction.createdAt",
    label: "Created Date",
    sortKey: "created_at",
    sortable: true,
    width: 140,
    type: "date",
    lineBreak: true
  },
  {
    id: "transaction.updatedAt",
    label: "Updated Date",
    sortKey: "updated_at",
    sortable: true,
    width: 140,
    type: "date",
    lineBreak: true
  },
  {
    id: "transaction.status",
    label: "Status",
    type: "status",
    width: 100
  },
  {
    id: "transaction.value",
    label: "Order Value",
    type: "cent",
    width: 100
  }
];

export const DEFAULT_ORDER = "desc";
export const DEFAULT_ORDER_BY = "transaction.createdAt";

export const defaultFilters = {
  filter: {
    date_range: {
      created_at_from: subDays(new Date(), 30),
      created_at_to: new Date()
    }
  },
  pagination: {
    pageLimit: 20,
    page: 0
  }
};

export const defaultTransactionsState = {
  pagination: {},
  transaction_id: []
};

export const transactionsStatus = [
  // {
  //   value: 0,
  //   label: "NOT_SPECIFIED"
  // },
  {
    value: 1,
    label: "FAILURE"
  },
  {
    value: 2,
    label: "SUCCESS"
  },
  {
    value: 3,
    label: "REFUND_PENDING"
  },
  {
    value: 4,
    label: "REFUNDED"
  },
  {
    value: 5,
    label: "DISPUTED"
  }
];

export const collectFilter = (
  filter,
  order = DEFAULT_ORDER,
  orderBy = DEFAULT_ORDER_BY
) => {
  const { brands, ...rest } = filter.filter;
  const start = formatToUTC(rest.date_range.created_at_from);
  const end = formatToUTC(rest.date_range.created_at_to, "end");

  const newFilters = {
    ...filter,
    filter: {
      ...rest,
      date_range: {
        created_at_from: start,
        created_at_to: end
      }
    },
    pagination: {
      ...filter.pagination,
      sortBy: {
        field: find(headCells, ({ id }) => id === orderBy)?.sortKey || orderBy,
        order: order.toUpperCase()
      }
    }
  };
  if (brands?.length)
    newFilters.filter.brand_id = brands?.map(({ uuid }) => uuid);
  return newFilters;
};

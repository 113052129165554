import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";
import {
  CREATE_CAMPAIGN_PAGE,
  fieldCannotBeEmpty,
  midnightBlue,
  palette
} from "settings";
import { deliveryMethodOptions } from "./constant";
import { createApiCall } from "helpers/api";

import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormHelperText from "@mui/material/FormHelperText";

export const DeliveryMethod = ({ open, onClose, duplicateCampaign }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      delivery_method: ""
    },
    shouldUnregister: true
  });
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = methods;
  const [loading, setLoading] = useState(false);

  const onSubmit = handleSubmit(({ delivery_method }) => {
    if (duplicateCampaign) {
      setLoading(true);
      createApiCall({
        method: "POST",
        url: `campaigns/duplicate`,
        data: {
          campaign_uuid: duplicateCampaign.uuid,
          delivery_method
        }
      }).then(({ status, data }) => {
        if (status === 200) {
          enqueueSnackbar(
            `A campaign ${duplicateCampaign.name} was duplicated successfully.`,
            { variant: "success" }
          );
        } else {
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
        }
        setLoading(false);
        onClose();
      });
      return;
    }
    navigate(`${CREATE_CAMPAIGN_PAGE}?delivery_method=${delivery_method}`);
  });

  return (
    <Drawer open={open} onClose={onClose}>
      <Stack sx={{ height: "100%" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            p: {
              xs: "24px 16px",
              sm: 3
            },
            borderBottom: `1px solid ${palette.grayscale["200"]}`
          }}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
            <svg
              width={24}
              height={24}
              color={palette.primary.main}
              style={{ minWidth: 24 }}
            >
              <use xlinkHref={"#limits"} />
            </svg>
            <Typography
              variant={"subLead"}
              fontWeight={700}
              color={"purple.900"}
            >
              Campaign type
            </Typography>
          </Stack>
          <IconButton size={"small"} onClick={onClose}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use href="#close" />
            </svg>
          </IconButton>
        </Stack>
        <Stack
          component={"form"}
          onSubmit={onSubmit}
          noValidate
          sx={{
            p: {
              xs: "24px 16px",
              sm: 3
            },
            flex: "0 1 100%",
            overflowY: "auto",
            height: "100%"
          }}
        >
          <Stack gap={"16px"} paddingBottom={"40px"} sx={{ flexGrow: 1 }}>
            <FormControl error={!!errors["delivery_method"]}>
              <Controller
                control={control}
                name={"delivery_method"}
                rules={{
                  required: fieldCannotBeEmpty
                }}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    sx={{
                      gap: "16px"
                    }}
                  >
                    {deliveryMethodOptions.map(({ value, title, icon }) => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        control={<Radio disableRipple />}
                        label={
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={2}
                            justifyContent={"space-between"}
                          >
                            <svg
                              width={32}
                              height={32}
                              style={{ minWidth: 32 }}
                            >
                              <use xlinkHref={"#circle-plus"} />
                            </svg>
                            <Box
                              sx={{
                                width: "100%"
                              }}
                            >
                              <Typography fontWeight={600}>{title}</Typography>
                            </Box>
                            <svg
                              width={24}
                              height={24}
                              style={{ minWidth: 24 }}
                              color={midnightBlue}
                            >
                              <use xlinkHref={`#${icon}`} />
                            </svg>
                          </Stack>
                        }
                        sx={{
                          p: 3,
                          mx: 0,
                          borderRadius: "8px",
                          border: `1px solid ${
                            field.value === value
                              ? palette.primary.main
                              : palette.grayscale["200"]
                          }`,
                          ".MuiRadio-root": {
                            display: "none"
                          },
                          ".MuiFormControlLabel-label": {
                            width: "100%"
                          }
                        }}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
              {errors["delivery_method"] && (
                <FormHelperText>
                  {errors["delivery_method"].message}
                </FormHelperText>
              )}
            </FormControl>
          </Stack>
          <LoadingButton
            loading={loading}
            fullWidth
            variant={"contained"}
            type={"submit"}
          >
            Confirm
          </LoadingButton>
        </Stack>
      </Stack>
    </Drawer>
  );
};

import React from "react";

import IconButton from "@mui/material/IconButton";

export const Options = ({ onClick, data, sx = {} }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    if (onClick) {
      onClick(e, data);
    }
  };
  return (
    <IconButton size={"small"} onClick={handleClick} sx={sx} disableRipple>
      <svg width={24} height={24} style={{ minWidth: 24 }}>
        <use xlinkHref={"#more_options"} />
      </svg>
    </IconButton>
  );
};

import { subDays } from "date-fns";
import find from "lodash/find";

export const headCells = [
  {
    id: "firstName",
    disablePadding: false,
    label: "First name",
    width: 140
  },
  {
    id: "lastName",
    sortKey: "last_name",
    disablePadding: false,
    label: "Last name",
    sortable: true,
    width: 140
  },
  {
    id: "email",
    disablePadding: false,
    label: "Email",
    width: 230
  },
  {
    id: "phoneNumber",
    disablePadding: false,
    label: "Phone number",
    width: 150
  },
  {
    id: "tags",
    disablePadding: false,
    label: "Tags",
    type: "tag",
    width: 200,
    lineBreak: true
  },
  {
    id: "codeStatus",
    disablePadding: false,
    type: "code-status",
    label: "Code status",
    width: 150
  }
];

export const DEFAULT_ORDER = "asc";
export const DEFAULT_ORDER_BY = "last_name";

export const defaultFilters = {
  filter: {
    date_range: {
      created_at_from: subDays(new Date(), 30),
      created_at_to: new Date()
    }
  },
  pagination: {
    pageLimit: 20,
    page: 0
  }
};

export const defaultRecipientsList = {
  pagination: {
    totalResults: 0
  },
  customers: []
};

export const collectFilter = (
  filter,
  order = DEFAULT_ORDER,
  orderBy = DEFAULT_ORDER_BY
) => {
  return {
    ...filter,
    pagination: {
      ...filter.pagination,
      sortBy: {
        field: find(headCells, ({ id }) => id === orderBy)?.sortKey || orderBy,
        order: order.toUpperCase()
      }
    }
  };
};

import React from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export const CircularBackdrop = ({ open, size = 40, sx = {} }) => {
  return (
    <Backdrop
      transitionDuration={0}
      sx={[
        {
          zIndex: (theme) => theme.zIndex.modal + 1
        },
        sx
      ]}
      open={open}
    >
      <CircularProgress size={size} />
    </Backdrop>
  );
};

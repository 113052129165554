import React, { useCallback, useEffect, useMemo, useState } from "react";
import _debounce from "lodash/debounce";
import _pullAllBy from "lodash/pullAllBy";
import { Controller } from "react-hook-form";
import { useAuth } from "components/AuthProvider";
import { useSnackbar } from "notistack";
import { createApiCall } from "helpers/api";
import { fieldCannotBeEmpty, palette } from "settings";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

const PAGE_LIMIT = 20;

export const FieldTagsAutocomplete = ({
  multiple = true,
  control,
  watch,
  formState: { errors },
  label = "Tags",
  fieldName = "tags",
  placeholder = "",
  rules = {
    required: fieldCannotBeEmpty
  },
  filterSelectedOptions = false
}) => {
  const value = watch(fieldName);
  const { session } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const filteredOptions = useMemo(() => {
    return [..._pullAllBy([...options], value, "clientTagUuid")];
  }, [options, value]);

  useEffect(() => {
    if (session?.clientUuid) {
      getTags();
    }
  }, [session?.clientUuid]);

  const getTags = (name = "") => {
    setLoading(true);
    setOptions([]);
    createApiCall({
      url: `tags/${
        session?.clientUuid
      }?disabled=FALSE&pagination.sortBy.field=created_at&pagination.sortBy.order=DESC&pagination.pageLimit=${PAGE_LIMIT}&pagination.page=0${
        name ? `&name=${name}` : ""
      }`
    }).then(({ status, data }) => {
      if (status === 200) {
        setPage(1);
        setOptions(data.tags);
        setTotal(data.pagination.totalResults);
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  };

  const debounceGetTags = useCallback(_debounce(getTags, 500), []);

  const loadMore = () => {
    setLoading(true);
    createApiCall({
      url: `tags/${
        session?.clientUuid
      }?disabled=FALSE&pagination.sortBy.field=created_at&pagination.sortBy.order=DESC&pagination.pageLimit=${PAGE_LIMIT}&pagination.page=${page}${
        inputValue ? `&name=${inputValue}` : ""
      }`
    }).then(({ status, data }) => {
      if (status === 200) {
        setPage(page + 1);
        setOptions([...options, ...data.tags]);
        setTotal(data.pagination.totalResults);
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  };

  return (
    <FormControl error={!!errors[fieldName]} sx={{ width: "100%" }}>
      <Controller
        name={fieldName}
        rules={rules}
        control={control}
        render={({ field: { onChange } }) => {
          return (
            <Autocomplete
              filterSelectedOptions={filterSelectedOptions}
              loading={loading}
              ListboxProps={{
                role: "list-box", // fixed scroll
                onScroll: (event) => {
                  const listboxNode = event.currentTarget;
                  if (
                    listboxNode.scrollTop + listboxNode.clientHeight ===
                    listboxNode.scrollHeight
                  ) {
                    if (!loading && options.length < total) {
                      loadMore();
                    }
                  }
                }
              }}
              disableCloseOnSelect={multiple}
              onChange={(_event, item) => {
                onChange(item);
              }}
              value={value}
              inputValue={inputValue}
              onInputChange={(_event, newValue) => {
                setLoading(true);
                setOptions([]);
                debounceGetTags(newValue);
                setInputValue(newValue);
              }}
              multiple={multiple}
              limitTags={3}
              id={fieldName}
              options={filteredOptions}
              freeSolo={!inputValue?.length}
              noOptionsText={"No tags found"}
              getOptionLabel={(option) => option.name || "-"}
              popupIcon={
                <svg width={24} height={24} stroke={palette.primary.main}>
                  <use href="#cheveron-down" />
                </svg>
              }
              renderTags={(value, getTagProps) => {
                return value?.map((option, index) => (
                  <Chip
                    key={option}
                    variant="squared-secondary"
                    tabIndex={-1}
                    label={option.name || "-"}
                    {...getTagProps({ index })}
                  />
                ));
              }}
              renderOption={(props, option) => {
                return (
                  <Box
                    {...props}
                    key={option.clientTagUuid || option.uuid}
                    sx={{
                      py: "12px !important"
                    }}
                  >
                    <Chip
                      variant="squared-secondary"
                      tabIndex={-1}
                      label={option.name || "-"}
                    />
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={"filled"}
                  label={label}
                  placeholder={placeholder}
                  sx={{
                    ".MuiFilledInput-root": {
                      paddingTop: "25px",
                      paddingBottom: "8px",
                      "&:before": {
                        visibility: "hidden"
                      }
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
          );
        }}
      />
      {errors[fieldName] && (
        <FormHelperText>{errors[fieldName].message}</FormHelperText>
      )}
    </FormControl>
  );
};

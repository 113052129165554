import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import _find from "lodash/find";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import {
  headCells,
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER,
  defaultFilters,
  defaultCampaignsState,
  campaignsStatus,
  collectFilter
} from "components/pages/portal/campaigns";
import { FilterCampaignsModal } from "components/pages/portal";
import {
  ConfirmModal,
  InfinityTable,
  TableOptions,
  ToolbarActions
} from "components/shared";
import { SearchInput } from "components/fields";
import { useAuth } from "components/AuthProvider";
import { DeliveryMethod } from "components/pages/portal/campaign";
import { createApiCall } from "helpers/api";
import {
  CAMPAIGN_PAGE,
  EDIT_CAMPAIGN_PAGE,
  midnightBlue,
  palette
} from "settings";
import { encodeObjectToQueryString } from "helpers/queryStrings";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";

const CampaignsListPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { session, getBalance } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const [openCreateCampaign, setOpenCreateCampaign] = useState(
    location?.state?.openDrawer || false
  );
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [campaigns, setCampaigns] = useState(defaultCampaignsState);
  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [orderBy, setOrderBy] = useState(DEFAULT_ORDER_BY);
  const [filter, setFilter] = useState(defaultFilters);
  const [anchorEl, setAnchorEl] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState(null);
  const [activeRowData, setActiveRowData] = useState(null);
  const [duplicateCampaign, setDuplicateCampaign] = useState(null);

  const formatStartEndRange = useMemo(() => {
    const start = filter?.startEndRange?.start
      ? format(filter.startEndRange.start, "MM/dd/yyyy")
      : "";
    const end = filter?.startEndRange?.end
      ? format(filter.startEndRange.end, "MM/dd/yyyy")
      : "";
    return { start, end };
  }, [filter.startEndRange]);

  useEffect(() => {
    if (session?.clientUuid) {
      fetchCampaigns();
    }
  }, [filter, order, orderBy, session?.clientUuid]);

  const fetchCampaigns = () => {
    setLoading(true);
    createApiCall({
      method: "GET",
      url: `campaigns/${session?.clientUuid}?${encodeObjectToQueryString(
        collectFilter(filter, order, orderBy)
      )}`
    }).then(({ data, status }) => {
      if (status === 200) {
        setCampaigns({
          pagination: data.pagination,
          campaigns: [...campaigns.campaigns, ...data.campaigns]
        });
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  };

  const onLoadMore = () => {
    setFilter({
      ...filter,
      pagination: {
        ...filter.pagination,
        page: filter.pagination.page + 1
      }
    });
  };

  const searchHandler = (name) => {
    setCampaigns(defaultCampaignsState);
    setFilter({
      ...filter,
      name,
      pagination: {
        ...filter.pagination,
        page: 0
      }
    });
  };

  const searchHandlerFilters = (newParam) => {
    setCampaigns(defaultCampaignsState);
    setFilter({
      ...filter,
      ...newParam,
      pagination: {
        ...filter.pagination,
        page: 0
      }
    });
  };

  const onFilter = (data) => {
    setCampaigns(defaultCampaignsState);
    setFilter({
      ...filter,
      ...data,
      pagination: {
        ...filter.pagination,
        page: 0
      }
    });
    setOpenFilter(false);
  };

  const sortHandler = (id, newOrder) => {
    setCampaigns(defaultCampaignsState);
    setFilter({
      ...filter,
      pagination: {
        ...filter.pagination,
        page: 0
      }
    });
    setOrderBy(id);
    setOrder(newOrder);
  };

  const onRowClick = (campaign) => {
    navigate(CAMPAIGN_PAGE(campaign.uuid));
  };

  const showMoreOptions = (event, data) => {
    setActiveRowData(data);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDuplicate = () => {
    setDuplicateCampaign(activeRowData);
    setAnchorEl(null);
  };

  const onEdit = () => {
    navigate(EDIT_CAMPAIGN_PAGE(activeRowData.uuid));
  };

  const onStart = () => {
    setConfirmModalData({
      open: true,
      data: {
        action: "ACTIVE",
        activeRowData
      },
      title: "Are you sure that you start campaign now?",
      description: `Delivery date and redemption code expiration will be changed after submission.`
    });
  };

  const onVoid = () => {
    setConfirmModalData({
      open: true,
      data: {
        action: "VOID",
        activeRowData
      },
      title: "Are you sure that you want to void?",
      description: `All redemption codes will be voided for this campaign and recipients will be notified by email about that.`
    });
  };

  const onShowFilter = () => {
    setOpenFilter(true);
  };

  const onConfirm = ({ action, activeRowData }) => {
    setSubmitting(true);
    if (action === "ACTIVE" || action === "VOID") {
      createApiCall({
        method: "PATCH",
        url: `campaigns/status`,
        data: {
          campaign_uuid: activeRowData?.uuid,
          status: action
        }
      }).then(({ data, status }) => {
        if (status === 200) {
          enqueueSnackbar(
            `A campaign ${activeRowData?.name} was ${
              action === "ACTIVE" ? "started" : "voided"
            } successfully.`,
            {
              variant: "success"
            }
          );
          getBalance();
          setCampaigns(defaultCampaignsState);
          setFilter({
            ...filter,
            pagination: {
              ...filter.pagination,
              page: 0
            }
          });
          fetchCampaigns();
        } else {
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
        }
        setConfirmModalData(null);
        setSubmitting(false);
        setAnchorEl(null);
      });
    }
  };

  const onCloseDeliveryMethods = () => {
    setOpenCreateCampaign(false);
    if (duplicateCampaign) {
      setDuplicateCampaign(null);
      setFilter({
        ...filter,
        pagination: {
          ...filter.pagination,
          page: 0
        }
      });
      setCampaigns(defaultCampaignsState);
      fetchCampaigns();
    }
  };

  const menuOptions = useMemo(
    () => [
      {
        id: 1,
        icon: "filter-new",
        text: "Filter",
        onClick: onShowFilter
      }
    ],
    [session]
  );

  return (
    <>
      <Helmet>
        <title>Campaigns</title>
      </Helmet>
      <Box sx={{ minHeight: "100%" }}>
        <ConfirmModal
          {...confirmModalData}
          loading={submitting}
          open={!!confirmModalData}
          onClose={() => setConfirmModalData(null)}
          onConfirm={onConfirm}
        />
        <Stack
          mb={1}
          direction={"row"}
          flexWrap={"wrap"}
          alignItems={"center"}
          gap={{ xs: 1, md: 3 }}
          justifyContent={"space-between"}
          sx={{ minHeight: 56 }}
        >
          <Typography variant={"h5"} color={midnightBlue}>
            Campaigns
          </Typography>
          <Button onClick={() => setOpenCreateCampaign(true)}>
            Create campaign
          </Button>
        </Stack>

        <Box mb={3}>
          <SearchInput
            multiple={false}
            placeholder={"Search for a name"}
            selectedItems={filter?.name || ""}
            setSelectedItems={searchHandler}
          />
          <Stack direction={"row"} flexWrap={"wrap"} gap={"0 10px"}>
            {!!filter?.name && (
              <Chip
                variant="squared-secondary"
                sx={{
                  mt: "10px"
                }}
                tabIndex={-1}
                label={filter.name}
                onDelete={() =>
                  searchHandlerFilters({
                    name: ""
                  })
                }
              />
            )}
            {(!!formatStartEndRange.start || !!formatStartEndRange.end) && (
              <Chip
                variant="squared-secondary"
                sx={{
                  mt: "10px"
                }}
                tabIndex={-1}
                label={`Campaign dates: ${formatStartEndRange.start} - ${formatStartEndRange.end}`}
                onDelete={() =>
                  searchHandlerFilters({
                    startEndRange: {
                      start: "",
                      end: ""
                    }
                  })
                }
              />
            )}
            {(!!filter?.codeValueRange.start ||
              !!filter?.codeValueRange.end) && (
              <Chip
                variant="squared-secondary"
                sx={{
                  mt: "10px"
                }}
                tabIndex={-1}
                label={`Gift card value: ${
                  filter?.codeValueRange?.start
                    ? filter.codeValueRange.start + "$ - "
                    : ""
                }${
                  filter?.codeValueRange?.end
                    ? filter?.codeValueRange.end + "$"
                    : ""
                }`}
                onDelete={() =>
                  searchHandlerFilters({
                    codeValueRange: {
                      start: "",
                      end: ""
                    }
                  })
                }
              />
            )}
            {(!!filter?.campaignTotalRange.start ||
              !!filter?.campaignTotalRange.end) && (
              <Chip
                variant="squared-secondary"
                sx={{
                  mt: "10px"
                }}
                tabIndex={-1}
                label={`Campaign amount: ${
                  filter?.campaignTotalRange?.start
                    ? filter.campaignTotalRange.start + "$ - "
                    : ""
                }${
                  filter?.campaignTotalRange?.end
                    ? filter?.campaignTotalRange.end + "$"
                    : ""
                }`}
                onDelete={() =>
                  searchHandlerFilters({
                    campaignTotalRange: {
                      start: "",
                      end: ""
                    }
                  })
                }
              />
            )}
            {!!filter?.status?.length &&
              filter?.status.map((item) => (
                <Chip
                  variant="squared-secondary"
                  sx={{
                    mt: "10px"
                  }}
                  tabIndex={-1}
                  label={_find(campaignsStatus, { value: item })?.label || "-"}
                  onDelete={() =>
                    searchHandlerFilters({
                      status: filter.status.filter((el) => el !== item)
                    })
                  }
                />
              ))}
          </Stack>
        </Box>

        <TableOptions anchorEl={anchorEl} onClose={handleClose}>
          <Button
            variant={"text"}
            fullWidth
            onClick={onDuplicate}
            sx={{
              gap: "12px",
              padding: { xs: "6px" },
              justifyContent: "flex-start"
            }}
          >
            <svg
              width={24}
              height={24}
              stroke={palette.grayscale["700"]}
              style={{ minWidth: 24 }}
            >
              <use href="#copy" />
            </svg>
            <Typography variant={"smallTitle"} color={"grayscale.800"}>
              Duplicate
            </Typography>
          </Button>
          {(activeRowData?.status?.toLowerCase() === "draft" ||
            activeRowData?.status?.toLowerCase() === "pending") && (
            <Button
              fullWidth
              variant={"text"}
              onClick={onEdit}
              sx={{
                gap: "12px",
                padding: { xs: "4px 8px" },
                justifyContent: "flex-start",
                color: palette.grayscale["800"]
              }}
            >
              <svg width={24} height={24} style={{ minWidth: 24 }}>
                <use href="#edit-pencil" />
              </svg>
              <Typography variant={"smallTitle"}>Edit</Typography>
            </Button>
          )}
          {activeRowData?.status?.toLowerCase() === "pending" && (
            <Button
              variant={"text"}
              fullWidth
              onClick={onStart}
              sx={{
                gap: "12px",
                padding: { xs: "6px" },
                justifyContent: "flex-start"
              }}
            >
              <svg
                width={24}
                height={24}
                stroke={palette.grayscale["700"]}
                style={{ minWidth: 24 }}
              >
                <use href="#play" />
              </svg>
              <Typography variant={"smallTitle"} color={"grayscale.800"}>
                Start now
              </Typography>
            </Button>
          )}
          {activeRowData?.status?.toLowerCase() === "active" && (
            <Button
              variant={"text"}
              fullWidth
              onClick={onVoid}
              sx={{
                gap: "12px",
                padding: { xs: "6px" },
                justifyContent: "flex-start"
              }}
            >
              <svg
                width={24}
                height={24}
                stroke={palette.grayscale["700"]}
                style={{ minWidth: 24 }}
              >
                <use href="#voided" />
              </svg>
              <Typography variant={"smallTitle"} color={"grayscale.800"}>
                Void
              </Typography>
            </Button>
          )}
        </TableOptions>

        <InfinityTable
          headCells={headCells}
          loading={loading}
          data={campaigns?.campaigns || []}
          total={campaigns?.pagination?.totalResults || 0}
          order={order}
          orderBy={orderBy}
          createSortHandler={sortHandler}
          onRowClick={onRowClick}
          onLoadMore={onLoadMore}
          onMoreOptions={showMoreOptions}
          toolbarTitle={`List ${
            campaigns?.pagination?.totalResults
              ? "(" + campaigns?.pagination?.totalResults + ")"
              : ""
          }`}
          toolbarActions={<ToolbarActions options={menuOptions} />}
        />
      </Box>
      <FilterCampaignsModal
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        filterParams={filter}
        onFilter={onFilter}
      />
      <DeliveryMethod
        open={openCreateCampaign || duplicateCampaign}
        onClose={onCloseDeliveryMethods}
        duplicateCampaign={duplicateCampaign}
      />
    </>
  );
};

export default CampaignsListPage;

import React from "react";
import { greenAlert, palette } from "settings";

import Chip from "@mui/material/Chip";

const getActiveName = (lowerCaseData) => {
  if (lowerCaseData === "expired") {
    return "completed";
  }
  return lowerCaseData;
};

const getActiveStyle = (lowerCaseData) => {
  if (!lowerCaseData) return {};
  if (
    lowerCaseData === "successful" ||
    lowerCaseData === "success" ||
    lowerCaseData === "active" ||
    lowerCaseData === "enabled"
  ) {
    return {
      color: "#FFF",
      background: greenAlert
    };
  }
  if (lowerCaseData === "draft") {
    return {
      color: "#FFF",
      background: palette.primary.main
    };
  }
  if (lowerCaseData === "completed" || lowerCaseData === "expired") {
    return {
      color: palette.primary.main,
      background: "transparent"
    };
  }
  if (lowerCaseData === "pending") {
    return {
      color: palette.grayscale["300"],
      background: "transparent"
    };
  }
};

export const ChipStatus = ({ data }) => {
  const lowerCaseData = data.toLowerCase();

  return (
    <Chip
      variant={"smallTitle"}
      component={"span"}
      label={getActiveName(lowerCaseData)}
      sx={[
        getActiveStyle(lowerCaseData),
        {
          borderRadius: "100px",
          border: `1px solid`,
          fontWeight: 900,
          fontSize: 12,
          height: "20px",
          padding: "1px 0",
          lineHeight: 1,
          letterSpacing: "0.96px",
          textTransform: "uppercase"
        }
      ]}
    />
  );
};

import React from "react";
import DatePicker from "react-datepicker";
import { palette } from "settings";
import { endOfDay, startOfDay } from "date-fns";

import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export const FieldRangeDatePickerNew = ({
  direction = "row",
  formState: { errors },
  register,
  watch,
  setValue,
  startFieldName = "startDate",
  endFieldName = "endDate"
}) => {
  const startDate = watch(startFieldName);
  const endDate = watch(endFieldName);

  const onChangeStartDate = (date) => {
    setValue(startFieldName, date ? startOfDay(date) : null);
  };

  const onChangeEndDate = (date) => {
    setValue(endFieldName, date ? endOfDay(date) : null);
  };

  return (
    <Stack gap={3}>
      <Stack
        direction={{ xs: "column", md: direction }}
        spacing={{ xs: 2, md: 6 }}
      >
        <FormControl variant="filled">
          <Typography
            variant={"subLead"}
            fontWeight={700}
            color={palette.vibrantBlue[900]}
            mb={1}
          >
            Start date
          </Typography>
          <DatePicker
            {...register(startFieldName)}
            placeholderText={"MM/DD/YYYY"}
            showPopperArrow={false}
            selected={startDate}
            onChange={onChangeStartDate}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate}
            wrapperClassName={`date_picker no-label ${
              errors[startFieldName] ? "error" : ""
            }`}
            portalId="body"
          />
        </FormControl>
        <FormControl variant="filled">
          <Typography
            variant={"subLead"}
            fontWeight={700}
            color={palette.vibrantBlue[900]}
            mb={1}
          >
            End date
          </Typography>
          <DatePicker
            {...register(endFieldName)}
            placeholderText={"MM/DD/YYYY"}
            showPopperArrow={false}
            selected={endDate}
            onChange={onChangeEndDate}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            portalId="body"
            wrapperClassName={`date_picker no-label ${
              errors[startFieldName] ? "error" : ""
            }`}
          />
        </FormControl>
      </Stack>
      {(startDate || endDate) && (
        <Button
          disableRipple
          variant={"text"}
          onClick={() => {
            setValue("startDate", null);
            setValue("endDate", null);
          }}
        >
          Clear all
        </Button>
      )}
    </Stack>
  );
};

import React from "react";
import {
  emailRex,
  emailIsNotCorrect,
  fieldCannotBeEmpty
} from "settings/constants";

import FormControl from "@mui/material/FormControl";
import FilledInput from "@mui/material/FilledInput";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";

export const emailValidationPattern = {
  value: emailRex,
  message: emailIsNotCorrect
};

export const FieldEmail = ({
  register,
  formState: { errors },
  label = "Email",
  autoFocus = false,
  readOnly = false,
  validation = {
    required: fieldCannotBeEmpty,
    pattern: emailValidationPattern
  },
  size = "medium"
}) => (
  <FormControl variant="filled" error={!!errors.email} size={size}>
    <InputLabel sx={{ mb: "6px" }}>{label}</InputLabel>

    <FilledInput
      type="email"
      fullWidth
      readOnly={readOnly}
      disableUnderline
      autoFocus={autoFocus}
      placeholder={"Enter your email"}
      {...register("email", validation)}
    />
    {errors.email && <FormHelperText>{errors.email.message}</FormHelperText>}
  </FormControl>
);

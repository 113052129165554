import React, { useEffect } from "react";
import { EmptyTablePlaceholder } from "components/shared";

import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableFooter from "@mui/material/TableFooter";
import Paper from "@mui/material/Paper";

export const virtuosoTableComponents = {
  // set the colspan below to the amount of columns you have.
  // https://github.com/petyosi/react-virtuoso/issues/609
  FillerRow: (props) => {
    const {
      height,
      context: { headCells }
    } = props;
    return (
      <TableRow>
        <TableCell
          colSpan={headCells.length}
          style={{ height: height, padding: 0, border: 0 }}
        ></TableCell>
      </TableRow>
    );
  },
  Scroller: React.forwardRef((props, ref) => {
    const {
      context: { total }
    } = props;

    useEffect(() => {
      if (!total && ref?.current) {
        ref.current.scrollLeft = 0;
      }
    }, [total, ref]);

    return (
      <TableContainer
        component={Paper}
        {...props}
        ref={ref}
        sx={{ overflowX: !total ? "hidden" : "auto" }}
      />
    );
  }),
  Table: (props) => <Table {...props} />,
  TableHead: React.forwardRef((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableFoot: React.forwardRef((props, ref) => (
    <TableFooter
      {...props}
      ref={ref}
      sx={{ background: "#fff", position: "static !important" }}
    />
  )),
  TableRow: React.forwardRef((props, ref) => {
    const {
      context: { onRowClick }
    } = props;
    return (
      <TableRow
        {...props}
        ref={ref}
        onClick={() => onRowClick && onRowClick(props.item)}
        sx={[
          onRowClick && {
            cursor: "pointer"
          }
        ]}
      />
    );
  }),
  EmptyPlaceholder: ({ context: { loading, total, headCells } }) => (
    <TableBody>
      <EmptyTablePlaceholder
        total={total}
        loading={loading}
        headCells={headCells}
      />
    </TableBody>
  ),
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  ))
};

import React, { useMemo } from "react";
import filter from "lodash/filter";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Stack from "@mui/material/Stack";
import { palette } from "settings";

export const InfinityLoader = ({ headCells }) => {
  const filteredHeadCells = useMemo(() => {
    return {
      leftCells: filter(headCells, (o) => o.sticky === "left"),
      cells: filter(headCells, (o) => !o.sticky),
      rightCells: filter(headCells, (o) => o.sticky === "right")
    };
  }, [headCells]);

  return (
    <>
      {Array.from(Array(2).keys()).map((i) => (
        <TableRow key={i}>
          {!!filteredHeadCells.leftCells.length && (
            <TableCell
              sx={{
                left: 0,
                position: "sticky",
                background: "#fff",
                zIndex: 1,
                padding: 0
              }}
            >
              <Stack
                justifyContent={"center"}
                sx={{
                  boxShadow: `1px 0px 0px 0px ${palette.grayscale["200"]}`,
                  height: "100%"
                }}
              >
                <table width={"100%"}>
                  <tbody>
                    <tr>
                      {filteredHeadCells.leftCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.align || "left"}
                          sx={{
                            width: headCell.width || "auto",
                            minWidth: headCell.width || "auto",
                            maxWidth: headCell.width || "none",
                            border: "none"
                          }}
                        >
                          ...
                        </TableCell>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </Stack>
            </TableCell>
          )}
          {filteredHeadCells.cells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.align || "left"}
              sx={{
                width: headCell.width || "auto",
                minWidth: headCell.width || "auto",
                maxWidth: headCell.width || "none"
              }}
            >
              ...
            </TableCell>
          ))}
          {!!filteredHeadCells.rightCells.length && (
            <TableCell
              sx={{
                right: 0,
                position: "sticky",
                background: "#fff",
                zIndex: 1,
                padding: 0
              }}
            >
              <Stack
                justifyContent={"center"}
                sx={{
                  boxShadow: `-1px 0px 0px 0px ${palette.grayscale["200"]}`,
                  height: "100%"
                }}
              >
                <table width={"100%"}>
                  <tbody>
                    <tr>
                      {filteredHeadCells.rightCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.align || "left"}
                          sx={{
                            width: headCell.width || "auto",
                            minWidth: headCell.width || "auto",
                            maxWidth: headCell.width || "none",
                            border: "none"
                          }}
                        >
                          ...
                        </TableCell>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </Stack>
            </TableCell>
          )}
        </TableRow>
      ))}
    </>
  );
};

import React, { useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { ConfirmModal, InfinityTable, TableOptions } from "components/shared";
import { useAuth } from "components/AuthProvider";
import {
  DEFAULT_ORDER,
  DEFAULT_ORDER_BY,
  defaultRecipientsList,
  headCells
} from "./constants";
import { palette } from "settings";
import { createApiCall } from "helpers/api";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export const Recipients = ({ campaign }) => {
  const { session } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeRowData, setActiveRowData] = useState(null);
  const [recipients, setRecipients] = useState(defaultRecipientsList);
  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [orderBy, setOrderBy] = useState(DEFAULT_ORDER_BY);
  const [filter, setFilter] = useState({
    pagination: {
      pageLimit: 20,
      page: 0
    }
  });
  const [submitting, setSubmitting] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState(null);

  const filteredHeadCells = useMemo(() => {
    if (
      campaign?.status?.toLowerCase() === "active" ||
      campaign?.status?.toLowerCase() === "draft"
    ) {
      return [
        ...headCells,
        {
          id: "more_options",
          label: "",
          type: "options",
          width: 70
        }
      ];
    }
    return headCells;
  }, [campaign?.status]);

  useEffect(() => {
    getRecipients();
  }, [filter, order, orderBy, campaign?.uuid]);

  const getRecipients = () => {
    if (campaign?.uuid && campaign?.customersCount) {
      setLoading(true);
      createApiCall({
        // BE can't filter and paginate with both params 'onCampaign=TRUE&campaign_uuid'
        url: `customers/${session?.clientUuid}?include_tags=TRUE&onCampaign=TRUE&campaign_uuid=${campaign?.uuid}`
      }).then(({ data, status }) => {
        if (status === 200) {
          const { customers } = data;
          setRecipients({
            pagination: {
              totalResults: customers.length
            },
            customers: filter.pagination.page
              ? [...recipients.customers, ...customers]
              : customers
          });
        } else {
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  const sortHandler = (id, newOrder) => {
    setRecipients(defaultRecipientsList);
    setOrderBy(id);
    setOrder(newOrder);
  };

  const onLoadMore = () => {
    setFilter({
      pagination: {
        ...filter.pagination,
        page: filter.pagination.page + 1
      }
    });
  };

  const showMoreOptions = (event, data) => {
    setActiveRowData(data);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setActiveRowData(null);
    setAnchorEl(null);
  };

  const onVoid = () => {
    setConfirmModalData({
      open: true,
      data: {
        action: "VOID",
        activeRowData
      },
      title: "Are you sure that you want to void code?",
      description: `A redemption code for ${activeRowData?.lastName} ${activeRowData?.firstName} will be voided and they will be notified by email about that.`
    });
  };

  const onResend = () => {
    createApiCall({
      method: "PATCH",
      url: "campaigns/codes/send",
      data: {
        campaign_uuid: campaign?.uuid,
        customer_uuid: activeRowData?.uuid
      }
    }).then(({ status, data }) => {
      if (status === 200) {
        enqueueSnackbar(
          `Mail with the code was sent to ${activeRowData?.email || "email"}.`,
          { variant: "success" }
        );
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setAnchorEl(null);
    });
  };

  const onConfirm = ({ action, activeRowData }) => {
    setSubmitting(true);
    if (action === "VOID") {
      createApiCall({
        method: "PATCH",
        url: `campaigns/codes`,
        data: {
          campaign_uuid: campaign?.uuid,
          customer_uuids: [activeRowData?.uuid]
        }
      }).then(({ data, status }) => {
        if (status === 200) {
          enqueueSnackbar(
            `Redemption code for ${activeRowData?.email} was voided successfully.`,
            { variant: "success" }
          );
          setRecipients(defaultRecipientsList);
          setFilter({
            pagination: {
              ...filter.pagination,
              page: 0
            }
          });
          getRecipients();
        } else {
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
        }
        setConfirmModalData(null);
        setSubmitting(false);
        setAnchorEl(null);
      });
    }
  };

  return (
    <Box>
      <TableOptions anchorEl={anchorEl} onClose={handleClose}>
        <Button
          fullWidth
          variant={"text"}
          onClick={onResend}
          sx={{
            gap: "12px",
            padding: { xs: "4px 6px" },
            justifyContent: "flex-start"
          }}
        >
          <svg
            width={24}
            height={24}
            stroke={palette.grayscale["700"]}
            style={{ minWidth: 24 }}
          >
            <use href="#mail" />
          </svg>
          <Typography variant={"smallTitle"} color={"grayscale.800"}>
            Resend email
          </Typography>
        </Button>
        <Button
          variant={"text"}
          fullWidth
          onClick={onVoid}
          sx={{
            gap: "12px",
            padding: { xs: "6px" },
            justifyContent: "flex-start"
          }}
        >
          <svg
            width={24}
            height={24}
            stroke={palette.grayscale["700"]}
            style={{ minWidth: 24 }}
          >
            <use href="#voided" />
          </svg>
          <Typography variant={"smallTitle"} color={"grayscale.800"}>
            Void code
          </Typography>
        </Button>
      </TableOptions>
      <InfinityTable
        headCells={filteredHeadCells}
        hideToolbar={true}
        loading={loading}
        data={recipients?.customers || []}
        total={recipients?.pagination?.totalResults || 0}
        order={order}
        orderBy={orderBy}
        createSortHandler={sortHandler}
        onLoadMore={onLoadMore}
        onMoreOptions={showMoreOptions}
      />
      <ConfirmModal
        {...confirmModalData}
        loading={submitting}
        open={!!confirmModalData}
        onClose={() => setConfirmModalData(null)}
        onConfirm={onConfirm}
      />
    </Box>
  );
};

import React from "react";
import { Helmet } from "react-helmet";
import { AuthBenefitsWrapper, SignUpForm } from "components/pages/auth";

const SignUpPage = () => {
  return (
    <>
      <Helmet>
        <title>Sign Up</title>
      </Helmet>
      <AuthBenefitsWrapper>
        <SignUpForm />
      </AuthBenefitsWrapper>
    </>
  );
};

export default SignUpPage;

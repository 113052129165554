import React from "react";
import InputMask from "react-input-mask";
import { Controller } from "react-hook-form";
import { fieldCannotBeEmpty } from "settings";

import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";

export const FieldWithMask = ({
  control,
  formState: { errors },
  label = "",
  fieldName = "",
  placeholder = "",
  rules = {},
  mask = "",
  maskPlaceholder = "_",
  size = "medium",
  endAdornment = null
}) => {
  return (
    <FormControl variant="filled" error={!!errors[fieldName]} size={size}>
      {label && <InputLabel sx={{ mb: "6px" }}>{label}</InputLabel>}
      <Controller
        render={({ field: { onChange, value, name, ref } }) => (
          <InputMask
            mask={mask}
            maskPlaceholder={maskPlaceholder}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          >
            <FilledInput
              name={name}
              fullWidth
              disableUnderline
              placeholder={placeholder}
              inputRef={ref}
              endAdornment={endAdornment}
            />
          </InputMask>
        )}
        name={fieldName}
        control={control}
        rules={{
          required: fieldCannotBeEmpty,
          ...rules
        }}
      />
      {errors[fieldName] && (
        <FormHelperText>{errors[fieldName].message}</FormHelperText>
      )}
    </FormControl>
  );
};

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  CustomSelect,
  FieldPhone,
  FieldText,
  FieldTextarea,
  FieldWithMask,
  RadioAsCheckbox,
  UploadButton
} from "components/fields";
import { StatusKYCModal } from "components/pages/portal";
import { useAuth } from "components/AuthProvider";
import { useSnackbar } from "notistack";
import { createApiCall } from "helpers/api";
import { getBase64 } from "helpers/getBase64";
import {
  businessYears,
  chooseOne,
  DASHBOARD_PAGE,
  employers,
  fieldCannotBeEmpty,
  industry,
  manyCharacters,
  palette,
  taxIDIsNotCorrect,
  urlIsNotCorrect,
  US_States,
  websiteRex
} from "settings";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

const AccountKYC = () => {
  const { session, kyc, setKyc } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const methods = useForm();
  const {
    handleSubmit,
    resetField,
    formState: { errors },
    watch,
    control
  } = methods;
  const [selectedTaxFiles, setSelectedTaxFiles] = useState([]);
  const [selectedGovFiles, setSelectedGovFiles] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [verification, setVerification] = useState(null);

  const brandInterestInput = watch("brand_interest");
  const descriptionInput = watch("description");

  useEffect(() => {
    return () => {
      if (verification) {
        setKyc(verification);
      }
    };
  }, [verification]);

  useEffect(() => {
    if (!kyc || kyc.status === "ACCEPTED") {
      navigate(DASHBOARD_PAGE);
    }
    setOpenStatusModal(kyc?.status === "PENDING");
  }, [kyc]);

  const handleSelectedFile = (event, type = "tax") => {
    const files = Array.from(event.target?.files) || [];
    if (type === "tax") {
      setSelectedTaxFiles(files);
    } else setSelectedGovFiles(files);
  };

  const handleRemove = (index, type = "tax") => {
    const files =
      type === "tax" ? [...selectedTaxFiles] : [...selectedGovFiles];
    files.splice(index, 1);
    if (type === "tax") {
      setSelectedTaxFiles(files);
      resetField("document_tax_value");
    } else {
      setSelectedGovFiles(files);
      resetField("document_gov_value");
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const {
      address1,
      country,
      state,
      zipcode,
      document_gov_value,
      document_tax_value,
      document_tax_kind
    } = data;

    const taxDocBase64 = await getBase64(document_tax_value.item(0));
    const govDocBase64 = await getBase64(document_gov_value.item(0));

    const formData = {
      verification: {
        address: {
          address1,
          country,
          state,
          city: state,
          zipcode
        },
        documents: [
          {
            kind: document_tax_kind,
            value: taxDocBase64.split(",")[1],
            contentType: taxDocBase64.substring(
              "data:".length,
              taxDocBase64.indexOf(";base64")
            )
          },
          {
            kind: "IDENTIFICATION",
            value: govDocBase64.split(",")[1],
            contentType: govDocBase64.substring(
              "data:".length,
              govDocBase64.indexOf(";base64")
            )
          }
        ]
      }
    };

    Object.keys(data).forEach((el) => {
      switch (el) {
        case "document_gov_kind":
        case "document_gov_value":
        case "document_tax_kind":
        case "document_tax_value":
        case "address1":
        case "country":
        case "zipcode":
        case "state":
          break;
        case "tax_id":
          return (formData.verification[el] = data[el]?.replace(
            /[ \-()+_]/g,
            ""
          ));
        case "phone_number":
          return (formData.verification[el] = String(
            data[el]?.replace(/[ \-()+_]/g, "")?.slice(1)
          ));
        default:
          return (formData.verification[el] = data[el]);
      }
    });

    if (session?.clientUuid && session.uuid) {
      formData.verification.client_uuid = session.clientUuid;
      formData.verification.user_uuid = session.uuid;

      createApiCall({
        method: "POST",
        url: `kyc/verification`,
        data: formData
      }).then(({ data, status }) => {
        if (status === 200 && data) {
          setSubmitted(true);
          setOpenStatusModal(true);
          setVerification(data?.verification);
        } else {
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
        }
        setLoading(false);
      });
    } else {
      enqueueSnackbar("Session error", { variant: "error" });
    }
  });

  return (
    <div>
      <Helmet>
        <title>Account KYC</title>
      </Helmet>

      <StatusKYCModal open={openStatusModal} submitted={submitted} />
      <Box>
        <Stack
          component={"form"}
          onSubmit={onSubmit}
          noValidate
          direction={"row"}
          gap={"30px"}
          flexWrap={{ xs: "wrap", lg: "nowrap" }}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
        >
          <Box
            sx={{
              p: { xs: 2, md: "16px 10px" },
              background: "#FFFFFF",
              borderRadius: "0 0 8px 8px"
            }}
            width={"100%"}
          >
            <Typography
              variant={"smallTitle"}
              fontWeight={700}
              sx={{ pb: "16px" }}
              color={palette.primary.main}
            >
              Business Information
            </Typography>
            <Stack
              sx={{
                borderTop: `1px solid ${palette.grayscale[100]}`,
                paddingTop: "10px",
                gap: "24px"
              }}
            >
              <FieldText
                {...methods}
                fieldName={"company_name"}
                placeholder={"Company name"}
                label={"Company name"}
              />
              <FieldText
                {...methods}
                fieldName={"address1"}
                placeholder={"Company address"}
                label={"Company address"}
              />
              <FieldText
                {...methods}
                fieldName={"website_url"}
                placeholder={"Company website"}
                label={"Company website"}
                validation={{
                  pattern: {
                    value: websiteRex,
                    message: urlIsNotCorrect
                  }
                }}
              />
              <Stack
                direction={{ xs: "column", md: "row" }}
                alignItems={"flex-start"}
                gap={3}
              >
                <CustomSelect
                  {...methods}
                  options={US_States}
                  fieldName={"state"}
                  placeholder={"State"}
                  label={"State"}
                />
                <CustomSelect
                  {...methods}
                  fieldName={"country"}
                  options={[{ value: "USA", label: "USA" }]}
                  placeholder={"Country"}
                  label={"Country"}
                />
                <FieldText
                  {...methods}
                  type={"number"}
                  fieldName={"zipcode"}
                  placeholder={"Zip Code"}
                  label={"Zip Code"}
                />
              </Stack>

              <FieldPhone
                {...methods}
                fieldName={"phone_number"}
                placeholder={"Company phone number"}
                label={"Company phone number"}
              />

              <FieldWithMask
                {...methods}
                fieldName={"tax_id"}
                placeholder={"Business tax ID number"}
                label={"Business tax ID number"}
                mask={"999-999-999"}
                rules={{
                  validate: {
                    check: (v) => {
                      const clearedField = v?.replace(/[ \-()+_]/g, "");
                      if (!clearedField?.length) return true;
                      if (clearedField?.length >= 9) {
                        return true;
                      }
                      return taxIDIsNotCorrect;
                    }
                  }
                }}
              />

              <FormControl variant="filled" error={!!errors?.["kind"]}>
                <Typography
                  variant={"body1"}
                  fontWeight={600}
                  sx={{ mb: "8px" }}
                  color={palette.grayscale[800]}
                >
                  Type of business
                </Typography>
                <Typography
                  variant={"smallTitle"}
                  sx={{ mb: "16px" }}
                  color={palette.grayscale[800]}
                >
                  Please select one
                </Typography>
                <Controller
                  rules={{ required: chooseOne }}
                  control={control}
                  name="kind"
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <Stack
                        direction={{ xs: "column", md: "row" }}
                        gap={"0 67px"}
                        flexWrap={"wrap"}
                      >
                        <FormControlLabel
                          control={<RadioAsCheckbox />}
                          value={"CORPORATION"}
                          label={
                            <Typography
                              variant={"smallTitle"}
                              color={"grayscale.800"}
                            >
                              Corporation
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          control={<RadioAsCheckbox />}
                          value={"LLC"}
                          label={
                            <Typography
                              variant={"smallTitle"}
                              color={"grayscale.800"}
                            >
                              LLC
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          control={<RadioAsCheckbox />}
                          value={"PARTNERSHIP"}
                          label={
                            <Typography
                              variant={"smallTitle"}
                              color={"grayscale.800"}
                            >
                              Partnership
                            </Typography>
                          }
                        />
                      </Stack>
                      <Stack
                        direction={{ xs: "column", md: "row" }}
                        gap={"0 50px"}
                      >
                        <FormControlLabel
                          control={<RadioAsCheckbox />}
                          value={"SOLE_PROPRIETOR"}
                          label={
                            <Typography
                              variant={"smallTitle"}
                              color={"grayscale.800"}
                            >
                              Sole Proprietor
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          control={<RadioAsCheckbox />}
                          value={"TRUST"}
                          label={
                            <Typography
                              variant={"smallTitle"}
                              color={"grayscale.800"}
                            >
                              Trust
                            </Typography>
                          }
                        />
                      </Stack>
                    </RadioGroup>
                  )}
                />
                {!!errors?.["kind"] && (
                  <FormHelperText>{errors["kind"].message}</FormHelperText>
                )}
              </FormControl>

              <FormControl variant="filled" error={!!errors["ownership"]}>
                <Typography
                  variant={"body1"}
                  fontWeight={600}
                  sx={{ mb: "8px" }}
                  color={palette.grayscale[800]}
                >
                  Ownership
                </Typography>
                <Typography
                  variant={"smallTitle"}
                  sx={{ mb: "16px" }}
                  color={palette.grayscale[800]}
                >
                  Please select one
                </Typography>
                <Controller
                  rules={{ required: chooseOne }}
                  control={control}
                  name="ownership"
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      row
                      sx={{ gap: "0 50px", flexWrap: "wrap" }}
                    >
                      <FormControlLabel
                        control={<RadioAsCheckbox />}
                        value={"PRIVATE"}
                        label={
                          <Typography
                            variant={"smallTitle"}
                            color={"grayscale.800"}
                          >
                            Private
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={<RadioAsCheckbox />}
                        value={"PUBLIC"}
                        label={
                          <Typography
                            variant={"smallTitle"}
                            color={"grayscale.800"}
                          >
                            Public
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  )}
                />
                {!!errors?.["ownership"] && (
                  <FormHelperText>{errors["ownership"].message}</FormHelperText>
                )}
              </FormControl>

              <Box>
                <Typography
                  variant={"body1"}
                  fontWeight={600}
                  sx={{ mb: "8px" }}
                  color={palette.grayscale[800]}
                >
                  Describe the nature of your business and the products/services
                  you offer
                </Typography>
                <FieldTextarea
                  {...methods}
                  rows={3}
                  placeholder={"Enter text here"}
                  fieldName={"description"}
                  validation={{
                    required: fieldCannotBeEmpty,
                    maxLength: {
                      value: 200,
                      message: manyCharacters
                    }
                  }}
                  endAdornment={
                    <Stack
                      direction="row"
                      alignItems={"center"}
                      sx={{ padding: "38px 0 8px", marginTop: "auto" }}
                    >
                      <Typography
                        variant={"leadCaption"}
                        color={"grayscale.400"}
                      >
                        {descriptionInput?.length || 0}
                      </Typography>
                      <Typography
                        variant={"leadCaption"}
                        color={"grayscale.400"}
                      >
                        /200
                      </Typography>
                    </Stack>
                  }
                />
              </Box>
              <Box>
                <Typography
                  variant={"body1"}
                  fontWeight={600}
                  sx={{ mb: "8px" }}
                  color={palette.grayscale[800]}
                >
                  What are the brands that you are interested in purchasing and
                  at what volume/frequency ?
                </Typography>
                <FieldTextarea
                  {...methods}
                  rows={3}
                  validation={{
                    required: fieldCannotBeEmpty,
                    maxLength: {
                      value: 200,
                      message: manyCharacters
                    }
                  }}
                  fieldName={"brand_interest"}
                  placeholder={"Enter text here"}
                  endAdornment={
                    <Stack
                      direction="row"
                      alignItems={"center"}
                      sx={{ padding: "38px 0 8px", marginTop: "auto" }}
                    >
                      <Typography
                        variant={"leadCaption"}
                        color={"grayscale.400"}
                      >
                        {brandInterestInput?.length || 0}
                      </Typography>
                      <Typography
                        variant={"leadCaption"}
                        color={"grayscale.400"}
                      >
                        /200
                      </Typography>
                    </Stack>
                  }
                />
              </Box>
            </Stack>
          </Box>
          <Box>
            <Box
              sx={{
                p: { xs: "16px 16px 24px", md: "16px 10px 24px" },
                background: "#FFFFFF",
                borderRadius: "0 0 8px 8px"
              }}
              width={"100%"}
              height={"100%"}
            >
              <Typography
                variant={"smallTitle"}
                fontWeight={700}
                sx={{ pb: "16px" }}
                color={palette.primary.main}
              >
                Business Information
              </Typography>
              <Stack
                sx={{
                  borderTop: `1px solid ${palette.grayscale[100]}`,
                  marginBottom: "24px",
                  borderBottom: `1px solid ${palette.grayscale[100]}`,
                  padding: "10px 0 24px",
                  gap: "24px"
                }}
              >
                <CustomSelect
                  {...methods}
                  fieldName={"industry"}
                  options={industry}
                  placeholder={"Industry"}
                  label={"Industry"}
                />
                <CustomSelect
                  {...methods}
                  options={businessYears}
                  fieldName={"age"}
                  placeholder={"Years in business"}
                  label={"Years in business"}
                />
                <CustomSelect
                  {...methods}
                  options={employers}
                  fieldName={"quantity_of_employees"}
                  placeholder={"Number of employees"}
                  label={"Number of employees"}
                />
                <FormControl
                  variant="filled"
                  error={!!errors?.["legal_obligations"]}
                >
                  <Typography
                    variant={"body1"}
                    fontWeight={600}
                    sx={{ mb: "16px" }}
                    color={palette.grayscale[800]}
                  >
                    Does your business have any legal or regulatory obligation
                    that it is required to comply with?
                  </Typography>
                  <Controller
                    rules={{ required: chooseOne }}
                    control={control}
                    name="legal_obligations"
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        row
                        sx={{ gap: "0 60px", flexWrap: "wrap" }}
                      >
                        <FormControlLabel
                          control={<RadioAsCheckbox />}
                          value={"TRUE"}
                          label={
                            <Typography
                              variant={"smallTitle"}
                              color={"grayscale.800"}
                            >
                              Yes
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          control={<RadioAsCheckbox />}
                          value={"FALSE"}
                          label={
                            <Typography
                              variant={"smallTitle"}
                              color={"grayscale.800"}
                            >
                              No
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    )}
                  />
                  {!!errors?.["legal_obligations"] && (
                    <FormHelperText>
                      {errors["legal_obligations"].message}
                    </FormHelperText>
                  )}
                </FormControl>
                <Stack gap={"24px"}>
                  <FormControl
                    variant="filled"
                    error={!!errors?.["document_tax_kind"]}
                  >
                    <Typography
                      variant={"body1"}
                      fontWeight={600}
                      sx={{ mb: "16px" }}
                      color={palette.grayscale[800]}
                    >
                      Select one of the following tax documents to upload
                    </Typography>
                    <Controller
                      rules={{ required: chooseOne }}
                      control={control}
                      name="document_tax_kind"
                      render={({ field }) => (
                        <RadioGroup
                          {...field}
                          row
                          sx={{ gap: "0 60px", flexWrap: "wrap" }}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value={"EIN"}
                            label={
                              <Typography
                                variant={"smallTitle"}
                                color={"grayscale.800"}
                              >
                                EIN
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            control={<Radio />}
                            value={"BANK_STATEMENT"}
                            label={
                              <Typography
                                variant={"smallTitle"}
                                color={"grayscale.800"}
                              >
                                Bank Statement
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      )}
                    />
                    {!!errors?.["document_tax_kind"] && (
                      <FormHelperText>
                        {errors["document_tax_kind"].message}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <UploadButton
                    {...methods}
                    fieldName={"document_tax_value"}
                    selectedFiles={selectedTaxFiles}
                    handleSelectedFile={(e) => handleSelectedFile(e, "tax")}
                    onRemove={(e) => handleRemove(e, "tax")}
                  />
                </Stack>
                <Stack gap={"24px"}>
                  <FormControl
                    variant="filled"
                    error={!!errors?.["document_gov_kind"]}
                  >
                    <Typography
                      variant={"body1"}
                      fontWeight={600}
                      sx={{ mb: "16px" }}
                      color={palette.grayscale[800]}
                    >
                      Select one of the following government issued photo ID to
                      upload
                    </Typography>
                    <Controller
                      rules={{ required: chooseOne }}
                      control={control}
                      name="document_gov_kind"
                      render={({ field }) => (
                        <RadioGroup
                          {...field}
                          row
                          sx={{ gap: "0 60px", flexWrap: "wrap" }}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value={"DL"}
                            label={
                              <Typography
                                variant={"smallTitle"}
                                color={"grayscale.800"}
                              >
                                Drivers License
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            control={<Radio />}
                            value={"State ID"}
                            label={
                              <Typography
                                variant={"smallTitle"}
                                color={"grayscale.800"}
                              >
                                State ID{" "}
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            control={<Radio />}
                            value={"Passport"}
                            label={
                              <Typography
                                variant={"smallTitle"}
                                color={"grayscale.800"}
                              >
                                Passport{" "}
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      )}
                    />
                    {!!errors?.["document_gov_kind"] && (
                      <FormHelperText>
                        {errors["document_gov_kind"].message}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <UploadButton
                    {...methods}
                    fieldName={"document_gov_value"}
                    selectedFiles={selectedGovFiles}
                    handleSelectedFile={(e) => handleSelectedFile(e, "gov")}
                    onRemove={(e) => handleRemove(e, "gov")}
                  />
                </Stack>
              </Stack>
              <LoadingButton
                loading={loading}
                disabled={kyc?.status === "PENDING"}
                fullWidth
                variant={"contained"}
                type={"submit"}
              >
                Continue
              </LoadingButton>
            </Box>
            <Typography
              variant={"smallTitle"}
              sx={{
                color: palette.grayscale[800],
                fontWeight: 300,
                marginTop: "15px"
              }}
            >
              If you decide to add more users to this account or reach a certain
              amount of rewards earned, you will be asked to supply more
              information about your account.
            </Typography>
          </Box>
        </Stack>
      </Box>
    </div>
  );
};
export default AccountKYC;

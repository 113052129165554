export const emailRex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const passwordRex =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()|_+\-={}\[\]\\\/<>?,.:"';~`])[a-zA-Z0-9!@#$%^&*()|_+\-={}\[\]\\\/<>?,.:"';~`]{8,}$/g;
export const websiteRex =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
export const tagsRex = /^[a-z0-9 ]+$/g;
export const fieldCannotBeEmpty = "This is a required field";
export const chooseOne = "Please select one";
export const emailIsNotCorrect =
  "Use the correct format of email: email@example.com";
export const tagIsNotCorrect = "Use the correct format of tag name";
export const urlIsNotCorrect =
  "Use the correct format of url: https://www.example.com";
export const max32characters =
  "Not possible to enter 32+ characters to the field";
export const manyCharacters = "Too many characters";
export const passwordDontMatch = "Passwords don’t match. Please, try again.";
export const passwordMin8 = "Please use 8+ characters for password";
export const passwordValidationText =
  "Password must contain 8 to 32 symbols, at least one upper and lower case, at least one number and symbol.";
export const phoneIsNotCorrect =
  "Use the correct format of mobile number: +1 (999) 999-9999";
export const taxIDIsNotCorrect =
  "Use the correct format of tax ID: 999-999-999";
export const documentsFormat =
  "Please load the file in pdf or any image format";
export const csvFormat = "Please load the file in csv format";
export const fileSize3Mb = 3 * 1024 * 1024;
export const documentsSize3Mb = "Please load the file smaller than 3MB";
export const incorrectFormat = "Incorrect format";
export const pleaseFillInput = "Please fill the field";
export const cardIsNotCorrect = "Use the correct format of card number";
export const invalidFormat = "Invalid field format";
export const chooseTimeInFuture = "Choose time in future";
export const chooseDateInFuture = "Choose date in future";
export const range1DayTo6Months = "Choose a date range from 1 day to 6 months";
export const fieldDontMatch = "Field value don’t match. Please, try again.";

export const US_States = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
  { value: "DC", label: "District Of Columbia" },
  { value: "AS", label: "American Samoa" },
  { value: "GU", label: "Guam" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "PR", label: "Puerto Rico" },
  { value: "UM", label: "United States Minor Outlying Islands" },
  { value: "VI", label: "Virgin Islands" }
];

export const employers = [
  { label: "0-50", value: "0-50" },
  { label: "50-100", value: "50-100" },
  { label: "100-500", value: "100-500" },
  { label: "500+", value: "500+" }
];
export const businessYears = [
  { label: "0-1", value: "0-1" },
  { label: "1-2", value: "1-2" },
  { label: "2-5", value: "2-5" },
  { label: "5+", value: "5+" }
];

export const industry = [
  { label: "Banking", value: "Banking" },
  { label: "Construction", value: "Construction" },
  { label: "Education", value: "Education" },
  { label: "Energy", value: "Energy" },
  { label: "Entertainment", value: "Entertainment" },
  { label: "Healthcare", value: "Healthcare" },
  { label: "Hospitality", value: "Hospitality" },
  { label: "Information Technology", value: "Information Technology" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Retail", value: "Retail" },
  { label: "Telecommunications", value: "Telecommunications" },
  { label: "Transportation", value: "Transportation" },
  { label: "Utilities", value: "Utilities" }
];
export const subjects = [
  { label: "Account", value: "Account" },
  { label: "Balance", value: "Balance" },
  { label: "Campaigns", value: "Campaigns" },
  { label: "Recipients", value: "Recipients" },
  { label: "Other", value: "Other" }
];

export const allCategories = [
  {
    id: 0,
    name: "APPAREL",
    text: "Apparel"
  },
  {
    id: 1,
    name: "AUTOMOTIVE",
    text: "Automotive"
  },
  {
    id: 2,
    name: "BABY_AND_KIDS",
    text: "Baby and Kids"
  },
  {
    id: 3,
    name: "BEAUTY",
    text: "Beauty"
  },
  {
    id: 4,
    name: "DINING_AND_DELIVERY",
    text: "Dining and Delivery"
  },
  {
    id: 5,
    name: "ENTERTAINMENT",
    text: "Entertainment"
  },
  {
    id: 6,
    name: "GROCERY",
    text: "Grocery"
  },
  {
    id: 7,
    name: "HOME",
    text: "Home"
  },
  {
    id: 8,
    name: "PETS",
    text: "Pets"
  },
  {
    id: 9,
    name: "SHOES",
    text: "Shoes"
  },
  {
    id: 10,
    name: "SPORTS_AND_OUTDOORS",
    text: "Sports and Outdoors"
  },
  {
    id: 11,
    name: "TRAVEL",
    text: "Travel"
  }
];
export const sortedCategories = [
  {
    id: 0,
    name: "asc",
    text: "A - Z"
  },
  {
    id: 1,
    name: "desc",
    text: "Z - A"
  }
];

export const sidebarWidth = 230;

export const alphabetsList = [...Array(26)].map((_, i) =>
  String.fromCharCode(i + 97)
);
export const numbersList = [...Array(10)].map((_, i) => i.toString());

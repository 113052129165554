export const email = (email) => {
  //https://emailregex.com/
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regex.test(email) ? false : "Invalid email";
};

export const required = (fields, formData) =>
  fields.find((field) => formData[field] === "");

export const runValidations = (fields = [], validations, formData) => {
  let errors = {};
  fields.forEach((field) => {
    const validators = validations[field];
    if (!validators) {
      return;
    }
    validators.forEach((validator) => {
      const isInvalid = validator(formData);
      if (isInvalid) {
        errors[field] = isInvalid;
      }
    });
  });

  return Object.keys(errors).length > 0 ? errors : null;
};

export const isDocument = (file) => {
  const ext = [".jpg", ".jpeg", ".bmp", ".gif", ".png", ".svg", ".pdf"];
  return ext.some((el) => file.name.endsWith(el));
};

export const isCSV = (file) => {
  const ext = [".csv", "text/csv", "application/csv", "application/x-csv"];
  return ext.some((el) => file.type === el);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  email,
  required,
  isDocument,
  runValidations,
  isCSV
};

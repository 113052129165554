import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { fieldCannotBeEmpty } from "settings";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { Controller } from "react-hook-form";

export const FieldDatePicker = ({
  control,
  watch,
  formState: { errors },
  fieldName = "date",
  label,
  placeholderText = "MM/DD/YYYY",
  portalId = "body",
  showTimeSelect = false,
  minDate = null,
  maxDate = null,
  minTime = null,
  maxTime = null,
  showTimeSelectOnly = false,
  timeIntervals = 15,
  timeCaption = "Time",
  dateFormat = "MM/dd/yyyy",
  validation = {
    required: fieldCannotBeEmpty
  }
}) => {
  const value = watch(fieldName);
  const [isEmptyOrOpen, setIsEmptyOrOpen] = useState(!value);

  return (
    <FormControl variant="filled" error={!!errors[fieldName]}>
      {label && (
        <InputLabel
          sx={{ mb: "6px" }}
          shrink={!!value || !isEmptyOrOpen}
          focused={!!value || !isEmptyOrOpen}
        >
          {label}
        </InputLabel>
      )}
      <Controller
        name={fieldName}
        control={control}
        rules={validation}
        render={({ field }) => (
          <DatePicker
            {...field}
            autoComplete="off"
            selected={field.value || null}
            placeholderText={placeholderText}
            showPopperArrow={false}
            minDate={minDate}
            maxDate={maxDate}
            minTime={minTime}
            maxTime={maxTime}
            showTimeSelect={showTimeSelect}
            showTimeSelectOnly={showTimeSelectOnly}
            timeIntervals={timeIntervals}
            timeCaption={timeCaption}
            portalId={portalId}
            dateFormat={dateFormat}
            wrapperClassName={`date_picker ${errors[fieldName] ? "error" : ""}`}
            popperClassName={showTimeSelectOnly ? "only-time" : ""}
            onCalendarClose={() => {
              if (!value) {
                setIsEmptyOrOpen(true);
              }
            }}
            onCalendarOpen={() => {
              setIsEmptyOrOpen(false);
              if (minTime) {
                // scroll to first available timeslot
                document
                  .querySelector(
                    "#" +
                      portalId +
                      " .react-datepicker__time-list-item:not(.react-datepicker__time-list-item--disabled)"
                  )
                  ?.scrollIntoView({ block: "start" });
              }
            }}
          />
        )}
      />

      {!!errors[fieldName] && (
        <FormHelperText>{errors[fieldName].message}</FormHelperText>
      )}
    </FormControl>
  );
};

import React from "react";
import {
  passwordRex,
  passwordValidationText,
  fieldCannotBeEmpty,
  passwordMin8
} from "settings";

import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";

export const FieldPassword = ({
  register,
  formState: { errors },
  validation = null,
  placeholder = "Enter your password",
  label = "Password",
  size = "medium"
}) => {
  return (
    <FormControl variant="filled" error={!!errors.password} size={size}>
      {label && <InputLabel>{label}</InputLabel>}
      <FilledInput
        type={"password"}
        fullWidth
        disableUnderline
        placeholder={placeholder}
        {...register(
          "password",
          validation || {
            required: fieldCannotBeEmpty,
            minLength: {
              value: 8,
              message: passwordMin8
            },
            maxLength: {
              value: 32,
              message: passwordValidationText
            },
            pattern: {
              value: passwordRex,
              message: passwordValidationText
            }
          }
        )}
      />
      {errors.password && (
        <FormHelperText>{errors.password.message}</FormHelperText>
      )}
    </FormControl>
  );
};

import React from "react";
import { lightPurple, palette } from "settings";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

export const AuthBenefitsWrapper = ({ children }) => {
  return (
    <Stack
      pt={4.5}
      pb={8.5}
      px={2.5}
      sx={{
        flex: 1,
        overflow: "hidden",
        background: lightPurple,
        position: "relative",
        boxShadow: "0 1px 2px 0px rgba(0, 0, 0, 0.12)",
        "&::before": {
          content: { xs: "none", md: '""' },
          width: 982,
          height: 483,
          transform: "rotate(-55deg)",
          bottom: 0,
          position: "absolute",
          left: 0,
          background:
            "radial-gradient(105.18% 99.28% at 87.41% 100.66%, #0000B1 0%, #734DF9 74.99%)",
          opacity: 0.1,
          filter: "blur(93px)"
        },
        "&::after": {
          content: { xs: "none", md: '""' },
          transform: "rotate(113deg)",
          top: 0,
          position: "absolute",
          right: 0,
          width: 618,
          height: 680,
          background:
            "radial-gradient(105.18% 99.28% at 87.41% 100.66%, #0000B1 0%, #734DF9 74.99%)",
          opacity: "0.1",
          filter: "blur(93px)"
        }
      }}
    >
      <Box mx={"auto"} width={"124px"} mb={{ xs: 11, md: 10 }}>
        <a href="/" title={"Raise"}>
          <svg width={124} height={40} fill={palette.purple["500"]}>
            <use xlinkHref={"#logo"} />
          </svg>
        </a>
      </Box>

      <Stack
        justifyContent={{ xs: "flex-start", md: "center" }}
        alignItems={"center"}
        sx={{ flex: 1 }}
      >
        {children}
      </Stack>
    </Stack>
  );
};

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import size from "lodash/size";
import braintreeClient from "braintree-web/client";
import {
  CustomSelect,
  FieldCardCode,
  FieldCardExpirationDate,
  FieldCardNumber,
  FieldText
} from "components/fields";
import { useAuth } from "components/AuthProvider";
import { useSnackbar } from "notistack";
import { US_States } from "settings";
import { createApiCall } from "helpers/api";

import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";

export const DebitCreditCardForm = ({
  setSubmittedStatus,
  fetchPaymentMethods
}) => {
  const { session } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm();
  const [loading, setLoading] = useState(false);
  const {
    formState: { dirtyFields },
    handleSubmit
  } = methods;

  useEffect(() => {
    setSubmittedStatus({
      isDirty: !!size(dirtyFields)
    });
  }, [dirtyFields]);

  const onSubmit = handleSubmit((data) => {
    setLoading(true);
    // https://github.com/braintree/braintree-web#advanced-integration
    braintreeClient.create(
      {
        authorization: session.braintreeToken
      },
      function (err, client) {
        if (err) {
          enqueueSnackbar(err.message || "Something went wrong", {
            variant: "error"
          });
          return;
        }
        client.request(
          {
            endpoint: "payment_methods/credit_cards",
            method: "post",
            data: {
              creditCard: {
                number: data.cardNumber.replace(/[ \-_]/g, ""),
                cardholderName: data.name,
                expirationDate: data.expirationDate,
                cvv: data.code,
                billingAddress: {
                  postalCode: data.zip_code,
                  streetAddress: data.street_address
                }
              }
            }
          },
          function (err, response) {
            if (err) {
              enqueueSnackbar(err?.message || "Something went wrong", {
                variant: "error"
              });
              setLoading(false);
              return;
            }
            const card = response.creditCards[0];
            // This is where you would submit payload.nonce to your server
            createApiCall({
              url: "payment-methods",
              method: "POST",
              data: {
                client_uuid: session.clientUuid,
                kind: "BRAINTREE",
                nonce: card?.nonce,
                // debit: "FALSE",
                is_primary: "TRUE",
                address: {
                  address1: data.street_address,
                  address2: "",
                  city: data.city,
                  state: data.state,
                  country: "USA",
                  zipcode: data.zip_code
                }
              }
            }).then(({ status, data }) => {
              if (status === 200 && data?.uuid) {
                enqueueSnackbar(
                  `The ${
                    data?.paymentMethod?.braintreeDetails?.details?.origin
                      ?.type || "Card"
                  } ****${card?.details?.lastFour} was successfully added.`,
                  {
                    variant: "success"
                  }
                );
                fetchPaymentMethods(data.uuid);
              } else {
                enqueueSnackbar(data?.title || "Something went wrong", {
                  variant: "error"
                });
              }
              setLoading(false);
            });
          }
        );
      }
    );
  });

  return (
    <form onSubmit={onSubmit} noValidate>
      <Stack paddingBottom={"40px"} sx={{ flexGrow: 1 }}>
        <Stack sx={{ flexGrow: 1, gap: "16px" }}>
          <FieldCardNumber {...methods} label={""} />
          <FieldText
            {...methods}
            fieldName={"name"}
            size="small"
            placeholder={"Cardholder Name"}
            label={""}
          />
          <Stack direction={{ sm: "row" }} gap={"24px"}>
            <FieldCardExpirationDate {...methods} />
            <FieldCardCode
              {...methods}
              size="small"
              fieldName={"code"}
              label={""}
              placeholder={"CVV"}
            />
          </Stack>
          <Typography
            mt={"8px"}
            variant={"smallTitle"}
            color={"grayscale.800"}
            fontWeight={600}
          >
            Billing address
          </Typography>
          <FieldText
            {...methods}
            size="small"
            fieldName={"street_address"}
            placeholder={"Street Address"}
            label={""}
          />
          <FieldText
            size="small"
            {...methods}
            fieldName={"city"}
            placeholder={"City/Town"}
            label={""}
          />
          <Stack direction={{ sm: "row" }} gap={"24px"}>
            <CustomSelect
              {...methods}
              size="small"
              options={US_States}
              fieldName={"state"}
              placeholder={"State"}
              label={""}
            />
            <FieldText
              {...methods}
              size="small"
              type={"number"}
              fieldName={"zip_code"}
              placeholder={"Zip Code"}
              label={""}
            />
          </Stack>
        </Stack>
      </Stack>
      <LoadingButton
        loading={loading}
        type={"submit"}
        variant={"contained"}
        fullWidth
      >
        Add Card
      </LoadingButton>
    </form>
  );
};

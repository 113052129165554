import React from "react";
import { Line, LineChart, ResponsiveContainer } from "recharts";
import { palette } from "settings";

import Stack from "@mui/material/Stack";

const CardsChart = ({ data, dataKey = "sum" }) => {
  return (
    <Stack sx={{ width: "110%", height: "100px", margin: "0 -10%" }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data}>
          <Line
            type="monotone"
            dataKey={dataKey}
            strokeWidth={2}
            stroke={palette.primary.main}
            dot={null}
            activeDot={null}
            name="Amount"
          />
        </LineChart>
      </ResponsiveContainer>
    </Stack>
  );
};
export default CardsChart;

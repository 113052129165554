import React from "react";
import { FooterLinks } from "./constants";
import { lightPurple, palette, royalBlue } from "settings";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

export const AuthFooter = () => {
  return (
    <Stack
      alignItems={"center"}
      sx={{
        zIndex: 1,
        padding: { xs: "24px 0", sm: "35px 0 48px" },
        background: lightPurple
      }}
    >
      <Stack
        flexWrap={"wrap"}
        direction={"row"}
        justifyContent={"flex-start"}
        gap={{ xs: 2, md: 9 }}
        sx={{ width: "100%", maxWidth: 960, px: 3 }}
      >
        <Box
          component={"a"}
          href="/"
          title={"Raise"}
          alignSelf={{ xs: "flex-end", md: "flex-start" }}
          sx={{ order: { xs: 3, md: -1 }, marginLeft: { xs: "auto", md: 0 } }}
        >
          <svg width={122} height={40} fill={palette.purple["500"]}>
            <use xlinkHref={"#logo"} />
          </svg>
        </Box>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          gap={{ xs: 4, sm: "76px" }}
        >
          {FooterLinks.map(({ id, title, submenu }) => (
            <Stack key={id} spacing={1.5}>
              <Typography fontWeight={700} color={royalBlue}>
                {title}
              </Typography>
              {submenu.map(({ id, title, path }) => (
                <Link
                  key={id}
                  href={path}
                  target={"_blank"}
                  title={title}
                  underline="none"
                  variant={"smallTitle"}
                  color={royalBlue}
                >
                  {title}
                </Link>
              ))}
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

import React from "react";
import { fieldCannotBeEmpty, palette } from "settings";
import { Controller } from "react-hook-form";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";

export const CustomSelect = ({
  control,
  multiple = false,
  formState: { errors },
  placeholder = "",
  label = "",
  fieldName = "",
  options,
  disabled = false,
  size = "medium",
  validation = {
    required: fieldCannotBeEmpty
  }
}) => {
  return (
    <FormControl error={!!errors[fieldName]} sx={{ width: "100%" }} size={size}>
      {label && <InputLabel variant={"filled"}>{label}</InputLabel>}
      <Controller
        control={control}
        name={fieldName}
        rules={validation}
        defaultValue={multiple ? [] : ""}
        render={({ field }) => {
          return (
            <Select
              {...field}
              options={options}
              multiple={multiple}
              placeholder={placeholder}
              displayEmpty
              renderValue={(value) => (value ? value : placeholder)}
              fullWidth
              disabled={disabled}
              IconComponent={(props) => (
                <svg
                  width={24}
                  height={24}
                  {...props}
                  stroke={palette.primary.main}
                >
                  <use href="#cheveron-down" />
                </svg>
              )}
            >
              <MenuItem value="" disabled>
                Placeholder
              </MenuItem>
              {options?.map((el) => (
                <MenuItem key={el.value} value={el.value}>
                  {el.label}
                </MenuItem>
              ))}
            </Select>
          );
        }}
      />

      {errors[fieldName] && (
        <FormHelperText>{errors[fieldName].message}</FormHelperText>
      )}
    </FormControl>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  headCells,
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER,
  defaultFilters,
  defaultData,
  EditUser,
  AddUser,
  RolesDescription
} from "components/pages/portal/users";
import { InfinityTable } from "components/shared";
import { SearchInput } from "components/fields";
import { useAuth } from "components/AuthProvider";
import { midnightBlue } from "settings";
import { createApiCall } from "helpers/api";
import { encodeObjectToQueryString } from "helpers/queryStrings";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

const UsersPage = () => {
  const { session } = useAuth();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [openRolesDescription, setOpenRolesDescription] = useState(false);
  const [openNewUser, setOpenNewUser] = useState(false);
  const [users, setUsers] = useState(defaultData);
  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [orderBy, setOrderBy] = useState(DEFAULT_ORDER_BY);
  const [filter, setFilter] = useState({
    ...defaultFilters,
    pagination: {
      ...defaultFilters.pagination,
      sortBy: {
        field: DEFAULT_ORDER_BY,
        order: DEFAULT_ORDER.toUpperCase()
      }
    }
  });

  const getUsers = useCallback(() => {
    if (session) {
      createApiCall({
        url: `user-management/users?clientUuid=${
          session?.clientUuid
        }&${encodeObjectToQueryString(filter)}`
      }).then(({ status, data }) => {
        if (status === 200) {
          setUsers({
            users: [...users.users, ...data.users],
            pagination: data.pagination
          });
          setLoading(false);
        }
      });
    }
  }, [filter, session]);

  useEffect(() => {
    setLoading(true);
    getUsers();
  }, [filter, session]);

  const onLoadMore = () => {
    setFilter({
      ...filter,
      pagination: {
        ...filter.pagination,
        page: filter.pagination.page + 1
      }
    });
  };

  const searchHandler = (email) => {
    setLoading(true);
    setUsers(defaultData);
    setFilter({
      ...filter,
      pagination: {
        ...filter.pagination,
        page: 0
      },
      email
    });
  };

  const sortHandler = (id, newOrder) => {
    setLoading(true);
    setUsers(defaultData);
    setOrderBy(id);
    setOrder(newOrder);
    setFilter({
      ...filter,
      pagination: {
        ...filter.pagination,
        page: 0,
        sortBy: {
          field: id,
          order: newOrder.toUpperCase()
        }
      }
    });
  };

  const onUserSubmitted = () => {
    setUsers(defaultData);
    setFilter({
      ...filter,
      pagination: {
        ...filter.pagination,
        pageLimit: 20,
        page: 0
      }
    });
    setOpenNewUser(false);
    setUser(null);
    getUsers();
  };

  return (
    <>
      <Helmet>
        <title>User Management</title>
      </Helmet>
      <Box sx={{ minHeight: "100%" }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          mb={1}
          gap={2}
          alignItems={"center"}
        >
          <Typography variant={"h5"} color={midnightBlue}>
            User Management
          </Typography>
          <Button onClick={() => setOpenNewUser(true)}>Add User</Button>
        </Stack>
        <Box mb={3}>
          <SearchInput
            multiple={false}
            placeholder={"Search for email"}
            selectedItems={filter.email ? [filter.email] : []}
            setSelectedItems={searchHandler}
          />
          <Stack direction={"row"} flexWrap={"wrap"} gap={"0 10px"}>
            {filter.email && (
              <Chip
                variant="squared-secondary"
                sx={{
                  mt: "10px"
                }}
                tabIndex={-1}
                label={filter.email}
                onDelete={() => searchHandler("")}
              />
            )}
          </Stack>
        </Box>
        <InfinityTable
          headCells={headCells}
          loading={loading}
          data={users?.users || []}
          total={users?.pagination.totalResults || 0}
          order={order}
          orderBy={orderBy}
          createSortHandler={sortHandler}
          onLoadMore={onLoadMore}
          onRowClick={setUser}
          toolbarTitle={`Your User List ${
            users?.pagination.totalResults
              ? "(" + users?.pagination.totalResults + ")"
              : ""
          }`}
        />
        <Stack alignItems={"flex-end"} mt={3}>
          <Link
            component={"button"}
            underline="none"
            variant={"smallTitle"}
            onClick={() => setOpenRolesDescription(true)}
          >
            Learn more about role descriptions
          </Link>
        </Stack>
      </Box>
      <EditUser
        onClose={() => setUser(null)}
        user={user}
        onSubmitted={onUserSubmitted}
      />
      <AddUser
        onClose={() => setOpenNewUser(false)}
        open={!!openNewUser}
        onSubmitted={onUserSubmitted}
        onOpenRoles={() => setOpenRolesDescription(true)}
      />
      <RolesDescription
        open={openRolesDescription}
        onClose={() => setOpenRolesDescription(false)}
      />
    </>
  );
};

export default UsersPage;

export function removeCookie(cname) {
  const expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC;";
  document.cookie = `${cname}=;${expires};path=/`;
}

export function setCookie(cname, cvalue, hours = 1) {
  const date = new Date();
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

export function getCookie(cname) {
  const matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        cname.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : null;
}

import { useEffect, useMemo, useRef } from "react";
import debounce from "lodash/debounce";

export const useDebounce = (callback, timout = 500) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => ref.current?.();
    return debounce(func, timout);
  }, []);

  return debouncedCallback;
};

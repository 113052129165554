import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import _includes from "lodash/includes";
import { differenceInDays, format, subDays, isToday } from "date-fns";
import {
  FilterModal,
  headCells,
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER,
  defaultFilters,
  defaultTransactionsState,
  transactionsStatus,
  collectFilter
} from "components/pages/portal/transactions";
import { InfinityTable, ToolbarActions } from "components/shared";
import { SearchInput } from "components/fields";
import { useSnackbar } from "notistack";
import { useAuth } from "components/AuthProvider";
import { createApiCall } from "helpers/api";
import { midnightBlue, theme } from "settings";
import { downloadFile } from "helpers/downloadBinary";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import useMediaQuery from "@mui/material/useMediaQuery";

const TransactionsPage = () => {
  const { session } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [openFilter, setOpenFilter] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState(defaultTransactionsState);
  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [orderBy, setOrderBy] = useState(DEFAULT_ORDER_BY);
  const [filter, setFilter] = useState(defaultFilters);

  const isTabletOrSmall = useMediaQuery(theme.breakpoints.down("md"));

  const isDefaultFilter = useMemo(() => {
    const {
      filter: {
        date_range: { created_at_from, created_at_to }
      }
    } = filter;
    return (
      isToday(created_at_to) &&
      differenceInDays(created_at_to, created_at_from) === 30
    );
  }, [filter]);

  const formatDateRange = useMemo(() => {
    const from = filter.filter?.date_range?.created_at_from
      ? format(filter.filter?.date_range?.created_at_from, "MM/dd/yyyy")
      : "";
    const to = filter.filter?.date_range?.created_at_to
      ? format(filter.filter?.date_range?.created_at_to, "MM/dd/yyyy")
      : "";
    return { from, to };
  }, [filter.filter?.date_range]);

  useEffect(() => {
    setLoading(true);

    createApiCall({
      method: "POST",
      prefix: "/admin/v2/",
      url: `transactions`,
      data: collectFilter(filter, order, orderBy)
    }).then(({ data, status }) => {
      if (status === 200) {
        setTransactions({
          pagination: data.pagination,
          transaction_id: filter.pagination.page
            ? [...transactions.transaction_id, ...data.transactions]
            : [...data.transactions]
        });
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoading(false);
    });
  }, [filter, order, orderBy]);

  const onLoadMore = () => {
    setFilter({
      ...filter,
      pagination: {
        ...filter.pagination,
        page: filter.pagination.page + 1
      }
    });
  };

  const searchHandler = (transaction_id) => {
    setTransactions(defaultTransactionsState);
    setFilter({
      ...defaultFilters,
      filter: { ...filter.filter, transaction_id }
    });
  };

  const searchHandlerFilters = (newParam) => {
    setTransactions(defaultTransactionsState);
    setFilter({
      ...defaultFilters,
      filter: { ...filter.filter, ...newParam }
    });
  };

  const onFilter = (data) => {
    setTransactions(defaultTransactionsState);
    const newFilterParams = {
      date_range: {
        created_at_from: data.startDate || subDays(new Date(), 30),
        created_at_to: data.endDate || new Date()
      }
    };
    if (data.brands?.length) newFilterParams.brands = data.brands;
    if (data.transactions) newFilterParams.transaction_id = data.transactions;
    if (data.customers) newFilterParams.customer_id = data.customers;
    if (data.emails) newFilterParams.email = data.emails;
    if (data.status) newFilterParams.status = data.status;
    setFilter({
      ...defaultFilters,
      filter: newFilterParams
    });
    setOpenFilter(false);
  };

  const clearFilter = () => {
    setTransactions(defaultTransactionsState);
    setFilter(defaultFilters);
  };

  const onExport = () => {
    setLoadingFile(true);
    createApiCall({
      method: "POST",
      prefix: "/admin/v2/",
      url: `transactions-report`,
      data: {
        filter: collectFilter(filter, order, orderBy).filter
      }
    }).then(async ({ data, status }) => {
      if (status === 200) {
        const {
          date_range: { created_at_from, created_at_to }
        } = filter.filter;
        downloadFile(
          data.report,
          `Transactions report for ${format(
            created_at_from,
            "MM/dd/yyyy"
          )} - ${format(created_at_to, "MM/dd/yyyy")}.csv`
        );
      } else {
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
      setLoadingFile(false);
    });
  };

  const sortHandler = (id, newOrder) => {
    setTransactions(defaultTransactionsState);
    setFilter({
      ...filter,
      pagination: defaultFilters.pagination
    });
    setOrderBy(id);
    setOrder(newOrder);
  };

  const onOpenFilter = () => {
    setOpenFilter(true);
  };

  const menuOptions = useMemo(
    () => [
      {
        id: 0,
        isHidden: _includes(session?.roles, "CLIENT_AGENT"),
        icon: "export",
        loading: loadingFile,
        text: "Download .CSV",
        onClick: onExport
      },
      {
        id: 1,
        icon: "filter-new",
        text: "Filter",
        onClick: onOpenFilter
      }
    ],
    [loadingFile, session]
  );

  return (
    <>
      <Helmet>
        <title>Transactions</title>
      </Helmet>
      <Box sx={{ minHeight: "100%" }}>
        <Stack justifyContent={"center"} sx={{ minHeight: 56 }} mb={1}>
          <Typography variant={"h5"} color={midnightBlue}>
            Transactions
          </Typography>
        </Stack>
        <Box mb={3}>
          <SearchInput
            placeholder={"Search for transaction ID"}
            selectedItems={filter?.filter?.transaction_id || []}
            setSelectedItems={searchHandler}
          />
          <Stack direction={"row"} flexWrap={"wrap"} gap={"0 10px"}>
            {!!filter.filter?.brands?.length &&
              filter.filter.brands.map((item) => (
                <Chip
                  key={item.uuid}
                  variant="squared-secondary"
                  sx={{
                    mt: "10px"
                  }}
                  tabIndex={-1}
                  label={item.name}
                  onDelete={() =>
                    searchHandlerFilters({
                      brands: filter.filter.brands.filter(
                        (el) => el.uuid !== item.uuid
                      )
                    })
                  }
                />
              ))}
            {!!filter.filter?.transaction_id?.length &&
              filter.filter.transaction_id.map((item) => (
                <Chip
                  key={item}
                  variant="squared-secondary"
                  sx={{
                    mt: "10px"
                  }}
                  tabIndex={-1}
                  label={item}
                  onDelete={() =>
                    searchHandlerFilters({
                      transaction_id: filter.filter.transaction_id.filter(
                        (el) => el !== item
                      )
                    })
                  }
                />
              ))}
            {!!filter.filter?.customer_id?.length &&
              filter.filter.customer_id.map((item) => (
                <Chip
                  key={item}
                  variant="squared-secondary"
                  sx={{
                    mt: "10px"
                  }}
                  tabIndex={-1}
                  label={item}
                  onDelete={() =>
                    searchHandlerFilters({
                      customer_id: filter.filter.customer_id.filter(
                        (el) => el !== item
                      )
                    })
                  }
                />
              ))}
            {!!filter.filter?.email?.length &&
              filter.filter.email.map((item) => (
                <Chip
                  key={item}
                  variant="squared-secondary"
                  sx={{
                    mt: "10px"
                  }}
                  tabIndex={-1}
                  label={item}
                  onDelete={() =>
                    searchHandlerFilters({
                      email: filter.filter.email.filter((el) => el !== item)
                    })
                  }
                />
              ))}
            {!!filter.filter?.status?.length &&
              filter.filter.status.map((item) => (
                <Chip
                  key={item}
                  variant="squared-secondary"
                  sx={{
                    mt: "10px"
                  }}
                  tabIndex={-1}
                  label={
                    transactionsStatus.find((o) => o.value === item)?.label
                  }
                  onDelete={() =>
                    searchHandlerFilters({
                      status: filter.filter.status.filter((el) => el !== item)
                    })
                  }
                />
              ))}
            {!isDefaultFilter && formatDateRange.from && formatDateRange.to && (
              <Chip
                variant="squared-secondary"
                sx={{
                  mt: "10px"
                }}
                tabIndex={-1}
                label={`From: ${formatDateRange.from} To ${formatDateRange.to}`}
                onDelete={() =>
                  searchHandlerFilters({
                    date_range: {
                      created_at_from: subDays(new Date(), 30),
                      created_at_to: new Date()
                    }
                  })
                }
              />
            )}
          </Stack>
        </Box>
        <InfinityTable
          headCells={headCells}
          loading={loading}
          data={transactions?.transaction_id || []}
          total={transactions?.pagination?.totalResults || 0}
          order={order}
          orderBy={orderBy}
          createSortHandler={sortHandler}
          onLoadMore={onLoadMore}
          toolbarTitle={`Your Transaction Report ${
            transactions?.pagination?.totalResults
              ? "(" + transactions?.pagination?.totalResults + ")"
              : ""
          }`}
          toolbarActions={
            <>
              {!isTabletOrSmall && (
                <>
                  {isDefaultFilter ? (
                    <Typography variant={"smallTitle"} color={"#fff"}>
                      Past 30 days
                    </Typography>
                  ) : (
                    <Link
                      component={"button"}
                      underline={"none"}
                      variant={"smallTitle"}
                      sx={{
                        color: "#fff",
                        lineHeight: 1,
                        whiteSpace: "nowrap",
                        display: "flex",
                        alignItems: "center"
                      }}
                      onClick={clearFilter}
                    >
                      <svg width={24} height={24} stroke={"#fff"}>
                        <use href="#close" />
                      </svg>
                      Clear Filter
                    </Link>
                  )}
                </>
              )}
              <ToolbarActions options={menuOptions} />
            </>
          }
        />
      </Box>
      <FilterModal
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        filterParams={filter.filter}
        defaultValues={{
          startDate: filter.filter.date_range.created_at_from,
          endDate: filter.filter.date_range.created_at_to,
          brands: [],
          transactions: null,
          customers: null,
          emails: null,
          status: null
        }}
        onFilter={onFilter}
      />
    </>
  );
};

export default TransactionsPage;

import { endOfDay, startOfDay, toDate } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

import qs from "qs";

export const dateToUnix = (date) => Math.floor(toDate(date).getTime() / 1000);
const dateRangeToUnix = (date) => Math.floor(date.getTime() / 1000);

/* Returns query string for range filters */
export const filtersToQueryString = (filters) => {
  const key = Object.keys(filters)[0];
  let range = { from: "", to: "", keys: {} };
  const { field, value } = filters;
  switch (key) {
    case "preset":
      range.from = dateToUnix(filters[key].start);
      range.to = dateToUnix(filters[key].end);
      range.keys = {
        from: filters[key].start,
        to: filters[key].end
      };
      break;
    case "range":
      range.from = dateRangeToUnix(startOfDay(filters[key][0].startDate));
      range.to = dateRangeToUnix(endOfDay(filters[key][0].endDate));
      range.keys = {
        from: filters[key][0].startDate,
        to: filters[key][0].endDate
      };
      break;
    default:
      range.from = filters.start;
      range.to = filters.end;
      break;
  }

  let searchString = "";
  if (field && value) {
    searchString = `&search_by=${field}&value=${value}`;
  }

  const isOneDay = range.to - range.from < 86400;
  if (isOneDay) {
    range.from = dateRangeToUnix(startOfDay(new Date(range.from * 1000)));
    range.to = dateRangeToUnix(endOfDay(new Date(range.from * 1000)));
  }

  const translateToUTC = (base, unix) => {
    const dt = base ? new Date(base) : new Date();
    const offset = dt.getTimezoneOffset();
    return unix - offset * 60;
  };

  //translate to UTC
  range.from = translateToUTC(range.keys.from, range.from);
  range.to = translateToUTC(range.keys.to, range.to);

  return {
    string: `?created_at_from=${range.from}&created_at_to=${range.to}${searchString}`,
    range
  };
};

/* Returns query string for sorting */
export const sortToQueryString = (sort) => {
  if (!sort) {
    return "";
  }

  const mapping = {
    //1 - value, 0 - created_at
    denomination: 1,
    date: 0
  };
  return `&sort_order=${sort.direction.toUpperCase()}&sort_by=${
    mapping[sort.key]
  }`;
};

/* Returns query string for pagination */
export const paginationToQueryString = (currentPage) => {
  if (currentPage === undefined) {
    return "";
  }
  return `&page_from=${currentPage - 1}&page_limit=${13}`;
};

/* Returns query string from object */
export const encodeObjectToQueryString = (object, skipNulls = false) => {
  if (!object) return "";
  return qs.stringify(object, {
    encoder: (str, defaultEncoder, charset, type) => {
      if (type === "key") {
        return str.replace(/\[/g, ".").replace(/]/g, "");
      }
      return encodeURIComponent(str);
    },
    skipNulls,
    indices: false
  });
};
export const formatToUTC = (date, position = "start") => {
  if (!date) return undefined;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // Get the beginning of the day in the local time zone

  const startOfDayLocal = startOfDay(date, { timeZone });
  const endOfDayLocal = endOfDay(date, { timeZone });
  // Convert the start or end of the day to UTC
  const utcDate = zonedTimeToUtc(
    position === "start" ? startOfDayLocal : endOfDayLocal,
    "UTC"
  );
  return utcDate.toISOString();
};

import {
  documentsFormat,
  documentsSize3Mb,
  fieldCannotBeEmpty,
  palette
} from "../../settings";
import { isDocument } from "helpers/validation";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import React from "react";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";

export const UploadButton = ({
  handleSelectedFile,
  selectedFiles,
  multiple = false,
  onRemove,
  register,
  formState: { errors },
  fieldName = "name",
  validation = {
    required: fieldCannotBeEmpty
  },
  label = "Upload Documents",
  btnVariant = "outlinedPrimary",
  accept = ["image/*", ".pdf"]
}) => {
  const { onChange, ...params } = register(fieldName, {
    ...validation,
    validate: {
      lessThan3MB: (files) => files[0]?.size < 3145728 || documentsSize3Mb,
      acceptedFormats: (files) => isDocument(files[0]) || documentsFormat
    }
  });
  return (
    <FormControl error={!!errors[fieldName]}>
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems={"baseline"}
        gap={{ xs: 3, md: 5 }}
      >
        <Button
          variant={btnVariant}
          component="label"
          size={"small"}
          disabled={selectedFiles?.length}
          startIcon={
            <svg
              width={24}
              height={24}
              style={{ minWidth: 24, color: "inherit" }}
            >
              <use xlinkHref={"#upload"} />
            </svg>
          }
          sx={{
            whiteSpace: "nowrap",
            width: { xs: "100%", md: "auto" },
            py: 1.6,
            px: 4,
            "&.Mui-disabled": {
              background: palette.purple[100],
              color: palette.purple[300],
              stroke: palette.purple[300]
            }
          }}
        >
          {label}
          <input
            hidden
            {...params}
            onChange={(e) => {
              onChange(e);
              handleSelectedFile(e);
            }}
            id={"files"}
            accept={accept}
            multiple={multiple}
            type="file"
          />
        </Button>
        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          width={{ xs: "100%", md: "50%" }}
          sx={{ gap: "8px" }}
        >
          {selectedFiles?.map((el, index) => (
            <Stack
              key={el.name}
              direction={"row"}
              alignItems={"center"}
              sx={{ gap: "8px" }}
            >
              <svg
                color={palette.grayscale["700"]}
                width={24}
                height={24}
                style={{ minWidth: 24 }}
              >
                <use xlinkHref={"#uploadFile"} />
              </svg>
              <Typography
                variant={"smallTitle"}
                sx={{ color: palette.grayscale[800] }}
              >
                {el.name}
              </Typography>
              <IconButton size={"small"} onClick={() => onRemove(index)}>
                <svg
                  width={24}
                  height={24}
                  stroke={palette.grayscale["700"]}
                  style={{ minWidth: 24 }}
                >
                  <use href="#close" />
                </svg>
              </IconButton>
            </Stack>
          ))}
        </Stack>
      </Stack>
      {!!errors[fieldName] && (
        <FormHelperText>{errors[fieldName].message}</FormHelperText>
      )}
    </FormControl>
  );
};

import React from "react";
import { palette, theme } from "settings";

import MenuList from "@mui/material/MenuList";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";

export const TableOptionsMenu = ({ options = [], onClose }) => {
  const isTabletOrSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <MenuList
      component={Stack}
      direction={isTabletOrSmall ? "column" : "row"}
      spacing={isTabletOrSmall ? "8px" : 4}
      alignItems={isTabletOrSmall ? "flex-start" : "center"}
      sx={{
        padding: "6px",
        ".MuiMenuItem-root": {
          padding: "8px",
          width: "100%"
        }
      }}
    >
      {options?.map((el) => {
        const {
          onClick,
          id,
          icon,
          isHidden = false,
          text,
          divider = false,
          loading = false
        } = el;
        if (isHidden) {
          return null;
        }

        if (divider) {
          return (
            <Divider
              key={id}
              orientation={isTabletOrSmall ? "horizontal" : "vertical"}
              sx={
                isTabletOrSmall
                  ? {
                      borderWidth: "1px",
                      width: "100%",
                      mb: "0 !important",
                      borderColor: palette.grayscale[200]
                    }
                  : {
                      mx: "10px",
                      width: "1px",
                      height: "16px",
                      background: "#fff"
                    }
              }
            />
          );
        }

        if (!isTabletOrSmall) {
          return (
            <Tooltip
              key={id}
              title={text}
              componentsProps={{
                tooltip: {
                  sx: {
                    padding: "4px 12px",
                    fontSize: 12
                  }
                }
              }}
              placement="bottom"
              variant={"small"}
            >
              <IconButton
                size={"small"}
                disabled={loading}
                onClick={() => {
                  if (onClose) onClose();
                  onClick();
                }}
              >
                <svg
                  width={24}
                  height={24}
                  stroke={loading ? palette.grayscale["400"] : "#fff"}
                >
                  <use href={`#${icon}`} />
                </svg>
              </IconButton>
            </Tooltip>
          );
        }

        return (
          <MenuItem
            key={id}
            disabled={loading}
            onClick={() => {
              if (onClose) onClose();
              onClick();
            }}
          >
            <ListItemIcon>
              <svg
                width={24}
                height={24}
                stroke={
                  loading ? palette.grayscale["400"] : palette.grayscale[800]
                }
              >
                <use href={`#${icon}`} />
              </svg>
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontWeight: 700,
                fontSize: 14
              }}
            >
              {text}
            </ListItemText>
          </MenuItem>
        );
      })}
    </MenuList>
  );
};

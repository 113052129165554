import React, { useMemo } from "react";
import valid from "card-validator";
import InputMask from "react-input-mask";
import { Controller } from "react-hook-form";
import filter from "lodash/filter";
import some from "lodash/some";
import { cardIsNotCorrect, fieldCannotBeEmpty, theme } from "settings";

import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import useMediaQuery from "@mui/material/useMediaQuery";

export const cardBrandCode = [
  "visa",
  "americanexpress",
  "discover",
  "mastercard"
];

export const FieldCardNumber = ({
  control,
  watch,
  formState: { errors },
  label = "Card Number",
  fieldName = "cardNumber",
  placeholder = "Card Number",
  size = "small"
}) => {
  const upSM = useMediaQuery(theme.breakpoints.up("sm"));
  const value = watch(fieldName);

  const filteredCardAdornments = useMemo(() => {
    const clearedField = value?.replace(/[ \-()+_]/g, "");
    const cardType = valid
      .number(clearedField, { luhnValidateUnionPay: true })
      ?.card?.type?.replace(/[ \-()+_]/g, "");
    if (cardType && some(cardBrandCode, (o) => o === cardType)) {
      return filter(cardBrandCode, (o) => o === cardType);
    }
    return upSM ? cardBrandCode : [];
  }, [value, upSM]);

  return (
    <FormControl variant="filled" error={!!errors[fieldName]} size={size}>
      {label && <InputLabel sx={{ mb: "6px" }}>{label}</InputLabel>}
      <Controller
        name={fieldName}
        control={control}
        rules={{
          required: fieldCannotBeEmpty,
          validate: {
            cardValid: (v) => {
              const clearedField = v?.replace(/[ \-()+_]/g, "");
              return (
                valid.number(clearedField, { luhnValidateUnionPay: true })
                  .isValid || cardIsNotCorrect
              );
            }
          }
        }}
        render={({ field: { onChange, value, name, ref } }) => (
          <InputMask
            mask={"9999 9999 9999 9999 9999"}
            maskPlaceholder={null}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          >
            <FilledInput
              name={name}
              fullWidth
              disableUnderline
              placeholder={placeholder}
              inputRef={ref}
              endAdornment={
                <InputAdornment position="end" sx={{ gap: "5px" }}>
                  {filteredCardAdornments.map((el) => (
                    <svg key={el} width={30} height={20}>
                      <use
                        xlinkHref={`#${el}${
                          filteredCardAdornments.length <= 1 ? "-active" : ""
                        }`}
                      />
                    </svg>
                  ))}
                </InputAdornment>
              }
            />
          </InputMask>
        )}
      />
      {errors[fieldName] && (
        <FormHelperText>{errors[fieldName].message}</FormHelperText>
      )}
    </FormControl>
  );
};

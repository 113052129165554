import React, { useMemo, useState } from "react";
import { palette, redAlert } from "settings";
import { BrandsLogo } from "../brands";

import _some from "lodash/some";

import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

export const SelectedBrands = ({
  open,
  onClose,
  handleSubmit,
  checkedBrands = [],
  confirmSelectedBrands,
  onDelete,
  confirmedAmount,
  errors
}) => {
  const [searchValue, setSearchValue] = useState("");

  const sortedBrands = useMemo(() => {
    if (Array.isArray(checkedBrands)) {
      return checkedBrands.filter((el) => {
        if (el?.name && searchValue) {
          return el.name.toLowerCase().includes(searchValue.toLowerCase());
        } else return el;
      });
    } else return [];
  }, [checkedBrands, searchValue]);

  const handleClose = () => {
    onClose();
    setSearchValue("");
  };

  const handleConfirm = (e) => {
    handleSubmit(e);
    handleClose();
  };

  const handleSearch = (event) => {
    const value = event.target.value || "";
    setSearchValue(value);
  };

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      sx={{
        ".MuiPaper-root": {
          background: "#FFFFFF",
          width: { xs: "100%", md: 500 }
        }
      }}
    >
      <Stack sx={{ height: "100%" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            p: 3,
            borderBottom: `1px solid ${palette.grayscale["200"]}`
          }}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
            <svg width={24} height={24} stroke={palette.purple["500"]}>
              <use href="#tag-ico" />
            </svg>
            <Typography
              variant={"subLead"}
              fontWeight={700}
              color={"purple.900"}
            >
              Selected brands
            </Typography>
          </Stack>
          <IconButton size={"small"} onClick={() => onClose(true)}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use href="#close" />
            </svg>
          </IconButton>
        </Stack>

        <Stack
          sx={{
            p: { xs: "16px 16px 0", md: "24px 24px 0" },
            flex: "0 1 100%",
            gap: 2,
            overflowY: "auto",
            height: "100%",
            "& .table-wrapper": {
              maxWidth: "100%",
              overflowX: "auto"
            }
          }}
        >
          <TextField
            variant={"filled"}
            value={searchValue}
            name={"search brand"}
            onChange={handleSearch}
            placeholder={"Search brand"}
            className={"filled-rounded"}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <>
                  <InputAdornment
                    position="start"
                    sx={{
                      width: "auto",
                      height: "auto",
                      marginTop: "0 !important"
                    }}
                  >
                    <svg
                      width={24}
                      height={24}
                      stroke={palette.grayscale["800"]}
                    >
                      <use xlinkHref={"#search-new"} />
                    </svg>
                  </InputAdornment>
                </>
              )
            }}
          />

          <Box my={2}>
            <Stack
              mb={3}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography
                variant={"subLead"}
                fontWeight={700}
                color={"grayscale.800"}
              >
                {confirmSelectedBrands
                  ? `Confirm brands (${checkedBrands?.length || 0})`
                  : `Selected brands (${checkedBrands?.length || 0})`}
              </Typography>
              {!!checkedBrands?.length ? (
                <Button
                  variant={"text"}
                  sx={{
                    padding: { xs: "4px 8px" }
                  }}
                  onClick={() => onDelete("all")}
                >
                  Remove all
                </Button>
              ) : (
                errors?.checked_brands && (
                  <Typography
                    variant={"body1"}
                    color={redAlert}
                    fontWeight={700}
                  >
                    {errors.checked_brands?.message}
                  </Typography>
                )
              )}
            </Stack>

            <Stack
              direction={"row"}
              flexWrap={"wrap"}
              justifyContent={"center"}
              sx={{ width: "100%" }}
              gap={{ xs: 2.5, md: 3 }}
            >
              {sortedBrands?.map((element) => {
                const { fixedLoad, variableLoad } = element;
                let isError = false;
                const amountCent = Number(confirmedAmount || 0) * 100;
                if (!!amountCent && fixedLoad?.amounts?.length) {
                  isError = !_some(fixedLoad.amounts, (o) => o === amountCent);
                } else if (!!amountCent) {
                  isError = confirmedAmount
                    ? amountCent < variableLoad?.minimumAmount ||
                      amountCent > variableLoad?.maximumAmount
                    : false;
                }

                return (
                  <BrandsLogo
                    key={element.uuid}
                    data={element}
                    onRemove={onDelete}
                    status={{ error: isError }}
                  />
                );
              })}
            </Stack>
          </Box>
        </Stack>

        {confirmSelectedBrands && (
          <Box p={"16px 24px 24px"}>
            <Button
              onClick={handleConfirm}
              fullWidth
              variant={"contained"}
              disabled={!!errors?.checked_brands}
            >
              Confirm
            </Button>
          </Box>
        )}
      </Stack>
    </Drawer>
  );
};

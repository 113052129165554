import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { endOfDay, startOfDay, subDays, subMonths, subYears } from "date-fns";
import { CAMPAIGNS_PAGE, palette } from "settings";

import TopBrands from "./TopBrands";
import CardsChart from "./CardsChart";
import { displayFullMoney } from "helpers/money";
import { calculatePercentDifference } from "helpers/math";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";
import { useAuth } from "components/AuthProvider";

const date = new Date();
const startDay = startOfDay(date);
const endDay = endOfDay(date);
export const periodsList = [
  {
    value: "week",
    label: "W",
    tooltipText: "Previous 7 days",
    param: { preset: { end: endDay, start: subDays(startDay, 7) } }
  },
  {
    value: "month",
    label: "M",
    tooltipText: "Previous 30 days",
    param: { preset: { end: endDay, start: subMonths(startDay, 1) } }
  },
  {
    value: "year",
    label: "Y",
    tooltipText: "Previous year",
    param: { preset: { end: endDay, start: subYears(startDay, 1) } }
  },
  {
    value: "custom",
    label: "",
    icon: "#edit-pencil",
    tooltipText: "Custom period"
  }
];

const OverviewNew = ({
  activePeriod,
  isPortalClient,
  dashboardInfo,
  onChangePeriod,
  setOpenFilter,
  setOpenRecipient,
  loading
}) => {
  const navigate = useNavigate();
  const { isApiEnabled, isCampaignApiClient, isCampaignEnabled } = useAuth();
  const isApiOnly = isApiEnabled && !isCampaignApiClient && !isCampaignEnabled;
  const cards = useMemo(() => {
    // campaign data
    const oldCampaignData = dashboardInfo?.campaignCodes?.previousTimeframeData;
    const newCampaignData = dashboardInfo?.campaignCodes?.timeframeData;
    const countCampaignPercent = calculatePercentDifference(
      oldCampaignData?.count,
      newCampaignData?.count
    );

    // transaction data
    const oldTransactionData =
      dashboardInfo?.transactions?.previousTimeframeData;
    const newTransactionData = dashboardInfo?.transactions?.timeframeData;
    const transactionPercentage = calculatePercentDifference(
      +(oldTransactionData?.sum / 100) || 0,
      +(newTransactionData?.sum / 100) || 0
    );

    // order volume data
    const orderVolumePercentage = calculatePercentDifference(
      oldTransactionData?.count,
      newTransactionData?.count
    );

    return [
      {
        label: "Order volume",
        isPortal: false,
        isCampaign: true,
        value: newTransactionData?.count || 0,
        percentage: orderVolumePercentage,
        chart: dashboardInfo?.transactions?.denomData || []
      },
      {
        label: "Card value purchased",
        isPortal: false,
        isCampaign: true,
        dataKey: "count",
        value: displayFullMoney(newTransactionData?.sum || 0),
        percentage: transactionPercentage,
        chart: dashboardInfo?.transactions?.denomData || []
      },
      {
        label: "Redemptions",
        isPortal: true,
        isCampaign: false,
        dataKey: "count",
        value: newCampaignData?.count || 0,
        percentage: countCampaignPercent,
        chart: dashboardInfo?.campaignCodes?.denomData || []
      }
    ];
  }, [dashboardInfo]);

  return (
    <Stack gap={2}>
      <Stack
        justifyContent={"space-between"}
        alignItems={{ xs: "flex-start", lg: "center" }}
        direction={{ xs: "column", lg: "row" }}
        gap={2}
      >
        <Typography variant={"h5"}>Overview</Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={{ xs: "flex-start", md: "center" }}
          width={"100%"}
          justifyContent={{ xs: "space-between", lg: "flex-end" }}
          gap={2}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={1 / 2}
            sx={{
              padding: "9px",
              height: 56,
              background: "white",
              border: `1px solid ${palette.grayscale[200]}`,
              borderRadius: "100px"
            }}
          >
            {periodsList.map(({ label, value, icon = null, tooltipText }) => (
              <Tooltip
                key={value}
                title={tooltipText}
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      textAlign: "center",
                      padding: "8px 12px",
                      fontSize: 14
                    }
                  }
                }}
                placement="bottom"
                variant={"small"}
              >
                <Chip
                  sx={{
                    width: "48px",
                    fontSize: "18px",
                    borderRadius: "100px",
                    height: "37px",
                    fontWeight: "600",
                    transition: "all 0.3s",
                    color:
                      activePeriod.value === value
                        ? "white"
                        : palette.grayscale[400],
                    background:
                      activePeriod.value === value
                        ? palette.primary.main
                        : "transparent",
                    "&:hover": {
                      backgroundColor:
                        activePeriod.value === value
                          ? palette.primary.main
                          : "transparent"
                    },
                    "& .MuiChip-icon": {
                      margin: 0,
                      color:
                        activePeriod.value === value
                          ? "white"
                          : palette.grayscale["400"]
                    },
                    "& .MuiChip-icon +  .MuiChip-label ": {
                      padding: 0
                    }
                  }}
                  label={label}
                  clickable={true}
                  onClick={() => {
                    if (value === "custom") {
                      setOpenFilter(true);
                    } else onChangePeriod(value);
                  }}
                  icon={
                    icon && (
                      <svg width={24} height={24}>
                        <use href={icon} />
                      </svg>
                    )
                  }
                />
              </Tooltip>
            ))}
          </Stack>
          <Stack
            gap={2}
            direction={"row"}
            sx={{
              width: { xs: "100%", lg: "auto" },
              order: { xs: -1, md: 1 },
              justifyContent: { xs: "space-between", md: "flex-end" }
            }}
          >
            {isCampaignEnabled && (
              <Tooltip
                title={"Recipients"}
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      textAlign: "center",
                      padding: "8px 12px",
                      fontSize: 14
                    }
                  }
                }}
                placement="bottom"
                variant={"small"}
              >
                <Chip
                  label={dashboardInfo?.activeCustomers || 0}
                  sx={{
                    padding: 2,
                    fontSize: "18px",
                    height: "56px",
                    fontWeight: "600",
                    background: "white",
                    border: `1px solid ${palette.grayscale[200]}`,
                    borderRadius: "36px",
                    gap: 1,
                    "& .MuiChip-deleteIcon": {
                      margin: 0,
                      color: palette.grayscale["400"]
                    },
                    "& .MuiChip-label ": {
                      padding: 0
                    }
                  }}
                  onDelete={() => setOpenRecipient(true)}
                  deleteIcon={
                    <svg width={24} height={24} color={"#fff"}>
                      <use href="#add-user" />
                    </svg>
                  }
                />
              </Tooltip>
            )}

            <Button
              sx={{ minWidth: 190, display: !isApiOnly ? 'block' : 'none' }}
              onClick={() =>
                navigate(CAMPAIGNS_PAGE, { state: { openDrawer: true } })
              }
            >
              Create campaign
            </Button>
          </Stack>
        </Stack>
      </Stack>

      {/*  cards*/}
      <Grid container spacing={3} alignItems={"stretch"}>
        {!isCampaignEnabled && (
          <Grid item xs={12} lg={4}>
            {loading ? (
              <Skeleton variant="rounded" height={150} width={"100%"} />
            ) : (
              <TopBrands brands={dashboardInfo?.brands.slice(0, 5)} size={55} />
            )}
          </Grid>
        )}
        {cards.map((el) => {
          if (!el.isPortal && isPortalClient) {
            return null;
          }
          if (!el.isCampaign && !isCampaignEnabled) {
            return null;
          }
          if (loading) {
            return (
              <Grid item xs={12} lg={4} key={el.label}>
                <Skeleton variant="rounded" height={150} width={"100%"} />
              </Grid>
            );
          }
          return (
            <Grid item xs={12} lg={4} key={el.label}>
              <Paper
                elevation={2}
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 2,
                  padding: 3
                }}
              >
                <Grid
                  container
                  spacing={3}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Grid
                    item
                    xs={
                      !(!isPortalClient && isCampaignEnabled) &&
                      el.chart?.length
                        ? 6
                        : "auto"
                    }
                  >
                    <Typography variant={"subLead"} fontWeight={700}>
                      {el.label}
                    </Typography>

                    <Typography
                      variant={"lead"}
                      fontWeight={700}
                      color={"primary"}
                    >
                      {el.value}
                    </Typography>

                    {!(!isPortalClient && isCampaignEnabled) &&
                      el.chart?.length && (
                        <Chip
                          label={`${el.percentage}%`}
                          color={el.percentage > 0 ? "seaGreen" : "vibrantRed"}
                          size={"small"}
                          sx={{
                            mt: 2,
                            "& .MuiChip-icon": {
                              marginLeft: "10px",
                              marginRight: 0
                            },
                            "& .MuiChip-label ": {
                              padding: "0 10px 0 8px"
                            }
                          }}
                          icon={
                            <svg width={15} height={15} stroke={"#fff"}>
                              <use
                                xlinkHref={`#arrow-bold-${
                                  el.percentage > 0 ? "up" : "down"
                                }`}
                              />
                            </svg>
                          }
                        />
                      )}
                  </Grid>

                  <Grid
                    item
                    xs={
                      !(!isPortalClient && isCampaignEnabled) &&
                      el.chart?.length
                        ? 6
                        : "auto"
                    }
                  >
                    {(!isPortalClient && isCampaignEnabled) ||
                    !el.chart?.length ? (
                      <Chip
                        label={`${el.percentage}%`}
                        color={el.percentage > 0 ? "seaGreen" : "vibrantRed"}
                        size={"small"}
                        sx={{
                          "& .MuiChip-icon": {
                            marginLeft: "10px",
                            marginRight: 0
                          },
                          "& .MuiChip-label ": {
                            padding: "0 10px 0 8px"
                          }
                        }}
                        icon={
                          <svg width={15} height={15} stroke={"#fff"}>
                            <use
                              xlinkHref={`#arrow-bold-${
                                el.percentage > 0 ? "up" : "down"
                              }`}
                            />
                          </svg>
                        }
                      />
                    ) : (
                      <CardsChart
                        data={el.chart}
                        dataKey={el.dataKey || "sum"}
                      />
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          );
        })}
        {isPortalClient && (
          <Grid item xs={12} lg={8}>
            {loading ? (
              <Skeleton variant="rounded" height={150} width={"100%"} />
            ) : (
              <TopBrands brands={dashboardInfo?.brands} size={65} />
            )}
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};
export default OverviewNew;

import React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

const TagsLoader = () => {
  return (
    <Stack spacing={1}>
      <Box
        sx={{
          py: 0.5
        }}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Skeleton variant="rounded" height={40} />
      </Box>
      <Box
        sx={{
          py: 0.5
        }}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Skeleton variant="rounded" height={40} />
      </Box>
      <Box
        sx={{
          py: 0.5
        }}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Skeleton variant="rounded" height={40} />
      </Box>
    </Stack>
  );
};

export default TagsLoader;

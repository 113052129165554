import React, { useEffect, useState } from "react";
import { manyCharacters, palette, subjects } from "settings";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { createApiCall, ZENDESK_OAUTH_TOKEN } from "helpers/api";
import {
  CustomSelect,
  FieldEmail,
  FieldText,
  FieldTextarea
} from "components/fields";
import { useAuth } from "components/AuthProvider";

import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

export const GetInTouchBar = ({ open, setOpen, subdomain = "slidehelp" }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { session } = useAuth();
  const methods = useForm();

  const { handleSubmit, reset, setValue } = methods;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (session?.email) setValue("email", session.email);
  }, [session]);

  useEffect(() => {
    if (session) setValue("name", `${session.userName}`);
  }, [session]);

  const onSubmit = handleSubmit(({ name = "", email, subject, note }) => {
    setLoading(true);
    createApiCall({
      method: "POST",
      prefix: "",
      url: `https://${subdomain}.zendesk.com/api/v2/requests`,
      headers: {
        Authorization: `Bearer ${ZENDESK_OAUTH_TOKEN}`
      },
      data: {
        request: {
          subject,
          comment: { body: note },
          requester: {
            name,
            email
          }
        }
      }
    })
      .then(({ status, data }) => {
        setLoading(false);
        if (status === 201 || status === 200) {
          enqueueSnackbar(
            "Thank you for submitting your support ticket. We will be in touch.",
            {
              variant: "success"
            }
          );
          reset({
            email: session?.email || "",
            name: `${session?.userName}`,
            note: "",
            subject: ""
          });
        } else {
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
        }
      })
      .catch(() => {
        setLoading(false);
        enqueueSnackbar(
          "There was an issue submitting your ticket. Please try again.",
          {
            variant: "error"
          }
        );
      });
  });

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={() => setOpen(!open)}
      sx={{
        "& .MuiDrawer-paper": {
          background: palette.grayscale["100"]
        }
      }}
    >
      <Stack
        sx={{
          height: "100%"
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            p: 3,
            borderBottom: `1px solid ${palette.grayscale["200"]}`
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={"18px"}>
            <svg
              width={24}
              height={24}
              style={{ minWidth: 24 }}
              stroke={palette.primary.main}
            >
              <use href="#mail" />
            </svg>
            <Typography
              variant={"subLead"}
              color={palette.purple["900"]}
              fontWeight={700}
            >
              Get in touch
            </Typography>
          </Stack>
          <IconButton size={"small"} onClick={() => setOpen(false)}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use href="#close" />
            </svg>
          </IconButton>
        </Stack>
        <Stack
          component={"form"}
          onSubmit={onSubmit}
          noValidate
          sx={{
            p: 3,
            flex: "0 1 100%",
            overflowY: "auto",
            height: "100%"
          }}
        >
          <Stack gap={"16px"} paddingBottom={"40px"} sx={{ flexGrow: 1 }}>
            <CustomSelect
              {...methods}
              options={subjects}
              fieldName={"subject"}
              label={"Subject"}
            />
            <FieldText
              {...methods}
              fieldName={"name"}
              label={"Name"}
              placeholder={"Enter your name"}
              validation={{
                maxLength: {
                  value: 80,
                  message: manyCharacters
                }
              }}
            />
            <FieldEmail {...methods} readOnly={true} />
            <FieldTextarea
              {...methods}
              fieldName={"note"}
              rows={5}
              label={"Note"}
              placeholder={"Enter text here"}
            />
          </Stack>
          <Box mt={"auto"}>
            <LoadingButton
              mt={"auto"}
              loading={loading}
              fullWidth
              variant={"contained"}
              type={"submit"}
            >
              Submit
            </LoadingButton>
          </Box>
        </Stack>
      </Stack>
    </Drawer>
  );
};

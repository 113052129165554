import React, { useState } from "react";
import { palette } from "settings";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

const StyledMenu = styled((props) => (
  <Menu
    elevation={2}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    {...props}
  />
))(() => ({
  "& .MuiPaper-root": {
    "& .MuiMenu-list": {
      padding: "8px"
    },
    "& .MuiMenuItem-root": {}
  }
}));
export const BrandsOrderMenu = ({ option = "ASC", setOption }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (val) => {
    setOption(val);
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        aria-controls={anchorEl ? "brand-filter-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? "true" : undefined}
        variant="text"
        sx={{ padding: { xs: "4px 8px" } }}
        disableRipple
        onClick={handleClick}
        startIcon={
          <svg
            width={24}
            height={24}
            stroke={palette.primary.main}
            style={{
              transition: "0.3s",
              transform: option === "DESC" ? "rotate(180deg)" : "none"
            }}
          >
            <use xlinkHref={"#arrow-down"} />
          </svg>
        }
        endIcon={
          <svg
            width={24}
            height={24}
            stroke={palette.primary.main}
            style={{
              transition: "0.3s",
              transform: anchorEl ? "rotate(180deg)" : "none"
            }}
          >
            <use href="#cheveron-up" />
          </svg>
        }
      >
        {option === "DESC" ? "Z To A" : "A To Z"}
      </Button>
      <StyledMenu
        id="brand-filter-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => handleChange("ASC")}
          disableRipple
          sx={{ gap: "12px" }}
        >
          <svg width={24} height={24} stroke={palette.grayscale[700]}>
            <use xlinkHref={"#arrow-down"} />
          </svg>
          <Typography
            variant={"smallTitle"}
            color={"grayscale.800"}
            fontWeight={600}
          >
            A To Z
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => handleChange("DESC")}
          disableRipple
          sx={{ gap: "12px" }}
        >
          <svg
            width={24}
            height={24}
            stroke={palette.grayscale[700]}
            style={{ transform: "rotate(180deg)" }}
          >
            <use xlinkHref={"#arrow-down"} />
          </svg>
          <Typography
            variant={"smallTitle"}
            color={"grayscale.800"}
            fontWeight={600}
          >
            Z To A
          </Typography>
        </MenuItem>
      </StyledMenu>
    </>
  );
};

export const campaignFormTabs = [
  {
    id: 0,
    title: "Campaign name"
  },
  {
    id: 1,
    title: "Recipients"
  },
  {
    id: 2,
    title: "Redemption options"
  },
  {
    id: 3,
    title: "Details"
  },
  {
    id: 4,
    title: "Preview"
  }
];

export const radioOptions = [
  {
    id: 1,
    name: "tag",
    text: "Tag"
  },
  {
    id: 2,
    name: "email",
    text: "Email"
  }
];

export const amountFilters = [
  {
    id: 0,
    value: 5
  },
  {
    id: 1,
    value: 25
  },
  {
    id: 2,
    value: 50
  },
  {
    id: 3,
    value: 100
  },
  {
    id: 4,
    value: 500
  },
  {
    id: 5,
    value: 1000
  }
];

export const dayOptions = [
  { label: "Days", value: "days" },
  { label: "Weeks", value: "weeks" },
  { label: "Month", value: "months" }
];

export const deliveryMethodOptions = [
  {
    title: "Email",
    icon: "mail-open",
    value: "EMAIL"
  },
  {
    title: "SMS",
    icon: "chat-alt",
    value: "SMS"
  }
];

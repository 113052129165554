import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { midnightBlue, palette, sidebarWidth } from "settings";
import { FieldRangeDatePicker } from "components/fields";

import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MuiLink from "@mui/material/Link";

const defaultForm = {
  startDate: null,
  endDate: null
};
export const TimeframeModal = ({
  open,
  onClose,
  onFilter,
  defaultValues,
  filterParams
}) => {
  const methods = useForm({
    defaultValues
  });
  const { handleSubmit, reset, setValue } = methods;

  useEffect(() => {
    setValue("startDate", filterParams.date_range?.start || null);
    setValue("endDate", filterParams.date_range?.end || null);
  }, [filterParams]);

  const onSubmit = handleSubmit((data) => {
    onFilter(data);
  });
  const clearAllHandler = () => {
    onFilter(defaultForm);
    reset(defaultForm);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        background: "rgba(217,217,217,0.7)",
        backdropFilter: "blur(2px)",
        top: { xs: 0, md: "100px" },
        left: { xs: 0, md: sidebarWidth }
      }}
    >
      <DialogTitle>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ height: "35px" }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <svg
              width={24}
              height={24}
              stroke={palette.primary.main}
              style={{ minWidth: "24px" }}
            >
              <use href="#calendar" />
            </svg>
            <Typography
              variant={"subLead"}
              fontWeight={700}
              sx={{ ml: "12px" }}
              color={midnightBlue}
            >
              Set custom timeframe
            </Typography>
          </Stack>

          <IconButton size={"small"} onClick={onClose}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use href="#close" />
            </svg>
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ mt: "20px" }}>
        <form onSubmit={onSubmit} noValidate autoComplete="off">
          <FieldRangeDatePicker {...methods} direction={"column"} />
          <MuiLink
            component={"button"}
            type={"button"}
            underline="none"
            onClick={clearAllHandler}
            variant={"smallTitle"}
            sx={{ mt: "12px", width: "fit-content" }}
            color={palette.primary.main}
          >
            Clear All
          </MuiLink>
          <Button type={"submit"} fullWidth sx={{ mt: "36px" }}>
            Continue
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

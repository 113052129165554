import React, { useEffect, useMemo, useState } from "react";
import find from "lodash/find";
import some from "lodash/some";
import includes from "lodash/includes";
import filter from "lodash/filter";
import { format } from "date-fns";
import { fieldCannotBeEmpty, max32characters, palette, roles } from "settings";
import { useForm } from "react-hook-form";
import {
  CustomSelect,
  FieldEmail,
  FieldPhone,
  FieldText
} from "components/fields";
import { useSnackbar } from "notistack";
import { useAuth } from "components/AuthProvider";
import { createApiCall } from "helpers/api";

import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import LoadingButton from "@mui/lab/LoadingButton";

export const EditUser = ({ onClose, user, onSubmitted }) => {
  const { session } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm({
    defaultValues: { ...(user || {}) }
  });
  const { handleSubmit, reset, setValue } = methods;
  const [loading, setLoading] = useState(false);
  const [checkedStatus, setCheckedStatus] = useState(false);

  const isAbleToChangeRole = useMemo(() => {
    if (
      includes(session?.roles, "CLIENT_MANAGER") &&
      (includes(user?.roleNames, "CLIENT_ADMIN") ||
        includes(user?.roleNames, "CLIENT_OWNER"))
    ) {
      return false;
    }
    return !includes(session?.roles, "CLIENT_AGENT");
  }, [user, session]);

  const filteredRoles = useMemo(() => {
    if (includes(session?.roles, "CLIENT_MANAGER") && isAbleToChangeRole) {
      return filter(
        roles,
        (role) =>
          role.value === "CLIENT_MANAGER" || role.value === "CLIENT_AGENT"
      );
    }
    return roles;
  }, [session, isAbleToChangeRole]);

  useEffect(() => {
    if (user) {
      const {
        phoneNumber,
        active,
        roleNames,
        firstName,
        lastName,
        email,
        uuid
      } = user;
      reset({
        uuid,
        firstName,
        lastName,
        active,
        roleNames,
        email
      });
      setValue("phoneNumber", phoneNumber ? `+1${phoneNumber}` : "");
      setCheckedStatus(active || false);
      setValue(
        "role",
        find(roles, (role) =>
          some(roleNames, (userRole) => userRole === role.value)
        )?.value
      );
      return;
    }
    reset({});
    setValue("phoneNumber", "");
    setCheckedStatus(false);
    setValue("role", undefined);
  }, [user]);

  const onSubmit = handleSubmit(
    ({ role, createdAt, updatedAt, uuid, password, ...rest }) => {
      setLoading(true);
      createApiCall({
        url: `user-management/users/${uuid}`,
        method: "PATCH",
        data: {
          ...rest,
          active: checkedStatus,
          phoneNumber: rest.phoneNumber?.replace(/[ \-()+_]/g, "")?.slice(1)
        }
      }).then(({ data, status }) => {
        if (status === 200) {
          enqueueSnackbar("User updated", { variant: "success" });
          const bindRole = find(roles, (role) =>
            some(user?.roleNames, (roleName) => roleName === role.value)
          )?.value;
          if (!!role && !!bindRole && role !== bindRole) {
            grantRevokeRole(role, bindRole);
          } else if (!bindRole && !!role) {
            grantRevokeRole(role);
          } else {
            setLoading(false);
            onSubmitted();
          }
        } else {
          enqueueSnackbar(data?.title || "Something went wrong", {
            variant: "error"
          });
          setLoading(false);
        }
      });
    }
  );

  const grantRevokeRole = (grantRole, revokeRole) => {
    createApiCall({
      url: `user-management/users/${user.uuid}/roles`,
      method: "POST",
      data: {
        roleName: grantRole
      }
    }).then(({ data, status }) => {
      if (status === 200 && !revokeRole) {
        onSubmitted();
        setLoading(false);
      } else if (status === 200 && revokeRole) {
        createApiCall({
          url: `user-management/users/${user.uuid}/roles/${revokeRole}`,
          method: "DELETE"
        }).then(({ data, status }) => {
          setLoading(false);
          if (status === 200) {
            onSubmitted();
          } else {
            enqueueSnackbar(data?.title || "Something went wrong", {
              variant: "error"
            });
          }
        });
      } else {
        setLoading(false);
        enqueueSnackbar(data?.title || "Something went wrong", {
          variant: "error"
        });
      }
    });
  };

  return (
    <Drawer open={!!user} onClose={onClose}>
      <Stack sx={{ height: "100%" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            p: 3,
            borderBottom: `1px solid ${palette.grayscale["200"]}`
          }}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
            <svg width={24} height={24} color={palette.purple["500"]}>
              <use href="#clip" />
            </svg>
            <Typography
              variant={"subLead"}
              fontWeight={700}
              color={"purple.900"}
            >
              Edit User
            </Typography>
          </Stack>
          <IconButton size={"small"} onClick={onClose}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use href="#close" />
            </svg>
          </IconButton>
        </Stack>
        <Stack
          component={"form"}
          onSubmit={onSubmit}
          noValidate
          sx={{
            p: 3,
            flex: "0 1 100%",
            overflowY: "auto",
            height: "100%"
          }}
        >
          <Stack gap={"24px"} paddingBottom={"40px"} sx={{ flexGrow: 1 }}>
            <CustomSelect
              {...methods}
              options={filteredRoles}
              fieldName={"role"}
              label={"Role"}
              disabled={!isAbleToChangeRole}
            />
            <FieldText
              {...methods}
              fieldName={"firstName"}
              label={"First Name"}
              placeholder={"Enter first name"}
              validation={{
                required: fieldCannotBeEmpty,
                maxLength: {
                  value: 32,
                  message: max32characters
                }
              }}
            />
            <FieldText
              {...methods}
              fieldName={"lastName"}
              label={"Last Name"}
              placeholder={"Enter last name"}
              validation={{
                required: fieldCannotBeEmpty,
                maxLength: {
                  value: 32,
                  message: max32characters
                }
              }}
            />
            <FieldEmail {...methods} label={"Email"} />
            <FieldPhone
              {...methods}
              label={"Phone Number"}
              fieldName={"phoneNumber"}
            />
            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent={"space-between"}
              gap={1}
            >
              <Typography variant={"subLead"} color={palette.grayscale["800"]}>
                Created At
              </Typography>
              <Typography variant={"subLead"} color={palette.grayscale["800"]}>
                {user?.createdAt
                  ? format(
                      new Date(user.createdAt),
                      "MM/dd/yyyy @ HH:mm:ss a"
                    ) + " CDT"
                  : "-"}
              </Typography>
            </Stack>
            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent={"space-between"}
              gap={1}
            >
              <Typography variant={"subLead"} color={palette.grayscale["800"]}>
                Updated At
              </Typography>
              <Typography variant={"subLead"} color={palette.grayscale["800"]}>
                {user?.updatedAt
                  ? format(
                      new Date(user.updatedAt),
                      "MM/dd/yyyy @ HH:mm:ss a"
                    ) + " CDT"
                  : "-"}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography
                fontWeight={700}
                variant={"subLead"}
                color={palette.grayscale["800"]}
              >
                User status is{" "}
                <span style={{ color: palette.primary.main }}>
                  {" "}
                  {checkedStatus ? "enabled" : "disabled"}
                </span>
              </Typography>
              <Switch
                checked={checkedStatus}
                onChange={() => setCheckedStatus(!checkedStatus)}
              />
            </Stack>
            <Typography
              mt={"-16px"}
              variant={"subLead"}
              color={palette.grayscale["600"]}
            >
              Active users are able to manage campaigns and have access to the
              dashboard and reports.
            </Typography>
          </Stack>
          <LoadingButton
            sx={{ mt: "auto" }}
            loading={loading}
            fullWidth
            variant={"contained"}
            type={"submit"}
          >
            Confirm
          </LoadingButton>
        </Stack>
      </Stack>
    </Drawer>
  );
};

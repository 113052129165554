import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import _forIn from "lodash/forIn";
import { midnightBlue, palette } from "settings";
import { defaultFilters, recipientStatus } from "components/pages/portal";
import { FieldTagsAutocomplete, FieldText } from "components/fields";
import _isEqual from "lodash/isEqual";

import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

export const FilterRecipients = ({ open, onClose, onFilter, filterParams }) => {
  const methods = useForm();
  const {
    handleSubmit,
    reset,
    setValue,
    formState: { dirtyFields },
    control
  } = methods;

  useEffect(() => {
    if (filterParams && open) {
      _forIn(filterParams, function (value, key) {
        setValue(key, value || "", {
          shouldDirty: true
        });
      });
    }
  }, [filterParams, open]);

  const isDirtyForm =
    Object.keys(dirtyFields)?.length > 0 ||
    !_isEqual(defaultFilters, filterParams);

  const onSubmit = handleSubmit((data) => {
    onFilter(data);
  });

  const onClear = () => {
    reset(defaultFilters);
    onFilter(defaultFilters);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"md"}
      scroll={"paper"}
      sx={{
        ".MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.5)"
        },
        ".MuiPaper-root": {
          margin: { xs: 0, sm: 4 },
          borderRadius: { xs: 0, sm: 2 },
          minHeight: { xs: "100vh", sm: "auto" },
          width: "100%"
        }
      }}
    >
      <DialogTitle>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <svg
              width={24}
              height={24}
              stroke={palette.primary.main}
              style={{ minWidth: "24px" }}
            >
              <use href="#filter-new" />
            </svg>
            <Typography
              variant={"subLead"}
              fontWeight={700}
              sx={{ ml: "12px" }}
              color={midnightBlue}
            >
              Filter
            </Typography>
          </Stack>

          <IconButton size={"small"} onClick={onClose}>
            <svg width={24} height={24} stroke={palette.grayscale["700"]}>
              <use href="#close" />
            </svg>
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <form
          style={{ paddingTop: "12px" }}
          onSubmit={onSubmit}
          noValidate
          autoComplete="off"
        >
          <Stack gap={"16px"}>
            <Box>
              <FieldText
                {...methods}
                type={"text"}
                fieldName={"firstName"}
                label={"First name"}
                validation={{}}
                showOptional={false}
              />
            </Box>
            <Box>
              <FieldText
                {...methods}
                type={"text"}
                fieldName={"lastName"}
                label={"Last name"}
                validation={{}}
                showOptional={false}
              />
            </Box>
            <Box>
              <FieldText
                {...methods}
                type={"text"}
                fieldName={"email"}
                label={"Email"}
                validation={{}}
                showOptional={false}
              />
            </Box>
            <Box>
              <FieldTagsAutocomplete {...methods} rules={{}} />
            </Box>
            {/*wait BE */}
            <Box>
              <FormControl>
                <Typography
                  variant={"body1"}
                  color={"grayscale.800"}
                  fontWeight={700}
                  sx={{ mb: "6px" }}
                >
                  Status
                </Typography>
                <Controller
                  control={control}
                  name="disabled"
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      row
                      sx={{
                        gap: "8px"
                      }}
                    >
                      {recipientStatus.map(({ value, label }) => (
                        <FormControlLabel
                          value={value}
                          key={value}
                          control={<Radio disableRipple />}
                          label={
                            <Typography
                              variant={"subLead"}
                              color={palette.grayscale["600"]}
                            >
                              {label}
                            </Typography>
                          }
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Box>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: 3, flexDirection: "column", gap: "16px" }}>
        {isDirtyForm && (
          <Button variant={"text"} onClick={onClear}>
            Clear all
          </Button>
        )}
        <Button fullWidth onClick={onSubmit}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import React, { forwardRef, useCallback, useMemo } from "react";
import { useSnackbar, SnackbarContent } from "notistack";
import { palette, redAlert } from "settings";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

export const Snackbar = forwardRef(({ id, message, variant, ...rest }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const colors = useMemo(() => {
    if (variant === "success") {
      return {
        color: palette.seaGreen["700"],
        background: palette.seaGreen["100"]
      };
    }
    if (variant === "info") {
      return {
        color: "#018189",
        background: "#D9FCFE"
      };
    }
    // type === "error"
    return {
      color: redAlert,
      background: palette.vibrantYellow["100"]
    };
  }, [variant]);

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        spacing={"6px"}
        sx={{
          p: 1.5,
          width: "100%",
          maxWidth: { md: 500 },
          background: colors.background
        }}
      >
        <Stack direction={"row"} alignItems={"center"} spacing={"6px"}>
          <Box
            sx={{
              minWidth: 24,
              height: 24,
              color: colors.color
            }}
          >
            {variant === "success" && (
              <svg width={24} height={24}>
                <use href="#check-circle" />
              </svg>
            )}
            {variant === "error" && (
              <svg width={24} height={24}>
                <use href="#exclamation" />
              </svg>
            )}
            {variant === "info" && (
              <svg width={24} height={24}>
                <use href="#information-circle" />
              </svg>
            )}
          </Box>
          <Typography variant="body2" color={colors.color}>
            {message}
          </Typography>
        </Stack>
        <IconButton
          size="small"
          disableRipple
          onClick={handleDismiss}
          sx={{
            p: 0,
            color: colors.color
          }}
        >
          <svg width={24} height={24}>
            <use href="#circle-close" />
          </svg>
        </IconButton>
      </Stack>
    </SnackbarContent>
  );
});
